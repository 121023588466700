<template>
    <div class="side-popup"
         :class="{
              'right': position === 'right',
              'left': position === 'left',
              'center': position === 'center',
              'visible': visible,
              'minimized': minimized
        }">
        <div :class="[`popup-icon ${iconClass}`, {
            'minimizable': canMinimize
        }]"
             @click="toggleState()"
        >

            <div class="icon-container">
                <i
                    v-if="canMinimize && position !== 'center'"
                    class="bx bx-chevron-right arrow-icon">

                </i>
                <slot name="icon" v-if="$slots.icon"></slot>
                <i class="bx bx-check"
                   v-if="!$slots.icon"
                >
                </i>
            </div>
        </div>

        <span class="popup-text">
            <slot name="text" v-if="$slots.text"></slot>
        </span>
            <i
                class="bx bx-x close-icon"
                @click="$emit('update:visible', false)"
            ></i>

    </div>
</template>

<script>
export default {
    name: "FeedbackPopup",
    props: {
        visible: {
            type: Boolean,
        },
        position: {
            type: String,
            default: "right"
        },
        iconClass: {
            type: String,
            required: false
        },
        canMinimize: {
            type: Boolean,
            required: false,
            default: false
        },
        minimized: {
            type: Boolean
        }
    },
    emits: [
        'update:visible',
        'update:minimized'
    ],
    /*data() {
        return {
            minimized: false
        }
    },*/
    methods: {
        toggleState() {
            if (this.canMinimize && this.position !== 'center') {
                this.$emit('update:minimized', !this.minimized)
            }
        }
    }
}
</script>

<style scoped>
.side-popup{
    position: fixed;
    background: var(--white);
    box-shadow: 0 0 2px 2px rgba(36, 88, 141, .15);
    width: 400px;
    display: flex;
    align-items: center;
    max-width: calc(100% - 1rem);
    transition: ease-in-out .3s;
    z-index: 9999;
}

.side-popup.right,
.side-popup.left{
    top: 75px;
}

.side-popup.center{
    border-radius: 8px;
    left: 50%;
    top: -100px;
    transform: translateX(-50%);
    padding: .85rem .75rem;
}

.center.visible{
    top: 50px;
}

.side-popup.center .popup-icon{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    flex: 0 0 auto;
    font-size: 22px;
    margin-right: 1rem;
}

.side-popup.right,
.side-popup.right .popup-icon{
    border-radius: 8px 0 0 8px;
}

.side-popup.right{
    right: 0;
    transform: translateX(100%);
}

.side-popup.left{
    flex-direction: row-reverse;
    left: 0;
    transform: translateX(-100%);
}

.side-popup:not(.center).visible{
    transform: translateX(0);
}

.side-popup.right.minimized{
    transform: translateX(calc(100% - 50px));
}

.side-popup.left.minimized{
    transform: translateX(calc(-100% + 50px));
}

.side-popup.left,
.side-popup.left .popup-icon{
    border-radius: 0 8px 8px 0;
}

.side-popup.left .popup-icon,
.side-popup.right .popup-icon{
    min-height: 50px;
    height: 100%;
    width: 50px;
    position: absolute;
    top: 0;
    font-size: 24px;
    transition: ease-in-out .15s;
}

.side-popup.right .popup-icon{
    left: 0;
}

.side-popup.left .popup-icon{
    right: 0;
}


.side-popup .popup-icon.minimizable:hover {
    opacity: .85;
    cursor: pointer;
}
.popup-icon .arrow-icon{
    font-size: 20px;
    font-weight: 700;
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
}

.side-popup.left .popup-icon .arrow-icon{
    transform: translateX(-50%) rotate(180deg);
}

.side-popup.right.minimized .popup-icon .arrow-icon{
    transform: translateX(-50%) rotate(180deg);
}

.side-popup.left.minimized .popup-icon .arrow-icon{
    transform: translateX(-50%) rotate(0deg);
}

.popup-icon .icon-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    color: var(--white);
    transition: ease-in-out .25s;
    position: relative;
}


.popup-text{
    font-size: .8rem;
    line-height: 1.1rem;
    display: block;
    white-space: initial;
    width: 100%;
}

.side-popup.right .popup-text{
    padding: .85rem .75rem .85rem 60px;
}
.side-popup.left .popup-text{
    padding: .85rem 60px .85rem .75rem;
}

.close-icon{
    font-size: 24px;
    padding: 0 .5rem;
    color: #707070;
    opacity: .75;
    transition: ease-in-out .3s;
}

.close-icon:hover{
    opacity: 1;
    cursor: pointer;
}


</style>

