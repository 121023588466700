<template>
    <div
            class="modal modal-center source-modal"
            v-if="modalState"
            :class="{
                  'modal-open': addAnimationClass
                }"
            >
        <div
            class="modal-content padding-md br-mid"
            ref="source_modal_content"
        >
            <div class="modal-header flexbox">
                <i class="bx bx-arrow-back prev-arrow modal-arrow-icon" v-if="source_stage === 1" @click="moveToInitStage()"></i>
                <i class="bx bx-x close-icon" @click="closeSourceModal()"></i>
            </div>

            <div class="init-stage" ref="init_stage" v-if="source_stage === 0">
                <header class="text-center bold-text y-margin-md">
                    How do you want to assign your lead cost?
                </header>

                <div>

                    <div ref="note_container" class="note-container centered-container">
                        <div @click="show_note_text = !show_note_text" ref="note_line" class="flexbox flex-align-center note-line">
                            <img src="../assets/icons/light-bulb.svg" class="light-bulb" alt="light bulb"/>
                            <span>Note</span>
                            <i  class="bx bx-chevron-down arrow-icon"
                                :class="{
                      'open': show_note_text
                   }"
                            ></i>
                        </div>

                        <div class="note-content text-center" ref="note_content">
                            If you know how much each lead costs (for example, you are buying
                            from a lead provider), then click "Assign Lead Cost”. If you know
                            how much money you spent to generate these leads but do not know
                            your actual lead cost (for example, you are running advertising campaigns),
                            then click “Calculate Lead Cost".
                        </div>
                    </div>

                    <div class="flexbox flex-justify-center source-action-buttons y-margin-md">
                        <button class="button lead-cost-btn button-blue right-margin-sm" @click="moveToNextStage('assign')">Assign lead cost</button>
                        <button class="button lead-cost-btn button-blue-alt" @click="moveToNextStage('calculate')">Calculate lead cost</button>
                    </div>
                </div>
            </div>

            <source-cost-generator
                    :source-cost-data="sourceCostData"
                    :source-pipeline-id="pipelineId"
                    :location="locationId"
                    :source="sourceName"
                    @sourceGenerated="sourceGenerated($event)"
                    :lead-cost-type="lead_cost_type"
                    v-if="source_stage === 1"
                    ref="source_stage">
            </source-cost-generator>
        </div>
    </div>
</template>

<script>
import SourceCostGenerator from "./SourceCostGenerator.vue";
import {getCurrentDate, getLastMonthDate} from "../helpers/date_functions";
import Velocity from "velocity-animate";
import _ from "lodash";

export default {
    name: "LeadCostModal",
    components: {SourceCostGenerator},
    props: {
        visible: {
            type: Boolean,
        },
        locationId: {
            type: String,
            required: true
        },
        dateRange: {
            required: true,
        },
        pipelineId: {
            type: String,
            required: true
        },
        reportType: {
            type: String,
            required: true
        },
        sourceName: {
            type: String,
            required: true
        }

    },
    emits: [
        'update:visible',
        'sourceGenerated'
    ],
    mounted() {
        this.open = this.visible;
        this.setState(this.open);
        document.addEventListener('click', this.detectClick)
    },
    unmounted() {
        document.removeEventListener('click', this.detectClick)
    },
    watch: {
        visible(state) {
            this.setState(state)
        },
        show_note_text(state) {
            let note_line = this.$refs.note_line, note_content =  this.$refs.note_content;

            if (note_line && note_content) {
                let note_line_height = note_line.offsetHeight,
                    note_content_height = note_content.offsetHeight,
                    margin = getComputedStyle(this.$refs.note_content).marginTop;

                state ?  this.animateNoteHeight(note_line_height + note_content_height + (parseInt(margin) * 2)):
                    this.animateNoteHeight(note_line_height);
            }
        },

        dateRange: {
            deep: true,
            handler(range) {
                if (range && range.from && range.to) {
                    this.costFields.fields = this.updateDateFields(this.costFields.fields, range)

                    if (this.sourceCostData.fields) {
                        this.sourceCostData.fields = this.updateDateFields(this.sourceCostData.fields, range)
                    }
                }
            }
        }
    },
    computed: {
        modalState() {
            return this.visible ? this.visible : this.open
        }
    },
    data() {
        return {
            open: false,
            source_stage: 0,
            show_note_text: false,
            addAnimationClass: false,
            costFields:  {
                    fields: [
                        {
                            type: "date",
                            label: "Start date",
                            value: this.dateRange && this.dateRange.from ? _.cloneDeep(this.dateRange).from.replace(/-/g, "/") : getLastMonthDate(),
                            api_key: 'fromDate',
                        },

                        {
                            type: "date",
                            label: "End date",
                            open: false,
                            value: this.dateRange && this.dateRange.to ? _.cloneDeep(this.dateRange).to.replace(/-/g, "/") : getCurrentDate(),
                            api_key: 'toDate'
                        },

                        {
                            type: "input",
                            label: "Lead cost ($)",
                            open: false,
                            value: '',
                            api_key: 'sourceCost'
                        }
                    ]
            },
            sourceCostData: {},
            assignData: {
                heading: "Enter lead cost",
                type: "assign_lead_cost",
                button_text: "Assign Lead Cost",
            },
            calculateData: {
                heading: "Enter your total spend",
                type: "calculate_lead_cost",
                button_text: "Calculate lead cost",
            },
            lead_cost_type: "assign"
        }
    },
    methods: {
        setState(state) {
            let timeout = state ? 0 : 300;

            if (!state) {
                this.addAnimationClass = state
            }

            setTimeout(() => {
                this.open = state;
                if (state) {
                    this.addAnimationClass = state;
                    this.source_stage = 0;
                    this.sourceCostData = {};
                    this.show_note_text = false;
                }
            }, timeout)
        },

        animateNoteHeight(height) {
            let note_container = this.$refs.note_container;
            Velocity(note_container,
                {
                    height: height
                }, 400)
        },

        moveToNextStage(type) {
            this.lead_cost_type = type;
            this.source_stage = 1;
            this.show_note_text = false;

            let fieldData = type === 'assign' ? _.cloneDeep(this.assignData) : _.cloneDeep(this.calculateData)

            this.sourceCostData = {
                ...fieldData,
                ..._.cloneDeep(this.costFields)
            }


        },
        sourceGenerated() {
            this.$emit('sourceGenerated');
            this.closeSourceModal();
        },

        moveToInitStage() {
            this.source_stage = 0;
        },
        closeSourceModal() {


            this.$emit('update:visible', false)

        },

        detectClick(event) {
            let elementClasses = [
                '.source-modal .modal-content',
                '.lead-cost-btn',
                '.modal-arrow-icon'
            ]

            let matchedTargets = elementClasses.filter(elementClass => event.target.closest(elementClass));

            if (this.open && matchedTargets.length === 0) {
                this.closeSourceModal()
            }
        },


        updateDateFields(fields, range) {
            range = _.cloneDeep(range)
            return fields.map(field => {
                if (field.api_key === 'fromDate') {
                    field.value = range.from.replace(/-/g, "/")
                }

                if (field.api_key === 'toDate') {
                    field.value = range.to.replace(/-/g, "/")
                }

                return field
            })
        }
    }

}
</script>

<style scoped>
.modal.source-modal .modal-content{
    background: var(--white);
    width: 600px;
    overflow-x: hidden;
    position: relative;
    transition: ease-in-out .3s;
    transform: scale(1.1);
    opacity: 0;
    /*min-height: 290px;*/
}

.source-modal.modal-open .modal-content{
    transform: scale(1);
    opacity: 1;
}

.source-modal .modal-header .prev-arrow,
.source-modal .modal-header .close-icon{
    color: var(--theme-blue-alt);
}

.source-modal .modal-header .prev-arrow:hover{
    transform: scale(1.1);
    color: var(--theme-blue-alt);
    cursor: pointer;
}

.source-modal .modal-header .prev-arrow{
    font-size: 20px;
    font-weight: 700;
}

.source-modal header{
    font-size: 1.05rem;
}

.note-container{
    width: calc(100% - 5rem);
    overflow-y: hidden;
    height: 24px;
}

.note-line .light-bulb{
    color: var(--theme-blue);
    margin-right: .85rem;
    width: 22px;
    height: 22px;
    filter: brightness(0) saturate(100%) invert(54%) sepia(81%) saturate(1371%) hue-rotate(187deg) brightness(93%) contrast(85%);
}

.note-line{
    cursor: pointer;
}
.note-line span{
    font-size: .9rem;
}

.note-line .arrow-icon{
    font-size: 24px;
    margin-left: auto;
    transition: ease-in-out .2s;
}

.note-line .arrow-icon.open{
    transform: rotate(180deg);
}

.note-content{
    font-size: .9rem;
    line-height: 1.5rem;
    color: rgba(0, 0, 0, .85);
    margin: 1.5rem 0;
}

</style>
