<template>
  <div class="sidebar" :class="{'open': this.sidebar_open, 'closed': !this.sidebar_open}" ref="sidebar_main">
      <div class="sidebar-content" ref="sidebarContent">
        <router-link
            v-for="(item, index) in sidebar_items"
            :key="item.id"
            class="sidebar-item"
            :class="{'active-item': active_index === index}"
            :ref="'sidebar_item_'+index"
            :to="'/'+item.link_url"
            @click="active_index = index"
            >

          <img src="../assets/icons/pie_chart.svg" v-if="item.name === 'Reports'" class="item-icon" alt="Reports" />
          <img src="../assets/icons/eye_icon.svg"  v-if="item.name === 'Visualization'" class="item-icon" alt="Visualization" />
          <img src="../assets/icons/server_icon.svg" v-if="item.name === 'Field management'" class="item-icon" alt="Server">
          <img src="../assets/icons/space_rocket.svg" v-if="item.name === 'Setup'" class="item-icon" alt="Space rocket" />

          <div class="item-text text-md">{{ item.name }}</div>
          <img v-if="item.has_dropdown" src="../assets/icons/arrow_down.svg" class="item-arrow-icon" alt="Arrow down"/>

        </router-link>


          <div @click="sidebar_open = !sidebar_open" class="sidebar-toggle-icon" ref="sidebar_toggle">
            <transition name="rotate">
              <i v-if="!sidebar_open && show_open_arrow" class="bx bx-chevrons-right"></i>
            </transition>

            <transition name="backward">
              <i v-if="sidebar_open && !show_open_arrow" class="bx bx-chevrons-left"></i>
            </transition>

          </div>



      </div>


  </div>
</template>

<script>

import Velocity from 'velocity-animate';

export default {
  name: "Sidebar",
  props: {
    location: String
  },
  mounted() {
    setTimeout(() => this.sidebar_items.map((item, index) => item.name === this.$route.name ? this.active_index = index : null), 1000);

  },
  data() {
    return {
      sidebar_open: false,
      show_open_arrow: true,
      active_index: null,
      sidebar_items: [
        {
          name: "Reports",
          has_dropdown: true,
          dropdown_content: [],
          link_url: `?location_id=${this.location}`
        },

        {
          name: 'Visualization',
          has_dropdown: true,
          dropdown_content: [],
          link_url: `visualization?location_id=${this.location}`
        },

        {
          name: 'Field management',
          has_dropdown: false,
          link_url: 'field-management'
        },

        {
          name: 'Setup',
          has_dropdown: false,
          link_url: `setup?location_id=${this.location}`
        }
      ]
    }
  },
  watch: {
    sidebar_open() {
      this.sidebar_open ? this.$emit('currentState', this.sidebar_open) : null;
      const width_value = this.sidebar_open ? 260 : 65;
      this.animateSidebar(width_value).then(() => {
        !this.sidebar_open ? this.$emit('currentState', this.sidebar_open) : null;
      });

      setTimeout(() => {
        width_value === 260 ? this.show_open_arrow = false : this.show_open_arrow = true;
      }, 320)
    },

    location() {
      console.log('Location changed to:', this.location)
    }
  },
  methods: {
    async animateSidebar(width) {
      Velocity(this.$refs['sidebarContent'], {width: width}, {
        duration: 500
      });

      await Velocity(this.$refs.sidebar_main, {width: width}, {
        duration: 500
      });
    }


  }
}
</script>

<style scoped>
@import url('../assets/css/sidebar.css');
</style>


// https://prod-reports.ordrmtv.com/api/source_names_get/?location_id=j7wq94XmsDYIOK2KjQN9
