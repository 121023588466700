import {defineStore} from "pinia";
import _ from "lodash";
import {
    capitalizeFirstLetters,
    formatKeyToUnderScore,
    formatString,
    parseValue,
    removeSpecialChars,
    replaceSlashes,
    replaceSpaceWithUnderscores,
    splitIntoCommas
} from "../helpers/helper_functions";
import {percentFields} from "../helpers/field_data_types";
import {/*getNextDay,*/ presetValues, setTimeValue} from "../helpers/date_functions";
import axios from "axios";
import {baseURL} from "../api/apiBase";
import html2canvas from "html2canvas";
import {evaluate} from "mathjs";
import {v4 as uuidv4} from "uuid";

export const useStore = defineStore('dataStore', {
    state: () => (
        {
            savedMetrics: {
                locations: [
                    /* {
                         id: '',
                         reports: [
                             {
                             id: '',
                             starred: {
                                 source: [],
                                 salesrep: [],
                                 tag: []
                             },
                             filterState: {
                                 source: {
                                     metrics: [],
                                     breakdown: []
                                 },
                                 salesrep: {
                                     metrics: [],
                                     breakdown: []
                                  },
                                  tag: {
                                     metrics: [],
                                     breakdown: []
                                  }
                             }
                         }
                         ]
                     }*/ // Default data format
                ]
            },
            summaryMetrics: ["Won", "Revenue", "Close Rate", "Average Gross Revenue", "Total Spent", "Average Cost Per Sale", "Profit", "ROI"],
            leadMetrics: ["Total Leads (Created)", "Total Leads (Modified)", "Lead Cost (Created)", "Lead Cost (Modified)", "Average Lead Cost (Created)", "Average Lead Cost (Modified)"],
            combinedMetrics: [],
            coloredColumns: [ ],
            averageColumns: ['lead_cost'],
            opportunityKeys: ['modifiedOpportunities', 'createdOpportunities'],
            noParentRowText: 'No tag parent',
            hidden_rows: [],
            dateObject: {
                days: "",
                hours: "",
                minutes: "",
                seconds: "",
                totalInMilliseconds: 0
            },
            countKeys: [ 'total_lead', 'won', 'lead' ],
            apiSavedMetrics: {},
            savedReports: [],
            reportTemplates: [],
            locations: [],
            locationFields: [
                {
                    type: 'text',
                    value: '',
                    name: 'templateName',
                    required: true,
                    label: 'Template name',
                    errorText: '',
                    placeholder: 'Name of the template',
                    hasError: false,
                    fieldType: 'input'
                }
            ],
            agencyFields: [
                {
                    fieldType: 'textarea',
                    name: 'description',
                    hasError: false,
                    errorText: '',
                    required: false,
                    label: "Description",
                    type: 'text',
                    value: '',
                    placeholder: 'Write a description of the template...',
                },
                {
                    fieldType: 'input',
                    name: 'imageUrl',
                    hasError: false,
                    errorText: '',
                    required: false,
                    label: "Image URL",
                    type: 'text',
                    value: '',
                    placeholder: 'Image URL'
                },
                {
                    type: 'text',
                    value: '',
                    name: 'videoUrl',
                    required: false,
                    label: 'Video URL',
                    errorText: '',
                    placeholder: 'Link to video',
                    hasError: false,
                    fieldType: 'input'
                }
            ],
            locationField: {
                value: [],
                fieldType: 'locationSelector',
                required: true,
                label: 'Locations',
                hasError: false,
                errorText: '',
                name: 'sharedLocations'
            },
            reportOptionFields: [
                {
                    name: 'saveAsReport',
                    value: false,
                    required: false,
                    label: 'Add as a new saved report in selected locations',
                    fieldType: 'checkbox',
                    note: 'Checking this option will add the template to the locations you have ' +
                        'selected and also run a background process that automatically ' +
                        'creates a new saved report in each location ' +
                        'using the template. Check the option below if you want the created' +
                        ' report to become the default report in those locations.',
                    visible: true
                },
                {
                    name: 'overrideDefaults',
                    value: false,
                    required: false,
                    label: 'Overwrite location default report',
                    fieldType: 'checkbox',
                    visible: true
                },
                {
                    name: 'removeFilters',
                    value: false,
                    required: false,
                    label: 'Do not use filters from saved report',
                    fieldType: 'checkbox',
                    note: 'This report has filters applied to it. This may lead to no results being displayed for the auto-created report ' +
                        'in the selected locations if the applied filters do not exist in these locations. Checking this option will not apply the filters ' +
                        'in the auto-created report.',
                    visible: false
                },
            ],
            reportFields: [ 'source', 'salesrep', 'tag', 'tag_parent' ],
            autoCreateState: {
                creating: false,
                processed: 0,
                total: 0
            },
            rightTurnAutoID: 'DiAO1ta8lTtZ6EnRO1WV',
            agencyLocationID: '',
            builtInStages: ['index', 'name', 'id'],
            pipelines: [],
            overviewColors: {
                background: '#FFFFFF',
                box: '#FFFFFF',
                header_and_label: '#000000',
                text: '#000000',
                metric: '#D8804C'
            }
        }
    ),
    getters: {
        getFilterTemplate() {
            return  {
                source: {
                    metrics: [],
                    breakdown: []
                },
                salesrep: {
                    metrics: [],
                    breakdown: []
                },
                tag: {
                    metrics: [],
                    breakdown: []
                }
            }
        },
    },
    actions: {
        getLocationName(locationID) {
            let name = '';
            let locations = this.locations;
            if (locations && Array.isArray(locations)) {
                let locationData = locations.find(location => location.id === locationID);
                if (locationData) {
                    name = locationData.name;
                }
            }

            return name
        },

        addPipelines(locationID, pipelines) {
            let locationPipeline = this.pipelines.find(pipeline => pipeline['locationId'] === locationID);
            if (locationPipeline) {
                let index = this.pipelines.indexOf(locationPipeline);
                if (index === -1) {
                    return
                }

                this.pipelines[index].pipelines = pipelines
            } else {
                this.pipelines = [
                    ...this.pipelines,
                    {
                        locationId: locationID,
                        pipelines
                    }
                ]
            }
        },

        getPipelineData(locationID, pipelineID) {
            let existingPipeline = this.pipelines.find(pipeline => pipeline && pipeline['locationId'] === locationID);
            if (!existingPipeline) {
                return null
            }

            let pipeline = existingPipeline.pipelines.find(pipeline => pipeline.id === pipelineID);
            return pipeline ? pipeline : null
        },

        createMetricFormat(metrics, filterItems, unchecked = []) {

            let dataFormat = {
                stages: [],
                timeToClose: null
            };
            filterItems.forEach(filterItem => {
               if (unchecked.indexOf(filterItem.column_name) === -1) {
                   if (filterItem.dropdown.length === 0) {
                       let metricKey = this.formatObjectKey(filterItem.column_name);
                       dataFormat[metricKey] = getMetricValue(filterItem.column_name)
                   } else {
                       let apiData = {
                           index: filterItem.index,
                           id: filterItem.id ? filterItem.id : '',
                           name: filterItem.label
                       };

                           if (filterItem.column_name === 'new_lead') {
                                apiData.conversionRate = null;
                                apiData.totalConversionRate = null;
                           }

                           apiData.index = filterItem.index;

                           filterItem.dropdown.forEach(dropdownItem => {
                               let objectKey = dropdownItem.dataKey ? dropdownItem.dataKey : this.formatObjectKey(dropdownItem.column_name);
                                apiData[objectKey] = getMetricValue(dropdownItem.column_name);
                           })

                           dataFormat.stages = [
                               ...dataFormat.stages,
                               apiData
                           ]
                   }
               }
            })

            function getMetricValue(metricField) {
                let savedMetrics = metrics.filter(metric => metric.column_name === metricField);
                return savedMetrics.length > 0 ? savedMetrics.map(metric => {
                    return metric.selected ? metric.selected.label.toLowerCase() : null
                }) : null
            }

            return {
                savedMetrics: dataFormat
            }
        },

        formatObjectKey(key) {
            let lastLetterSplitKeys = ['total_leads'];
            let averageSplitKeys = [
                'average_lead_cost',
                'average_lead_cost_created',
                'average_lead_cost_modified'
            ];

            if (lastLetterSplitKeys.indexOf(key) !== -1) {
                key = key.slice(0, key.length-1)
            }

            if (averageSplitKeys.indexOf(key) !== -1) {
                let splitValue = 'average'
                key = key.slice(splitValue.length+1)
            }

            let countKeys = this.countKeys;
            let formattedValue = key.split("_").map((value, index) => {
                if (index !== 0) {
                    value = capitalizeFirstLetters(value)
                }
                return value
            }).join().replace(/,/g, "");

            if (countKeys.indexOf(key) !== -1) {
                formattedValue = `${formattedValue}Count`
            }
            return formattedValue
        },

        updateSavedMetrics(reportType, data, locationId, reportId) {
           // console.log('Starred metrics updated')
            let existingLocationData = this.savedMetrics.locations.find(metric => metric.id === locationId)
                if (!existingLocationData) {
                    let saveData = {
                        source: [],
                        salesrep: [],
                        tag: []
                    }

                    saveData[reportType] = data;
                    this.savedMetrics.locations = [
                        ...this.savedMetrics.locations,
                        {
                            id: locationId,
                            reports: [
                                {
                                    id: reportId,
                                    starred: saveData
                                }
                            ]
                        }
                    ]
                } else {
                    let locationIndex = this.savedMetrics.locations.indexOf(existingLocationData);

                    if (locationIndex === -1) {
                        return
                    }


                    let reports = this.savedMetrics.locations[locationIndex].reports;
                    if (!reports) {
                        this.savedMetrics.locations[locationIndex].reports = [];
                        reports = [];
                    }

                    let existingReport = reports.find(report => report.id === reportId);
                    let starredData = {
                        source: [],
                        salesrep: [],
                        tag: []
                    }
                    if (existingReport) {
                        this.savedMetrics.locations[locationIndex].reports = reports.map(report => {
                            if (report.id === reportId) {
                                if (!report.starred) {
                                    report.starred = starredData
                                }
                                report.starred[reportType] =  _.cloneDeep(data)
                            }

                            return report
                        })
                    } else {
                        starredData[reportType] = _.cloneDeep(data)
                        this.savedMetrics.locations[locationIndex].reports = [
                            ...reports,
                            {
                                id: reportId,
                                starred: starredData
                            }
                        ]
                    }
            }

        },

        updateSavedFilters(reportType, data, locationId, reportId) {
            data = _.cloneDeep(data);
            let keys = Object.keys(data);
            keys.forEach(key => {
                data[key] = data[key].map(item => {
                    if (item.dropdown_visible) { item.dropdown_visible = false }
                    return item
                });
            })


            let existingLocationData = this.savedMetrics.locations.find(metric => metric.id === locationId);
           // console.log({ existingLocationData })
            if (!existingLocationData) {
                let filterData = _.cloneDeep(this.getFilterTemplate);
                filterData[reportType] = data;

                this.savedMetrics.locations = [
                    ...this.savedMetrics.locations,
                    {
                        id: locationId,
                        reports: [
                            {
                                id: reportId,
                                filterState: filterData
                            }
                        ]
                    }
                ]
            } else {
                let locationIndex = this.savedMetrics.locations.indexOf(existingLocationData);


                if (locationIndex === -1) {
                    return
                }

                let reports = this.savedMetrics.locations[locationIndex].reports;
                if (!reports) {
                    this.savedMetrics.locations[locationIndex].reports = [];
                    reports = [];
                }

                let existingReport = reports.find(report => report.id === reportId);
                let filterTemplate = _.cloneDeep(this.getFilterTemplate);


                if (existingReport) {
                    this.savedMetrics.locations[locationIndex].reports = reports.map(report => {
                        if (report.id === reportId) {
                            if (!report.filterState) {
                                report.filterState = filterTemplate;
                            }

                            report.filterState[reportType] = _.cloneDeep(data)
                        }
                        return report
                    })
                } else {
                    filterTemplate[reportType] = _.cloneDeep(data)
                    this.savedMetrics.locations[locationIndex].reports = [
                        ...reports,
                        {
                            id: reportId,
                            filterState: filterTemplate
                        }
                    ]
                }

            }

          // console.log({ savedMetrics: this.savedMetrics })
        },


        getSavedFilters(reportType, locationId, reportId) {
            let filters = [];

            let locationFilters = this.savedMetrics.locations.find(metric => "id" in metric && metric.id === locationId);
            if (!locationFilters || (locationFilters && !("reports" in locationFilters))) {
                return []
            }

            let reportFilters = locationFilters.reports.find(report => "id" in report && report.id === reportId);
            if (!reportFilters || (reportFilters && !("filterState" in reportFilters))) {
                return []
            }

            let savedFilters = reportFilters.filterState[reportType];
            if (!savedFilters) {
                return []
            }

            return savedFilters;
        },

        formatOpportunityKeys(itemKey) {
            let countIndex = itemKey.toLowerCase().indexOf('count');
            let keyValue = countIndex !== -1 ? `${itemKey.slice(0, countIndex)}s` : itemKey;

            return formatKeyToUnderScore(keyValue);
        },

        createOpportunityKeys(reportData, reportType) {
            let opportunityKeys = this.opportunityKeys;
            let averageColumns = this.averageColumns;
            let opportunityColumns = [];

            reportData.forEach(report => {
                opportunityKeys.forEach(key => {
                    let opportunityData = {};

                    if (reportType === 'source' && report.report) {
                        opportunityData =  report.report[key];
                    } else {
                         opportunityData = report[key]
                    }

                    //  console.log({ key, opportunityData })
                    if (opportunityData) {
                        let itemKeys = Object.keys(opportunityData);

                        itemKeys.forEach(itemKey => {
                            let keyData = this.formatOpportunityKeys(itemKey)

                            if (opportunityColumns.indexOf(keyData) === -1) {
                                opportunityColumns = [...opportunityColumns, keyData];
                            }
                        })
                    }
                })
            })

            if (reportType !== 'source') {
                opportunityColumns = opportunityColumns.map(column => {
                    let columnName = column;
                    if (averageColumns.indexOf(column) !== -1) {
                        columnName = `average_${column}`
                    }

                    return columnName;
                });
            }

            return opportunityColumns
        },


        createColumnsAndFilterItems(reportType, reportData) {
            let init_column_object = [
                { name: "total_leads", label: "Total Leads", field: "total_leads", sortable: true, align: "center", column_name: 'total_leads' }
            ];

            let init_visible_columns = [
                'total_leads'
            ];

            let lead_cost_type = reportType === 'source' ? 'lead_cost' : 'average_lead_cost';
            let first_visible_item;

            if (reportType === 'source') {
                first_visible_item = 'source'
            } else if (reportType === 'salesrep') {
                first_visible_item = 'salesrep'
            } else if (reportType === 'tag') {
                first_visible_item = 'tag'
            }

            init_visible_columns = [
                lead_cost_type,
                first_visible_item,
                ...init_visible_columns
            ]
            let lead_cost_field,
                leadCostObject = { name: "lead_cost", label: "Lead Cost", field: "lead_cost", sortable: true, align: "center", column_name: 'lead_cost' },
                averageLeadCostObject = { name: "average_lead_cost", label: "Average Lead Cost", field: "average_lead_cost", sortable: true, align: "center", column_name: 'average_lead_cost' }

            lead_cost_field = reportType === 'source' ? leadCostObject : averageLeadCostObject

            init_column_object.splice(0,0, lead_cost_field);
            let first_column;

            let sourceColumnObject = { name: "source", label: "Sources", field: "source", sortable: true, align: "left", column_name: 'source' };

            switch (reportType) {
                case 'source':
                    first_column = sourceColumnObject
                    break
                case 'salesrep':
                    first_column = { name: "salesrep", label: "Sales rep", field: "salesrep", sortable: true, align: "left", column_name: 'salesrep' }
                    break
                case 'tag':
                    first_column = { name: "tag", label: "Tag", field: "tag", sortable: true, align: "left", column_name: 'tag' }
                    break
                default:
                    first_column = sourceColumnObject
            }


            init_column_object.unshift(first_column);
            let init_filter_items = [];

            init_filter_items = [
                ...init_filter_items,
                { label: `${capitalizeFirstLetters(reportType)}`,  column_name: `${reportType}`, visible: true, dropdown: [], starred: false },
                { label: "Total Leads", column_name: "total_leads", visible: true, dropdown: [], starred: false }
            ]
            let lead_filter_item = reportType === 'source' ? { label: "Lead Cost", column_name: "lead_cost",  visible: true, dropdown: [], starred: false }:
                { label: "Average Lead Cost", column_name: "average_lead_cost",  visible: true, dropdown: [], starred: false };

            init_filter_items.splice(1, 0, lead_filter_item);
            let reportStages = []

            reportData.forEach(report => {
                let stage;
                if (reportType === 'source') {
                    stage = report['report']['stages'];
                } else {
                    stage = report['stages'];
                }

                if (stage) {
                    stage.forEach(stage => {
                        let stageName = stage.name
                        let existingStage = reportStages.find(stage => stage.name === stageName)
                        if (!existingStage) {
                            reportStages.push({ name: stage.name, id: stage.id, index: stage.index })
                        }
                    })
                }
            })

            //  console.log({ reportStages })

            /*let result_data = reportData[0],
                stage_data = reportType === 'source' ? result_data['report']['stages'] : result_data['stages'];*/


            reportStages.map(item => {
                let stage_name = formatString(item.name);
                let filter_item = {
                    label: item.name,
                    column_name: stage_name,
                    visible: true,
                    dropdown: [],
                    all_items_visible: false,
                    starred: false,
                    apply_to_all: false,
                    index: item.index,
                    id: item.id
                };

                let stageItemData = [
                    {
                        name: `avg_time_to_${stage_name}`,
                        label: `Avg. time to ${item.name}`,
                        key: 'avg_time',
                        dataKey: "timeToStage"
                    },
                    {
                        name: `avg_total_time_to_${stage_name}`,
                        label: `Avg. total time to ${item.name}`,
                        key: 'avg_total_time',
                        dataKey: "totalTimeToStage"
                    }
                ]




                function isNewLeadColumn(columnName, index) {
                    return columnName === 'new_lead' || (columnName.includes('new_lead') && index === 1)
                }

                stageItemData.forEach(data => {
                    init_column_object.push({
                        name: data.name,
                        label: data.label,
                        field: data.name,
                        sortable: true,
                        align: "center",
                        column_name: stage_name
                    });

                    const itemState = !isNewLeadColumn(data.name, item.index)

                    if (itemState) {
                        init_visible_columns.push(data.name)
                    }

                    filter_item['dropdown'].push({
                        item: data.label,
                        visible: itemState,
                        column_name: data.name,
                        key: data.key,
                        starred: false,
                        dataKey: data.dataKey
                    })

                })

                /**
                 * If the stage isn't the "New leads" stage, adds a % to {stage} column
                 * to the table columns and adds the % to {stage} and {stage} items to
                 * the dropdown_array for the filter_item object, the use of this can be
                 * seen in the "Filter Stages" sidebar as items with dropdown not empty
                 * are grouped together into a single item
                 **/

                let conversionData = [
                    {
                        columnName: `percent_to_${stage_name}`,
                        label: `% to ${item.name}`,
                        key: 'percent',
                        dataKey: "conversionRate"
                    },
                    {
                        columnName: `total_percent_to_${stage_name}`,
                        label: `Total % to ${item.name}`,
                        key: 'total_percent',
                        dataKey: "totalConversionRate"
                    },
                ]

                if (!isNewLeadColumn(stage_name, item.index)){
                    conversionData.forEach(data => {
                        const columnName = data.columnName;
                        init_column_object.push({
                            name: columnName,
                            label: data.label,
                            field: columnName,
                            sortable: true,
                            align: "center",
                            column_name: stage_name
                        });

                        /** Adds % to {stage} column as visible column **/
                        const itemState = true

                        if (itemState) {
                            init_visible_columns.push(columnName)
                        }

                        filter_item['dropdown'].push({
                            item: data.label,
                            visible: itemState,
                            column_name: columnName,
                            key: data.key,
                            starred: false,
                            dataKey: data.dataKey
                        });

                        filter_item['dropdown_visible'] = false;/** Makes "Filter stages" sidebar dropdown hidden by default **/
                    })

                }

                /** Adds current stage to table column **/
                init_column_object.push({
                    name: stage_name,
                    label: item.name,
                    field: stage_name,
                    sortable: true,
                    align: "center",
                    column_name: stage_name
                });

                /** Adds stage column as visible column **/
                init_visible_columns.push(stage_name)


                filter_item['dropdown'].push({
                    item: item.name,
                    visible: true,
                    column_name: stage_name,
                    key: 'stage_name',
                    starred: false,
                    dataKey: "leadCount"
                })

                filter_item.all_items_visible = filter_item.dropdown.filter(dropdownItem => dropdownItem.visible).length === filter_item.dropdown.length

                init_filter_items.push(filter_item) /** Pushes filter item object to filter_items array **/
            })



            /** Adds the "Won", "Revenue", "Close Rate" etc. as table columns and to the filter_items array  **/
            let info_column = this.summaryMetrics
            info_column.map(item => {
                let column_operated = formatString(item);
                // const visibilityStatus = this.checkSavedState(column_operated);
                init_column_object.push({
                    name: column_operated,
                    label: item,
                    field: column_operated,
                    sortable: true,
                    align: "center",
                    column_name: column_operated
                });

                init_visible_columns.push(column_operated);

                /** Adds these columns as colored ones by pushing their indexes to the colored indexes array **/
                if (this.coloredColumns.indexOf(column_operated) === -1) {
                    this.coloredColumns.push(column_operated);
                }


                let filter_item = {
                    label: item,
                    visible: true,
                    column_name: column_operated,
                    dropdown: [],
                    starred: false
                }

                init_filter_items.push(filter_item);
            })

                // console.log({ reportType, reportData });

               if (reportType === 'tag') {
                   let tagLabel = `${capitalizeFirstLetters(reportType)} Parent`, tagValue = `${reportType}_parent`;

                   if (init_column_object[0].name !== tagValue) {
                       let columnSample = {...init_column_object[0]}

                       columnSample.name = tagValue;
                       columnSample.label = tagLabel;
                       columnSample.field = tagValue;
                       columnSample.column_name = tagValue;
                       columnSample.sortable = true;

                       init_column_object = [ columnSample, ...init_column_object];
                   }
               }

                let opportunityColumns = this.createOpportunityKeys(reportData, reportType)
                let visibleOpportunityColumns = [];


                init_filter_items.forEach((item, index) => {
                    if (opportunityColumns.indexOf(item.column_name) !== -1) {
                        let opportunityItems = ['modified', 'created'];

                        opportunityItems.forEach(opportunity => {
                            let modifiedItem =  {...item, column_name: `${item.column_name}_${opportunity}`, label: `${item.label} (${capitalizeFirstLetters(opportunity)})`, starred: false, visible: true, dropdown: []}
                            if (opportunity === 'modified') {
                                init_filter_items[index] = modifiedItem
                            } else {
                                init_filter_items.splice(index, 0, modifiedItem)
                            }
                        })

                    }
                })

                init_column_object.forEach((column, index) => {

                    if (opportunityColumns.indexOf(column.name) !== -1) {
                        let createdColumnName = `${column.name}_created`,
                            modifiedColumnName = `${column.name}_modified`;
                        let createdColumnObject = {
                            name:  createdColumnName,
                            field:  createdColumnName,
                            label: `${column.label} (Created)`,
                            column_name: createdColumnName
                        }

                        let modifiedColumnObject = {
                            name: modifiedColumnName,
                            field: modifiedColumnName,
                            label: `${column.label} (Modified)`,
                            column_name: modifiedColumnName
                        }

                        modifiedColumnObject = {...column, ...modifiedColumnObject};
                        createdColumnObject = {...column, ...createdColumnObject};

                        visibleOpportunityColumns = [...visibleOpportunityColumns, ...[createdColumnName, modifiedColumnName]]

                        if (this.coloredColumns.indexOf(createdColumnName) === -1) {
                            this.coloredColumns.push(createdColumnName)
                        }

                        if (this.coloredColumns.indexOf(modifiedColumnName) === -1) {
                            this.coloredColumns.push(modifiedColumnName)
                        }

                        init_column_object[index] = modifiedColumnObject
                        init_column_object.splice(index, 0, createdColumnObject)

                    }

                })

                init_visible_columns = [...init_visible_columns, ...visibleOpportunityColumns];



            let filterArrayItem = init_filter_items;

            /**
             *
             * Checks for filter items that have been "starred" and
             * adds the correct status to the filter items
             *
             * **/


                    let metricData = this.getStarredMetrics(reportType);
                    if (metricData && metricData.length > 0) {
                        metricData = metricData.map(metric => metric.column_name);

                        init_filter_items = init_filter_items.map(filterItem => {
                            if (metricData.indexOf(filterItem.column_name) !== -1 && filterItem.dropdown.length === 0) {
                                filterItem.starred = true
                            } else {
                                if (filterItem.dropdown.length > 0) {
                                    filterItem.dropdown = filterItem.dropdown.map(item => {
                                        if (metricData.indexOf(item.column_name) !== -1) {
                                            item.starred = true
                                        }
                                        return item
                                    })

                                    let starredItems = filterItem.dropdown.filter(item => item.starred).length;
                                    if (starredItems === filterItem.dropdown.length) {
                                        filterItem.starred = true;
                                    }
                                }
                            }

                            return filterItem
                        });
                    }


            return { columns: init_column_object, visibleColumns: init_visible_columns, filterItems: filterArrayItem }

        },

        getStarredMetrics(reportType, locationId, reportId) {
            let savedMetrics = this.savedMetrics.locations;

            let starredMetrics = [];

            if (!savedMetrics) {
                return []
            }

            let existingData = savedMetrics.find(location => location.id === locationId);
            if (!existingData || (existingData && !existingData.reports)) {
                return []
            }

            let existingReport = existingData.reports.find(report => report.id === reportId);

            if (!existingReport || (existingReport && !existingReport.starred)) {
                return []
            }

            let metricData = existingReport.starred && existingReport.starred[reportType];

            if (metricData) {
                starredMetrics = existingReport.starred[reportType]
            }


            return starredMetrics
        },

        createColumnItem(data)  {
            return {
                label: data.item,
                column_name: data.column_name,
                value: '',
                starred: data.starred
            }
        },

        createCustomMetricList(metrics) {
            return metrics.map(metric => {
                let { id, customMetrics } = metric
                return { id, ...customMetrics }
            })
        },

        createRow(reportData, item, report_type, customMetrics) {
            let tableData = this.createColumnsAndFilterItems(report_type, reportData)
            let columns = tableData.columns;

            let row_data = {
                key: uuidv4()
            }

            switch (report_type) {
                case 'source':
                    row_data['source'] = !item['source'] ? 'Unlabeled Source' : capitalizeFirstLetters(item['source'])
                    break
                case 'salesrep':
                    row_data['salesrep'] = !item['salesRepName'] ? 'Not Assigned' : capitalizeFirstLetters(item['salesRepName'])
                    break
                case 'tag':
                    row_data['tag'] = !item['tagName'] ? 'Untagged' : capitalizeFirstLetters(item['tagName']);
                    break
            }

            let missingSourceData = {
                name: row_data[report_type],
                data: item['missingSourceCosts'],
                key: row_data.key
            }

            let report_item = report_type === 'source' ?  item['report'] : item;
            let apiReportItem = [];

           report_item.stages.forEach(reportStage => {
                let existingReportItem = apiReportItem.find(item => item.name === reportStage.name);

                if (!existingReportItem) {
                    apiReportItem.push(reportStage)
                }
            })

                this.opportunityKeys.forEach(key => {
                    if (report_item[key]) {
                        let opportunityItem = report_item[key];
                        let itemKeys = Object.keys(opportunityItem)
                        itemKeys.forEach(itemKey => {
                            let opportunityIndex = key.toLowerCase().indexOf('opportunities');

                            if (opportunityIndex !== -1) {
                                let dataKey = key.slice(0, opportunityIndex);
                                let modifiedKey = this.formatOpportunityKeys(itemKey);

                                let formattedKey = `${modifiedKey}_${dataKey}`;
                                if (this.averageColumns.indexOf(modifiedKey) !== -1 && report_type !== 'source') {
                                    formattedKey = `average_${formattedKey}`
                                }

                                row_data[formattedKey] = parseFloat(opportunityItem[itemKey])
                            }
                        })
                    }
                })


            report_item['stages'].forEach((stage, index) => {
                let stage_name = stage['name'];
                let formatted_string = formatString(stage_name);

                let stage_metrics = stage['stageMetrics'] ? stage['stageMetrics'] : stage,
                    percent_to_next_level = stage_metrics['conversionRate'],
                    total_percent_to_next_level = stage_metrics['totalConversionRate'];

              //  console.log({ stage, stage_metrics, report_item, item, reportData })
                if (index !== 0) {
                    row_data[`percent_to_${formatted_string}`] = !percent_to_next_level ? 0 : parseFloat(percent_to_next_level) * 100;
                    row_data[`total_percent_to_${formatted_string}`] = !total_percent_to_next_level ? 0 : parseFloat(total_percent_to_next_level) * 100;
                }

                let time_to_stage = stage_metrics['timeToStage'], total_time_to_stage = stage_metrics['totalTimeToStage'];

                row_data[`avg_time_to_${formatted_string}`] = !time_to_stage ? 0 : time_to_stage['totalInMilliseconds'];
                row_data[`avg_total_time_to_${formatted_string}`] = !total_time_to_stage ? 0 : total_time_to_stage['totalInMilliseconds'];

                row_data[`${formatted_string}`] = stage_metrics['leadCount'];
            })

            let summary_metrics = report_item['summaryMetrics'];

            for (let key in summary_metrics){
               if (key !== 'timeToClose') {
                   const formatted_key = formatKeyToUnderScore(key)

                   if (formatted_key.includes('won')){
                       //  console.log({ key, value: summary_metrics[key], row_data })
                       let wonKey = 'wonCount';
                       row_data['won'] = summary_metrics[wonKey]
                   } else{
                       let summaryValue = summary_metrics[key]
                       row_data[formatted_key] = percentFields.indexOf(formatted_key) !== -1 ? parseFloat(summaryValue) * 100 : summaryValue
                   }
               }
            }


            for (let key in row_data) {
                if (!row_data[key]) {
                    row_data[key] = 0
                }
            }


            let rowKeys = Object.keys(row_data);

            columns.map(column => column.name).forEach(column => {
                if (rowKeys.indexOf(column) === -1) {
                    row_data[column] = 0;
                }
            });


                let opportunityKeys = this.createOpportunityKeys(reportData, report_type);
                let opportunityLabels = ['created', 'modified'];

                opportunityLabels.forEach(label => {
                    opportunityKeys.forEach(key => {
                        let keyItem = `${key}_${label}`;

                        if (!(keyItem in row_data && row_data)) {
                            row_data[keyItem] = 0
                        }
                    })
                })

            customMetrics.forEach(customMetric => {
                if (customMetric && customMetric.field && customMetric.formula) {
                    row_data = this.addCustomMetricValue(row_data, customMetric.field, customMetric.formula)
                }
            })


            return { row: row_data, missingSourceData, apiReportItem }
        },

        createDefaultOverviewMetricData(dashboardReport) {
            let { reportData, report, kpiData, filterData, metrics } = {...dashboardReport};
            let customMetrics = report['custom_metrics'] ? report['custom_metrics'] : [];

            customMetrics = customMetrics.map(metric => ({ id: metric.id, ...metric.customMetrics }));

            let rows = reportData.map(item => {
                return this.createRow(reportData, item, report.report_type, customMetrics).row
            })

            let unchecked = [report.report_type, 'key'];
            if (report.report_type === 'tag') {
                unchecked = [
                    ...unchecked,
                    `${report.report_type}_parent`
                ]
            }

            let tableData = this.createColumnsAndFilterItems(report.report_type, reportData);
            let columns = tableData.columns.filter(column => unchecked.indexOf(column.field) === -1);
            columns = this.setColumnOrder(filterData, columns, customMetrics);
            let totals = _.cloneDeep(this.calculateTotals(rows, unchecked));
            let averages = _.cloneDeep( this.calculateAverages(rows, unchecked));

            let filterItems = [ ...filterData.breakdown, ...filterData.metrics ];
            let kpis = this.completeKpiData(kpiData, filterItems, reportData, report.report_type, customMetrics);

            let { colors } = metrics;
            return { totals, averages, columns, kpis, customMetrics, colors, rows }
        },

        async getKpiData(reportType, locationId, pipelineId) {
            return await axios.get(`${baseURL}/locations/${locationId}/pipelines/${pipelineId}/report/${reportType}/kpis`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 30000
            });
        },


        async updateMetrics(metricId, metricData, locationId) {
            return await axios.put(`${baseURL}/locations/${locationId}/saved-metrics/${metricId}`, metricData, {
                timeout: 30000
            })
        },

        createInitKpiData(kpi_data, reportType) {
           // console.log({ kpi_data })
            let kpi_row = [], closeTime = 0;
            if (Object.values(kpi_data).length > 0) {
                for (const key in kpi_data) {
                    let key_value = kpi_data[key];
                    let formatted_key;

                    if (key !== 'stages') {
                        formatted_key = formatKeyToUnderScore(key);
                        if (formatted_key !== 'time_to_close') {
                            let count_index = formatted_key.indexOf("count");
                            let row_name = formatted_key;


                            if (count_index !== -1) {
                                let formatted_name = formatted_key.slice(0, count_index-1);
                                row_name = formatted_name === 'total_lead' ? `${formatted_name}s` : formatted_name
                            }

                            let conversionField = percentFields.indexOf(row_name) !== -1;

                            if (key_value && conversionField) {
                                key_value = key_value * 100;
                            }

                            let appendedKey;

                            let opportunityFields = [ 'total_leads', 'lead_cost',  'total_spent' ];

                            if (opportunityFields.indexOf(row_name) !== -1) {
                                appendedKey = formatted_key.includes('created') ? 'created' : 'modified'
                            } else {
                                appendedKey = ''
                            }

                            row_name = appendedKey ? row_name +'_'+ appendedKey : row_name;

                            if (reportType !== 'source' && row_name.includes('lead_cost')) {
                                row_name = `average_${row_name}`
                            }

                            let kpiObject = {
                                name: row_name,
                                value: !key_value ? '' : key_value,
                                stage_type: false,
                                conversion_field: conversionField
                            }


                            kpi_row.push(kpiObject)
                        } else {
                            closeTime = key_value
                        }
                    } else {
                        key_value.map(value => {
                            formatted_key = value.name.toLowerCase().replace(/\s/g, "_");
                            let average_time_date_object = value['timeToStage'] == null ? this.dateObject : setTimeValue(value['timeToStage'], false)

                            kpi_row.push({
                                name: `avg_time_to_${formatted_key}`,
                                value: createDateObject(average_time_date_object),
                                stage_type: true,
                                conversion_field: false,
                                time_field: true,
                                key: 'avg_time',
                                stage_name: formatted_key,
                                dataKey: "timeToStage"
                            })

                            let average_total_time_date_object = value['totalTimeToStage'] == null ? this.dateObject : setTimeValue(value['totalTimeToStage'], false);

                            kpi_row.push({
                                name: `avg_total_time_to_${formatted_key}`,
                                value: createDateObject(average_total_time_date_object),
                                stage_type: true,
                                conversion_field: false,
                                time_field: true,
                                key: 'avg_total_time',
                                stage_name: formatted_key,
                                dataKey: "totalTimeToStage"
                            })

                            let conversionKpis = [
                                {
                                    name: `percent_to_${formatted_key}`,
                                    value: !value['conversionRate'] ? "" : value['conversionRate'] * 100,
                                    key: "conversionRate"
                                },
                                {
                                    name: `total_percent_to_${formatted_key}`,
                                    value: !value['totalConversionRate'] ? "" : value['totalConversionRate'] * 100,
                                    key: "totalConversionRate"
                                }
                            ]
                         //   console.log({ conversionKpis, value })
                            if (formatted_key !== 'new_lead') {
                                conversionKpis.forEach(item => {
                                    kpi_row.push({
                                        name: item.name,
                                        value: item.value,
                                        stage_type: true,
                                        conversion_field: true,
                                        dataKey: item.key,
                                        stage_name: formatted_key
                                    })
                                })

                            }

                            kpi_row.push({
                                name: `${formatted_key}`,
                                value: value['leadCount'] === null || parseInt(value['leadCount']) === 0 ? '' : value['leadCount'],
                                stage_type: true,
                                conversion_field: false,
                                dataKey: "leadCount",
                                stage_name: formatted_key
                            })
                        })
                    }
                }
            }


            return { kpiRow : kpi_row, closeTime }

            function createDateObject(date_object) {
                return Object.values(date_object).map(value => {return value === 0 ? '' : value})
            }
        },

        completeKpiData(existingData, filterItems, reportData, reportType, customMetrics = []) {
            let kpiData = _.cloneDeep(existingData);
            filterItems.forEach(filter_item => {
                if (filter_item.column_name !== reportType) {
                        if (filter_item.dropdown.length === 0) {
                            let existing_kpi_value = kpiData.filter(filter => filter.name === filter_item.column_name);

                            if (existing_kpi_value.length === 0) {
                                let stage_attribute_type = filter_item.column_name === 'new_lead'
                                kpiData.push({ name: filter_item.column_name, value: getFieldValue(filter_item.column_name), stage_type: stage_attribute_type })
                            }
                        } else {
                            filter_item.dropdown.map(stage => {
                                let existing_kpi_data = kpiData.filter(filter => filter.name === stage.column_name);

                                if (existing_kpi_data.length === 0) {
                                    let conversion_field = stage.column_name.includes('percent'), time_field = stage.column_name.includes('time');

                                    kpiData.push({
                                        name:  stage.column_name,
                                        value: time_field ? Object.values(this.dateObject) : getFieldValue(stage.column_name),
                                        stage_type: true,
                                        conversion_field: conversion_field,
                                        time_field: time_field,
                                        key: time_field && stage.column_name.includes('total') ? 'avg_total_time' : 'avg_time',
                                        stage_name: filter_item.column_name,
                                        dataKey: stage.dataKey
                                    })
                                }
                            })
                        }
                }
            })

                let kpiRowModified = [];

                let opportunityKeys = this.createOpportunityKeys(reportData, reportType);
                kpiData.forEach(row => {
                    if ( opportunityKeys.indexOf(row.name) !== -1 ) {
                        let modifiedRow = {...row, name: `${row.name}_modified`};
                        let createdRow = {...row, name: `${row.name}_created`};

                        kpiRowModified.push(modifiedRow);
                        kpiRowModified.push(createdRow);
                    } else {
                        kpiRowModified.push(row)
                    }
                })

                kpiData = kpiRowModified

            return kpiData

            function getFieldValue(columnName) {
              let value = '';
              let customMetricField = customMetrics.find(metric => metric.field === columnName);
              if (customMetricField) {
                  value = customMetricField.kpi ? customMetricField.kpi : ''
              }

              return value
            }

        },

        createSavedMetricData(metricData, reportType, data, totals = {}, averages = {}, customMetrics = []) {
            let metricKeys = Object.keys(metricData);
            let overviewMetrics = [], unmatched = [];
            if (metricKeys.length > 0) {
                let dataKeys = metricKeys.filter(key => key !== 'stages');

                totals[reportType] = 'Total';
                averages[reportType] = 'Average';

                let dataList = [
                    ...data,
                    totals,
                    averages
                ]

                dataKeys.forEach(key => {
                    let metricValue = metricData[key];
                    if (metricValue) {
                        let values = formatMetricValues(metricValue);

                        values.forEach(value => {
                            let formattedKey = this.formatKey(key, reportType);
                            let selected = {
                                label: capitalizeFirstLetters(value)
                            }

                            let selectedRow = dataList.find(row => row[reportType] === selected.label);
                            if (selectedRow) {
                                selected.value = selectedRow[formattedKey];
                                let dataLabel = formattedKey.split("_").map(item => {
                                    item = capitalizeFirstLetters(item)
                                    if (item.toLowerCase() === 'created' || item.toLowerCase() === 'modified') {
                                        item = `(${item})`
                                    }
                                    return item
                                }).toString().replace(/,/g, " ");

                                if (this.isACustomMetric(customMetrics, formattedKey) && getCustomMetricName(formattedKey)) {
                                    dataLabel = getCustomMetricName(formattedKey)
                                }

                                let dataObject = {
                                    label: dataLabel,
                                    column_name: formattedKey,
                                    selected
                                }

                                overviewMetrics = [
                                    ...overviewMetrics,
                                    dataObject
                                ]
                            } else {
                                unmatched.push({ column_name: formattedKey, label: selected.label })
                            }
                        })
                    }
                })

                let stages = metricData.stages;

                let metricMatches = {
                    totalTimeToStage: {
                        key: "avg_total_time_to",
                        label: "avg total time to"
                    },
                    timeToStage: {
                        key: "avg_time_to",
                        label: "avg time to"
                    },
                    conversionRate: {
                        key: "percent_to",
                        label: "% to"
                    },
                    totalConversionRate: {
                        key: "total_percent_to",
                        label: "total % to"
                    },
                    leadCount: {
                        key: "", label: ""
                    }
                }

                stages.forEach(stage => {
                    let builtInStages = this.builtInStages
                    let customStageKeys = Object.keys(stage).filter(key => !metricMatches[key] && builtInStages.indexOf(key) === -1);
                    if (customStageKeys.length > 0) {
                        customStageKeys.forEach(customStage => {
                            let formattedKey = this.formatKey(customStage, reportType);
                            let stageValues = formatMetricValues(stage[customStage]);


                            stageValues.forEach(value => {
                                let data = {
                                    label: getCustomMetricName(formattedKey),
                                    column_name: formattedKey,
                                    selected: { label: capitalizeFirstLetters(value), value: '' }
                                }

                                let matchingRow = dataList.find(row => row[reportType] === data.selected.label);

                                if (matchingRow) {
                                    data.selected.value = matchingRow[formattedKey]
                                }

                                if (data.selected.label && data.selected.value) {
                                    overviewMetrics = [ ...overviewMetrics, data ]
                                } else {
                                    unmatched.push({ column_name: formattedKey, label: data.selected.label })
                                }
                            })
                        })
                    }

                    Object.keys(metricMatches).forEach(key => {
                        if (stage[key]) {
                            let values = formatMetricValues(stage[key])

                            values.forEach(value => {
                                let keyValue = metricMatches[key].key ? `${metricMatches[key].key}_` : ""
                                let stageColumn = `${keyValue}${formatString(stage.name)}`
                                let currentValue = {
                                    label: capitalizeFirstLetters(value)
                                }

                                let matchingRow = dataList.find(row => row[reportType] === currentValue.label);
                                if (matchingRow) {
                                    currentValue.value = matchingRow[stageColumn];
                                    let labelKey = metricMatches[key].label ? `${metricMatches[key].label} ` : ""
                                    let data = {
                                        label: capitalizeFirstLetters(`${labelKey}${stage.name}`),
                                        column_name: stageColumn,
                                        selected: currentValue
                                    };

                                    overviewMetrics = [
                                        ...overviewMetrics,
                                        data
                                    ]
                                } else {
                                    unmatched.push({ label: currentValue.label, column_name: stageColumn })
                                }
                            })
                        }
                    })
                })
            }


            function formatMetricValues(value) {
                if (!value) {
                    return []
                }

                value = Array.isArray(value) ? value : [ value ]
                value = value.map(item => {
                    if (typeof item === "number") {
                        if (item === -1) {
                            item = 'average'
                        } else {
                            if (data[item]) {
                                let rowValue = data[item][reportType]
                                item = rowValue ? rowValue.toLowerCase() : 'average'
                            } else {
                                item = 'average'
                            }
                        }
                    }
                    return item
                })

                return value
            }


            function getCustomMetricName(field) {
                let name = '';
                let customMetric = customMetrics.find(metric => metric.field === field);
                if (customMetric) {
                    name = customMetric.name
                }
                return name
            }

            return { overviewMetrics, unmatched };
        },

        formatKey(key, reportType) {
            let formatted = formatKeyToUnderScore(key);
            let countIndex = formatted.indexOf('count');
            if (countIndex !== -1) {
                formatted = formatted.slice(0, countIndex-1)
            }

            if (formatted.includes('lead_cost') && reportType !== 'source') {
                formatted = `average_${formatted}`
            }

            if (formatted === 'total_lead') {
                formatted = `${formatted}s`
            }

            return formatted
        },

        calculateTotals(data, unchecked) {
            if (data.length === 0) {
                return {}
            }
            let totals = {}


            let keys = Object.keys(data[0]);

            keys.forEach(key => {
                if (unchecked.indexOf(key) === -1) {
                    let totalData = data.map(item => ( item[key] ))
                        .reduce((a, b) => {
                            return parseFloat(a) + parseFloat(b)
                        }, 0)

                    totals[key] = isNaN(totalData) ? 0 : totalData
                } else {
                    totals[key] = ''
                }

            })
            return totals
        },

        calculateAverages(data, unchecked) {
            let totals = this.calculateTotals(data, unchecked);
            let averages = {};

            Object.keys(totals).forEach(key => {
                if (unchecked.indexOf(key) !== -1) {
                    averages[key] = ''
                } else {
                    averages[key] = totals[key] / data.length
                }
            })

            return averages
        },

        async getDashboardImage() {
            let mainDash = document.getElementById('mainDash');
            let image;


            if (mainDash) {
                let canvas = await html2canvas(mainDash);
                image = canvas.toDataURL("image/png", 1.0)
                let imgRequest = await fetch(image);
                let imageBlob = await imgRequest.blob();

                image = new File([imageBlob], `image-screenshot-${Date.now()}.png`, {
                    type: 'image/png'
                })
            }

            return image
        },

        createFormData(requestData) {
            let formData = new FormData();
            let unparsedKeys = [ 'screenshot', 'fromDate', 'toDate', 'reportName', 'datePreset' ]
            for (let key in requestData) {
                let keyValue = unparsedKeys.indexOf(key) === -1 ? JSON.stringify(requestData[key]) : requestData[key];

                if (key === 'datePreset' && !keyValue)  {
                    keyValue = null
                }
                formData.append(key, keyValue)
            }

            return formData
        },

        async saveReportWithMetrics(locationId, pipelineId, reportType, requestData) {
            let formData = this.createFormData(requestData)
            return await axios.post(`${baseURL}/locations/${locationId}/pipelines/${pipelineId}/save-as/save-report/${reportType}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 30000
            })
        },

        async addCustomMetricsToReport(metrics, savedReport) {
            return await new Promise((resolve, reject) => {
                let report = savedReport;
                let store = this;

                createMetric(0)
                function createMetric(index) {
                    if (index < 0 || index >= metrics.length) {
                        resolve(report);
                        return
                    }

                    let storedReport = store.savedReports.find(storedReport => storedReport.id === report.id);

                    if (!storedReport) {
                        reject({ message: 'Saved report not found' })
                        return
                    }
                    let metric = _.cloneDeep(metrics[index]);

                    let existingMetric = storedReport['custom_metrics'].find(item => item.customMetrics.field === metric.field);
                    if (existingMetric) {
                        createMetric(index+1);
                        return
                    }

                    delete metric.id;
                    delete metric.templateMetric;

                    let requestData = {
                        customMetrics: metric
                    }

                    store.sendCustomMetricRequest(savedReport.id, requestData)
                        .then(response => {
                            let responseData = response.data;
                            let reportItem = store.savedReports.find(storedReport => storedReport.id === report.id);
                            reportItem['custom_metrics'].push(responseData);
                            store.updateReport(reportItem);

                            report = reportItem;
                            createMetric(index+1)
                        }).catch(error => {
                        reject(error)
                    })
                }
            })
        },

        async saveReport(locationId, pipelineId, reportType, requestData) {
            let formData = this.createFormData(requestData)

           return await axios.post(`${baseURL}/locations/${locationId}/pipelines/${pipelineId}/save-report/${reportType}`, formData, {
               headers: {
                   'Content-Type': 'multipart/form-data'
               },
               timeout: 30000
           })
        },

        async updateSavedReport(locationId, pipelineId, reportId, requestData) {
            let formData = this.createFormData(requestData)
            return await axios.put(`${baseURL}/locations/${locationId}/pipelines/${pipelineId}/update-report/${reportId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 30000
            })
        },

        addNewSavedReport(report) {
            if (report.default) {
                this.savedReports = this.savedReports.map(report => {
                    report.default = false;
                    return report
                })
            }
            this.savedReports = [
                ...this.savedReports,
                report
            ]
        },

        setDefaultReport(reportId) {
            this.savedReports = this.savedReports.map(report => {
                if (report.id === reportId) {
                    report.default = !report.default
                } else {
                    if (report.default) {
                        report.default = false
                    }
                }
                return report
            })
        },


        updateReport(reportData) {
            let existingReport = this.savedReports.find(report => report.id === reportData.id)
            this.savedReports = this.savedReports.map(savedReport => {
                if (savedReport.id === reportData.id) {
                    savedReport = reportData
                } else {
                    if (existingReport && reportData.default) {
                        savedReport.default = false
                    }
                }

                return savedReport
            })
        },

        async getReportData(locationId, reportId) {
            return await axios.get(`${baseURL}/locations/${locationId}/saved-report/${reportId}`, {
                timeout: 30000
            })
        },

        async getSavedMetrics(locationId, savedMetricId) {
            return await axios.get(`${baseURL}/locations/${locationId}/saved-metrics/${savedMetricId}`, {
                timeout: 30000
            })
        },

        async getSavedReport(locationId, reportId, customRange = {}, customFilters = [], useCustomFilters = false) {
            let reportRequest = await this.getReportData(locationId, reportId)

            let { report, metrics } = reportRequest.data;

            if (metrics) {
                let { savedMetrics, colors } = metrics;
                metrics = {
                    savedMetrics,
                    colors
                }
            } else {
                metrics = {
                    savedMetrics: [],
                    colors: {}
                }
            }

            let kpiData = [];
            this.getKpiData(report.report_type, report.location_id, report.pipeline_id).then(response => {
                kpiData = this.createInitKpiData(response.data, report.report_type).kpiRow
            }).catch(error => { console.log(error) })

            let filterData = {
                metrics: report['report_data'],
                breakdown: report.breakdowns
            }

            let dateRange = {
                from: report.from_date,
                to: report.to_date
            };

            if ("date_preset" in report && report.date_preset && this.getPresetRange(report.date_preset)) {
                dateRange = this.getPresetRange(report.date_preset);
            }

            let range = customRange && customRange.from && customRange.to ? customRange : dateRange;
            let filters = customFilters && useCustomFilters ? customFilters : report.filters;

            report.filters = filters;

            let reportDataRequest = await this.getReportTableData(report.location_id, report.pipeline_id, report.report_type, range, filters);
            let reportData = reportDataRequest.data.results;

            return {
                report,
                metrics,
                filterData,
                reportData,
                savedMetricsId: report['saved_metrics_id'],
                kpiData
            }
        },



        getPresetRange(preset) {
            let range = null;
            let presetKey = preset.toLowerCase().replace(/\s/g, "_");
            let presetRange = presetValues[presetKey];

            if (presetRange) {
                range = {
                    from: replaceSlashes(presetRange.start),
                    to: replaceSlashes(presetRange.end)
                }
            }

            return range;
        },

        async getPipelines(location_id) {
            return await axios.get(`${baseURL}/locations/${location_id}/pipelines`)
        },

        async getAgencyLocation(locationId) {
            return await axios.get(`${baseURL}/locations/${locationId}/report-templates/agency-location`)
        },

        async getReportTableData(locationId, pipelineId, reportType, range, filters) {
            let request_data = {
                "fromDate": range.from,
                "toDate": /*replaceSlashes(getNextDay(range.to))*/range.to,
                "filters": filters
            }

            return await axios.post(`${baseURL}/locations/${locationId}/pipelines/${pipelineId}/report/${reportType}`, request_data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 120000
            })
        },

        async getDefaultReport(locationId) {
            return await axios.get(`${baseURL}/locations/${locationId}/saved-report/default`, {
                timeout: 30000
            })
        },

        async getLocationSavedReports(locationId) {
            return await axios.get(`${baseURL}/locations/${locationId}/saved-reports`)
        },

        async getSavedReportsTemplatesAndLocations(locationId) {
            let reportRequest = await this.getLocationSavedReports(locationId);
            let { reports, isAgencyLocation } = reportRequest.data;

            this.savedReports = reports
            let templateRequest = await axios.get(`${baseURL}/locations/${locationId}/report-templates`);
            this.reportTemplates = templateRequest.data.reportTemplates;

            let locations = [];

            // Gets agency location itself
            let agencyLocationRequest = await this.getAgencyLocation(locationId);
            let agencyLocation = agencyLocationRequest.data.location;
            locations.push(agencyLocation);

            this.agencyLocationID = agencyLocation.id;
            let locationRequest = await this.getAgencyLocations(agencyLocation.id);
            locations = [
                ...locations,
                ...locationRequest.data.locations
            ]


            //console.log({ locations })

            this.locations = locations;
            let pageUrl = new URL(window.location);
            let mode = pageUrl.searchParams.get('mode');
            let location = pageUrl.searchParams.get('location_id');

            let isAdminUser = false;
            if (mode && mode === 'admin' && location) {
                // Allows us to create agency level templates from right turn auto or any other locations under TurnoverCRM
                if (location === this.rightTurnAutoID) {
                    isAdminUser = true;
                } else {

                    let agencyLocationsRequest = await this.getAgencyLocations(this.rightTurnAutoID);
                    let locations = agencyLocationsRequest.data.locations;

                    let locationIDs = locations.map(location => location.id);
                    if (locationIDs.indexOf(location) !== -1) {
                        isAdminUser = true;
                    }
                }
            }

            return { reports, templates: this.reportTemplates, locations, isAgencyLocation, isAdminUser }
        },

        async sendDeleteRequest(locationId, reportId) {
            return await axios.delete(`${baseURL}/locations/${locationId}/saved-report/${reportId}`)
        },

        removeReport(reportId) {
            this.savedReports = this.savedReports.filter(report => report.id !== reportId)
        },


        createMetricFieldFormat(data) {
            let field = data.name.toLowerCase().replace(/\s/g, "_"),
                label = capitalizeFirstLetters(data.name);

            return {
                field,
                format: data.format === 'numeric' ? '' : data.format,
                name: label,
                formula: data.formula,
                id: data.id
            }
        },

        getUsedStageMetrics(formula) {
            return formula.entryList.filter(entry => entry.metricValue && entry.type === 'stage_metrics');
        },

        addNewCustomMetric(metric, filterItems) {
            let usedStageMetrics = this.getUsedStageMetrics(metric.formula)
            let label = metric.name
            let filterItem = {
                column_name: metric.field,
                visible: true,
                starred: false,
                metric_type: "combined_metric",
              /*  metric_id: metric.id*/
            }

            let preceedingColumn, stageType = false;

            if (usedStageMetrics.length === 1) {
                filterItem = {
                    ...filterItem,
                    item: label
                }

                filterItems = filterItems.map(item => {
                    if (item.index && item.index === usedStageMetrics[0].index) {
                        let lastColumnItem = item.dropdown[item.dropdown.length-1];
                        preceedingColumn = lastColumnItem.column_name;
                        stageType = true

                        item.dropdown = [
                            ...item.dropdown,
                            filterItem
                        ]
                    }
                    return item
                })

            } else {
                filterItem = {
                    ...filterItem,
                    label,
                    dropdown: []
                }

                let lastFilterItem = filterItems[filterItems.length-1];
                if (lastFilterItem.dropdown.length === 0) {
                    preceedingColumn = lastFilterItem.column_name
                } else {
                    let dropdownItems = lastFilterItem.dropdown.length;
                    let lastDropdownItem = lastFilterItem.dropdown[dropdownItems-1];
                    preceedingColumn = lastDropdownItem.column_name;
                }

                filterItems.push(filterItem);
            }

            return { filterItems, preceedingColumn, stageType }
        },

        updateMetricList(filterItems, previousMetric, currentMetric) {
            let usedStageMetrics = this.getUsedStageMetrics(currentMetric.formula);
            let metricFilterItem = {
                column_name: currentMetric.field,
                visible: true,
                starred: false,
                metric_type: "combined_metric"
            }

            if (usedStageMetrics.length === 1) {
                let updatedFilterItems = [];

                filterItems.forEach(item => {
                    if (item.dropdown.length === 0) {
                        if (item.column_name !== previousMetric.field) {
                            updatedFilterItems.push(item)
                        }
                    } else {
                        item.dropdown = item.dropdown.filter(dropdownItem => dropdownItem.column_name !== previousMetric.field);
                        if (item.index && usedStageMetrics[0].index === item.index) {
                            item.dropdown.push({
                                ...metricFilterItem,
                                item: currentMetric.name
                            })
                        }

                        updatedFilterItems.push(item)
                    }
                })

                filterItems = updatedFilterItems
            } else {
                let usedStagesForPreviousMetric = this.getUsedStageMetrics(previousMetric.formula);

                if (usedStagesForPreviousMetric.length === 1) {
                    let updatedFilterItems = filterItems.map(item => {
                        item.dropdown = item.dropdown.filter(dropdownItem => dropdownItem.column_name !== previousMetric.field);
                        return item
                    })

                    updatedFilterItems.push({
                        ...metricFilterItem,
                        label: currentMetric.name,
                        dropdown: []
                    })

                    filterItems = updatedFilterItems
                } else {
                    filterItems = filterItems.map(filterItem => {
                        if (filterItem.dropdown.length === 0) {
                            if (filterItem.column_name === previousMetric.field) {
                                filterItem.column_name = currentMetric.field;
                                filterItem.label = currentMetric.name
                            }
                        } else {
                            filterItem.dropdown = filterItem.dropdown.map(dropdownItem => {
                                if (dropdownItem.column_name === previousMetric.field) {
                                    dropdownItem.item = currentMetric.name;
                                    dropdownItem.column_name = currentMetric.field;
                                }

                                return dropdownItem
                            })
                        }

                        return filterItem
                    })
                }

            }




            return filterItems
        },


        createReportFormat(report) {
            return {
                fromDate: report.from_date,
                toDate: report.to_date,
                reportName: report['report_name'],
                filters: report.filters,
                reportData: report['report_data'],
                default: report.default,
                averages: report.averages,
                kpis: report.kpis,
                totals: report.totals,
                kpiIndicators: report['kpi_indicators'],
                breakdowns: report.breakdowns,
                metricId: report['saved_metrics_id'],
                datePreset: report['date_preset'],
            }
        },

        setColumnOrder(filterData, columns, customMetrics) {
            let filterItemList = filterData.metrics;
            if (filterData.breakdown.length > 0) {
                filterItemList = [
                    ...filterData.breakdown,
                    ...filterItemList
                ]
            }

                let orderedColumns = [];
                filterItemList.forEach(item => {
                    if (item.dropdown.length === 0) {
                        let column = columns.find(column => column.name === item.column_name);
                        if (column) {
                            orderedColumns.push(column)
                        } else {
                            let customMetric = customMetrics.find(metric => metric.field === item.column_name);
                            if (customMetric) {
                                orderedColumns.push(createCustomMetric(customMetric))
                            }
                        }
                    } else {
                        item.dropdown.forEach(dropdownItem => {
                            let column = columns.find(column => column.name === dropdownItem.column_name);
                            if (column) {
                                orderedColumns.push(column)
                            } else {
                                let customMetric = customMetrics.find(metric => metric.field === dropdownItem.column_name);

                                if (customMetric) {
                                    orderedColumns.push(createCustomMetric(customMetric))
                                }
                            }
                        })
                    }
                })

                return orderedColumns

            function createCustomMetric(customMetric) {
                let label = customMetric.name, field = customMetric.field;
                return {
                    label,
                    field,
                    name: field,
                    sortable: true,
                    align: "center",
                    column_name: field
                }
            }
        },

        addCustomMetricValue(row, field, formula) {
            let metricValues = formula.entryList.filter(item => item.metricValue)

            let expression = formula.expression;
            for (let rowKey in row) {
                let value = row[rowKey];
                let existingMetric = metricValues.find(metric => metric.column_name === rowKey);

                if (existingMetric) {
                    expression = expression.replaceAll(rowKey, value.toString())
                }
            }

            let evaluatedFormula = this.evaluateExpression(expression);
            row[field] = !evaluatedFormula || evaluatedFormula === Infinity ? 0 : evaluatedFormula;

            return row
        },

        deleteCustomMetric(filter_items, metric) {
            let filterItems = [];
            filter_items.forEach(filterItem => {
                if (filterItem.dropdown.length === 0) {
                    if (filterItem.column_name !== metric.field) {
                        filterItems.push(filterItem)
                    }
                } else {
                    filterItem.dropdown = filterItem.dropdown.filter(item => item.column_name !== metric.field);
                    filterItems.push(filterItem)
                }
            })

            return filterItems
        },

        evaluateExpression(expression) {
            if (!expression) {
                return null
            }

            try {
                let evaluated = evaluate(expression);

                if (evaluated) {
                    return evaluated
                }
            } catch (e) {
                return null
            }
        },

        isACustomMetric(customMetrics, field) {
            let matchingField = customMetrics.find(metric => metric.field === field);
            return !!matchingField
        },

        formatCustomMetricValue(customMetrics, field, value) {
            if (!value || parseFloat(value) === 0) {
                return '-'
            }

            let customMetric = customMetrics.find(metric => metric.field === field);
            let fieldValue = splitIntoCommas(parseValue(value));

            return customMetric.format === '%' ? fieldValue + ' ' + customMetric.format : customMetric.format + ' ' + fieldValue
        },

        async sendTemplateCreationRequest(location, requestData, reportType) {
           return await axios.post(`${baseURL}/locations/${location}/report-templates/${reportType}`, requestData, {
               timeout: 30000
           })
        },


        async sendTemplateCustomMetricRequest(templateId, requestData) {
            return await axios.post(`${baseURL}/report-template/${templateId}/custom-metrics`, requestData, {
                timeout: 30000
            })
        },

        async sendTemplateCustomMetricUpdateRequest(templateId, customMetricId,  requestData) {
            return await axios.put(`${baseURL}/report-template/${templateId}/custom-metrics/${customMetricId}`, requestData, {
                timeout: 30000
            })
        },

        async sendTemplateCustomMetricDeleteRequest(templateId, customMetricId) {
            return await axios.delete(`${baseURL}/report-template/${templateId}/custom-metrics/${customMetricId}`, {
                timeout: 30000
            })
        },

        async getTemplateData(locationId, templateId) {
           return await axios.get(`${baseURL}/locations/${locationId}/report-templates/template/${templateId}`, {
               timeout: 30000
           })
        },

        createTemplateFormat(reportData) {
            let templateFormat = [];
            let reportFields = this.reportFields;
            reportData = this.addMetricTypes(reportData);

            reportData.filter(data => reportFields.indexOf(data.column_name) === -1).forEach(data => {
                if (data.dropdown.length === 0) {
                    let { column_name, dropdown, starred, visible, metric_type } = data;
                    templateFormat.push({
                        column_name,
                        dropdown,
                        starred,
                        visible,
                        metric_type
                    })
                } else {
                    let { all_items_visible, apply_to_all, dropdown, index, starred, visible } = data;

                    dropdown = dropdown.map(item => {
                        let { key, dataKey, visible, starred, metric_type } = item;


                        let data = {
                            visible,
                            starred,
                            metric_type
                        }

                        if (item.metric_type === 'stage_metric') {
                            data = {
                                ...data,
                                key,
                                dataKey
                            }
                        } else {
                            data = {
                                ...data,
                                column_name: item.column_name,
                                item: item.item
                            }
                        }
                        return data
                    })

                    let dataFormat = {
                        all_items_visible,
                        apply_to_all,
                        index,
                        starred,
                        visible,
                        dropdown
                    }

                    templateFormat.push(dataFormat)
                }
            })
            return templateFormat
        },

        addNewTemplate(template) {
            this.reportTemplates = [
                ...this.reportTemplates,
                template
            ]
        },

        async sendTemplateUpdateRequest(locationId, templateId, requestData) {
            return await axios.put(`${baseURL}/locations/${locationId}/report-templates/template/${templateId}`, requestData, {
                timeout: 30000
            })
        },

        async sendTemplateMetricsUpdateRequest(templateId, savedMetricsId, requestData) {
            return await axios.put(`${baseURL}/report-template/${templateId}/saved-metrics/${savedMetricsId}`, requestData, {
                timeout: 30000
            })
        },

        updateTemplate(template) {
            this.reportTemplates = this.reportTemplates.map(reportTemplate => {
                if (reportTemplate.id === template.id) {
                    reportTemplate = template
                }
                return reportTemplate
            })
        },

        async sendTemplateDeleteRequest(locationId, templateId) {
            return await axios.delete(`${baseURL}/locations/${locationId}/report-templates/template/${templateId}`, {
                timeout: 30000
            })
        },

        deleteTemplate(templateId) {
            this.reportTemplates = this.reportTemplates.filter(template => template.id !== templateId)
        },


        async getAgencyLocations(locationId) {
            return await axios.get(`${baseURL}/locations/${locationId}/report-templates/agency-locations`, {
                timeout: 30000
            })
        },

        async sendCustomMetricRequest(savedReportId, customMetric) {
            return await axios.post(`${baseURL}/saved-report/${savedReportId}/custom-metrics`, customMetric, {
                timeout: 30000
            })
        },


        async sendMetricUpdateRequest(savedReportId, metricId, customMetric) {
            return await axios.put(`${baseURL}/saved-report/${savedReportId}/custom-metrics/${metricId}`, customMetric, {
                timeout: 30000
            })
        },

        async sendMetricDeleteRequest(savedReportId, metricId) {
            return await axios.delete(`${baseURL}/saved-report/${savedReportId}/custom-metrics/${metricId}`, {
                timeout: 30000
            })
        },

        templateNameExists(templateName) {
            let template = this.reportTemplates.find(template => template['templateName'].toLowerCase() === templateName.toLowerCase());
            return !!template
        },

        createTemplateFields(addAgencyFields = false) {
            let fields = _.cloneDeep(this.locationFields);

            if (addAgencyFields) {
                fields = [
                    ...fields,
                    ..._.cloneDeep(this.agencyFields)
                ]
            }

            if (!addAgencyFields) {
                fields = [
                    ...fields,
                    _.cloneDeep(this.locationField)
                ]
            }

            return fields
        },
         async createFormulaExpression(formulaArray) {
            return await new Promise(resolve => {
                let formula = _.cloneDeep(formulaArray)
                let formulaString = '';
                getFormulaString(0)

                function getFormulaString(index) {
                    if (index < 0 || index >= formula.length) {
                        resolve(formulaString)
                        return
                    }

                    let formulaItem = formula[index]

                    if (!formulaItem.singularValue) {
                        formulaString += formulaItem.signValue ? formulaItem.value : formulaItem.column_name;
                        getFormulaString(index+1)
                    } else {
                        let slicedFormula = formula.slice(index);
                        let sliceEnd = slicedFormula.find(item => !item.singularValue);

                        sliceEnd = !sliceEnd ? formula.length : formula.indexOf(sliceEnd);
                        let slicedRange = formula.slice(index, sliceEnd);

                        let singularValue = slicedRange.map(item => item.value).join().replace(/,/g, "");
                        formulaString += singularValue.toString();
                        getFormulaString(sliceEnd)
                    }
                }
            })
        },

        addMetricTypes(filterItems) {
            let store = this;

            let summaryMetricColumns = store.summaryMetrics.map(metric => ( replaceSpaceWithUnderscores(removeSpecialChars(metric.toLowerCase())) ))
            let leadMetricColumns = store.leadMetrics.map(metric => ( replaceSpaceWithUnderscores(removeSpecialChars(metric.toLowerCase())) ));

            summaryMetricColumns = [
                ...summaryMetricColumns,
                'total_spent_created',
                'total_spent_modified'
            ]

            return filterItems.map(item => {
                if (item.dropdown.length === 0) {
                    if (item.metric_type === undefined || item.metric_type == null) {
                        item.metric_type = getMetricType(item.column_name)
                    }
                } else {
                    item.dropdown = item.dropdown.map(dropdownItem => {
                        if (dropdownItem.metric_type === undefined) {
                            dropdownItem.metric_type = 'stage_metric'
                        }

                        return dropdownItem
                    })
                }
                return item
            })

            function getMetricType(column_name) {
                let metricType = null;

                if (summaryMetricColumns.indexOf(column_name) !== -1) {
                    metricType = 'summary_metric'
                } else if (leadMetricColumns.indexOf(column_name) !== -1) {
                    metricType = 'lead_metric'
                }

                return metricType
            }
        },

        generateFilterData(filterItems, reportType) {
            let filterData = {
                metrics: filterItems,
                breakdown: []
            }

            if (reportType === 'tag') {
                let tagLabel = `${capitalizeFirstLetters(reportType)} Parent`, tagValue = `${reportType}_parent`;

                let filterSample = {...filterItems[0]}
                filterSample.label = tagLabel;
                filterSample.column_name = tagValue;
                filterSample.visible = false;
                filterData.breakdown = [ filterSample ];
            }

            return filterData
        },

        createTemplateCustomMetrics(customMetrics) {
            if (!customMetrics || (customMetrics && customMetrics.length === 0)) {
                return []
            }

            return [...customMetrics].map(metric => {
                metric = metric.customMetrics;
                delete metric.kpi;

                metric.formula.entryList = metric.formula.entryList.map(entry => {
                    if (entry.type && entry.type === 'stage_metrics') {
                        entry.value = '';
                        entry.column_name = ''
                    }
                    return entry
                })

                metric.formula.expression = ''

                return metric
            })
        },

        applyTemplateToReportData(filterData, template, reportType) {
            let filterItems = _.cloneDeep([
                ...filterData.metrics,
                ...filterData.breakdown
            ]);

            let templateFilterItems = [];
            let templateData = _.cloneDeep(template);

            templateData.filter(data => this.reportFields.indexOf(data.column_name) === -1).forEach(templateItem => {
                if (templateItem.dropdown.length === 0) {
                    let templateColumn = templateItem.column_name;

                    if (reportType === 'source') {
                        if (templateColumn.includes('lead_cost') && templateColumn.includes('average')) {
                            templateColumn = templateColumn.replaceAll('average_', '')
                        }
                    } else {
                        if (templateColumn.includes('lead_cost') && !templateColumn.includes('average')) {
                            templateColumn = `average_${templateColumn}`
                        }
                    }

                    let filterItem = filterItems.find(item => item.column_name === templateColumn);
                    if (filterItem) {
                        let { starred, visible } = templateItem;
                        filterItem.starred = starred;
                        filterItem.visible = visible;

                        templateFilterItems.push(filterItem)
                    } else {
                        templateFilterItems.push({
                            ...templateItem,
                            label: capitalizeFirstLetters(templateItem.column_name.replace(/_/g, " "))
                        })
                    }
                } else {
                    let { index, apply_to_all, all_items_visible, starred, visible, dropdown } = templateItem
                    let matchingStage = filterItems.find(item => item.dropdown.length > 0 && item.index === index);

                    if (matchingStage) {
                        matchingStage = {
                            ...matchingStage,
                            apply_to_all,
                            all_items_visible,
                            starred,
                            visible
                        }

                        let dropdownItems = [];
                        let stageMetrics = dropdown.filter(dropdownItem => dropdownItem.metric_type === 'stage_metric');
                        matchingStage.dropdown.forEach(item => {
                            let dropdownItem = stageMetrics.find(dropdownItem => dropdownItem.key && dropdownItem.key === item.key);
                            if (dropdownItem) {
                                let { visible, starred } = dropdownItem;
                                item = {
                                    ...item,
                                    visible,
                                    starred
                                }

                                dropdownItems.push(item)
                            }
                        })

                        dropdown.filter(dropdownItem => dropdownItem.metric_type === 'combined_metric').forEach(dropdownItem => {
                            dropdownItems.push(dropdownItem)
                        })

                        /*let unaddedDropdownItems = matchingStage.dropdown.filter(item => !dropdownItems.find(dropdownItem => dropdownItem.column_name === item.column_name));
                        if (unaddedDropdownItems.length > 0) {
                            dropdownItems = [
                                ...dropdownItems,
                                unaddedDropdownItems
                            ]
                        }*/

                        matchingStage.dropdown = dropdownItems;
                        templateFilterItems.push(matchingStage)
                    }
                }
            })


            let templateColumns = templateFilterItems.map(item => item.column_name);
            let unaddedItems = filterItems.filter(item => templateColumns.indexOf(item.column_name) === -1 && this.reportFields.indexOf(item.column_name) === -1 && (item.metric_type && item.metric_type !== 'combined_metric'));
            let stageColumns = templateFilterItems.filter(item => item.dropdown.length > 0);
            unaddedItems.forEach(item => {
                if ( item.dropdown.length > 0 ) {
                    let stageIndex = getLastStageIndex();
                    if (stageIndex !== -1) {
                        templateFilterItems.splice(stageIndex+1, 0, item)
                    }
                } else {
                    templateFilterItems.push(item)
                }
            })

            function getLastStageIndex() {
                let stageIndex = -1;

                if (stageColumns.length > 0) {
                    let lastStage = stageColumns[stageColumns.length-1];
                    let stageData = templateFilterItems.find(templateItem => templateItem.column_name === lastStage.column_name);

                    if (stageData) {
                        stageIndex = templateFilterItems.indexOf(stageData);
                    }
                }

                return stageIndex
            }

            templateFilterItems = [
                filterData.metrics[0],
                ...templateFilterItems
            ]

            return templateFilterItems
        },

        addTemplateCustomMetrics(customMetrics, filterItems) {
            return customMetrics.map(data => {
                let metric = data.customMetrics;
                if (data.id) {
                    metric.id = data.id;
                }

                metric.formula.entryList = metric.formula.entryList.map(entry => {
                    if (entry.type === 'stage_metrics') {
                        let stage = filterItems.find(item => item.index === entry.index);
                        if (stage) {
                            let stageColumn = stage.dropdown.find(dropdownItem => dropdownItem.key === entry.key);
                            if (stageColumn) {
                                entry.column_name = stageColumn.column_name;
                                entry.value = stageColumn.item
                            }
                        }
                    }

                    return entry
                })

                this.createFormulaExpression(metric.formula.entryList).then(formula => {
                    metric.formula.expression = formula
                })

                metric.templateMetric = true;
                return metric
            });
        },

        getStarredColumns(filterItems) {
            let starredColumns = [];
            filterItems.forEach(filterItem => {
                if (filterItem.dropdown.length === 0) {
                    if (filterItem.starred) { starredColumns.push({ ...filterItem, dropdownItem: false }) }
                } else {
                    filterItem.dropdown.forEach(dropdownItem => {
                        if (dropdownItem.starred) { starredColumns.push({ ...dropdownItem, dropdownItem: true }) }
                    })
                }
            })

            return starredColumns
        },

        async makeTemplate(templateData, savedReport) {
            /*let savedMetricRequest = await this.store.getSavedMetrics(this.report.location_id, this.report['saved_metrics_id']);
            let { savedMetrics, colors } = savedMetricRequest.data;*/

            let { reportData, metrics, report } = await this.getSavedReport(savedReport.location_id, savedReport.id);
            let { savedMetrics, colors } = metrics;
            let customMetrics = report['custom_metrics'] ? report['custom_metrics'] : [];
            let reportType = report['report_type']

            let dataRows = reportData.map(item => {
                return this.createRow(reportData, item, reportType, customMetrics).row
            })

            for (let key in savedMetrics) {
                if (key !== 'stages') {
                    savedMetrics[key] = getMetricIndexes(savedMetrics[key]);
                } else {
                    savedMetrics[key] = savedMetrics[key].map(metric => {
                        for (let metricKey in metric) {
                            if (this.builtInStages.indexOf(metricKey) === -1) {
                                metric[metricKey] = getMetricIndexes(metric[metricKey])
                            }
                        }

                        return metric
                    })
                }
            }

            templateData.savedMetrics =  {
                metrics: savedMetrics,
                colors
            }

            let reportCustomMetrics = savedReport['custom_metrics'] ? savedReport['custom_metrics'] : [];
            templateData.customMetrics = this.createTemplateCustomMetrics(reportCustomMetrics);
            let { location_id, report_type } = savedReport;
            let templateRequest = await this.sendTemplateCreationRequest(location_id, templateData, report_type)

            return templateRequest.data['reportTemplate']

            function getMetricIndexes(metricValue) {
                if (!metricValue) {
                    return metricValue
                }

                let value = Array.isArray(metricValue) ? metricValue : [ metricValue ];
                return value.map(item => {
                    item = item.toLowerCase()

                    if (item === 'total' || item === 'average') {
                        value = item;
                    } else {
                        value = -1;
                        let matchingRow = dataRows.find(row => row[reportType].toLowerCase() === item.toLowerCase());
                        if (matchingRow) {
                            value = dataRows.indexOf(matchingRow);
                        }
                    }

                    return value
                })
            }

        },

        async autoPopulateLocations(locations, template, baseReport, overrideDefault = false, useFilters = true) {
            return await new Promise((resolve, reject) => {
                if (!locations || !Array.isArray(locations)) {
                    reject({ message: 'Locations should be passed as an array.'})
                    return
                }

                let locationsData = {
                    populated: [],
                    toBePopulated: locations,
                    errors: []
                };

                let store = this;
                store.autoCreateState = {
                    ...store.autoCreateState,
                    creating: true,
                    processed: locationsData.populated.length,
                    total: locationsData.toBePopulated.length
                }
                addTemplateToLocation(0);
                function addTemplateToLocation(index) {
                    if (index < 0 || index === locationsData.toBePopulated.length) {
                        let response = {
                            locations: locationsData.populated
                        }



                       if (locationsData.errors.length > 0 && locationsData.errors.length === locationsData.toBePopulated.length) {
                           reject({ message: 'Could not create reports in selected locations', locationErrors: locationsData.errors })
                       } else {
                           if (locationsData.populated.length === 0) {
                               reject({ message: locations.length === 0 ? 'No locations selected' : 'Could not create reports in selected locations'})
                           } else {
                               if (locationsData.toBePopulated.length !== locationsData.populated.length) {
                                   response['locationErrors'] = locationsData.errors
                               }

                               resolve(response)
                           }
                       }


                        store.autoCreateState = {
                            ...store.autoCreateState,
                            creating: false
                        }

                        return
                    }

                    let location = locationsData.toBePopulated[index];

                    addToLocation(location.id, template, baseReport, overrideDefault, useFilters)
                        .then(() => {
                            // console.log({ report })
                            locationsData.populated.push(location);

                            store.autoCreateState.processed += 1

                            addTemplateToLocation(index+1)
                        }).catch(error => {
                            // console.log({ error })
                            locationsData.errors.push({
                                location,
                                error: error.message
                            })
                            store.autoCreateState.processed += 1
                            addTemplateToLocation(index+1)
                        })

                }


                async function getReport(locationId, reportType, dateRange) {
                    let pipelineRequest = await store.getPipelines(locationId);
                    let { pipelines } = pipelineRequest.data;

                    if (pipelines.length === 0) {
                        throw new Error('No pipelines found in location')
                    }

                    let pipelineId = pipelines[0].id;
                    let reportRequest = await store.getReportTableData(locationId, pipelineId, reportType, dateRange, []);
                    let report = reportRequest.data.results;

                    if (report.length === 0) {
                        throw new Error('No reports available for date range.')
                    }

                    let { filterItems } = store.createColumnsAndFilterItems(reportType, report);
                    return { report: filterItems, pipeline: pipelineId }
                }

                function getStarredMetrics(reportData) {
                    let starredColumns = store.getStarredColumns(reportData);
                    return starredColumns.map(column => {
                        if (column.dropdownItem) {
                            column = {
                                label: column.item,
                                column_name: column.column_name,
                                value: '',
                                starred: column.starred
                            }
                        }

                        let { label, column_name } = column;
                        return {
                            label,
                            column_name,
                            selected: { label: 'Average', value: null }
                        }
                    })
                }

                async function createSavedReport(locationId, pipelineId, reportType, format) {
                    let request = await store.saveReportWithMetrics(locationId, pipelineId, reportType, format);
                    return request.data.report;
                }


                async function addCustomMetrics(report, customMetrics) {
                    return await new Promise((resolve, reject) => {
                        let updatedReport = _.cloneDeep(report);

                        addMetric(0)
                        function addMetric(index) {
                            if (index < 0 || index >= customMetrics.length) {
                                resolve(updatedReport);
                                return
                            }

                            let metric = customMetrics[index];
                            delete metric.id;
                            delete metric.templateMetric;

                            let requestData = {
                                customMetrics: metric
                            }

                            store.sendCustomMetricRequest(report.id, requestData)
                                .then(response => {
                                    let metricData = response.data;
                                    updatedReport['custom_metrics'].push(metricData);
                                    addMetric(index + 1)
                                }).catch(error => {
                                    reject(error)
                            })
                        }
                    })
                }

                async function addToLocation(locationId, template, baseReport, overrideDefault, useFilters) {
                    let reportType = baseReport['report_type'];
                    let dateRange = {
                        from: baseReport.from_date,
                        to: baseReport.to_date
                    }

                    if ("date_preset" in baseReport && baseReport.date_preset && store.getPresetRange(baseReport.date_preset)) {
                        dateRange = store.getPresetRange(baseReport.date_preset)
                    }

                    let { report, pipeline } = await getReport(locationId, reportType, dateRange);
                    report = store.addMetricTypes(report);

                    let addBreakdown = false;
                    if (baseReport.breakdowns && baseReport.breakdowns.length > 0) {
                        addBreakdown = baseReport.breakdowns[0].visible;
                    }

                    let filterData = store.generateFilterData(report, reportType);
                    if (filterData.breakdown.length > 0 && addBreakdown) {
                        filterData.breakdown[0].visible = addBreakdown;
                    }

                /*    if (baseReport['screenshot_url']) {
                        let file = await store.getFile(baseReport['screenshot_url']);
                        console.log({ file })
                    }


*/
                    let templateData = template.data;
                    if (template.report && "template_id" in template.report) {
                        let reportTemplateID = template.report['template_id'];
                        if (reportTemplateID) {
                            let templateRequest = await this.getTemplateData(template.report.location_id, reportTemplateID);
                            templateData = templateRequest.data['reportTemplate'].templateData;
                        } else {
                            let templateName = template.name.toLowerCase();
                            if (templateName) {
                                let nameSplit = template.split(" ");
                                let lastWord = nameSplit[nameSplit.length-1];
                                templateName = lastWord === 'template' ? templateName : templateName + ' template';
                            }
                            let templateFormat = {
                                templateName: capitalizeFirstLetters(templateName),
                                sharedLocations: [],
                                templateData
                            }

                            let createdTemplate = await this.makeTemplate(templateFormat, template.report);
                            this.addNewTemplate(createdTemplate);
                        }
                    }

                    let appliedTemplateReport = store.applyTemplateToReportData(filterData, templateData, reportType);
                    filterData.metrics = appliedTemplateReport;
                    let preset = !baseReport['date_preset'] || !store.getPresetRange(baseReport['date_preset']) ? 'No preset' : baseReport['date_preset'];
                    let starredMetrics = getStarredMetrics(appliedTemplateReport);
                    let unchecked = [ ...store.reportFields, 'key' ]
                    // console.log({ appliedTemplateReport, report, pipeline })
                    let { averages, totals, kpis, filters, kpi_indicators } = baseReport;
                    let reportFormat = {
                        fromDate: dateRange.from,
                        toDate: dateRange.to,
                        reportName: template.name,
                        reportData: appliedTemplateReport,
                        default: overrideDefault,
                        averages,
                        totals,
                        kpis,
                        filters: useFilters ? filters : [],
                        kpiIndicators: kpi_indicators,
                        breakdowns: filterData.breakdown,
                        metrics: store.createMetricFormat(starredMetrics, appliedTemplateReport, unchecked),
                        datePreset: preset,
                        screenshot: null
                    }

                    let customMetrics = store.addTemplateCustomMetrics(template.customMetrics, appliedTemplateReport);
                    let locationSavedReportsRequest = await store.getLocationSavedReports(locationId);
                    let locationSavedReports = locationSavedReportsRequest.data.reports;

                    let existingReport = locationSavedReports.find(report => report['report_name'].toLowerCase() === reportFormat.reportName.toLowerCase());
                    if (existingReport) {
                        throw new Error('Report with selected name already exists in this location.');
                       // return existingReport
                    }


                    let savedReport = await createSavedReport(locationId, pipeline, baseReport.report_type, reportFormat);
                    savedReport = await addCustomMetrics(savedReport, customMetrics);
                    /*console.log({ savedReport, customMetrics })

                    console.log({ metrics: reportFormat.metrics, customMetrics, starredMetrics, appliedTemplateReport })*/
                    return savedReport;
                }
            })
        },


        async getFile(fileLink) {
            let request = await fetch(fileLink);
            return await request.blob()
        }
    }
})
