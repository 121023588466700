<template>
<div v-if="visible"
     :class="[
         'report-save-component',
         {
           'popup-component': closeable,
           'within-view': closeable && open,
           'out-of-view': closeable && !open
         }]">
  <div :class="[
      'header-section',
       {
           'modal-header' : closeable
       }]">
    <div class="flex header-actions">
     <div v-for="(section, sectionIndex) in availableSections"
          :key="sectionIndex"
          :class="[
              'section-heading',
               {
               'active-section': section.section === activeSection
              }
          ]"
          @click="activeSection = section.section"
     >
         <h3>{{ section.label }}</h3>
     </div>
    </div>

      <i class="bx bx-x close-icon"
         @click="closeReports()"></i>
  </div>

  <div class="report-data-section full-height" v-if="activeSection === 'reports'">
      <div class="flex align-items-center select-section y-padding-sm">
          <div v-if="reportList.length > 0" class="flex select-all flex-align-center">
              <checkbox
                      color="#5094DD"
                      :checked="selectAll"
                      @change="toggleSelectAll($event)"
              />

              <label class="field-label check-label">
                  Select All
              </label>
          </div>
          <div class="performable-actions" v-if="checkedReports.length > 0">
              <div class="bulk-action-section flex flex-align-center">
                  <label class="field-label right-margin-sm">Bulk actions:</label>
                  <dropdown
                          :items="bulkActions.options"
                          :default-value="bulkActions.defaultValue"
                          :searchable="false"
                          class="select-list"
                          @value-change="getSelectedAction($event)"
                  />
              </div>
          </div>
      </div>

      <div class="y-padding-sm search-section" v-if="savedReportList.length > 1">
          <div class="search-bar">
              <i class="bx bx-search-alt search-icon"></i>
              <input
                      type="text"
                      class="form-field"
                      v-model="searchQuery"
                      placeholder="Search for a report"
                      @input="filterReports()"
              >
          </div>
      </div>


      <div class="top-margin-md text-center" v-if="reportList.length === 0">
          {{ !searchQuery ? 'There are no saved reports in this location.' : 'No reports found.' }}
      </div>

      <div class="report-view">
          <div class="report-section" v-if="reportList.length > 0">
              <div v-for="(item, index) in reportList"
                   :class="['report-item shadowed', { 'selected': item.selected }]"
                   :key="index"

              >
                  <div class="bottom-margin-md flex flex-align-center">
                      <div class="name-data flex">
                          <checkbox
                                  color="#5094DD"
                                  :checked="item.selected"
                                  @change="detectChange($event, item)"
                                  class="data-checkbox"
                          />
                          <h5 class="report-name bold-text"> {{ item.report['report_name'] }}</h5>
                      </div>

                      <options-list
                              :options="item.optionsList"
                              :option-class="getOptionClass"
                              @select="setAction($event, item, index)"
                      >
                          <template v-slot:option="{ option }">
                              <span class="slot-option-data">
                                  {{ option.action === 'setDefault' && item.report.default ? 'Remove as default' : option.label }}
                              </span>
                          </template>
                      </options-list>
                  </div>

                  <div class="report-image">
                      <div :class="['star-section']"
                           @click="toggleDefault(item)"
                      >
                          <i :class="['bx bxs-star', {'scale-star': item.scaleStar }]" v-if="item.report.default"></i>
                          <i  :class="['bx bx-star', {'scale-star': item.scaleStar }]" v-if="!item.report.default"></i>
                      </div>

                      <!--          <div class="img-overlay"></div>-->

                      <i class="bx bxs-dashboard reload-icon" v-if="!item.state.imageLoaded"></i>

                      <img v-if="item.report['screenshot_url']"
                           :alt="item.report['report_name']"
                           :src="item.report['screenshot_url']"
                           v-show="item.state.imageLoaded"
                           @load="getImage(item)"
                      >
                  </div>

                  <div class="button-section">
                      <button
                              :class="[
                                'button view-btn',
                                {
                                  'button-blue': !item.state.viewButton.fetchError,
                                  'button-red': item.state.viewButton.fetchError
                                }
                              ]"
                              @click="getReport(item, false)"
                              :disabled="fetchingReport || isLocationReport(item.report.id)"
                      >
                         <span class="btn-text">
                           {{ item.state.viewButton.fetchError && item.state.viewButton.errorText ? item.state.viewButton.errorText : item.state.viewButton.buttonText  }}
                         </span>

                          <span v-if="item.state.viewButton.fetching" class="process-spinner">
                            <q-spinner />
                          </span>
                      </button>

                      <button
                              :class="[
                                'button view-btn',
                                {
                                  'button-blue-alt': !item.state.addButton.fetchError,
                                  'button-red': item.state.addButton.fetchError
                                }
                              ]"
                              @click="getReport(item, true)"
                              :disabled="fetchingReport || isLocationReport(item.report.id)"
                      >
                         <span class="btn-text">
                           {{ item.state.addButton.fetchError && item.state.addButton.errorText ? item.state.addButton.errorText : item.state.addButton.buttonText  }}
                         </span>

                          <span v-if="item.state.addButton.fetching" class="process-spinner">
                            <q-spinner />
                          </span>
                      </button>
                  </div>
              </div>
          </div>
      </div>

      <report-editor
              v-if="activeReport"
              :report-data="reportFormat"
              :report-type="activeReport.report_type"
              :location="activeReport.location_id"
              :pipeline="activeReport.pipeline_id"
              :screenshot-url="activeReport['screenshot_url']"
              v-model:visible="showEditor"
              :default-value="activeReport['report_name']"
              action="rename"
              @complete="updateReportList($event)"
      />

      <confirmation-dialog
              v-model:visible="confirmation.visible"
              :text="confirmation.text"
              :header="confirmation.header"
              @confirm="confirmAction()"
      />

      <report-deletor
              v-model:visible="showDeleteDialog"
              :reports="selectedReports"
              @complete="refreshList()"
              @close="clearBulkAction()"
      />

      <template-creator
              v-model:visible="templateCreator.visible"
              :report="activeReport ? activeReport : {}"
              :top-level="templateCreator.topLevel"
              :admin-user="isAdminUser"
              @auto-create="emitAutoCreation($event)"
      />

      <report-share
              :report="activeReport ? activeReport : {}"
              v-model:visible="reportShare.visible"
              @share="emitAutoCreation($event)"
      />
  </div>

  <template-section
      :visible="activeSection === 'templates'"
      @template-select="getTemplate($event)"
      @template-update="getUpdatedTemplate($event)"
      @delete="emitDeletion($event)"
      :agency-location="agencyLocation"
      :admin-user="isAdminUser"
  />
</div>
</template>

<script>
import {useStore} from "../store/dataStore";
import ReportEditor from "./ReportEditor.vue";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import CustomDropDown from "./CustomDropDown.vue";
import CustomCheckbox from "./CustomCheckbox.vue";
import ReportDeletor from "./ReportDeletor.vue";
import TemplateSection from "./TemplateSection.vue";
import TemplateCreator from "./TemplateCreator.vue";
import OptionsList from "./OptionsList.vue";
import ReportShare from "./ReportShare.vue";
export default {
  name: "SavedReports",
  components: {
      ReportShare,
      OptionsList,
      TemplateCreator,
      TemplateSection,
    ReportDeletor,
    ConfirmationDialog,
    ReportEditor,
    'dropdown': CustomDropDown,
    'checkbox': CustomCheckbox
  },
  props: {
    visible: {
      type: Boolean
    },
    closeable: {
      type: Boolean,
      default: true
    },
    location: {
        type: String,
        required: true
    },
    locationReports: {
      type: Array
    },
    agencyLocation: {
        type: Boolean,
        required: false,
        default: false
    },
    isAdminUser: {
        type: Boolean,
        required: false,
        default: false
    }
  },
  setup() {
    let store = useStore();

    return {
      store
    }
  },
  emits: [
      'update:visible',
      'update',
      'get',
      'templateSelect',
      'templateDelete',
      'templateUpdate',
      'autoCreate'
  ],
  mounted() {
    this.open = this.visible;
    this.addTemplateCreationOptions();
    this.reportList = this.savedReportList;

    document.addEventListener('click', this.detectOutsideClick)
  },
  unmounted() {
    document.removeEventListener('click', this.detectOutsideClick)
  },
  watch: {
    visible(state) {
        setTimeout(() => {
          this.open = state
          if (state) {
            this.addTemplateCreationOptions();
            this.reportList = this.savedReportList;
          }
        }, 0)
    }
  },
  data() {
    return {
      open: false,
      reportList: [],
      activeSection: "reports",
      sections: [
          {
              label: "Saved Reports",
              section: "reports"
          },
          {
              label: "Templates",
              section: "templates"
          }
      ],
      reportOptions: [
        {
            label: 'Rename report',
            action: 'rename'
        },
        {
            label: 'Set as default',
            action: 'setDefault'
        },
        {
            label: 'Delete report',
            action: 'delete'
        }
      ],
      templateOptions: {
          location: {
              label: 'Create template',
              action: 'createTemplate'
          },
          agency: {
              label: 'Create agency template',
              action: 'createAgencyTemplate'
          },
          report: {
              label: "Share report",
              action: "shareReport"
          },
      },
      activeReport: null,
      showEditor: false,
      confirmation: {
          visible: false,
          header: 'Confirm action',
          text: 'Are you sure you want to perform this action?',
          action: '',
          report: null
      },
      warning: {
        header: 'Remove default report ?',
        text: 'Are you sure you want to remove your default report? Not having a default report would lead to not having overview metrics ' +
            'displayed on your location dashboard.',
        action: 'removeDefault'
      },
      showDeleteDialog: false,
      selectedReports: [],
      selectAll: false,
      bulkActions: {
        defaultValue: '',
        options: [
          { label: 'Delete', value: 'delete' }
        ]
      },
      searchQuery: '',
      templateCreator: {
          visible: false,
          topLevel: false
      },
      reportShare: {
          visible: false
      }
    }
  },

  computed: {
      fetchingReport() {
         return this.reportList.filter(listItem => listItem.state.viewButton.fetching || listItem.state.addButton.fetching).length > 0
      },


      availableSections() {
        let sections = this.sections;
        if (this.store.reportTemplates.length === 0) {
            sections = sections.filter(section => section.section !== 'templates')
        }

        // console.log({ templates: this.store.reportTemplates, agencyLocation: this.agencyLocation, sections})
        return sections
    },

    savedReportList() {
      return  [...this.store.savedReports].map(report => {
        return {
          report,
          optionsOpen: false,
          optionsList: [...this.reportOptions],
          selected: false,
          scaleStar: false,
          state: {
            imageLoaded: false,
            viewButton: {
                fetching: false,
                fetchError: false,
                buttonText: 'View Report',
                errorText: ''
            },
            addButton: {
                fetching: false,
                fetchError: false,
                buttonText: 'Add Report',
                errorText: ''
            }
          }
        }
      })
    },

    checkedReports() {
      return this.reportList.filter(item => item.selected)
    },

    reportFormat() {
      let format = null;
      if (this.activeReport) {
        format = {
          fromDate: this.activeReport.from_date,
          toDate: this.activeReport.to_date,
          reportName: this.activeReport['report_name'],
          filters: this.activeReport.filters,
          reportData: this.activeReport['report_data'],
          default: this.activeReport.default,
          averages: this.activeReport.averages,
          kpis: this.activeReport.kpis,
          totals: this.activeReport.totals,
          kpiIndicators: this.activeReport['kpi_indicators'],
          breakdowns: this.activeReport.breakdowns,
          metricId: this.activeReport['saved_metrics_id'],
          id: this.activeReport.id,
          customMetrics: this.activeReport['custom_metrics'] ? this.activeReport['custom_metrics'] : [],
          screenshot: null
        }
      }
      return format
    }
  },
  methods: {
      isLocationReport(reportId) {
          let locationReports = this.locationReports.map(report => report.id);
          return locationReports.indexOf(reportId) !== -1
      },
      addTemplateCreationOptions() {
          for (let key in this.templateOptions) {
              let templateOption = this.templateOptions[key];
              let { action } = templateOption

              let existingOption = this.reportOptions.find(option => option.action === action);
              if (!existingOption) {
                  let addOption = true;
                  if (templateOption.action === 'createAgencyTemplate' && !this.isAdminUser) {
                      addOption = false
                  }

                  if ((action === 'createTemplate' || action === 'shareReport') && !this.agencyLocation) {
                    addOption = false
                  }

                  if (addOption) {
                      let availableOptions = this.reportOptions.length;
                      this.reportOptions.splice(availableOptions-1, 0, templateOption)
                  }
              }
          }

      },

      detectChange(selected, item) {
          item.selected = selected;
          this.selectAll = this.reportList.filter(item => item.selected).length === this.reportList.length
      },
      getOptionClass(option) {
        return { 'delete-text': option.action === 'delete' }
      },
      getImage(item) {
       item.state.imageLoaded = true
      },
      filterReports() {
        this.reportList = this.savedReportList.filter(item => item.report['report_name'].toLowerCase().includes(this.searchQuery.toLowerCase()))
      },
      getReport(item, addReport = true) {
        let report = item.report;

        let stateObject = addReport ? 'addButton' : 'viewButton';
        item.state[stateObject] = {
          ...item.state[stateObject],
          fetching: true,
          fetchError: false,
          buttonText: 'Loading data...',
          errorText: ''
        }

        this.getReportData(report.location_id, report.id)
            .then(data => {
              item.state[stateObject] = {
                ...item.state[stateObject],
                fetching: false,
                fetchError: false,
                buttonText: `${addReport ? 'Add' : 'View'} report`
              }

              this.closeReports();
              this.$emit('get', { data, addReport })
            }).catch(error => {

              item.state[stateObject] = {
                ...item.state[stateObject],
                fetching: false,
                fetchError: true,
                errorText: 'Failed to fetch report : ' + error.message,
                buttonText: `${addReport ? 'Add' : 'View'} report`
              }


              setTimeout(function () {
                item.state[stateObject] = {
                  ...item.state[stateObject],
                  fetchError: false,
                  errorText: ''
                }
              }, 3000)
            })
      },

      async getReportData(locationId, reportId) {
        return await this.store.getSavedReport(locationId, reportId);
      },

      toggleSelectAll(checked) {
        this.selectAll = checked;

        this.reportList = this.reportList.map(item => {
          item.selected = checked;
          return item
        })
      },

      clearBulkAction() {
        this.bulkActions.defaultValue = ''
      },
      getSelectedAction(event) {
        let optionValue = event.value;
        let reports = this.checkedReports.map(item => item.report);

        this.bulkActions.defaultValue = event.label;

        switch (optionValue) {
          case 'delete':
            this.selectedReports = reports;
            this.showDeleteDialog = true;
            return
        }
      },

      detectOutsideClick(event) {
        if (!event.target.closest('.options-section')) {
          this.reportList = this.reportList.map(item => {
            item.optionsOpen = false;
            return item
          })
        }
      },
      setAction(event, item) {
        let { action } = event;
        this.activeReport = item.report;
        switch (action) {
          case 'rename':
            this.showEditor = true;
            break
          case 'setDefault':
            this.toggleDefault(item);
            break
          case 'delete':
            this.displayDeleteDialog(item.report);
            break
          case 'createTemplate':
            this.templateCreator = {
                ...this.templateCreator,
                topLevel: false,
                visible: true
            }
            break
          case 'createAgencyTemplate':
            this.templateCreator = {
                ...this.templateCreator,
                topLevel: true,
                visible: true
            }
            break
          case 'shareReport':
           this.reportShare.visible = true;
           break
        }
      },

      displayDeleteDialog(report) {
        this.selectedReports = [ report ]
        this.showDeleteDialog = true;
      },

      toggleDefault(item, confirmed = false) {
        let reportId = item.report.id;
        let reportItem = this.reportList.find(item => item.report.id === reportId);
        this.activeReport = reportItem.report;

        item.scaleStar = true;
        setTimeout(() => {
            item.scaleStar = false;
        }, 5000);

        let newState = !reportItem.report.default;
        if (!newState && !confirmed) {
          this.confirmation = {
              visible: true,
              report: item,
              ...this.warning
          }
        } else {
          this.store.setDefaultReport(reportId)

          let format = this.reportFormat;
          delete format.id;
          delete format.screenshot;

          this.store.updateSavedReport(this.activeReport.location_id, this.activeReport.pipeline_id, this.activeReport.id, format)
        }
      },

      toggleOptions(index) {
        this.reportList = this.reportList.map((item, itemIndex) => {
          if (index !== itemIndex) {
            item.optionsOpen = false
          } else {
            item.optionsOpen = !item.optionsOpen
          }
          return item
        })

      },
      closeReports() {
        this.open = false;

        setTimeout(() => {
          this.$emit('update:visible', false);
          this.resetList();
        }, 300)
      },

      resetList() {
        this.selectAll = false;
        this.reportList = this.reportList.map(item => {
          item.selected = false;
          return item
        })
      },

      refreshList() {
        this.bulkActions.defaultValue = '';

        let deletedTemplates = this.selectedReports.map(report => report.id);
        this.reportList = this.reportList.filter(item => deletedTemplates.indexOf(item.report.id) === -1)

        this.updateCurrentReport();

        this.activeReport = null
      },

      updateCurrentReport() {
        if (this.locationReports.length > 0) {
          this.locationReports.forEach(report => {
              let updatedReport = this.reportList.find(item => item.report.id === report.id);
              if (!updatedReport) {
                  this.$emit('update', { id: report.id, location_id: report.location_id, report: {}})
              }
          })
        }
      },

      confirmAction() {
        switch (this.confirmation.action) {
            case "removeDefault":
                this.toggleDefault(this.confirmation.report, true)
                return
        }
      },

   updateReportList(data) {
        console.log({ data, reportList: this.reportList });
        let report = data.report.report;
        this.reportList = this.reportList.map(item => {
            if (item.report.id === report.id) {
                item.report = report
            }
            return item
        })

       this.$emit('update', { id: report.id, location_id: report.location_id, report })
   },

    getTemplate(data) {
         this.closeReports();
         this.$emit('templateSelect', data)
    },
    emitDeletion(event) {
      this.$emit('templateDelete', event)
    },

    getUpdatedTemplate(event) {
        this.$emit('templateUpdate', event)
    },

    emitAutoCreation(eventData) {
         this.$emit('autoCreate', eventData)
    }
  }
}
</script>

<style lang="scss">
.select-list{
  .dropdown-menu{
    min-width: 100%;
    max-width: 100%;
  }
}

.report-section, .template-section{
  .report-item, .template-item{
    &.selected{
      .options-section{
        button {
          color: var(--white);
        }
      }

      .options-list{
        ul{
          &:before{
            border-bottom: 8px solid rgba(255, 255, 255, 1);
          }
        }
      }
    }

    &:not(.selected) {
      .options-section{
        button {
          color: var(--theme-blue-alt);
        }
      }

      .options-list{
        ul{
          &:before{
            border-bottom: 8px solid rgba(80, 148, 221, 0.25);
          }
        }
      }
    }
  }
}
</style>


<style lang="scss" scoped>
@import "../assets/css/saved-reports.scss";
@import "../assets/css/reports-templates.scss";
</style>
