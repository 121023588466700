import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from "../views/DashboardView";
import SetupView from "../views/SetupView";
import FieldManagementView from "../views/FieldManagementView";
import VisualizationV2 from "../views/VisualizationV2";
import Overview from "../views/Overview";
import PageNotFound from "../views/PageNotFound";
import TestView from "../views/TestView.vue";

const routes = [
  {
    path: '/',
    name: 'Reports',
    component: Dashboard
  },
  {
    path: '/setup',
    name: 'Setup',
    component: SetupView
  },
  {
    path: '/field-management',
    name: 'Field management',
    component: FieldManagementView
  },
  {
    path: '/:locationId/visualization',
    name: 'Visualization',
    component: VisualizationV2
  },
  {
    path: '/overview/:locationId',
    name: 'Overview metrics',
    component: Overview
  },
  {
    path: '/test-view',
    name: 'Testing page',
    component: TestView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: "/:pathMatch(.*)*",
    name: '404 - Page Not Found',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
