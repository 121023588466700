<template>
  <div
      class="error-handler flexbox flex-align-center flex-justify-center br-mid"
      >
    <img src="../assets/icons/exclamation-triangle.svg" alt="exclamation triangle"/>
    <div class="error-text left-margin-sm">{{ error }}</div>
    <slot name="body" v-if="$slots.body"></slot>
  </div>
</template>

<script>
export default {
  name: "ErrorHandler",
  props: {
    error: String
  }
}
</script>

<style scoped>
.error-handler{
  background: var(--white);
  width: calc(100% - 10rem);
  margin: 3rem auto;
  padding: 3rem 0;
  color: #ff0000;
}

.error-handler img{
  width: 24px;
  height: 24px;
  filter: brightness(0) saturate(100%) invert(16%) sepia(56%) saturate(7084%) hue-rotate(2deg) brightness(101%) contrast(127%);
}

.error-handler .error-text{
  font-size: .9rem;
  font-weight: 700;
}

@media screen and (max-width: 650px) {
  .error-handler{
    flex-direction: column;
    width: calc(100% - 2rem);
  }
}
</style>
