<template>
    <div class="dashboard-options-sidebar shadowed" ref="options_sidebar">
        <div class="main-content">
            <div class="options-menu-mini">
                <div
                        :class="{open_bar: options_bar_open}" class="menu-trigger" @click="options_bar_open = !options_bar_open">
                    <img src="../assets/icons/options_icon.svg" class="icon" alt="Filter icon"  />
                </div>
            </div>

            <div class="menu-content options-menu-main" ref="filter_stages">
                <div class="menu-header">Filter Stages</div>
                <div class="table-content-listing"
                     @drop="$emit('drop', true)"
                     @dragenter.prevent
                     @dragover.prevent
                     ref="table_content_listing"
                >
                    <div class="mode-switcher" v-if="reportType === 'tag'">
                        <div class="mode-items">
                            <div class="item-state"
                                 v-for="(filters, index) in filter_types"
                                 :key="index"
                                 :class="{'active-state': filters === filterState}"
                                 @click="$emit('stateChange', { state: filters })"
                            >
                                <img alt="switch to metrics" src="../assets/icons/metrics.svg" v-if="filters === 'metrics'">
                                <img alt="switch to breakdown" src="../assets/icons/protractor.svg" v-if="filters === 'breakdown'"/>
                                <span>{{ capitalizeFirstLetter(filters) }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="column-item item-control" v-if="filterState !== 'breakdown'">
                        <div class="item-heading not-draggable">
                            <checkbox
                                    :checked="allStagesVisible"
                                    color="#5094DD"
                                    @change="$emit('allStagesStateChange', $event)"
                            />

                            <div class="text-item">Show All</div>
                            <img alt="refresh filters" class="refresh-icon" src="../assets/icons/refresh-icon.svg" @click="$emit('refresh')"/>
                        </div>
                    </div>

                    <div class="column-item"
                         v-for="(item, index) in filterItems"
                         :key="item.label"
                         :ref="'column-item-'+index"
                         data-item="column-item"
                         :data-label="item.column_name"
                         :class="{
                           'hovered-element': dragData.position === index,
                           'unlisted': item.dropdown.length === 0
                         }"
                        >
                        <div
                                class="item-heading"
                                :class="{
                                    'filter-item-bg': item.dropdown.length > 0,
                                    'not-draggable': uncheckedFields.indexOf(item.column_name) !== -1,
                                    'draggable': uncheckedFields.indexOf(item.column_name) === -1
                                  }"
                                :data-label="item.column_name"
                                :ref="'column-item-heading-'+index"
                                draggable="true"
                                @drag="detectDrag($event)"
                                @dragstart="startDrag($event, item.column_name, index)"
                                @dragend="endDrag(index)"
                        >
                            <div v-if="uncheckedFields.indexOf(item.column_name) === -1"
                                 class="drag-icon-holder"
                                 :ref="'drag-icon-'+index"
                                 :data-label="item.column_name"
                                 data-item="drag-icon"
                            >
                                <i  class="bx bx-dots-vertical-rounded drag-icon"></i>
                            </div>
                            <checkbox
                                    :checked="item.dropdown.length === 0 ? item.visible : item.all_items_visible"
                                    color="#5094DD"
                                    @change="active_stage_index = index;toggleColumn(index);"
                                    :disabled="loadBreakdown"
                            />
                            <div :data-label="item.column_name" class="txt">
                                <div class="inner-text">
                                    <div class="metric-name"  @click="handleFilterItemClick(index)">
                                        <span> {{ item.label }}</span>
                                    </div>

                                    <i class="bx bx-link-alt link-icon"
                                       v-if="item.metric_type && item.metric_type === 'combined_metric' && canEditCustomMetrics"
                                       @click="$emit('displayCustomMetrics', item.column_name)"
                                    >

                                    </i>

                                    <i class="bx star-section"
                                       v-if="item.dropdown.length > 0"
                                       @click="toggleStarredColumn(item, true, false)"
                                       :class="{
                                          'bxs-star': hasStarredItems(item),
                                          'bx-star': !hasStarredItems(item)
                                       }"
                                    ></i>
                                </div>
                                <div class="arrow-icon"
                                     v-if="item.dropdown.length > 0"
                                     @click="handleFilterItemClick(index)"
                                >
                                    <i v-if="item.dropdown.length > 0"
                                       class="bx bx-chevron-down"
                                       :class="{
                                          'open': item.dropdown_visible
                                         }"
                                    />
                                </div>
                            </div>

                            <i class="bx star-section"
                               v-if="item.dropdown.length === 0 && uncheckedFields.indexOf(item.column_name) === -1"
                               :class="{
                                  'bxs-star': isStarred(item.column_name),
                                  'bx-star': !isStarred(item.column_name)
                               }"
                               @click="toggleStarredColumn(item, false)"
                            ></i>

                        </div>
                        <div class="dropdown-list" v-if="item.dropdown.length > 0">
                            <div class="dropdown-item"
                                 v-for="(dropdown_item, item_index) in item.dropdown"
                                 :key="dropdown_item.item">
                                <checkbox
                                        :checked="dropdown_item.visible"
                                        color="#5094DD"
                                        @change="active_stage_index = index; toggleDropdownColumn(index, item_index)"
                                />

                                <div class="text-item">{{ dropdown_item.item }}</div>

                                <i class="bx bx-link-alt link-icon"
                                   v-if="dropdown_item.metric_type && dropdown_item.metric_type === 'combined_metric' && canEditCustomMetrics"
                                   @click="$emit('displayCustomMetrics', dropdown_item.column_name)"
                                />

                                <i class="star-section bx"
                                   @click="toggleStarredColumn(dropdown_item, false, true); checkParentColumn(dropdown_item, item)"
                                   :class="{
                                      'bxs-star': isStarred(dropdown_item.column_name),
                                      'bx-star': !isStarred(dropdown_item.column_name)
                                   }"
                                ></i>
                            </div>

                            <div class="dropdown-item apply-to-all-text">
                                <checkbox
                                        :checked="item.apply_to_all"
                                        color="#5094DD"
                                        @change="toggleApplyToAll(index)"
                                />
                                <div class="text-item">{{ applyToAllText }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="custom-metric-section"
                         v-if="filterState === 'metrics' && canEditCustomMetrics">
                        <button class="trigger-btn" @click="$emit('displayCustomMetrics')">
                            <i class="bx bxs-plus-circle"></i>
                            <span>Custom Metric</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
import checkbox from "./CustomCheckbox.vue";
import Velocity from "velocity-animate";
import {capitalizeFirstLetters} from "../helpers/helper_functions";
import _ from "lodash";
import { useStore } from "../store/dataStore";

export default {
    name: "StageFilters",
    components: {checkbox},
    setup() {
        return {
            store: useStore()
        }
    },
    props: {
        reportType: {
            type: String,
            required: true
        },
        filterState: {
            type: String,
            required: true,
            default: 'metrics'
        },
        allStagesVisible: {
            type: Boolean,
            required: true,
            default: false
        },
        uncheckedFields: {
            type: Array
        },
        filterItems: {
            type: Array,
            required: true
        },
        loadBreakdown: {
            type: Boolean
        },
        dragData: {
            type: Object
        },
        visibleColumns: {
            type :Array
        },
        canEditCustomMetrics: {
            type: Boolean,
            default: false
        },
        canEditStages: {
            type: Boolean,
            default: false
        },
        activateBreakdown: {
            type: Boolean,
            default: false
        },
        starredMetrics: {
            type: Array,
            required: true
        }
    },
    emits: [
        'drop',
        'stateChange',
        'metricReset',
        'allStagesStateChange',
        'refresh',
        'displayCustomMetrics',
        'starred',
        'createReport',
        'change',
        'applyToAll',
        'update:loadBreakdown',
        'update:dragData',
        'update:visibleColumns',
        'update:filterItems',
        'update:activateBreakdown'
    ],
    mounted() {
       // console.log({ filterItems: this.filterItems })
        this.closeDropdowns()
    },
    watch: {
        options_bar_open(state) {
            let width = !state ? 0 : 290,
                container_width = !state ? 60 : 350,
                opacity = !state ? 0 : 1;


            Velocity(this.$refs.filter_stages,
                {
                    width: width,
                    opacity: opacity
                }, {
                    duration: 500,
                    complete:  () => {
                        if (!state) {
                            this.closeDropdowns()
                        }
                        // console.log(width);
                        //  width === 60 ? filter_stages.style.display = "none" : null;
                    }
                })
            Velocity(this.$refs.options_sidebar, {width: container_width}, 500)
        },
    },
    data() {
        return {
            options_bar_open: false,
            filter_types: ['metrics', 'breakdown'],
            active_stage_index: null,
            applyToAllText: 'Apply selected fields to every stage',
        }
    },
    methods: {
        isStarred(columnName) {
            let metric = this.starredMetrics.find(metric => metric.column_name === columnName);
            return !!metric
        },
        hasStarredItems(item) {
            let starred = item.dropdown.filter(dropdownItem => this.isStarred(dropdownItem.column_name));
            return starred.length === item.dropdown.length
        },
        capitalizeFirstLetter(string) {
            return capitalizeFirstLetters(string)
        },
        getDragPosition(event) {
            // console.log('Distance from top:', event.clientY);
            let sideBar = this.$refs.options_sidebar;
            let index = Array.from(document.querySelectorAll('.dashboard-options-sidebar')).indexOf(sideBar);
            if (index === -1) {
                return
            }

            let filter_table_offset = this.$refs.table_content_listing.getBoundingClientRect();

            let quasarTable = document.querySelectorAll('.q-table__container .scroll')[index]
            let tableHeader = quasarTable.querySelector('.table-header-row');

            let table_heading_offset = tableHeader.getBoundingClientRect().top,
                table_heading_height = tableHeader.clientHeight;

            if ((event.clientY > table_heading_offset) && (event.clientY <= table_heading_offset + table_heading_height) && (event.clientX < filter_table_offset.left)) {
                let maxOffset = 0;
                let header_rows = Array.from(tableHeader.children),
                    tableScroll = quasarTable.scrollLeft

                let children_within_offset =  header_rows.map(child => {
                    maxOffset += child.offsetWidth;
                    return  maxOffset <= event.clientX + tableScroll ? child : ''
                }).filter(element => element !== '');

                let headerElement = header_rows[children_within_offset.length].dataset.label
                let dragData = _.cloneDeep(this.dragData)
                dragData.headerElement = headerElement
                this.$emit('update:dragData', dragData)
            }

            if (event.clientX > filter_table_offset.left) {
                let tableContentListing = this.$refs.table_content_listing;
                let columnItems = Array.from(tableContentListing.querySelectorAll(".table-content-listing .column-item"));

                columnItems.map((column, index) => {
                    if (this.isWithinFilterItem(event, column )) {
                        if (index > 0) {
                            let dragData = _.cloneDeep(this.dragData)
                            dragData.position = index;
                            this.$emit('update:dragData', dragData)
                        }
                    }
                })
            } else{
                if (this.dragData.position !== -1) {
                    let dragData = _.cloneDeep(this.dragData)
                    dragData.position = -1
                    this.$emit('update:dragData', dragData)
                }
            }
        },

        isWithinFilterItem(event, column, topOffset = 0, leftOffset = 0) {
            let mousePosition = {
                x: event.clientX,
                y: event.clientY
            }

            let clientPosition = column.getBoundingClientRect();
            let columnPosition = [clientPosition.left, clientPosition.top];
            let maxPosition = [clientPosition.left + column.clientWidth, clientPosition.top + column.clientHeight];

            let withinWidth = (mousePosition.x > (columnPosition[0] - leftOffset)) && mousePosition.x < maxPosition[0];
            let withinHeight = (mousePosition.y > (columnPosition[1] - topOffset)) && mousePosition.y < maxPosition[1];

            return withinWidth && withinHeight
        },

        detectDrag(event) {
            if (this.dragData.start !== -1) {
                let columnRef = this.$refs['column-item-'+this.dragData.start];

                if (columnRef && !columnRef.classList.contains('out-of-view')) {
                    columnRef.classList.add('out-of-view')
                }
            }

            this.debounceDrag(event)
        },

        debounceDrag: _.debounce(function (event) {
            this.getDragPosition(event)
        }, 5),

        /**  Detects drag of item in the "Filter stages" sidebar and stores its index **/
        startDrag(event, column_name, index) {
            let drag_icon = this.$refs[`drag-icon-${index}`];
            if (this.uncheckedFields.indexOf(column_name) === -1) {
                if (event.offsetX >= drag_icon.offsetLeft && event.offsetX <= drag_icon.offsetLeft + drag_icon.clientWidth) {
                    event.dataTransfer.dropEffect = "move";
                    event.dataTransfer.effectAllowed = "move";
                    let currentDragData = _.cloneDeep(this.dragData)
                    currentDragData.start = index;

                    this.$emit('update:dragData', currentDragData)
                } else{
                    event.preventDefault();
                }
            } else{
                event.preventDefault();
            }
        },

        endDrag(index) {
            let columnRef = this.$refs['column-item-'+index];
            columnRef.classList.remove('out-of-view')
        },
        /**
         * Toggles dropdown visibility of items with dropdowns in the
         * "Filter stages" sidebar
         **/
        handleFilterItemClick(index)  {
            let item = this.filterItems[index]
            if (item.dropdown.length > 0) {
                let filterItems = [...this.filterItems].map((dropdownItem, itemIndex) => {
                    let dropdownVisible = dropdownItem.dropdown.length > 0 && itemIndex !== index && dropdownItem.dropdown_visible
                    if (dropdownVisible) {
                        this.toggleDropdownState(false, itemIndex)
                    }

                    return dropdownItem
                })
                let state = !item.dropdown_visible;

                this.toggleDropdownState(state, index);
                this.$emit('update:filterItems', filterItems)
            }
        },

        /** Toggles table column visibility of the main items in the
         *  "Filter Stages" sidebar, toggleDropdownColumn method toggles
         *  the visibility of the items in the dropdowns
         **/
        toggleColumn(index) {
            let item = this.filterItems[index];

            if (item.dropdown.length > 0) {
                item.all_items_visible = !item.all_items_visible
                item.dropdown = item.dropdown.map(dropdown_item => {
                    !item.all_items_visible ? this.removeColumn(dropdown_item.column_name)
                        : this.addColumn(dropdown_item.column_name);

                    dropdown_item.visible = item.all_items_visible
                    return dropdown_item
                })


            } else{
                item.visible = !item.visible;
                !item.visible ? this.removeColumn(item.column_name) : this.addColumn(item.column_name);
            }



            this.removeApplyToAll(item);
            this.$emit('change')

            if (this.filterState === 'breakdown') {
                let activeBreakdowns = this.filterItems.filter(item => item.visible).length;
                let activateBreakdown = false;
                if (activeBreakdowns > 0 && !this.activateBreakdown) {
                    activateBreakdown = true;
                }

                if (activeBreakdowns === 0 && this.activateBreakdown) {
                    activateBreakdown = false;
                }

                this.$emit('update:activateBreakdown', activateBreakdown)
                this.$emit('update:loadBreakdown', true)

            } else {
               this.$emit('metricReset')
            }
        },
        checkParentColumn(dropdownItem, parent) {
            if (!dropdownItem.starred) {
                parent.starred = false;
            }

            let starredItems = parent.dropdown.filter(item => item.starred);

            if (starredItems.length === parent.dropdown.length) {
                parent.starred = true;
            }
        },

        applyStageToAll(index) {

            let filter_item_state = this.filterItems[index]['dropdown'];
            //console.log('Selected filter item:', filter_item_state);

            if (this.filterItems[index]['apply_to_all']) {
                let filterItems = [...this.filterItems]
                filterItems = filterItems.map((filter_item, filter_index) => {
                    if (filter_index !== index) {
                        filter_item.apply_to_all = false
                    }

                    if (filter_item.dropdown.length  > 0) {
                        let filter_item_dropdown = filter_item.dropdown;

                       filter_item.dropdown = filter_item_dropdown.map(dropdown_item => {
                            const stage_item = filter_item_state.find(state_item => state_item.key === dropdown_item.key);

                            if (stage_item) {
                                dropdown_item.visible = stage_item.visible;
                            }

                            return dropdown_item
                        })

                        filter_item.all_items_visible = filter_item_dropdown.filter(item => !item.visible).length === 0;
                    }

                    return filter_item
                })

               this.$emit('applyToAll')
               // this.$emit('update:visibleColumns', visibleColumns)
                this.$emit('update:filterItems', filterItems)
            }
        },

        removeApplyToAll(item) {
            if (item.dropdown.length > 0 && !item.apply_to_all){
                let filterItems = [...this.filterItems].map(filterItem => {
                    if (filterItem.apply_to_all) {
                        filterItem.apply_to_all = false
                    }
                    return filterItem
                })

                this.$emit('update:filterItems', filterItems)
            }
        },

        toggleApplyToAll(index) {
            let filterItems = [...this.filterItems].map((item, itemIndex) => {
                if (itemIndex !== index && item.apply_to_all) {
                    item.apply_to_all = false
                }
                if (itemIndex === index) {
                    item.apply_to_all = !item.apply_to_all
                    if (item.apply_to_all) {
                        this.applyStageToAll(index);
                    }
                }
                return item
            })

            this.$emit('update:filterItems', filterItems)
            this.$emit('change');
            this.$emit('metricReset');

        },

        toggleStarredColumn(column, hasDropdown, dropdownItem = false) {
            if (!this.canEditStages) {
                let currentColumn = {
                    column,
                    hasDropdown,
                    dropdownItem
                }

                this.$emit('createReport', currentColumn)
            } else {
                this.setStarredColumn(column, hasDropdown, dropdownItem)
            }
        },


        /** Toggles visibility of items in dropdown in "Filter Stages" sidebar **/
        toggleDropdownColumn(index, item_index) {
            let dropdown_item = this.filterItems[index]['dropdown'][item_index];
            dropdown_item.visible ? this.removeColumn(dropdown_item.column_name)
                : this.addColumn(dropdown_item.column_name);

            dropdown_item.visible = !dropdown_item.visible;
            let filterItems = [...this.filterItems]

            filterItems[index].all_items_visible =  this.filterItems[index]['dropdown'].filter(item => !item.visible).length === 0;
            this.$emit('update:filterItems', filterItems);

            let mainItem =this.filterItems[index]
            if (!mainItem.apply_to_all) {
                this.removeApplyToAll(mainItem)
            } else {
                this.applyStageToAll(index)
            }

            this.$emit('metricReset')
            this.$emit('change')
        },

        /** Hides a column by removing it from the visible_columns array  **/
        removeColumn(column_name) {
            this.$emit('update:visibleColumns', this.visibleColumns.filter(column => column !== column_name))
        },

        /** Shows a column by adding it to the visible_columns array **/
        addColumn(column_name) {
           this.$emit('update:visibleColumns',  [
               ...this.visibleColumns,
               column_name
           ])
        },

        setStarredColumn(column, hasDropdown, dropdownItem = false, state = null) {
            if (!state) {
                column.starred = !column.starred;
            } else {
                column.starred = state;
            }

            if (!hasDropdown) {
                if (!dropdownItem) {
                    this.$emit('starred', column)
                } else {
                    let columnItem = this.store.createColumnItem(column);
                    this.$emit('starred', columnItem)
                }
            } else {
                if (!dropdownItem) {
                    column.dropdown = column.dropdown.map(dataItem => {
                        dataItem.starred = column.starred

                        let columnItem = this.store.createColumnItem(dataItem)
                        this.$emit('starred', columnItem)
                        return dataItem
                    })
                }
            }
        },

        toggleDropdownState(open, index) {
            let columnItem = this.$refs['column-item-'+index];
            if (!columnItem) {
                return
            }
            let filterItems = [...this.filterItems];
            filterItems[index].dropdown_visible = open;

            this.$emit('update:filterItems', filterItems)


            if (open) {
                setTimeout(() => {
                    let totalHeight = 0;
                    let columnChildren = Array.from(columnItem.children);

                    columnChildren.forEach(child => {
                        totalHeight += child.clientHeight
                    })

                    columnItem.style.height = `${totalHeight}px`
                })
            } else {
                columnItem.removeAttribute('style')
            }
        },

        closeDropdowns() {
            this.filterItems.forEach((item, index) => {
                if (item.dropdown.length > 0 && item.dropdown_visible) {
                    this.toggleDropdownState(false, index)
                }
            })
        },
    }
}
</script>

<style scoped>
@import "../assets/css/stage-filters.css";
</style>
