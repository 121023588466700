import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from './router';
import { createPinia } from 'pinia';

const app = createApp(App).use(router).use(Quasar, quasarUserOptions).use(createPinia());

let url_params = new URLSearchParams(window.location.search);
// console.log('Running in iframe with location id:', url_params.has('location_id'));
app.config.globalProperties.running_in_iframe = url_params.has('location_id');
app.mount('#app');
