<template>
<div class="edit-section dashboard-editor-section">
  <div :class="['edit-button', { 'show-actions': displayActions }]">
      <div class="button-holder">
          <button class="btn-edit" @click="triggerAction()">
              <i :class="['bx action-icon', { 'bxs-dashboard': !editMode, 'bx-x': editMode }]"> </i>
          </button>


          <button @click="triggerComplete()" :class="['btn-check', { 'in-view': editMode } ]">
              <i class="bx bx-check action-icon"></i>
          </button>
      </div>
      <ul class="edit-options" v-if="!editMode">
          <li v-for="(option, index) in options" :key="index" @click="emitAction(option.action)">
             <i :class="['bx '+option.iconClass]"></i> {{ option.icon }} <span>{{ option.label }}</span>
          </li>
      </ul>
  </div>
</div>
</template>

<script>
export default {
    name: "DashboardEditor",
    emits: [
        'reportSelect',
        'edit',
        'update:editMode',
        'cancel',
        'complete'
    ],
    props: {
        editMode: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
      document.addEventListener('click', this.detectClick)
    },
    unmounted() {
      document.removeEventListener('click', this.detectClick)
    },
    data() {
        return {
            displayActions: false,
            options: [
                {
                    label: 'Edit',
                    action: 'edit',
                    iconClass: 'bxs-pencil'
                },
                {
                    label: 'Saved Reports',
                    action: 'reportSelect',
                    iconClass: 'bxs-component'
                }
            ]
        }
    },
    methods: {
        detectClick(event) {
            let targets = [
                '.dashboard-editor-section',
               /* '.edit-options'*/
            ]
            let matchingTargets = targets.filter(target => event.target.closest(target))
            // console.log({ matchingTargets })
            if (matchingTargets.length === 0 && this.displayActions) {
                this.displayActions = false
            }
        },

        emitAction(action) {
            this.$emit(action);
            if (action === 'edit') {
                this.$emit('update:editMode', true)
            }
            this.displayActions = false
        },

        triggerComplete() {
            this.$emit('update:editMode', false);
            this.$emit('complete');
        },

        triggerAction() {
            if (!this.editMode) {
                this.displayActions = !this.displayActions
            } else {
                this.$emit('update:editMode', false);
                this.$emit('cancel')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.edit-section{
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 99;

  .edit-button{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid transparent;
    position: relative;



    .button-holder{
      width: 100%;
      height: 100%;
      position: relative;

      button{
        margin: 0;
        padding: 0;
        background: transparent;
        width: 100%;
        height: 100%;
        border: 1px solid transparent;
        border-radius: 50%;
        color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        outline: 0;
        cursor: pointer;
        transition: ease-in-out .2s;

        &.btn-edit{
          background: var(--theme-blue-alt);
          box-shadow: 0 0 2px 2px rgba(80, 148, 221, .15), 0 0 0 4px rgba(80, 148, 221, .2);
          position: relative;
          z-index: 2;

          &:hover{
            box-shadow: 0 0 2px 4px rgba(80, 148, 221, .225), 0 0 2px 7px rgba(80, 148, 221, .275);
          }
        }

        &.btn-check{
          background: var(--theme-green);
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;

          &.in-view{
            box-shadow: 0 0 2px 2px rgba(39, 174, 96, .15), 0 0 0 4px rgba(39, 174, 96, .2);
            left: -70px;
            &:hover{
              box-shadow: 0 0 2px 4px rgba(39, 174, 96, .225), 0 0 2px 7px rgba(39, 174, 96, .275);
            }
          }


        }
      }

      .action-icon{
        font-size: 28px;
      }
    }


    .edit-options{
      position: absolute;
      top: 25%;
      transform: rotate(10deg) translateY(-85%);
      right: 65px;
      list-style: none;
      padding-left: 0;
      width: 150px;
      transition: ease-in-out .2s;
      opacity: 0;
      visibility: hidden;

      li{
        padding: .6rem .6rem;
        background: var(--theme-blue-alt);
        color: var(--white);
        border-radius: 8px;
        display: flex;
        align-items: center;
        line-height: 1rem;
        position: relative;
        z-index: 1;
        transition: ease-in-out .2s;
        opacity: 0;
        transform: scale(.2);

        &:after{
          content: '';
          z-index: 2;
          width: 0;
          height: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -7px;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 8px solid var(--theme-blue-alt);
        }


        &:not(:last-child) {
          margin-bottom: .6rem;
        }

        i{
          font-size: 20px;
          margin-right: .4rem;
        }

        span{
          font-size: .9rem;
        }


      }

    }

    &.show-actions{
      .edit-options{
        opacity: 1;
        visibility: visible;
        transform: rotate(0deg) translateY(-60%);

        li{
          opacity: 1;
          transform: scale(1);
          transition: ease-in-out .15s;

          &:hover{
            background: var(--black);
            cursor: pointer;

            &:after{
              border-left: 8px solid var(--black);
            }
          }
        }
      }
    }
  }
}
</style>
