<template>
    <div v-if="reportData.length > 0">
        <header class="flexbox flex-align-center bottom-margin-md">
            <div class="heading flex flex-align-center">
                <h4>
                  <span class="template-class" v-if="template && 'templateName' in template">
                      {{ templateName }}
                  </span>

                  <span v-else-if="savedReport && savedReport['report'] && savedReport['report']['report_name']">
                      {{ savedReport['report']['report_name'] }}
                  </span>

                  <span class="default-span"  v-else>
                     {{  `${capitalizeFirstLetter(report_type)} Report` }}
                  </span>
                </h4>

                <div class="clear-filters"
                     v-if="filters.length > 1"
                     @click="clearFilters()">
                    &times;
                </div>

<!--                <button v-if="dataStore.savedReports.length > 0" class="button button-blue-alt report-btn" @click="showSavedReports = true">
                    <i class="bx bxs-report"></i>
                    <span>
                      Saved reports
                    </span>
                </button>-->
            </div>

            <div class="filter-holder flexbox flex-column" v-if="filters.length > 0 && !inEditMode">
                <div class="filter-grid">
                    <div class="filter-grid-item flexbox"
                         v-for="(filter, index) in filters"
                         :key="index"
                         :class="filter.type">
                        <div class="filter-item">{{ capitalizeFirstLetter(filter.query) }}</div>
                        <div class="icon" @click="deleteFilter(index)">
                            <i class="bx bx-x-circle"></i>
                        </div>
                    </div>
                </div>

            </div>


            <div class="flexbox flex-align-center report-save-section">
                <button :disabled="inEditMode" class="button button-blue report-btn" @click="showReportSaver()">
                    <i class="bx bx-save"></i>
                    <span>{{ saveButtonText }}</span>

                </button>

                <button :class="[
                    'button report-btn',
                    {
                      'button-blue-alt': !updateState.updateSuccess && !updateState.updateError,
                      'button-green': updateState.updateSuccess && !updateState.updateError,
                      'button-red': !updateState.updateSuccess && updateState.updateError
                    }]"
                        :disabled="updateState.updating || inEditMode"
                        v-if="viewingSavedReport"
                        @click="sendUpdateRequest()"
                >
                    <i class="bx bx-pen"></i>
                    <span> {{ updateState.buttonText }}</span>
                </button>

                <button :class="[
                    'button report-btn',
                    {
                        'button-red': !templateState.updateSuccess && templateState.updateError,
                        'button-orange': !templateState.updateSuccess && !templateState.updateError,
                        'button-green': templateState.updateSuccess && !templateState.updateError,
                    }
                ]"
                        v-if="canModifyTemplate"
                        @click="updateTemplateData()"
                        :disabled="templateState.updating || inEditMode"
                >
                    <i class="bx bx-pen"></i>
                    <span>
                 {{ templateState.buttonText }}
            </span>
                </button>
            </div>
        </header>

        <div>
            <div :class="['dashboard-main-content br-large', { 'edit-mode': inEditMode }]" id="mainDash" ref="mainDash">
                <div class=" dashboard-section flexbox flex-column">
                    <div class="flex flex-align-center justify-center breakdown-load-section" v-if="loadBreakdown">
                        <q-spinner-dots class="load-spinner"/> <span class="left-margin-sm">Organizing {{ activateBreakdown ? 'breakdowns' : 'report data' }}...</span>
                    </div>
                    <!--          <div class="unassigned-section" v-if="unassigned_items.length > 0 && init_pagination.page === 1 && show_unassigned_items">

                              </div>-->
                    <div class="dashboard-data-table"
                         :class="{'with-unassigned-items': unassigned_items.length > 0}"
                         ref="dashboard_data_table"
                         v-if="visible_columns.length > 0"
                    >
                        <div class="location-data-section">
                           <span class="location-name">
                               <b>Location:</b>
                               <span>{{ locationName }}</span>
                           </span>
                        </div>
                        <q-table
                            :rows="rows"
                            :columns="columns"
                            row-key="name"
                            :visible-columns="visible_columns"
                            ref="data_table"
                            :pagination="init_pagination"
                            @dragenter.prevent
                            @dragover.prevent
                            @drop="onDrop()"
                            :loading="refreshingTable"
                            @update:pagination="updatePagination($event)"
                            v-if="!loadBreakdown"
                            :sort-method="sortTable"
                        >
                            <template
                                v-slot:top="props">
                                <div class="dashboard-top-row flexbox full-width">
                                    <div class="pipeline-name flexbox flex-align-center">
                                        <span class="name-tag"> {{ pipeline['name'] }}</span>
                                        <span class="state-text"
                                              :class="{
                                            'visible': refreshingTable && !errorText
                                          }">
                                      <span>Updating data...</span>
                                      <q-spinner-dots/>
                                    </span>

                                        <span class="state-text error-text"
                                              :class="{
                                                'visible': !refreshingTable && errorText
                                          }"
                                        >{{ errorText }}</span>
                                    </div>

                                    <slot v-if="$slots.tableHeader" name="tableHeader"></slot>

                                    <div class="top-actions">
                                        <div class="magnifiers" ref="magnifiers" :class="{'hidden': !showScalers}">
                                            <button @click="scaleScreen('up')" :disabled="currentScale >= maxScale">

                                                <img alt="magnifier-plus" src="../assets/icons/plus-icon.svg" class="plus-icon"/>
                                            </button>

                                            <button @click="scaleScreen('down')" :disabled="currentScale <= minScale">
                                                <img alt="magnifier-minus" src="../assets/icons/minus-icon.svg" class="minus-icon"/>
                                            </button>
                                        </div>
                                        <button
                                            v-if="!running_in_iframe"
                                            class="button button-blue-alt visualization-button right-margin-md"
                                        >View Visualization
                                        </button>

                                        <q-btn
                                            :props="props"
                                            :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
                                            @click="props.toggleFullscreen"
                                            class="fullscreen-toggle"
                                        ></q-btn>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:header="props">
                                <q-tr :props="props" class="table-row table-header-row" ref="table_header">
                                    <q-th
                                        v-for="col in props.cols"
                                        :key="col.name"
                                        :props="props"
                                        :class="{
                                          'main-column': col.name === report_type,
                                          'sticky-column': col.name === report_type || (report_type === 'tag' && col.name === `${report_type}_parent`),
                                          'with-shadow-header': col.column_name === dragData.headerElement && dragData.headerElement !== this.report_type,
                                          'tag-parent': col.name === `${report_type}_parent`,
                                          'tag-child': report_type === 'tag' && col.name === report_type && activateBreakdown && visible_columns.indexOf(`${report_type}_parent`) !== -1
                                          }"
                                        :ref="'table_header_'+col.column_name"
                                        :data-label="col.column_name"
                                    >
                                        <div class="cell-content">
                                            <div class="header-data">
                                                <span > {{ col.label }} </span>
                                                <div class="parent-state-change" v-if="activateBreakdown && col.name === `${report_type}_parent`" >
                                                    <div class="tooltip position-bottom success-tooltip">
                                                        <div class="tooltip-content">{{ !tagParentsHidden ? 'Hide': 'Show' }} tag names from parents</div>
                                                    </div>
                                                    <img v-if="tagParentsHidden" @click="toggleParentVisibility($event)"  alt="toggle eye" src="../assets/icons/eye-cancelled.svg"/>
                                                    <img v-if="!tagParentsHidden" @click="toggleParentVisibility($event)" alt="toggle eye cancelled" src="../assets/icons/eye.svg"/>

                                                </div>

                                                <i :class="['header-column-star bx', {
                                                    'bx-star': !columnIsStarred(col.name),
                                                    'bxs-star': columnIsStarred(col.name)
                                                }]"
                                                   v-if="!(col.name === report_type || col.name === `${report_type}_parent`)"
                                                   @click.prevent="toggleHeaderColumn(col.name, $event)"
                                                >
                                                </i>
                                            </div>

                                        </div>
                                    </q-th>
                                </q-tr>
                            </template>
                            <template v-slot:body="props">
                                <q-tr
                                    :props="props"
                                    class="table-row"
                                    :ref="'table-row-'+rows.indexOf(props.row)"
                                    :class="{
                                        'hidden-row': hidden_rows.indexOf(props.row) !== -1,
                                        'visible-row': hidden_rows.indexOf(props.row) === -1,
                                        'unassigned-row': unassigned_items.map(item => item.key).indexOf(props.row.key) !== -1,
                                        'parent-row': props.row.parentRow,
                                        'parent-child': activateBreakdown && !props.row.parentRow,
                                         'null-parent': props.row.parentRow &&  props.row.noParentRow,
                                         'hidden-total-row': !activateBreakdown && props.row.parentRow

                                      }">
                                    <!--                  <div class="hidden">{{ activateBreakdown }} {{ props.row[`${report_type}_parent`] }}</div>-->

                                    <q-td
                                        v-for="(col) in props.cols"
                                        :key="col.name"
                                        :props="props"
                                        :class="[ `${getKpiClass(col)} ${setFieldColor(col.name)}`,
                                            {
                                          'source-column': col.name === 'source',
                                          'sticky-column': col.name === report_type || col.name === `${report_type}_parent`,
                                          'tag': report_type === 'tag' && col.name === report_type,
                                          'tag-parent': col.name === `${report_type}_parent`,
                                          'tag-child': report_type === 'tag' && col.name === report_type && activateBreakdown && visible_columns.indexOf(`${report_type}_parent`) !== -1,
                                          'main-column': col.name === report_type,
                                          'no-tag-parent': col.name === `${report_type}_parent` && props.row.noParentRow
                                        }]"
                                    >
                                        <info-popup v-if="props.row.parentRow &&  hasAverageInclusiveKey(col.name)  && intValue(col.value)" :data-value="intValue(col.value)" text="This field displays averages"/>
                                        <div class="row-toggle" @click="toggleRow(props.row.key)" v-if="col.name === report_type && unassigned_items.filter(column => column[report_type] === col.value).length > 0">
                                            <div class="data-item">
                                                <!--                        <div class="tooltip position-bottom success-tooltip" v-if="hidden_rows.indexOf(props.row) === -1">
                                                                          <div class="tooltip-content">
                                                                            <span>{{ hidden_rows.indexOf(props.row) !== -1 ? 'Show' : 'Hide'}} {{ report_type === 'source' ? 'Unlabeled' : 'Not Assigned'}} {{ capitalizeFirstLetter(report_type) }}</span>
                                                                          </div>
                                                                        </div>-->
                                                <i class='bx bx-reflect-horizontal icon-item collapse-icon' v-if="hidden_rows.indexOf(props.row) === -1"></i>
                                                <i class='bx bx-expand-vertical icon-item expand-icon'  v-if="hidden_rows.indexOf(props.row) !== -1"></i>

                                            </div>
                                        </div>
                                        <div class="column-content" :class="{'source': report_type === 'source', 'centered-column': (report_type === 'tag' && col.name !== 'tag' && col.name !== `${report_type}_parent`) || (report_type !== 'tag' && col.name !== report_type)}">
                                            <div class="source-cost-tooltip"
                                                 v-if="col.name === report_type && findMissingSource(props.row.key) > 0"
                                            >
                                                <div class="relative-position"
                                                     @click="showMissingSourceCosts(props.row.key)"
                                                >
                                                    <img alt="missing source costs viewer icon" src="../assets/icons/exclamation-icon.svg">
                                                </div>
                                            </div>
                                            <span class="item-data"
                                                  :class="{
                                                    'censored-column': col.name === report_type && dataCensored,
                                                    'null-field':  formatValue(col.name, col.value) === '-'
                                              }">
                                                <span v-if="!activateBreakdown || ( activateBreakdown && col.name !== report_type )">
                                                 {{ col.name === `${report_type}_parent` && !props.row.parentRow ? '' : formatValue(col.name, col.value) }}
                                                </span>

                                                <span class="tag-state-item" v-if="activateBreakdown && col.name === report_type">
                                                  <span v-if="!getTagParent(col.value)">{{ col.value }}</span>
                                                  <span v-if="getTagParent(col.value)">
                                                    <span class="parent-prefix" v-if="!tagParentsHidden">{{ capitalizeFirstLetter(getTagParent(col.value)) }}:</span>
                                                    <span class="main-data">{{ col.value.slice(getTagParent(col.value).length+1, col.value.length)}}</span>
                                                  </span>

                                                </span>
                                              </span>

                                            <button
                                                class="button"
                                                :class="{
                                                  'button-blue': hasMissingLeadCosts(props.row.key),
                                                  'button-green': !hasMissingLeadCosts(props.row.key)
                                                }"
                                                v-if="col.name === 'source'"
                                                @click="showModal(col.value)"
                                            >
                                                {{ hasMissingLeadCosts(props.row.key)  ? 'Add' : 'Edit' }} lead cost
                                            </button>
                                        </div>



                                    </q-td>
                                </q-tr>

                            </template>

                            <template v-slot:bottom-row>
                                <q-tr class="additional-fields total-row"
                                      :class="calcRowPosition"
                                      v-if="totals_checked && Object.keys(totals).length > 0"
                                      ref="total-row"
                                >
                                    <q-td v-for="(col, key) in totals" :key="key"
                                          :class="[
                                              `${getKpiClass({ name: key, value: col })}`,
                                              {
                                            'sticky-column': key === report_type || key === `${report_type}_parent`,
                                            'tag': (report_type === 'tag' && key === report_type && activateBreakdown) || (!activateBreakdown && key === report_type),
                                            'tag-child': report_type === 'tag' && key === report_type && activateBreakdown,
                                          }
                                          ]"
                                          :ref="'total-data-'+key"
                                    >
                                        <div>
                                            <info-popup
                                                v-if="hasAverageInclusiveKey(key) && formatValue(key, col) !== '-'"
                                                text="This is the column average"
                                            />
                                            <span :class="{
                                                  'null-field': formatValue(key, col) === '-' || formatValue(key, col) === 0
                                              }"
                                            >
                                          {{ formatValue(key, col) }}
                                        </span>
                                        </div>
                                    </q-td>
                                </q-tr>

                                <q-tr class="additional-fields average-row"
                                      :class="{
                                        'bottom-123': kpi_checked && typing && hasVisibleTimeColumns(),
                                        'bottom-50': kpi_checked && typing && !hasVisibleTimeColumns(),
                                         'bottom-48': kpi_checked && !typing
                                      }"
                                      v-if="averages_checked && Object.keys(averages).length > 0"
                                      ref="average-row"
                                >
                                    <q-td v-for="(col, key) in averages"
                                          :class="[
                                              `${getKpiClass({ name: key, value: col })}`,
                                              {
                                                'sticky-column': key === report_type || key === `${report_type}_parent`,
                                                'tag': (report_type === 'tag' && key === report_type && activateBreakdown) || (!activateBreakdown && key === report_type),
                                                'tag-child': report_type === 'tag' && key === report_type && activateBreakdown,
                                              }
                                          ]"
                                          :key="key"
                                          :ref="'average-data-'+key">
                                        <div class="data-span" :data-field="'average-data-'+key+'-'+col">
                                      <span :class="{
                                              'null-field': formatValue(key, col) === '-'
                                            }">
                                        {{ formatValue(key, col) }}
                                      </span>
                                        </div>
                                    </q-td>
                                </q-tr>

                                <q-tr class="kpis"
                                      v-if="kpi_checked"
                                >
                                    <q-td
                                        v-for="(col, index) in kpi_row"
                                        :key="index"
                                        :class="{
                                      'sticky-column': col.fieldKey,
                                      'tag-child': col.fieldKey && col.fieldKey === report_type && activateBreakdown ,
                                      'tag': (col.fieldKey && col.fieldKey === report_type && activateBreakdown) ||  (!activateBreakdown && index === 0)
                                    }"
                                        @click="editField(index)"
                                    >
                                        <span v-if="index === 0">{{ col.name }}</span>
                                        <span
                                            v-if="!typing && index !== 0"
                                            class="field-value"
                                        >
                                      <span
                                          v-if="!col.time_field"
                                          :class="{'greyed-field': col.value === ''}">
                                        {{ col.value === '' ? '' : formatValue(col.name, col.value) }}
                                      </span>
                                      <span
                                          v-if="col.time_field"
                                          :class="{'greyed-field': col.value[milliSecondIndex] === 0 || col.value[milliSecondIndex] === ''}">


                                        {{ col.value[milliSecondIndex] === 0 || col.value[milliSecondIndex] === '' ? '' :  createDateFormat(col.value[milliSecondIndex]) }}
                                      </span>
                                    </span>

                                        <form
                                            ref="kpi_input_form"
                                            v-if="!col.fieldKey && typing"
                                            @submit.prevent="onSubmit().then(() => this.kpi_value_submitted = false)"
                                        >
                                            <div class="single-input" v-if="!col.time_field">
                                                <input
                                                    type="text"
                                                    v-model="col.value"
                                                    class="form-field"
                                                    v-if="typing || col.value === ''"
                                                    :key="index"
                                                    @focus="focused_field = index"
                                                    @input="validateFieldValue($event, col)"
                                                />
                                            </div>

                                            <div
                                                class="multi-input grid double-grid"
                                                v-if="col.time_field"
                                            >
                                                <div
                                                    v-for="(value, value_index) in col.value"
                                                    :key="value_index"
                                                >
                                                    <div v-if="value_index !== col.value.length-1" class="flexbox flex-align-center">
                                                        <label>{{ capitalizeFirstLetter(getObjectKey(value_index)).charAt(0) }} :</label>
                                                        <input
                                                            type="text"
                                                            v-model="col.value[value_index]"
                                                            class="form-field"
                                                            v-if="typing || col.value === ''"
                                                            :key="value_index"
                                                            @input="validateAndSetTimeValue($event, col, value_index)"
                                                            @focus="focused_field = index"
                                                        />
                                                    </div>
                                                </div>
                                                <input type="submit">
                                            </div>
                                        </form>
                                    </q-td>
                                </q-tr>
                            </template>


                            <template v-slot:no-data>
                                <q-tr>
                                    <q-td colspan="100%" class="no-data-row">
                                        <img src="../assets/icons/exclamation-icon.svg" alt="exclamation circle" class="exclamation-icon right-margin-sm"/> Couldn't find any data matching your search.
                                    </q-td>
                                </q-tr>
                            </template>

                        </q-table>
                    </div>

                    <div class="data-build-section" v-if="visible_columns.length === 0">
              <span>
                Select a metric from the sidebar and start building your report.
              </span>
                    </div>
                </div>


                <stage-filters
                    v-model:filter-items="filter_items"
                    :filter-state="filter_state"
                    :report-type="reportType"
                    :unchecked-fields="uncheckedFields"
                    :all-stages-visible="all_stages_visible"
                    :can-edit-custom-metrics="canModifyTemplate || viewingSavedReport"
                    :can-edit-stages="canEditStages"
                    :starred-metrics="starredMetrics"
                    v-model:visible-columns="visible_columns"
                    v-model:load-breakdown="loadBreakdown"
                    v-model:activate-breakdown="activateBreakdown"
                    v-model:drag-data="dragData"
                    @metric-reset="resetMetrics()"
                    @change="updateStoreSavedFilters()"
                    @create-report="displayReportCreator($event)"
                    @drop="onDrop($event)"
                    @state-change="setFilterState($event)"
                    @all-stages-state-change="setAllStagesVisible($event)"
                    @refresh="refreshList()"
                    @display-custom-metrics="showMetricModal($event)"
                    @starred="modifyStarredColumns($event)"
                    @apply-to-all="checkForVisibleColumns()"
                />


                <div class="indicators" v-if="visible_columns.length > 0">
                    <div class="flexbox flex-align-center indicator-item">
                        <div class="txt text-sm right-margin-sm">Totals</div>
                        <Switch
                            @change="toggleTotals($event)"
                            v-model:checked="totals_checked"
                            :size="24"
                            :disabled="refreshingTable"
                        />

                    </div>
                    <div class="flexbox flex-align-center indicator-item">
                        <div class="txt text-sm right-margin-sm">Averages</div>
                        <Switch
                            @change="toggleAverages($event)"
                            v-model:checked="averages_checked"
                            :size="24"
                            :disabled="refreshingTable"
                        />
                    </div>

                    <div class="flexbox flex-align-center indicator-item" >
                        <div class="txt text-sm right-margin-sm">KPIs</div>
                        <Switch
                            @change="toggleKPIs($event)"
                            v-model:checked="kpi_checked"
                            :size="24"
                            :disabled="refreshingTable || viewingTemplate"
                        />
                    </div>

                    <div class="flexbox flex-align-center indicator-item checkbox">
                        <checkbox
                            :checked="show_kpi_indicators"
                            @change="show_kpi_indicators = $event"
                            color="#000000"
                            class="right-margin-sm"/>
                        <div class="txt text-sm">Show KPI Indicators</div>
                    </div>

                    <button
                        class="button button-blue save-report"
                        :disabled="visible_columns.length === 0"
                        @click="exportReport()">
                        Export Report
                    </button>
                </div>


            </div>
            <edit-overlay :visible="inEditMode"
                          v-model:hide="hideComponent"
                          :deletable="true"
                          @deleted="$emit('delete')"
            />
        </div>

        <custom-metric-creator
            v-if="viewingSavedReport || viewingTemplate"
            v-model:visible="customMetricCreator.visible"
            :active-metric="customMetricCreator.activeMetric"
            :metric-list="filter_items"
            :report="savedReport['report']"
            :template="template"
            :custom-metrics="customMetrics"
            @create="addCustomMetric($event)"
            @update="updateCustomMetric($event)"
            @delete="deleteCustomMetric($event)"
        />

        <missing-source-data
            v-model:visible="show_missing_source"
            @closeModal="closeDataModal($event)"
            :missingSourceData="missing_source_costs"
            :index="missing_source_index"
            :report-type="reportType"
        />

        <report-saver
                v-model:open="saveState.open"
                :report-type="this.reportType"
                :report-data="createReportDataFormat"
                :pipeline="this.pipeline.id"
                :location="location"
                @save="getSavedReport($event)"
        />

        <report-editor
                v-model:visible="showReportCreator"
                :pipeline="this.pipeline['id']"
                :location="location"
                :report-type="this.reportType"
                :report-data="createReportDataFormat"
                @complete="getCreatedReport($event)"
        />

        <lead-cost-modal
            :date-range="currentRange ? currentRange : dateRange"
            v-model:visible="source.visible"
            :source-name="source.name"
            :report-type="reportType"
            :pipeline-id="pipeline['id']"
            :location-id="location"
            @source-generated="$emit('regen')"
        />
    </div>

    <div v-if="reportData.length === 0" class="empty-table-section">
        <error-handler v-if="!$slots.emptyTable"
                       error="No report data available for this time period"
        />
        <slot name="emptyTable" v-else></slot>
    </div>


</template>

<script>
import checkbox from "./CustomCheckbox.vue";
import Switch from "./Switch.vue";
import InfoPopup from "./InfoPopup.vue";
import {v4 as uuidv4} from "uuid";
import {
    addFieldSigns,
    capitalizeFirstLetters, formatTemplateName,
    formatValue, getLocationKey,
    removeSpecialChars,
    replaceSpaceWithUnderscores,
    validateInput
} from "../helpers/helper_functions";
import {useStore} from "../store/dataStore";
import _ from "lodash";
import axios from "axios";
import CustomMetricCreator from "./CustomMetricCreator.vue";
import {baseURL} from "../api/apiBase";
import MissingSourceData from "./MissingSourceData.vue";
import {setTimeValue} from "../helpers/date_functions";
import {costFields, dollarFields, percentFields} from "../helpers/field_data_types";
import StageFilters from "./StageFilters.vue";
import ReportSaver from "./ReportSaver.vue";
import ReportEditor from "./ReportEditor.vue";
import LeadCostModal from "./LeadCostModal.vue";
import {date} from "quasar";
import EditOverlay from "./EditOverlay.vue";
import ErrorHandler from "./ErrorHandler.vue";

export default {
    name: "ReportTable",
    components: {
        ErrorHandler,
        EditOverlay,
        LeadCostModal,
        ReportEditor,
        ReportSaver,
        StageFilters,
        MissingSourceData,
        CustomMetricCreator,
        InfoPopup, Switch,
        checkbox
    },
    setup() {
        return {
            dataStore: useStore()
        }
    },
    mounted() {
        let userAgent = navigator.userAgent;

        if (userAgent.match(/firefox|fxios/i)) {
            this.showScalers = false
        }

        this.mountTable();
        document.addEventListener("keydown", this.detectKeyPress);
    },
    unmounted()  {
        document.removeEventListener("keydown", this.detectKeyPress);
    },
    props: {
        currentRange: {
            required: false
        },
        searchQuery: {
            type: Object,
            required: false
        },
        reportType: {
            type: String,
            required: true,
            default: 'source'
        },
        reportData: {
            type: Array,
            required: true
        },
        savedReport: {
            type: Object,
            required: false
        },
        template: {
            type: Object,
            required: false
        },
        refreshTable: {
            type: Boolean,
            required: false,
            default: false
        },
        starredMetrics: {
            type: Array
        },
        pipeline: {
            type: Object,
            required: true
        },
        location: {
            type: String,
            required: true
        },
        filters: {
            type: Array,
            required: false
        },
        dateRange: {
            required: true
        },
        agencyLocation: {
            type: Boolean,
            default: false
        },
        metricColors: {
            type: Object
        },
        preset: {
            type: String,
            required: false,
            default: 'No preset'
        },
        savedMetricId: {
            required: false
        },
        errorText: {
            type: String,
            required: false
        },
        reportMetricId: {
            required: false
        },
        inEditMode: {
            type: Boolean,
            default: false
        },
        hidden: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'update:starredMetrics',
        'update:savedMetricId',
        'update:template',
        'update:savedReport',
        'metricChange',
        'tableDataChange',
        'update:filters',
        'filterDelete',
        'filterClear',
        'regen',
        'update:reportMetricId',
        'hide',
        'delete',
        'addSavedReport',
        'templateUpdate'
    ],
    data() {
        return {
            hideComponent: false,
            loadBreakdown: false,
            activateBreakdown: false,
            rows: [],
            columns: [],
            visible_columns: [],
            kpi_indicator_colors: [],
            missing_source_costs: [],
            filter_items: [],
            init_pagination: {
                sortBy: 'desc',
                descending: false,
                page: 1,
                rowsPerPage: 50
            },
            rowsPerPageMetrics: 50,
            rowsPerPageBreakdown: 0,
            dataStructure: "metrics",
            filterData: {
                metrics: [],
                breakdown: []
            },
            filter_state: 'metrics',
            all_stages_visible: true,
            tableData: {
                rows: [],
                columns: [],
                visible_columns: [],
                filter_items: []
            },
            dollar_fields: dollarFields,
            cost_fields: costFields,
            percent_fields: percentFields,
            totals: {},
            averages: {},
            kpi_row: [],
            init_kpi_row: [],
            customMetricCreator: {
                visible: false,
                activeMetric: {}
            },
            unassigned_items: [],
            refreshingTable: false,
            showScalers: true,
            currentScale: 1,
            minScale: .75,
            maxScale: 1,
            scaleStep: .05,
            tagParentsHidden: false,
            hidden_rows: [],
            show_kpi_indicators: false,
            missing_source_index: 0,
            show_missing_source: false,
            keyLogger: [],
            censorKeyCombo: [
                "ControlLeft",
                "ShiftLeft",
                "KeyH"
            ],
            dataCensored: false,
            averages_checked: false,
            kpi_checked: false,
            totals_checked: false,
            typing: true,
            date_object: this.dataStore.dateObject,
            focused_field: 0,
            kpi_value_submitted: false,
            dragData: {
                start: -1,
                position: -1,
                headerElement: ''
            },
            updateState: {
                updating: false,
                updateError: false,
                updateSuccess: false,
                buttonText: 'Update report'
            },
            templateState: {
                updating: false,
                updateSuccess: false,
                updateError: false,
                buttonText: 'Update template'
            },
            closeTime: 0,
            saveState: {
                open: false,
                takingScreenshot: false,
                screenshot: null,
                buttonText: 'Save As'
            },
            showReportCreator: false,
            /*generatedReport: {
                range:  {
                    from: replaceSlashes(getLastMonthDate()),
                    to:  replaceSlashes(getCurrentDate())
                },
                pipeline: {
                    name: '',
                    id: ''
                },
                reportType: '',
                presets: 'No preset'
            },*/
            currentColumn: {},
            report: [],
            source: {
                name: '',
                visible: false
            },
            customMetrics: [],
            searchTable: _.debounce(function (search_param) {
                // console.log({ search_param })
                if (!("value" in search_param)) {
                    search_param.value = ''
                }

                let filters = this.filters && Array.isArray(this.filters) ? this.filters : []
                if (!this.activateBreakdown) {
                    let og_rows = this.tableData.rows;

                    if (filters.length === 0 && search_param.value.length === 0) {
                        this.rows = og_rows;
                    } else{

                        if (!search_param.has_filters) {
                            // console.log({ og_rows, search_param })
                            //console.log('Search parameters:', search_param)

                            let search_parameters = search_param.value.split(",");
                            let search_results = [];
                            // console.log({ search_parameters })

                            if (!search_param.value) {
                                search_results = og_rows;
                            } else {
                                if (search_param.inclusiveSearch) {
                                    search_parameters.map(param => {
                                        let param_item = trimParam(param);


                                        if (param_item !== '') {
                                            let filter_results = og_rows.filter(item => trimParam(item[this.report_type].toLowerCase()).includes(param_item.toLowerCase()));
                                            if (filter_results.length > 0) {
                                                filter_results.forEach(result => {
                                                    let existing_results = search_results.filter(search_item => search_item.key === result.key);
                                                    existing_results.length === 0 ? search_results.push(result) : null;
                                                });
                                            }
                                        }
                                    })
                                }  else {
                                    search_results = og_rows.filter(row => {
                                        let matchCount = search_parameters.filter(param => trimParam(param) !== '' && trimParam(param[this.report_type].toLowerCase()).includes(trimParam(param).toLowerCase())).length;
                                        return matchCount === 0 ? row : ''
                                    }).filter(value => value !== '');
                                }
                            }

                            this.rows = search_results;
                            this.reCalculateMetrics()
                        }
                    }
                }else {
                    if (filters.length === 0 && search_param.value.length === 0) {
                        this.organizeBreakdowns(this.report)
                    } else {
                        if (!search_param.has_filters) {
                            let searchValue = search_param.value;
                            if (searchValue.length === 0) {
                                this.organizeBreakdowns(this.report)
                            } else {
                                let exactSearch = false;
                                if (searchValue && searchValue.length > 1) {
                                    if (searchValue[0] === '[' && searchValue[searchValue.length-1] === ']') {
                                        searchValue = searchValue.slice(1, searchValue.length-1);
                                        exactSearch = true;
                                    }
                                }

                                let searchParams = searchValue.toLowerCase().split(",").map(param => trimParam(param));
                                let key = `${this.report_type}Name`;
                                let results = [];
                                let reportData = [...this.report];

                                if (exactSearch) {
                                    let tagParents = [];
                                    reportData.filter(data => data['tagParent']).forEach(data => {
                                        let tagParent = data['tagParent'].toLowerCase();
                                        if (tagParents.indexOf(tagParent) === -1) {
                                            tagParents.push(tagParent)
                                        }
                                    });


                                    let matchingTagParents = tagParents.filter(tag => searchParams.indexOf(tag) !== -1);
                                    results = reportData.filter(data => data['tagParent'] && matchingTagParents.indexOf(data['tagParent'].toLowerCase()) !== -1);

                                    //  console.log({ results })
                                } else {
                                    if (search_param.inclusiveSearch) {
                                        searchParams.forEach(param => {
                                            param = trimParam(param)
                                            if (param !== '') {
                                                let matchingData;
                                                // console.log({ reportData })
                                                matchingData = reportData.filter(data => data[key].toLowerCase().includes(param.toLowerCase()));
                                                if (matchingData.length > 0) {
                                                    results = listResults(results, matchingData, this.report_type)
                                                }

                                            }
                                        })

                                    } else {
                                        results = reportData.map(data => {
                                            let inclusive = searchParams.filter(param => trimParam(param) !== -1 && (data[key].toLowerCase().includes(trimParam(param.toLowerCase()))))
                                            return inclusive.length === 0 ? data : -1
                                        }).filter(value => value !== -1)
                                    }
                                }



                                // console.log({ results })

                                this.organizeBreakdowns(results).then(() => {
                                    this.reCalculateMetrics()
                                })
                            }
                        }
                    }
                }



                function listResults(results, dataArray, reportType) {
                    let searchResults = _.cloneDeep(results);
                    dataArray.forEach(data => {
                        let key = `${reportType}Name`;
                        let matchingItem = searchResults.find(item => item[key] === data[key]);

                        if (!matchingItem) {
                            searchResults.push(data)
                        }
                    })

                    return searchResults
                }
                function trimParam(param) {
                    return param.replace(/\s+/g, ' ').trim()
                }
            }, 250),
            apiReportItem: [],
            locationKey: ""
        }
    },
    computed: {
        date() {
            return date
        },
        saveButtonText() {
            return this.savedReport && "filterData" in this.savedReport ? "Save As" : "Save"
        },
        templateName() {
            if (!(this.template && "templateName" in this.template)) {
                return ''
            }

            let templateName = this.template["templateName"];
            return formatTemplateName(templateName)
        },

        canModifyTemplate() {
            return this.viewingTemplate && this.agencyLocation
        },
        viewingSavedReport() {
            return this.savedReport && "filterData" in this.savedReport;
        },
        viewingTemplate() {
            return this.template && "templateData" in this.template;
        },
        calcRowPosition() {
            let positionClass = '';

            let averagesChecked = this.averages_checked && Object.keys(this.averages).length > 0
            if (averagesChecked && !this.kpi_checked) {
                positionClass = 'bottom-48'
            } else if (this.kpi_checked && !averagesChecked) {
                if (this.typing) {
                    positionClass = this.hasVisibleTimeColumns() ? 'bottom-123' : 'bottom-48'
                } else {
                    positionClass = 'bottom-48'
                }
            } else if (this.kpi_checked && averagesChecked) {
                if (this.typing) {
                    positionClass = !this.hasVisibleTimeColumns() ? 'bottom-max-reg' : 'bottom-max'
                } else {
                    positionClass = 'bottom-96'
                }
            }
            return positionClass
        },
        report_type() {
            return this.reportType
        },

        createTotalRow() {
            let total_row = this.calculateTotals();
            if (!this.activateBreakdown) {
                total_row[this.report_type] = 'Totals';
            }

            if (this.activateBreakdown) {
                total_row[`${this.report_type}_parent`] = 'Totals';
                total_row[this.report_type] = ''
            }

            let totals = this.createDataRow(total_row, "totals");
            this.$emit('metricChange', { metric: 'totals', value: totals })
            return totals
        },

        createAverageRow() {
            let average_row = this.createDataRow(this.calculateAverages());
            this.$emit('metricChange', { metric: 'averages', value: average_row })
            return average_row
        },

        getOverviewColumns() {
            let overviewColumns = this.columns
                .filter(column => this.uncheckedFields.indexOf(column.field) === -1)
                .map(column => ({ label: column.label, field: column.field }))


            this.$emit('metricChange', { metric: 'columns', value: overviewColumns })
            return overviewColumns
        },
        createOverviewMetricData() {
            let format = this.dataStore.createMetricFormat(this.starredMetrics, this.filterData.metrics, this.uncheckedFields);
            format['colors'] = this.metricColors;

            // console.log({ format, metrics: this.starredMetrics.data })

            return format
        },

        createReportDataFormat() {
            let format = {
                fromDate: this.dateRange.from,
                toDate: this.dateRange.to,
                reportName: "",
                filters: this.filters,
                reportData: this.filterData.metrics,
                default: false,
                averages: this.averages_checked,
                kpis: this.kpi_checked,
                totals: this.totals_checked,
                kpiIndicators: this.show_kpi_indicators,
                breakdowns: this.filterData.breakdown,
                metricId: this.savedMetricId,
                metrics: this.createOverviewMetricData,
                datePreset: this.preset === 'No preset' || !this.preset ? '' : this.preset,
                customMetrics: this.customMetrics
            }

            return format
        },

        canEditStages() {
            let canEditTemplate = this.viewingTemplate && this.agencyLocation;
            return !(!this.savedMetricId && !canEditTemplate)
        },

        starredColumns() {
            return this.dataStore.getStarredColumns(this.filter_items)
        },

        uncheckedFields() {
            let fields = [this.report_type, 'key'];

            if (this.report_type === 'tag') {
                fields = [
                    ...fields,
                    `${this.report_type}_parent`
                ]
            }

            return fields
        },
        locationName() {
            return this.dataStore.getLocationName(this.location)
        },
        milliSecondIndex() {
            return Object.keys(this.date_object).indexOf('totalInMilliseconds');
        },
    },
    watch: {
        inEditMode: {
            handler(state) {
                if (state) {
                    this.hideComponent = this.hidden;
                }
            },
            immediate: true
        },

        hideComponent(state){
            //  console.log('hideComponentState', state)
            this.$emit('hide', state)
        },

        hidden(state) {
            this.hideComponent = state
        },
        /**
         *
         Watches for changes to the "filter_items" array that can be found in the data,
         and changes the state of visibility of items in the array depending on action taken.
         *
         * */
        filter_items: {
            deep: true,
            handler(filterItems) {
                let all_stages_visible = true;
                filterItems.forEach(item => {
                    if (!item.visible) {
                        all_stages_visible = false;
                    } else {
                        if (item.dropdown.length > 0) {
                            item.dropdown.forEach(dropdown_item => {
                                !dropdown_item.visible ? all_stages_visible = false : null
                            })
                        }
                    }
                })

                this.all_stages_visible = all_stages_visible;
                this.filterData[this.filter_state] = filterItems;
            }
        },
        refreshTable(state) {
            this.refreshingTable = state;

            if (state) {
                this.totals_checked = false;
                this.kpi_checked = false;
                this.averages_checked = false;
            }
        },

        searchQuery: {
            deep: true,
            handler(query) {
                // console.log({ query })
                this.searchTable(query)
            }
        },
        reportData: {
            deep: true,
            handler(data) {
               /* this.filterData = {
                    metrics: [],
                    breakdown: []
                }*/
                this.createTable(data)
            }
        },
        savedReport: {
            deep: true,
            handler(/*reportItem*/) {
                 // console.log({ reports })
              //  console.log({ reportItem })
                 this.applySavedReport()
            }
        },
        loadBreakdown(state) {
            if (state) {
                this.organizeBreakdowns(this.report)
            }
        },
        activateBreakdown(state) {
            this.init_pagination.rowsPerPage = state ? this.rowsPerPageBreakdown : this.rowsPerPageMetrics;
            this.columns = this.columns.map(column => {
                if (column.name === this.report_type) {
                    column.sortable = !state
                }
                return column
            })
        },
        template: {
            deep: true,
            handler(data) {
                this.applyTemplate(data)
            }
        },

        starredMetrics: {
            deep: true,
            handler(data) {
                if (data.length === 0) {
                    this.setAllFilterItemState()
                }
            }
        }
    },
    methods: {
        getFilterItem(columnName) {
            let column, hasDropdown = false, dropdownItem = false;
            this.filter_items.forEach(filterItem => {
                if (filterItem.dropdown.length > 0) {
                    filterItem.dropdown.forEach(item => {
                        if (item.column_name === columnName) {
                            column = dropdownItem;
                            hasDropdown = false;
                            dropdownItem = true;
                        }
                    })
                } else {
                    if (filterItem.column_name === columnName) {
                        column = filterItem;
                        hasDropdown = false;
                        dropdownItem = false;
                    }
                }
            })

            return { column, hasDropdown, dropdownItem }
        },
        toggleHeaderColumn(colName, event){
            event.stopPropagation();
            let filterItem = this.getFilterItem(colName);
            if (!this.canEditStages) {
                this.displayReportCreator({...filterItem})
            } else {

                this.filter_items = this.filter_items.map(filterItem => {
                    if (filterItem.dropdown.length > 0) {
                       filterItem.dropdown = filterItem.dropdown.map(item => {
                            if (item.column_name === colName) {
                                item.starred = !item.starred;
                            }

                            return item
                        })

                        let starredItems = filterItem.dropdown.filter(item => item.starred);
                        filterItem.starred = filterItem.dropdown.length === starredItems.length;
                    } else {
                        if (filterItem.column_name === colName) {
                            filterItem.starred = !filterItem.starred
                        }
                    }
                    return filterItem
                })

                this.modifyStarredColumns();
            }
        },
        columnIsStarred(colName) {
            let starred = false;
            this.starredMetrics.forEach(metric => {
                if (metric.column_name === colName && !starred) {
                    starred = true;
                }
            })

            return starred;
        },
        setAllFilterItemState(starred = false) {
            this.filter_items = this.filter_items.map(item => {
                item.starred = starred
                if (item.dropdown.length > 0) {
                    item.dropdown = item.dropdown.map(dropdownItem => {
                        dropdownItem.starred = starred;
                        return dropdownItem
                    })
                }

                return item
            })
        },
        showMetricModal(columnName = null) {
            let activeMetric = null;
            if (columnName) {
                let customMetric = this.customMetrics.find(metric => metric.field === columnName);

                if (customMetric) {
                    activeMetric = customMetric
                }
            }

            this.customMetricCreator = {
                ...this.customMetricCreator,
                visible: true,
                activeMetric
            }
        },

        refreshList() {
            this.columns = this.tableData.columns;
            this.visible_columns = this.tableData.visible_columns;

            this.filter_items = [...this.tableData.filter_items].map(item => {
                let existingFilter = this.filter_items.find(filterItem => filterItem.column_name === item.column_name)
                item.starred = existingFilter && existingFilter.starred;

                if (existingFilter && !item.starred && item.dropdown.length > 0) {
                    item.dropdown = item.dropdown.map(dropdownItem => {
                        let existingDropdownItem = existingFilter.dropdown.find(dataItem => dataItem.column_name === dropdownItem.column_name);
                        dropdownItem.starred = existingDropdownItem && existingDropdownItem.starred
                        return dropdownItem
                    })
                }
                return item
            })

            console.log('List refreshed')

            this.updateStoreSavedFilters();
        },
        applyTemplate(template) {
            // console.log({ template, rows: this.rows });
            if (!template || (template && !template['templateData'])) {
                return
            }
            let { breakdown, metrics } = this.filterData
            // console.log({ reportData: this.reportData })
            if (breakdown.length === 0 && metrics.length === 0) {
                if (this.reportData.length === 0) {
                    this.$emit('regen')
                }
                return
            }

            this.filter_state = 'metrics';
            let { customMetrics, savedMetrics, templateData } = _.cloneDeep(template);
            let templateFilterItems = this.dataStore.applyTemplateToReportData(this.filterData, templateData, this.report_type);

            this.$emit('update:savedReport', {})
            this.$emit('update:savedMetricId', null)
            this.$emit('update:starredMetrics', [])
            this.updateStoreMetrics([]);


            this.filter_items = templateFilterItems;
            this.updateStoreSavedFilters();

            let dataFilterItems = [...templateFilterItems];
            if (this.report_type === 'tag') {
                dataFilterItems = [
                    this.filterData.breakdown[0],
                    ...templateFilterItems
                ]
            }

            // console.log({ filterData: this.filterData, dataFilterItems, reportType: this.report_type, templateFilterItems })
            this.visible_columns = this.getVisibleColumns(dataFilterItems);
            this.columns = this.getColumns(dataFilterItems);

           // console.log({ starredColumns: this.starredColumns, templateFilterItems, template, savedReport: this.savedReport })
            /*this.starredColumns.forEach(column => {
                let data = column
                if (column.dropdownItem) {
                    data = this.dataStore.createColumnItem(data)
                }

                this.modifyStarredColumns(data)
            })*/

            this.modifyStarredColumns()


            this.savedColors = savedMetrics.colors;
            customMetrics = customMetrics ? customMetrics : []
            if (customMetrics) {
                customMetrics = this.dataStore.addTemplateCustomMetrics(customMetrics, templateFilterItems);

                let currentMetrics = _.cloneDeep(this.customMetrics);
                let tableData =  _.cloneDeep(this.tableData);


                currentMetrics.forEach(metric => {
                    let { rows } = tableData;
                    this.rows = this.rows.map(row => {
                        delete row[metric.field]
                        return row
                    })

                    tableData.rows = rows.map(row => {
                        delete row[metric.field];
                        return row
                    })
                })

               this.customMetrics = customMetrics

                setTimeout(() => {
                    this.applyCustomMetrics(customMetrics).then(() => {
                        let { overviewMetrics } = this.dataStore.createSavedMetricData(savedMetrics['savedMetrics'], this.report_type, this.rows, this.createTotalRow, this.createAverageRow, customMetrics);

                        this.$emit('update:starredMetrics', overviewMetrics)
                        customMetrics.forEach(metric => {
                            this.modifySavedRows(metric.field, metric.field)
                            tableData.rows = this.modifySavedRowData(tableData.rows, metric.field, metric.field)
                        })

                        tableData = {
                            ...tableData,
                            columns: [...this.columns],
                            visible_columns: [...this.visible_columns],
                            filter_items: templateFilterItems
                        }

                        this.tableData = tableData;
                        /*let starredFields = this.starredMetrics.data.map(metric => metric.column_name);
                        this.starFilters(starredFields)*/
                        // console.log({ tableData, rows: this.rows })
                        this.updateStoreMetrics(overviewMetrics);
                    })/*.then(response => {
                    console.log({ response })
                });*/
                }, 0)
            }
            /* let dataRows = this.reportData.map(item => {
                 return this.dataStore.createRow(this.reportData, item, this.report_type, templateCustomMetrics).row
             })*/

            this.$emit('update:template', template);
            this.$emit('update:savedReport', {});
            this.$emit('addSavedReport', false)
            this.clearIndicators();

            this.emitMetrics();
           // this.closeDropdowns();
        },

        async applyCustomMetrics(customMetrics) {
            return await new Promise((resolve) => {
                let component = this;
                applyMetric(0);
                function applyMetric(index) {
                    if (index === customMetrics.length) {
                        resolve({ success: true })
                        return
                    }

                    let metric = customMetrics[index];

                    component.modifyReportData(metric).then(() => {
                        setTimeout(() => {
                            applyMetric(index+1)
                        }, 100)
                    })
                }
            })
        },

        clearAppliedData() {
            this.$emit('update:savedMetricId', null);
            this.$emit('update:starredMetrics', []);
            this.clearCustomAndOverviewMetrics();
            this.updateStoreMetrics([]);

            this.clearIndicators();
            this.setVisibleColumns();
        },

        clearIndicators() {
            this.toggleTotals(false);
            this.toggleAverages(false);
            this.toggleKPIs(false);

            this.show_kpi_indicators = false;
        },

        clearCustomAndOverviewMetrics() {
            this.customMetrics = [];

            let clearedFilterItems = [];
            let { metrics, breakdown } = this.filterData;
            let filterItems = [
                ...breakdown,
                ...metrics
            ]
            filterItems.forEach(item => {
                if (item.dropdown.length === 0) {
                    if (item.metric_type !== 'combined_metric') {
                        item.starred = false;
                        clearedFilterItems.push(item)
                    }
                } else {
                    item.dropdown = item.dropdown.filter(dropdownItem => dropdownItem.metric_type !== 'combined_metric').map(dropdownItem => {
                        dropdownItem.starred = false;
                        return dropdownItem
                    })
                    clearedFilterItems.push(item)
                }
            })


            this.columns = this.getColumns(clearedFilterItems);
            this.visible_columns = this.getVisibleColumns(clearedFilterItems);
            this.filter_items = _.cloneDeep(clearedFilterItems)
        },

        getColumns(filterItems) {
            let columns = [];
            let existingColumns = this.columns;
            let store = this.dataStore;

            filterItems.forEach(item => {
                if (item.dropdown.length === 0) {
                    columns.push(createColumn(item.column_name))
                } else {
                    item.dropdown.forEach(dropdownItem => {
                        columns.push(createColumn(dropdownItem.column_name))
                    })
                }
            })

            return columns;


            function createColumn(columnName) {
                let existingColumn = existingColumns.find(column => column.field === columnName);
                if (existingColumn) {
                    return existingColumn
                } else {
                    return {
                        name: columnName,
                        field: columnName,
                        column_name: columnName,
                        sortable: true,
                        align: store.reportFields.indexOf(columnName) === -1 ? "center" : "left",
                        label: capitalizeFirstLetters(columnName.replace(/_/g, " "))
                    }
                }
            }
        },

        getPreceedingColumn(filterItems, column) {
            let preceedingColumn = null;


            filterItems.forEach((item, index) => {
                if (item.dropdown.length === 0) {
                    if (item.column_name === column) {
                        preceedingColumn = getPrevColumn(index-1)
                    }
                } else {
                    item.dropdown.forEach((dropdownItem, dropdownIndex) => {
                        if (dropdownItem.column_name === column) {
                            let prevIndex = dropdownIndex - 1;
                            if (dropdownIndex - 1 >= 0) {
                                preceedingColumn = item.dropdown[prevIndex].column_name;
                            } else {
                                preceedingColumn = getPrevColumn(index-1)
                            }
                        }
                    })
                }
            })


            function getPrevColumn(index) {
                if (index < 0 || index >= filterItems.length) {
                    return null
                }

                let preceedingItem = filterItems[index];

                let precedent = null;

                if (preceedingItem.dropdown.length === 0) {
                    precedent = preceedingItem.column_name
                } else {
                    let dropdownItems = preceedingItem.dropdown;
                    precedent = preceedingItem.dropdown[dropdownItems.length-1].column_name;
                }

                return precedent;
            }

            return preceedingColumn;
        },

        getVisibleColumns(filterItems) {
            let visibleColumns = [];
            // console.log({ filterItems })
            filterItems.forEach(item => {
                if (item && "dropdown" in item) {
                    if (item.dropdown.length === 0) {
                        if (item.visible) {
                            visibleColumns.push(item.column_name)
                        }
                    } else {
                        item.dropdown.forEach(dropdownItem => {
                            if (dropdownItem.visible) {
                                visibleColumns.push(dropdownItem.column_name)
                            }
                        })
                    }
                }
            });

            if (this.filterData.breakdown.length > 0) {
                let { breakdown } = this.filterData
                breakdown.forEach(item => {
                    if (item.visible) {
                        visibleColumns.push(item.column_name)
                    }
                })
            }

            return visibleColumns
        },



        reCalculateMetrics() {
            this.totals_checked = false;
            this.averages_checked = false;
            this.kpi_checked = false;
        },


        getSavedReport(report) {
            this.$emit('update:savedReport', report);
            this.$emit('update:savedMetricId', report.savedMetricsId);
            this.$emit('addSavedReport', true)

            let customMetrics = report['custom_metrics'];
            if (customMetrics && Array.isArray(customMetrics) && customMetrics.length > 0){
                customMetrics = customMetrics.map(item => {
                    return {
                        ...item.customMetrics,
                        id: item.id
                    };
                })
            } else {
                customMetrics = []
            }

            this.customMetrics = customMetrics
            this.$emit('update:template', {})
        },

        getCreatedReport(reportData) {
            let { report } = reportData;
            this.getSavedReport(report);
        },

        async performTemplateUpdate(requestData) {
            let templateUpdateRequest = await this.dataStore.sendTemplateUpdateRequest(this.template.locationId, this.template.id, requestData);
            let template = templateUpdateRequest.data['reportTemplate'];

            let savedMetricsRequest = await this.dataStore.sendTemplateMetricsUpdateRequest(this.template.id, template.savedMetrics.id, requestData.savedMetrics);
            template.savedMetrics = savedMetricsRequest.data;
            // console.log({ template, requestData })
            return template

        },


        updateTemplateData() {
            if (!this.viewingTemplate) {
                return
            }

            let templateFormat = this.dataStore.createTemplateFormat(this.filterData.metrics);
            let metricFormat = _.cloneDeep(this.createOverviewMetricData);
          /*  metricFormat['metrics'] = metricFormat['savedMetrics'];
            delete metricFormat['savedMetrics'];*/


            let requestData = {
                templateName: this.template.templateName,
                sharedLocations: this.template.sharedLocations,
                customMetrics: this.template.customMetrics.map(item => item.customMetrics),
                savedMetrics: metricFormat,
                templateData: templateFormat
            }



            this.templateState = {
                ...this.templateState,
                updating: true,
                updateSuccess: false,
                updateError: false,
                buttonText: 'Updating...'
            }

            this.performTemplateUpdate(requestData).then(responseData => {
                this.dataStore.updateTemplate(responseData);

                let template = {
                    ...this.template,
                    templateData: responseData['templateData'],
                    savedMetrics: responseData['savedMetrics']
                }

                this.$emit('templateUpdate', template)
                this.templateState = {
                    ...this.templateState,
                    updating: false,
                    updateError: false,
                    updateSuccess: true,
                    buttonText: 'Template updated!'
                }

                setTimeout(() => {
                    this.templateState = resetState()
                }, 3000)
            }).catch(() => {
                this.templateState = {
                    ...this.templateState,
                    updating: false,
                    updateSuccess: false,
                    updateError: true,
                    buttonText: 'Unable to update'
                }

                setTimeout(() => {
                    this.templateState = resetState()
                }, 3000)
            })


            function resetState() {
                return {
                    updating: false,
                    updateError: false,
                    updateSuccess: false,
                    buttonText: 'Update template'
                }
            }
        },

        sendUpdateRequest() {
            this.updateState = {
                ...this.updateState,
                updating: true,
                updateError: false,
                updateSuccess: false,
                buttonText: 'Updating...'
            }

            setTimeout(() => {
                this.dataStore.getDashboardImage().then(image => {
                    this.updateSavedReport(image).then(response => {
                        let updatedReport = response.data.report;
                        this.dataStore.updateReport(updatedReport);

                        this.updateState = {
                            ...this.updateState,
                            updating: false,
                            updateError: false,
                            updateSuccess: true,
                            buttonText: 'Updated!'
                        }

                        setTimeout(() => {
                            this.updateState = clearState()
                        }, 3000)
                    }).catch((error) => {
                        console.log({ error })
                        this.updateState = {
                            ...this.updateState,
                            updating: false,
                            updateError: true,
                            updateSuccess: false,
                            buttonText: 'Unable to update'
                        }

                        setTimeout(() => {
                            this.updateState = clearState()
                        }, 3000)
                    })
                })
            }, 100)

            function clearState() {
                return {
                    updating: false,
                    updateError: false,
                    updateSuccess: false,
                    buttonText: 'Update report'
                }
            }
        },


        async updateSavedReport(image) {
            let format = this.createOverviewMetricData;
            if (!this.savedMetricId) {
                return
            }

            await this.dataStore.updateMetrics(this.savedMetricId, format, this.location);


            let requestData = _.cloneDeep(this.createReportDataFormat);
            delete requestData.metrics;
            requestData.screenshot = image;

            requestData.default = this.savedReport.report.default;
            requestData.reportName = this.savedReport.report['report_name'];

            return await this.dataStore.updateSavedReport(this.location, this.pipeline.id, this.savedReport.report.id, requestData)
        },

        mountTable() {
            /*this.generatedReport = {
                range: this.dateRange,
                pipeline: this.pipeline,
                reportType: this.report_type,
                presets: this.preset
            }*/
           // console.log({ savedReport: this.savedReport, reportData: this.reportData, template: this.template })

            if (this.savedReport && "filterData" in this.savedReport) {
                this.applySavedReport()
            } else if (this.reportData.length > 0) {
                this.createTable(this.reportData)
            } else if (this.template && "templateData" in this.template) {
                this.applyTemplate(this.template)
            } else {
                this.$emit('regen')
            }

        },
        showReportSaver() {
            this.saveState.open = true;
        },
        deleteFilter(index){
            let filters = [...this.filters]
            this.$emit('filterDelete', {...filters[index]})
            this.$emit('update:filters', filters.filter((filter, filterIndex) => filterIndex !== index))
        },

        clearFilters() {
            this.$emit('update:filters', [])
            this.$emit('filterClear')
        },
        getObjectKey(index) {
            return Object.keys(this.date_object)[index]
        },


        /** Makes kpi row fields editable after input **/
        editField(index) {
            //console.log('KPI row:', this.kpi_row)
            // this.kpi_value_submitted = true
            if (index !== 0) {
                this.focused_field = index
                this.kpi_row = this.kpi_row.map((item, index) => {
                    if (!item.time_field && item.value && index !== 0) {
                        item.value = removeSpecialChars(item.value)
                    }

                    return item
                });
                this.typing = true;
                this.edit_index = index;
            }

            // setTimeout(() => {this.kpi_value_submitted = false}, 100)
        },

        hasVisibleTimeColumns() {
            return this.visible_columns.filter(column => column.toLowerCase().includes('time')).length > 0
        },
        /** Returns string passed as argument with first letters after space capitalized **/
        capitalizeFirstLetter(string) {
            return capitalizeFirstLetters(string)
        },

        hasMissingLeadCosts(key) {
            let missingSource = this.findMissingSource(key);
            return missingSource != null && missingSource > 0
        },

        detectKeyPress(event) {
            if (this.keyLogger.indexOf(event.code) === -1) {
                this.keyLogger.push(event.code)
            }
            if (this.keyLogger.length === 3) {
                let actionTriggered = this.censorKeyCombo.filter(keyCode => this.keyLogger.indexOf(keyCode) === -1).length === 0
                if (actionTriggered) {
                    this.dataCensored = !this.dataCensored
                }

                this.keyLogger = []
            }

            if (this.keyLogger.length >= 3) {
                this.keyLogger = []
            }
        },

        getTagParent(tagName) {
            let tagParent;

            if (tagName) {
                let breakdowns = this.destructureData(this.report);

                //console.log({ breakdowns, tagName })

                breakdowns.forEach(data => {
                    let matchingItems = data.items.filter(item => item[`${this.report_type}Name`].toLowerCase() === tagName.toLowerCase());
                    if (matchingItems.length > 0) {
                        tagParent = data.parent
                    }
                })
            }

            return tagParent
        },
        showModal(source_name) {
            /* let noReportNameValues = [ 'unlabeled source', 'not assigned', 'untagged']
             if (noReportNameValues.indexOf(source_name.toLowerCase()) !== -1) {
               source_name = ''
             }

             console.log({ source_name })*/
            this.source = {
                ...this.source,
                name: source_name,
                visible: true
            }
        },

        closeDataModal(event) {
            if (event.modify && event.modifyType === 'triggerLeadCostAdd') {
                let eventData = event.data;
                this.showModal(eventData.source)
            }
        },

        intValue(string) {
            return parseFloat(removeSpecialChars(string))
        },
        findMissingSource(sourceKey) {
            let missingSource = this.missing_source_costs.find(source => source.key === sourceKey);
            return missingSource ? missingSource.data.length : null
        },
        showMissingSourceCosts(sourceKey) {
            const missingSource = this.missing_source_costs.find(source => source.key === sourceKey);
            /*console.log({
              sourceCosts: this.missing_source_costs,
              sourceKey,
              missingSource
            })*/
            if (missingSource) {
                this.missing_source_index = this.missing_source_costs.indexOf(missingSource);
                // console.log({ missingSource, 'index': this.missing_source_index})
                this.show_missing_source = true
            }
        },
        hasAverageInclusiveKey(key) {
            let averageKeys = ['percent', 'average', 'avg', 'roi', 'close_rate'];
            let avgInclusiveKey = averageKeys.filter(avgKey => key.includes(avgKey) || avgKey === key).length

            let avgCustomMetric = this.customMetrics.find(metric => metric.field === key && metric.format === '%')
            return avgInclusiveKey > 0 || avgCustomMetric
        },

        toggleRow(rowKey) {
            // console.log({ rowKey })
            let matchingRow = this.rows.find(row => row.key === rowKey);

            if (matchingRow) {
                const row_index = this.hidden_rows.indexOf(matchingRow);
                row_index === -1 ? this.hidden_rows.push(matchingRow) : this.hidden_rows.splice(row_index, 1);

                // console.log({ matchingRow })

                this.resetMetrics()
            }


            // this.calculateOffsetTop(item)
        },

        toggleParentVisibility(event) {
            event.preventDefault();
            event.stopPropagation();
            this.tagParentsHidden = !this.tagParentsHidden
        },

        scaleScreen(type) {
            let scaleType =  this.scaleStep;
            let newScale = type === 'down' ? this.currentScale - scaleType : this.currentScale + scaleType;

            this.setZoomLevel(newScale)
        },


        setZoomLevel(newScale) {
            if (newScale <= 1) {
                let userAgent = navigator.userAgent;
                let dataTable = this.$refs.data_table;
                let index = -1;
                if (dataTable && dataTable.$el) {
                    index = Array.from(document.querySelectorAll('.q-table__container')).indexOf(dataTable.$el)
                }

                if (index === -1) {
                    return
                }
                let zoomableItem = document.querySelectorAll('.q-table')[index];

                if (!userAgent.match(/firefox|fxios/i) && zoomableItem) {
                    zoomableItem.style.zoom = `${newScale * 100}%`
                }
                this.currentScale = newScale
            }
        },
        setFilterData(tableData) {
            /*this.filterData.metrics = tableData.filterItems;
            let tagLabel = `${this.capitalizeFirstLetter(this.report_type)} Parent`, tagValue = `${this.report_type}_parent`;

            let filterSample = {...tableData.filterItems[0]}
            filterSample.label = tagLabel;
            filterSample.column_name = tagValue;
            filterSample.visible = false;

            this.filterData.breakdown = [ filterSample ]*/
            this.filterData = this.dataStore.generateFilterData(tableData.filterItems, this.report_type);
        },

        isExistingCustomMetric(field) {
            return !!this.customMetrics.find(metric => metric.field === field)
        },

        applySavedReport() {
            if (!this.savedReport) {
                return
            }

            let {
                filterData,
                report,
                reportData } = _.cloneDeep(this.savedReport);
            if (!filterData || !report) {
                return
            }

            let customMetrics = report['custom_metrics'] ? report['custom_metrics'] : [];
            customMetrics = this.dataStore.createCustomMetricList(customMetrics);
            this.customMetrics = customMetrics;
            // console.log({ customMetrics })
            this.filter_state = 'metrics';

            let filterItems = [];

            /**
             *  Removes custom metrics that may be saved in the data and is not
             *  in the custom metrics array
             * **/

            filterData.metrics.forEach(item => {
                if (item.dropdown && item.dropdown.length === 0) {
                    if (item.metric_type && item.metric_type === 'combined_metric') {
                        if (this.isExistingCustomMetric(item.column_name)) {
                            filterItems.push(item)
                        }
                    } else {
                        filterItems.push(item)
                    }
                } else {
                    let dropdownItems = [];
                    item.dropdown.forEach(dropdownItem => {
                        if (dropdownItem.metric_type && dropdownItem.metric_type === 'combined_metric') {
                            if (this.isExistingCustomMetric(dropdownItem.column_name)) {
                                dropdownItems.push(dropdownItem)
                            }
                        } else {
                            dropdownItems.push(dropdownItem)
                        }
                    })
                    item.dropdown = dropdownItems;
                    filterItems.push(item)
                }
            })

            // console.log({ filterItems })

            // console.log({ filterData })

            filterData.metrics = filterItems;
            this.filter_items = filterItems;


            this.filterData = filterData;
           // this.dataStore.updateSavedFilters(this.report_type, this.filterData, this.location, report.id);

            this.$emit('update:template', {})

            this.createTable(reportData, true)
        },

        async getKpiData() {
            return await this.dataStore.getKpiData(this.report_type, this.location, this.pipeline.id)
        },

        createKpiRow(kpi_data) {
            let dataItems = this.dataStore.createInitKpiData(kpi_data, this.report_type);
            this.init_kpi_row = dataItems.kpiRow;
            this.closeTime = dataItems.closeTime
        },


        createTable(reportData, applyReport = false) {
            let report_type = this.report_type;
            let results = reportData;
            this.missing_source_costs = [];
            this.report = reportData;
            this.apiReportItem = [];

            if (report_type === 'tag') {
                let tableData = _.cloneDeep(this.setReportData(report_type, results));
                this.filter_state = 'metrics';


                this.columns = tableData.columns;
                this.filter_items = tableData.filterItems;
                this.visible_columns = tableData.visibleColumns;

                // console.log({ tableData })
                // console.log({ report_type, filterData: this.filterData })

                if (this.filterData.breakdown.length === 0) {
                    this.setFilterData(tableData);
                    this.activateBreakdown = false;
                } else {
                    let visibleBreakdowns = [];
                    this.filterData.breakdown.forEach(breakdown => {
                        if (breakdown.visible) {
                            visibleBreakdowns.push(breakdown.column_name)
                        }
                    })

                    // console.log({ visibleBreakdowns })
                    this.activateBreakdown = visibleBreakdowns.length > 0
                }

                if (this.activateBreakdown) {
                    if (this.visible_columns.indexOf('tag_parent') === -1) {
                        this.visible_columns = [
                            'tag_parent',
                            ...this.visible_columns
                        ]
                    }
                }


               // this.updateStoreSavedFilters()

                this.organizeBreakdowns(_.cloneDeep(results)).then(() => {
                    if (!this.viewingSavedReport && !this.viewingTemplate) {
                        this.setVisibleColumns();
                    }
                    if (applyReport) { this.addMetricsAndIndicators(this.savedReport) }

                    this.saveInitialData();
                    this.emitMetricsAndFetchKPIs(applyReport)
                });
               // this.addKpis(this.filter_items)
            } else {
                this.activateBreakdown = false;
                let dataClone = _.cloneDeep(results)
                this.organizeData(report_type, dataClone);

                if (applyReport) {
                    this.addMetricsAndIndicators(this.savedReport)
                }
                this.saveInitialData();
                this.emitMetricsAndFetchKPIs(applyReport)
            }

            setTimeout(() => {
                this.setZoomLevel(this.currentScale);
            }, 0)
        },

        updatePagination(object) {
            // console.log('Object:', object)
            this.init_pagination = object
            // console.log({ object })

            //object.page === 1 ? this.unassigned_items.map(item => {this.calculateOffsetTop(item)}) : null
        },
        emitMetrics() {
            let metrics = [
                { metric: 'totals', value: this.createTotalRow },
                { metric: 'averages', value: this.createAverageRow },
                { metric: 'columns', value: this.getOverviewColumns },
                { metric: 'customMetrics', value: this.customMetrics }
            ]

            metrics.forEach(metric => {
                this.$emit('metricChange', metric)
            })
        },

        emitMetricsAndFetchKPIs(applyReport = false) {
            let savedReport = _.cloneDeep(this.savedReport);



            if (this.currentColumn && "column" in this.currentColumn) {
                let starColumn = {...this.currentColumn};
                let { column } = starColumn;
               // console.log({ column })
                if (!column.dropdownItem) {
                    this.filter_items = this.filter_items.map(item => {
                        if (item.column_name === column.column_name) {
                            item.starred = true;
                            if (column.hasDropdown) {
                                item.dropdown = item.dropdown.map(dropdownItem => {
                                    dropdownItem.starred = true;
                                    return dropdownItem
                                })
                            }
                        }
                        return item
                    })
                } else {
                    this.filter_items = this.filter_items.map(item => {
                        item.dropdown = item.dropdown.map(dropdownItem => {
                            if (dropdownItem.column_name === column.column_name) {
                                dropdownItem.starred = true;
                            }

                            return dropdownItem
                        })
                        return item
                    })
                }
                this.modifyStarredColumns();
            }

            this.getKpiData().then(response => {
                this.createKpiRow(response.data);
                this.addKpis(this.filter_items);
                this.resetMetrics()

                if (savedReport && "filterData" in savedReport && applyReport) {
                    this.toggleKPIs(savedReport.report.kpis)
                }

                this.emitMetrics();
            }).catch(() => {
              //  console.log({  data: this.kpi_row })
                this.addKpis(this.filter_items);

            })
        },

        saveInitialData() {
            this.tableData = {
                rows: _.cloneDeep(this.rows),
                columns: _.cloneDeep(this.columns),
                visible_columns: _.cloneDeep(this.visible_columns),
                filter_items: _.cloneDeep(this.filter_items)
            }

            // console.log('Initial data', this.tableData)

            this.$emit('tableDataChange', this.tableData);
            if (this.template && "templateData" in this.template) {
                this.applyTemplate(this.template);
            }


            this.updateStoreSavedFilters();
        },

        formatSummary(summaryObject) {
            let summaryKeys = Object.keys(summaryObject);
            let formattedSummary = [];

            summaryKeys.map(key => {
                let data = summaryObject[key]
                if (data) {
                    data = this.formatValue(key, data);
                    data = data.toString().replace(/,/g, " ")
                } else {
                    data = '-'
                }
                formattedSummary.push(data)
            })

            return formattedSummary
        },

        exportReport() {
            let filteredColumns = this.columns.filter(column => this.visible_columns.indexOf(column.name) !== -1);
            let columnData = filteredColumns.map(column => column.label);
            let rowData = this.rows.map(row => {
                let items = [];

                Object.keys(row).forEach(key => {
                    if (this.visible_columns.indexOf(key) !== -1) {
                        let value = row[key];
                        let parentKey = `${this.report_type}_parent`

                        /** Removes breakdown name value for rows under a breakdown
                         * so if the row falls a breakdown with a name of 'audience'
                         * it hides the value in the exported report same way it does
                         * in the report table
                         * **/
                        if (this.activateBreakdown && key === this.report_type) {
                            //  console.log({key, value, parentValue: row[parentKey], reportType: this.report_type })
                            if (value.toLowerCase() !== 'all' && row[parentKey]) {
                                value = ''
                            }
                        }

                        if (this.activateBreakdown && key === parentKey && !row['parentRow']) {
                            value = ''
                        }

                        let dataIndex = -1;
                        filteredColumns.forEach((column, columnIndex) => {
                            if (column.name === key) {
                                dataIndex = columnIndex
                            }
                        })

                        value = this.formatValue(key, value).replace(/,/g, " ")
                        if (dataIndex !== -1) {
                            items[dataIndex] = value
                        }
                    }
                })

                return items
            });


            let reportData = [ columnData ];
            reportData = [...reportData, ...rowData ]

            if (this.totals_checked) {
                reportData = [...reportData, ...[ this.formatSummary(this.totals) ]]
            }

            if (this.averages_checked) {
                reportData = [...reportData, ...[ this.formatSummary(this.averages )]]
            }
            if (this.kpi_checked) {
                let kpiObject = this.kpi_row.map((data, index) =>  {
                    let rowValue;
                    if (index === 0) {
                        rowValue = data.name
                    } else {
                        if (data.time_field) {
                            let dateValue = this.createDateFormat(data.value[this.milliSecondIndex]).replace(/,/g, " ");
                            rowValue = !dateValue ? '-' : dateValue
                        } else {
                            rowValue = !data.value  ? '-' : data.value
                        }
                    }

                    if (rowValue && rowValue !== '-') {
                        rowValue = removeSpecialChars(rowValue.toString())
                        rowValue = this.isACustomMetric(data.name) ? this.displayCustomMetric(data.name, rowValue) : addFieldSigns(rowValue, data.name)
                    }

                    return rowValue
                })

                reportData = [...reportData, ...[ kpiObject ]]
            }

            let csvData = reportData.map(data => data.join(",")).join('\n');
            this.createCSV(csvData)
        },


        createCSV(csv_data) {
            //console.log(csv_data)
            let csv_file = new Blob([csv_data], {type: 'text/csv'});

            let download_link = document.createElement('a');

            download_link.download = `${capitalizeFirstLetters(this.report_type)} report for ${this.pipeline['name'].toLowerCase()} ${this.dateRange ? `- (${this.dateRange.from} to ${this.dateRange.to}) timeline` : ''}.csv`;
            download_link.href= URL.createObjectURL(csv_file);

            download_link.style.display = 'none';
            document.body.appendChild(download_link);

            download_link.click();
            document.body.removeChild(download_link);
        },


        createDateFormat(milliseconds) {
            return setTimeValue(milliseconds)
        },



        addMetricsAndIndicators(savedReport) {
            if (Object.keys(savedReport).length === 0) {
                return
            }


           // console.log({ savedReport })
            let { report, metrics } = savedReport;

            if (!report || !metrics) {
                return
            }

            let customMetrics = report['custom_metrics'] ? report['custom_metrics'] : [];
            customMetrics = this.dataStore.createCustomMetricList(customMetrics);

            let { savedMetrics } = metrics;

            //console.log({ savedMetrics })
            // console.log({ overviewMetrics })


            let totals = this.createTotalRow, averages = this.createAverageRow;
            let { overviewMetrics } = this.dataStore.createSavedMetricData(savedMetrics, this.report_type, this.rows, totals, averages, customMetrics);
            let starredFields = overviewMetrics.map(metric => metric.column_name);
            this.$emit('update:starredMetrics', overviewMetrics);
            this.starFilters(starredFields);
            this.updateStoreMetrics(overviewMetrics);
           /*
            let starredMetrics = this.dataStore.getStarredMetrics(report.report_type, report.location_id, this.reportMetricId);
           if (starredMetrics.length === 0) {

            }*/



            this.toggleTotals(report.totals);
            this.toggleAverages(report.averages);
            this.toggleKPIs(report.kpis);

            this.show_kpi_indicators = report['kpi_indicators'];
        },
        toggleKPIs(checked) {
            checked ? this.kpi_row = this.setValueOrder() : null;
            this.kpi_checked = checked;
        },


        toggleTotals(checked) {
            this.totals = {};

            if (checked) {
                this.totals = this.createTotalRow;
                if (this.activateBreakdown) {
                    this.totals[this.report_type] = ''
                }

            }

            this.totals_checked = checked;
        },

        /** Toggles visibility of average row **/
        toggleAverages(checked) {
            this.averages = {};
            if (checked) {
                this.averages = this.createAverageRow;
                if (this.activateBreakdown) {
                    this.averages[this.report_type] = ''
                }
            }

            this.averages_checked = checked;
        },

        starFilters(starredFields) {
            // console.log({ starredFields })
            this.filter_items = this.filter_items.map(item => {
                if (item.dropdown.length === 0) {
                    item.starred = starredFields.indexOf(item.column_name) !== -1
                } else {
                    item.dropdown = item.dropdown.map(dropdownItem => {
                        dropdownItem.starred = starredFields.indexOf(dropdownItem.column_name) !== -1
                        return dropdownItem
                    })
                    item.starred = item.dropdown.filter(dropdownItem => dropdownItem.starred).length === item.dropdown.length;
                }
                return item
            })

           // console.log('Filter starred')
           // this.updateStoreSavedFilters();
        },

        updateStoreSavedFilters: _.debounce(function () {
            if ( !this.reportMetricId ) { return }
           // console.log({ filterData: this.filterData })
            this.dataStore.updateSavedFilters(this.report_type, this.filterData, this.location, this.reportMetricId);
            // console.log('Filters saved', this.getLocalFilterState())
        }, 500),

        updateStoreMetrics(metrics) {
            if (!this.reportMetricId ) { return }
            this.dataStore.updateSavedMetrics(this.report_type, metrics, this.location, this.reportMetricId);
            // console.log('Store metric filter state', this.getLocalFilterState())
        },

        setValueOrder() {
            // console.log({ initRow: this.init_kpi_row, columns: this.columns })
            let kpi_row = this.columns.filter(column => this.visible_columns.indexOf(column.name) !== -1 && this.uncheckedFields.indexOf(column.name) === -1).map((item) => {
                let kpi_item = this.init_kpi_row.find(kpi_item => kpi_item.name === item.name);

                if (kpi_item) {
                    let kpi_object = {...kpi_item};
                    if (!kpi_object.time_field && kpi_object.value !== '') {
                        kpi_object.value = removeSpecialChars(kpi_object.value.toString())
                    }

                    return kpi_object
                } else {
                    return ''
                }
            }).filter(value => value !== '')

            kpi_row.unshift({
                name: 'KPIs',
                value: '',
                fieldKey: this.activateBreakdown ? `${this.report_type}_parent` : this.report_type,
                stage_type: false
            })

            if (this.activateBreakdown) {
                let breakdownObject = {
                    name: '',
                    value: '',
                    fieldKey: this.report_type,
                    stage_type: false
                }
                kpi_row.splice(1, 0, breakdownObject);
            }

            return kpi_row;
        },


        destructureData(reportData) {
            let dataGroup = []
            reportData.forEach(data => {
                let tagParent = data['tagParent'];
                if (dataGroup.indexOf(tagParent) === -1 && tagParent != null) {
                    dataGroup.push(tagParent)
                }
            })

            return dataGroup.map(data => {
                return {
                    parent: data,
                    items: reportData.filter(dataItem => dataItem['tagParent'] === data)
                }
            })
        },
        async createBreakdownList(breakdowns, activateBreakdown = false) {
            return await new Promise(resolve => {
                let reportRows = [];
                let tagValue = `${this.report_type}_parent`;
                const uncheckedFields = this.uncheckedFields;
                let component = this;
                let reportType = this.report_type;

                createList(0)

                function createList(index) {
                    if (index < 0 || index >= breakdowns.length) {
                        resolve(reportRows);
                        return
                    }
                    let data = breakdowns[index]
                    let itemRowData = data.items.map(item => {
                        let rowData = component.createRowData(item, reportType);
                        rowData[`${tagValue}`] = data.parent;
                        return rowData
                    })


                    if (activateBreakdown) {
                        let tagParentRowData = {
                            tag: 'All',
                            key: uuidv4(),
                            parentRow: true
                        }

                        tagParentRowData[tagValue] = capitalizeFirstLetters(data.parent)

                        let rowSample = {...itemRowData[0]}
                        let rowKeys = Object.keys(rowSample);

                        rowKeys.forEach(key => {
                            if (uncheckedFields.indexOf(key) === -1) {
                                let rowValues = itemRowData.map(item => item[key]);
                                let rowTotal = rowValues.reduce((prevValue, currentValue) => {
                                    return parseFloat(removeSpecialChars(prevValue)) + parseFloat(removeSpecialChars(currentValue))
                                }, 0)

                                if (component.hasAverageInclusiveKey(key)) {
                                    rowTotal = rowTotal / rowValues.length
                                }

                                tagParentRowData[key] = rowTotal
                            }
                        })

                        reportRows = [...reportRows, tagParentRowData, ...itemRowData]
                    } else {
                        reportRows = [...reportRows, ...itemRowData]
                    }

                    createList(index+1)
                }
            })
        },
        async addRowsWithNoBreakdowns(reportData) {
            return await new Promise(resolve => {
                let dataWithNoParentTags = reportData.filter(data => data['tagParent'] == null) ;
                let tagValue = `${this.report_type}_parent`;
                let reportRows = [];
                let store = this.dataStore;
                let reportType = this.report_type;
                let customMetrics = this.customMetrics;
                let component = this;

                if (dataWithNoParentTags.length > 0) {
                    if (this.activateBreakdown) {
                        let parentRowData = this.createRowData(dataWithNoParentTags[0], this.report_type);

                        Object.keys(parentRowData).forEach(key => parentRowData[key] = key === this.report_type ? '' : 0);
                        parentRowData[tagValue] = this.dataStore['noParentRowText'];
                        parentRowData.key = uuidv4();
                        parentRowData.parentRow = true;
                        parentRowData.noParentRow = true;

                        reportRows.push(parentRowData)
                    }
                }

                addRow(0)
                function addRow(index) {
                    if (index < 0 || index >= dataWithNoParentTags.length) {
                        resolve(reportRows);
                        return
                    }
                    let data = dataWithNoParentTags[index];

                    let rowData = component.createRowData(data, reportType);
                    rowData[tagValue] = '';
                    let nullObject = {};
                    if (index === 0) {
                        nullObject['null_parent'] = true
                    }

                    customMetrics.forEach(customMetric => {
                        if (customMetric && customMetric.field && customMetric.formula) {
                            rowData = store.addCustomMetricValue(rowData, customMetric.field, customMetric.formula)
                        }
                    })
                    reportRows.push({...rowData, ...nullObject});

                    addRow(index+1)
                }
            })
        },

        async createBreakdownFormat(reportData) {
            return await new Promise(resolve => {
                let breakdowns = this.destructureData(reportData);
                this.createBreakdownList(breakdowns, this.activateBreakdown)
                    .then(breakdownRows => {
                        this.addRowsWithNoBreakdowns(reportData).then(noParentRows => {
                            let reportRows = [
                                ...breakdownRows,
                                ...noParentRows
                            ]

                            resolve({ reportRows, breakdowns })
                        })
                    })
            })
        },

        addKpis(filterItems) {
            this.init_kpi_row = this.dataStore.completeKpiData(this.init_kpi_row, filterItems, this.report, this.report_type, this.customMetrics);
            this.kpi_row = this.init_kpi_row;
            this.$emit('metricChange', { metric: 'kpis', value: this.init_kpi_row })
        },

        validateFieldValue(event, col) {
            let value = event.target.value;
            col.value = validateInput(value)
        },

        validateAndSetTimeValue(event, column, valueIndex){
            let value = validateInput(event.target.value, false);
            column.value[valueIndex] = value ? parseInt(value) : ''


            const millisecond_multiplier = {
                days: 24 * 60 * 60 * 1000,
                hours: 60 * 60 * 1000,
                minutes: 60 * 1000,
                seconds: 1000
            };

            let milliSecondValue = 0;
            const dateKeys = Object.keys(this.date_object);

            column.value.forEach((colValue, colIndex) => {
                if (dateKeys[colIndex] !== 'totalInMilliseconds') {
                    colValue = colValue ? colValue : 0;
                    let multiplier = millisecond_multiplier[this.getObjectKey(colIndex)]

                    milliSecondValue += (colValue * multiplier)
                }
            })


            column.value[this.milliSecondIndex] = milliSecondValue;
        },

        getKpiClass(column) {
            if (!this.show_kpi_indicators) {
                return ''
            }

            let { name, value } = column;
            if (this.uncheckedFields.indexOf(name) !== -1) {
                return ''
            }


            let timeField = name.includes('time');
            value =  value === '' ? 0 : parseFloat(value);
            let kpiData = this.kpi_row.find(kpiRow => kpiRow.name === name);
            if (!kpiData) {
                return ''
            }
            let kpiValue = timeField ? kpiData.value[this.milliSecondIndex] : kpiData.value;
            kpiValue = kpiValue === '' ? 0 : parseFloat(kpiValue.toString());

            if (kpiValue === 0 || value === 0) {
                return ''
            }

            let fieldClass = ''
            if (value > kpiValue) {
               fieldClass  = this.cost_fields.indexOf(name) !== -1 || timeField ? "below-kpi" : "above-kpi";
            } else if (value < kpiValue) {
                fieldClass = this.cost_fields.indexOf(name) !== -1  || timeField  ? "#above-kpi" : "below-kpi";
            } else{
                fieldClass = "matches-kpi";
            }

            return fieldClass
        },

        async organizeBreakdowns(reportData) {
            this.dataStructure = 'breakdown'

            this.rows = []
            let format = await this.createBreakdownFormat(reportData);
            let { reportRows } = format;

            // console.log({ reportRows })
            this.rows = reportRows;

            /*
              if (this.activateBreakdown) {
                  let breakdownColumn = this.filterData.breakdown[0]
                  this.columns = [
                      {
                          name: breakdownColumn.column_name,
                          field: breakdownColumn.column_name,
                          column_name:
                      }
                  ]
              }
            */

            setTimeout(() => {
                this.loadBreakdown = false
            }, 0)

            this.resetMetrics();
            return { reportRows }
        },

        async organizeData(report_type, reportData) {
            /** Formats data **/
            this.dataStructure = "metrics";

            let columnData = _.cloneDeep(this.setReportData(report_type, reportData));
            this.columns = columnData.columns;
            this.filter_items = columnData.filterItems;

          //  console.log({ columnData })

            this.filterData = {
                metrics: columnData.filterItems,
                breakdown: []
            }

            let rows = reportData.map(item => {
                let rowData = this.createRowData(item, report_type);
                if (report_type === 'salesrep') {
                    rowData['salesRepId'] = item['salesRepId']
                }

                return rowData
            })

            let unassignedRows = [], assignedRows = [];
            rows.forEach(row => {
                const item_name = row[report_type]
                if ((item_name === 'Unlabeled Source' && report_type === 'source') || (item_name === 'Not Assigned' && report_type === 'salesrep') || (item_name === 'Untagged' && report_type === 'tag')) {
                    unassignedRows = [ ...unassignedRows, row ]
                } else {
                    assignedRows = [ ...assignedRows, row ]
                }
            })

            this.unassigned_items = unassignedRows;
            this.rows = [
                ...unassignedRows,
                ...assignedRows
            ]

            let localState = this.getLocalFilterState();
            this.fetchUnassignedReps(unassignedRows, report_type, reportData, this.location)
            if (!this.viewingSavedReport && !this.viewingTemplate && localState.visibleColumns.length === 0) {
               //  console.log('Not viewing saved report')
                this.setVisibleColumns();
            } else {
                this.visible_columns = columnData.visibleColumns;
            }
          //  this.addKpis(this.filter_items)

            return columnData
        },


        fetchUnassignedReps(unassignedRows, reportType, reportData, locationID) {
            if (reportType !== 'salesrep') {
                return
            }

            // console.log({ unassignedRows, reportData });
            let component = this;

            loadUnassignedRep(0)

            function loadUnassignedRep(index) {
                if (index < 0 || index >= unassignedRows.length) {
                    return
                }

                let rowData = unassignedRows[index]
                if (!rowData['salesRepId']) {
                    loadUnassignedRep(index+1)
                    return
                }



                getUnassignedRep(rowData, locationID).then(response => {
                    // console.log({ response })
                    if (response.name) {
                        component.updateUnassignedRow(rowData, response.name)
                    }

                    loadUnassignedRep(index+1)
                }).catch(() => {
                    loadUnassignedRep(index+1)
                })
            }

            async function getUnassignedRep(repRow, locationID) {
                let locationKey = component.locationKey;
                if (!locationKey) {
                    let locationKeyRequest = await getLocationKey(locationID);
                    locationKey = locationKeyRequest.data;
                    component.locationKey = locationKeyRequest.data;
                    // console.log({ locationKeyRequest })
                }

                let contactRequest = await axios.get(`https://rest.gohighlevel.com/v1/users/${repRow.salesRepId}`, {
                    headers: {
                        'Authorization': `Bearer ${locationKey}`
                    }
                })

                return contactRequest.data;
            }
        },

        updateUnassignedRow(rowData, salesRepName) {
            this.rows = this.rows.map(row => {
                if (row.key === rowData.key) {
                    row['salesrep'] = salesRepName
                }
                return row
            })

            this.tableData.rows = this.tableData.rows.map(row => {
                if (row.key === rowData.key) {
                    row['salesrep'] = salesRepName
                }
                return row
            })

            this.unassigned_items = this.unassigned_items.filter(row => row.key !== rowData.key)
        },

        createRowData(item, report_type) {
            let createdRowData = this.dataStore.createRow(this.report, item, report_type, this.customMetrics);
            // console.log({ createdRowData })

            let row_data = createdRowData.row;
            if (createdRowData.apiReportItem) {
                createdRowData.apiReportItem.forEach(stage => {
                    let existingReportItem = this.apiReportItem.find(item => item.name === stage.name);
                    if (!existingReportItem) {
                        this.apiReportItem.push(stage)
                    }
                })
            }

            this.missing_source_costs.push(createdRowData.missingSourceData)

            let row_keys = Object.keys(row_data);
            let kpi_indicator_color = {};

            row_keys.forEach(key => kpi_indicator_color[key] = '');
            this.kpi_indicator_colors.push(kpi_indicator_color);

            return row_data
        },


        calculateTotals() {
            let total_row  = {};
            let rows;
            if ( this.dataStructure === 'metrics' || (this.dataStructure === 'breakdown' && !this.activateBreakdown) ) {
                rows = [...this.rows];
            } else {
                let rowData = this.rows.filter(row => row.parentRow && !row.noParentRow);

                let unassignedRowParent = this.rows.find(row => row.noParentRow);
                if (unassignedRowParent) {
                    let rowIndex = this.rows.indexOf(unassignedRowParent)

                    if (rowIndex !== -1) {
                        let unassignedRows = this.rows.slice(rowIndex + 1, this.rows.length);
                        rowData = [...rowData, ...unassignedRows ]
                    }
                }

                rows = rowData;
            }

            let fieldsUnchecked = [this.report_type, `${this.report_type}_parent`];
            rows.map(item => {
                if (this.hidden_rows.indexOf(item) === -1){
                    for (let key in item) {
                        if (fieldsUnchecked.indexOf(key) === -1 && this.visible_columns.indexOf(key) !== -1) {
                            let item_key = Math.round(parseFloat(removeSpecialChars(item[key].toString())) * 1000) / 1000;

                            !total_row[key] ? total_row[key] = item_key
                                :  total_row[key] += item_key;
                        }
                    }
                }
            })




            return total_row;
        },


        createDataRow(data_object, type = undefined) {
            let data_row = {};
            let uncheckedFields = this.uncheckedFields;

            this.columns
                .filter(column => this.visible_columns.indexOf(column.name) !== -1)
                .forEach((column_object) => {
                    const key = column_object.name;
                    let data_value = data_object[key];

                    if (uncheckedFields.indexOf(key) === -1) {
                        if (type && type === 'totals'){
                            data_row[key] = !this.hasAverageInclusiveKey(key) ? data_value : this.createAverageRow[key]
                        } else{
                            data_row[key] = data_value;
                        }
                    } else {
                        // console.log({ key, data_value })
                        data_row[key] = data_value;
                    }
                })

            Object.keys(data_row).forEach(key => {
                data_row[key] = !data_row[key] && uncheckedFields.indexOf(key) === -1 ? 0 : data_row[key]
            });


            return data_row
        },

        /** Calculates averages of each column in the report table **/
        calculateAverages() {
            let average_row = {};
            let parentKey = `${this.report_type}_parent`;

            if (!this.activateBreakdown) {
                average_row[`${this.report_type}`] = "Averages";
            }

            if (this.activateBreakdown) {
                average_row[`${this.report_type}`] = "";
                average_row[parentKey] = "Averages";
            }


            let totals = this.calculateTotals();
            let average_row_totals = {...average_row, ...totals};

            let totalDivider;

            if (this.dataStructure === 'metrics' || (this.dataStructure === 'breakdown' && !this.activateBreakdown)) {
                totalDivider = this.rows.length
            }else {
                let dividerTotal = 0;

                this.rows.filter(row => row.parentRow && !row.noParentRow).forEach(row => {
                    let parentKey = `${this.report_type}_parent`;
                    let childItems = this.rows.filter(item => item[parentKey].toLowerCase() === row[parentKey].toLowerCase() && !item.parentRow);

                    dividerTotal += childItems.length;
                });

                let unassignedParentRow = this.rows.find(row => row.noParentRow);

                if (unassignedParentRow) {
                    let rowIndex = this.rows.indexOf(unassignedParentRow);

                    if (rowIndex !== -1) {
                        let unassignedRowLength = this.rows.slice(rowIndex + 1, this.rows.length).length;
                        dividerTotal += unassignedRowLength;
                    }
                }

                totalDivider = dividerTotal
            }

            for (let key in average_row_totals) {
                if (this.uncheckedFields.indexOf(key) === -1) {
                    average_row[key] = average_row_totals[key] / totalDivider;
                }
            }


            return average_row;
        },

        getFilterVisibleColumns(filterData) {
            let filterDataKeys = Object.keys(filterData);
            let modifiedVisibleColumns = [];

            filterDataKeys.reverse().forEach(key => {
                let filterList = filterData[key];
                filterList.forEach(filterItem => {
                    if (filterItem.dropdown.length === 0 && filterItem.visible) {
                        modifiedVisibleColumns = [...modifiedVisibleColumns, filterItem.column_name]
                    }

                    if (filterItem.dropdown.length > 0) {
                        filterItem.dropdown.forEach(dropdownItem => {
                            if (dropdownItem.visible) {
                                modifiedVisibleColumns = [...modifiedVisibleColumns, dropdownItem.column_name]
                            }
                        })
                    }
                })
            })

            return modifiedVisibleColumns
        },
        getLocalFilterState() {
            let savedMetrics = this.dataStore['savedMetrics'].locations;

            let filterData = {
                metrics: [],
                breakdown: []
            }, init_visible_columns = []

            if (savedMetrics) {
                let existingData = savedMetrics.find(location => location.id === this.location);
                if (existingData && "reports" in existingData) {
                    existingData = _.cloneDeep(existingData);
                    let savedReportData = existingData.reports.find(report => report.id === this.reportMetricId);
                    if (savedReportData) {
                        let savedFilterState = _.cloneDeep(savedReportData).filterState;
                        if (savedFilterState && savedFilterState[this.report_type]) {
                            //console.log({ savedMetrics, savedFilterState })

                            for (let key in savedFilterState[this.report_type]) {
                               filterData[key] = _.cloneDeep(savedFilterState[this.report_type][key])
                            }

                            init_visible_columns = this.getFilterVisibleColumns(filterData)
                        }
                    }
                }
            }

            return { filterData , visibleColumns: init_visible_columns }
        },
        setReportData(reportType, reportData) {
            let localSavedState = this.getLocalFilterState(), defaultData = this.dataStore.createColumnsAndFilterItems(reportType, reportData);
            let columns = [], filterItems = [], visibleColumns = [];

            let hasLocalState = !(localSavedState.filterData.metrics.length === 0 && localSavedState.filterData.breakdown.length === 0);

            if (hasLocalState && localSavedState.filterData.metrics[0].column_name === reportType) {
                let filterData = _.cloneDeep(localSavedState.filterData)
                filterItems = _.cloneDeep(filterData.metrics);
                columns = this.dataStore.setColumnOrder(localSavedState.filterData, defaultData.columns, this.customMetrics);
                visibleColumns = _.cloneDeep(localSavedState.visibleColumns);

                this.filterData = filterData;
                // console.log({ localSavedState, filterData, filters: this.filterData })
            } else {

                let hasFilterData = !(this.filterData.metrics.length === 0 && this.filterData.breakdown.length === 0);
                //console.log({ hasFilterData, filterData: this.filterData })

                if (hasFilterData && this.filterData.metrics[0].column_name === reportType) {
                    filterItems = this.filterData.metrics;
                    columns = this.dataStore.setColumnOrder(this.filterData, defaultData.columns, this.customMetrics);
                    visibleColumns = this.getFilterVisibleColumns(this.filterData)
                } else {
                    filterItems = defaultData.filterItems;
                    columns = defaultData.columns;
                    visibleColumns = defaultData.visibleColumns
                }
            }


            let starredMetrics = this.dataStore.getStarredMetrics(this.report_type, this.location, this.reportMetricId)
            this.$emit('update:starredMetrics', starredMetrics)

            // console.log({ filterItems, starredMetrics })
            return {
                columns: _.cloneDeep(columns),
                filterItems: _.cloneDeep(filterItems),
                visibleColumns: _.cloneDeep(visibleColumns)
            }
        },

        setVisibleColumns() {

            let visibleColumns = this.columns
                .filter(column => this.rows.filter(row => this.hasValue(column.field, row)).length > 0)
                .map(column => column.field);

            if (!this.activateBreakdown) {
                visibleColumns = visibleColumns.filter(column => column !== `${this.report_type}_parent`)
            }

            for (let key in this.filterData) {
                this.filterData[key] = this.filterData[key].map(item => {
                    if (item.dropdown.length === 0) {
                        item.visible = visibleColumns.indexOf(item.column_name) !== -1
                    } else {
                        item.dropdown = item.dropdown.map(dropdownItem => {
                            dropdownItem.visible =  visibleColumns.indexOf(dropdownItem.column_name) !== -1
                            return dropdownItem
                        })

                        item.all_items_visible = item.dropdown.filter(dataItem => dataItem.visible).length === item.dropdown.length
                    }
                    return item
                })
            }


            this.filter_items = this.filterData[this.filter_state];
            this.visible_columns = visibleColumns

        },

        hasValue(column, row) {
            let formattedValue = formatValue(column, row[column], this.uncheckedFields);
            return formattedValue !== '-'
        },


        /**
         * Sets field background color, darker shade of blue if its index is in the
         *  colored_indexes array and lighter shade if it is one of the % to {stage} column
         **/
        setFieldColor(field_name) {
            return this.dataStore['coloredColumns'].indexOf(field_name) !== -1 ? 'colored-column' :
                field_name.includes('percent') ? 'percent-column' : '';
        },

        /*checkIndexes() {
          console.log(this.filter_items)
        },*/

        /**
         *  Makes all items in the "Filter Columns" sidebar visible
         * **/
        setAllStagesVisible(state) {
            this.all_stages_visible = state;

            //  console.log({ filterItems: this.filter_items })


            let visible_columns = [];

            this.filter_items = this.filter_items.map(item => {
                item.visible = this.all_stages_visible;
                item.apply_to_all = false;

                if (item.dropdown.length > 0) {
                    item.dropdown = item.dropdown.map(dropdownItem => {
                        dropdownItem.visible = this.all_stages_visible;

                        if (dropdownItem.visible) {
                            visible_columns.push(dropdownItem.column_name)
                        }

                        return dropdownItem
                    })

                    item.all_items_visible = item.dropdown.filter(dataItem => dataItem.visible).length === item.dropdown.length
                } else {
                    if (item.visible) {
                        visible_columns.push(item.column_name)
                    }
                }
                return item
            })

            this.visible_columns = visible_columns;
            console.log('Stages visible')
            this.updateStoreSavedFilters();
        },

        /* getElementOffsetPercentage(item_index) {
           let element_height = this.$refs[`column-item-0`].offsetHeight,
               container_height = (element_height * this.filter_items.length) + element_height,
               offset_top = (element_height * 1.7) * (item_index + 1);

           /!*
             console.log({
               "Element height": element_height,
               "Container height": container_height,
               "Offset top": offset_percentage,
               "Offset percentage": `${offset_percentage}%`
              })
             *!/
           return (offset_top / container_height) * 100;
         },*/

        /** Detects drop of items in the "Filter stages" sidebar **/
        onDrop(scrollToSection = false) {
            let { start, position, headerElement } = this.dragData;
            if (position === -1 && !headerElement) {
                return
            }


            let dragStart = start, dragEnd = -1;

            if (position !== -1) {
                dragEnd = position;
            } else {
                if (headerElement) {
                    let filterItems = this.filter_items;

                    filterItems.forEach((item, index) => {
                        if (item.column_name === headerElement) {
                            dragEnd = index
                        }
                    })
                }
            }

            if (dragStart !== -1 && dragEnd !== -1) {
                if (dragStart === dragEnd) {
                    return
                }

                let dragDirection = dragStart > dragEnd ? 'upward': 'downward';

                let draggedFilterItem = {...this.filter_items[dragStart]};
                let droppedOnFilterItem = {...this.filter_items[dragEnd]};

                let updatedFilterItems = [], filterDragEnd = position !== -1 ? dragEnd - 1 : dragEnd;

                this.filter_items.forEach((filterItem, filterIndex) => {
                    if (filterIndex !== dragStart) {
                        if (filterIndex === dragEnd && dragDirection === 'upward') {
                            updatedFilterItems = [
                                ...updatedFilterItems,
                                draggedFilterItem
                            ]
                        }

                        updatedFilterItems = [ ...updatedFilterItems, filterItem]

                        if (filterIndex === filterDragEnd && dragDirection === 'downward') {
                            // console.log({ filterIndex, end: dragEnd })
                            updatedFilterItems = [...updatedFilterItems,
                                draggedFilterItem
                            ]
                        }
                    }
                })

                this.filter_items = updatedFilterItems;
                this.updateStoreSavedFilters();

                let draggedColumn = this.columns.filter(column => column.column_name === draggedFilterItem.column_name);
                let droppedOnColumn = this.columns.filter(column => column.column_name === droppedOnFilterItem.column_name);

                if (draggedColumn.length > 0 && droppedOnColumn.length > 0) {
                    let modifiedColumns = [];

                    this.columns.forEach(column => {
                        if (column.column_name !== draggedColumn[0].column_name) {
                            if (column.field === droppedOnColumn[0].field && dragDirection === 'upward') {
                                draggedColumn.forEach(columnData => {
                                    modifiedColumns = [ ...modifiedColumns, columnData ]
                                })
                            }

                            if (dragDirection === 'downward' && position !== -1 && column.field === droppedOnColumn[0].field)  {
                                draggedColumn.forEach(columnData => {
                                    modifiedColumns = [ ...modifiedColumns, columnData ]
                                })
                            }

                            modifiedColumns = [ ...modifiedColumns, column ]

                            if (dragDirection === 'downward' && column.field === droppedOnColumn[droppedOnColumn.length-1].field && position === -1) {
                                draggedColumn.forEach(columnData => {
                                    modifiedColumns = [ ...modifiedColumns, columnData ]
                                })

                            }
                        }
                    })

                    this.columns = modifiedColumns;
                    this.emitMetrics()
                }

                if (scrollToSection) {
                    let draggedColumn = draggedFilterItem.dropdown.length === 0 ? draggedFilterItem : draggedFilterItem.dropdown.find(item => item.visible);

                    if (draggedColumn && draggedColumn.visible) {
                        let field = draggedColumn.column_name;

                        setTimeout(() => {
                            this.scrollToColumn(field)
                        }, 0)
                    }

                }
            }

            setTimeout(() => {
                this.dragData = {
                    start: -1,
                    position: -1,
                    headerElement: ''
                }
                this.resetMetrics();
            }, 100)
        },

        resetMetrics() {
            //console.log('Reset metrics function called', this.totals_checked)
            this.totals_checked ? this.toggleTotals(true) : null;
            this.averages_checked ? this.toggleAverages(true) : null;



            this.kpi_checked ? this.reOrderKpi().then(() => this.kpi_value_submitted = false) : null;
        },

        async reOrderKpi() {
            this.kpi_value_submitted = true;
            this.typing = false;
            this.kpi_row = this.setValueOrder();

            return this.kpi_row;
        },

        /**
         *
         * Handles submit for KPI row fields and sets field color based on
         *  manually entered value in comparison to all values for that column
         *
         **/
        async onSubmit(initial_load = false) {
            this.kpi_value_submitted = true;
            this.typing = false;

            this.kpi_row.forEach(row_item => {
                if (this.uncheckedFields.indexOf(row_item.fieldKey) === -1) {
                    let kpi_item = this.init_kpi_row.find(item => item.name === row_item.name);

                    if (kpi_item) {
                        const index = this.init_kpi_row.indexOf(kpi_item);
                        kpi_item.value = row_item.value
                        this.init_kpi_row[index] = kpi_item
                    }
                }
            });

            if (!initial_load) {
                let request_format = {
                    "kpi": {
                        stages: [],
                        timeToClose: this.closeTime
                    }
                }

                let api_report_item = this.apiReportItem;

                let customMetricFields = this.customMetrics.map(metric => metric.field)
                this.init_kpi_row.filter(item => customMetricFields.indexOf(item.name) === -1).forEach(item => {
                    if (!item.fieldKey) {
                        if (!item.stage_type) {
                            let itemName = item.name;

                            if (itemName.includes('total_lead')) {
                                let newKey = 'total_lead_count';
                                itemName = !itemName.includes('created') ? newKey : newKey + '_created';
                            }
                            let itemKey = this.dataStore.formatObjectKey(itemName);

                            if (itemKey.toLowerCase().includes('modified')) {
                                let modifiedIndex = itemKey.toLowerCase().indexOf('modified');
                                itemKey = itemKey.slice(0, modifiedIndex)
                            }
                            let itemValue;

                            if (!item.value) {
                                itemValue = null
                            } else {
                                itemValue = parseFloat(removeSpecialChars(item.value));
                                itemValue =  item.conversion_field ? itemValue / 100 : itemValue;
                            }

                            request_format.kpi[itemKey] = itemValue
                        } else {
                            let stageName = item.stage_name.replace(/_/g, " ");
                            let stageFormatData = api_report_item.find(stage => stage.name.toLowerCase() === stageName.toLowerCase());

                            if (stageFormatData) {
                                let existingStage = request_format.kpi.stages.find(stage => stage.name === stageFormatData.name);

                                if (!existingStage) {
                                    let stageFormat = {
                                        index: stageFormatData.index,
                                        id: stageFormatData.id,
                                        name: stageFormatData.name,
                                        leadCount: null,
                                        conversionRate: null,
                                        totalConversionRate: null,
                                        timeToStage: null,
                                        totalTimeToStage: null
                                    }


                                    request_format.kpi.stages.push(stageFormat)
                                }

                                let itemValue;

                                if (item.time_field) {
                                    itemValue = item.value[this.milliSecondIndex]
                                } else  {
                                    itemValue = item.value
                                }

                                if (!itemValue) {
                                    itemValue = null
                                } else {
                                    if (!item.time_field) {
                                        itemValue = parseFloat(removeSpecialChars(itemValue));
                                        itemValue = item.conversion_field ? itemValue / 100 : itemValue;
                                    }
                                }

                                let stageData = request_format.kpi.stages.find(stage => stage.name === stageFormatData.name);
                                if (stageData) {
                                    let stageIndex = request_format.kpi.stages.indexOf(stageData);

                                    request_format.kpi.stages[stageIndex][item.dataKey] = itemValue;
                                }


                            }

                        }
                    }
                })


                // console.log({ request_format })
                let request_url = `${baseURL}/locations/${this.location}/pipelines/${this.pipeline.id}/report/${this.report_type}/kpis`;
                axios.post(request_url, request_format, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                let customMetricKpis = this.init_kpi_row.filter(item => customMetricFields.indexOf(item.name) !== -1);

                if (customMetricKpis.length > 0) {
                    let customMetrics = _.cloneDeep(this.customMetrics)
                    customMetricKpis.forEach(item => {
                         customMetrics = customMetrics.map(metric => {
                            if (metric.field === item.name) {
                                metric.kpi = item.value ? parseFloat(item.value) : item.value
                            }

                            return metric
                        })
                    })

                  this.customMetrics = customMetrics

                    this.updateCustomMetrics().then(() => {
                        this.dataStore.getReportData(this.savedReport.report.location_id, this.savedReport.report.id).then(response => {
                            let updatedReport = response.data.report;
                            this.dataStore.updateReport(updatedReport);
                        })
                    })
                }

            }

            return this.kpi_row

        },

        formatValue(name, value) {
            return this.isACustomMetric(name) ? this.displayCustomMetric(name, value) : formatValue(name, value, this.uncheckedFields)
        },

        isACustomMetric(field) {
            return this.dataStore.isACustomMetric(this.customMetrics, field)
        },

        displayCustomMetric(field,value) {
            return this.dataStore.formatCustomMetricValue(this.customMetrics, field, value)
        },

        addMetricColumn(metric, columns, preceedingColumn) {
            let clonedColumns = [];

            let { name, field } = metric;

            let column = {
                label: name,
                field,
                name: field,
                sortable: true,
                align: "center",
                column_name: field
            }

            columns.forEach(columnItem => {
                clonedColumns.push(columnItem)
                if (columnItem.name === preceedingColumn) {
                    clonedColumns.push(column)
                }
            })

            return clonedColumns
        },

        updateCustomMetricProp(value) {
            this.$emit('metricChange', { metric: 'customMetrics', value })
        },

        addMetric(event) {
            let data = event.data;

            let metric = this.dataStore.createMetricFieldFormat(data);
            let { filterItems, preceedingColumn, stageType } = this.dataStore.addNewCustomMetric(metric, this.filter_items)

            this.columns = this.addMetricColumn(metric, this.columns, preceedingColumn)
            this.customMetrics = [...this.customMetrics, metric];

            this.updateCustomMetricProp(this.customMetrics)

            this.visible_columns.push(metric.field);
            this.filter_items = _.cloneDeep(filterItems);


            let kpiRow = [];
            let kpiData = {
                name: metric.field,
                value: "",
                stage_type: stageType,
                conversion_field: data.format === '%',
                time_field: false,
                custom_metric_field: true
            }

            this.init_kpi_row.forEach(kpiItem => {
                kpiRow.push(kpiItem)
                if (kpiItem.name === preceedingColumn) {
                    kpiRow.push(kpiData)
                }
            })

            this.init_kpi_row = kpiRow;

            this.modifyReportData(metric).then(() => {
                this.modifySavedRows(metric.field, metric.field);
            });

            this.scrollToColumn(metric.field);
            // this.closeDropdowns();

            this.resetMetrics();
        },

        async modifyReportData(metric) {
            if (this.report_type === 'tag') {
                return await this.organizeBreakdowns(this.report)
            } else {
                this.rows = this.rows.map(row => {
                    row = this.dataStore.addCustomMetricValue(row, metric.field, metric.formula);
                    return row
                })


                return this.rows;
            }
        },

        updateCurrentReport(report) {
            let savedReport = _.cloneDeep(this.savedReport);
            savedReport.report = report;


            this.emitMetrics();
            this.updateStoreSavedFilters();

           // console.log({ report, range: this.dateRange })

            this.dataStore.getSavedReport(report.location_id, report.id, this.dateRange).then(data => {
                console.log({ data })
                this.$emit('update:savedReport', data)

                //console.log({ savedReport, report, data });
            })
        },
        addCustomMetric(event) {
            console.log({ event })
            this.addMetric(event);
            if (event.template) {
                this.updateCurrentTemplate(event.template)
            }

            if (event.report) {
                this.updateCurrentReport(event.report)
            }
        },

        deleteCustomMetric(event) {

            this.columns = this.columns.filter(column => column.name !== event.field);
            this.visible_columns = this.visible_columns.filter(column => column !== event.field);

            this.filter_items = this.dataStore.deleteCustomMetric(this.filter_items, event);

            this.customMetrics = this.customMetrics.filter(metric => metric.id !== event.id);
            this.updateCustomMetricProp(this.customMetrics)

            this.customMetricCreator.activeMetric = null

            let existingOverviewMetric = this.starredMetrics.find(item => item.column_name === event.field);
            if (existingOverviewMetric) {
                let starredMetrics = this.starredMetrics.filter(item => item.column_name !== event.field);
                this.$emit('update:starredMetrics', starredMetrics)
                this.updateStoreMetrics(starredMetrics);
            }

            this.modifySavedRows(event.field, event.field);
            // this.closeDropdowns();
            this.resetMetrics();

            if (event.template) {
                this.updateCurrentTemplate(event.template)
            }

            if (event.report) {
                this.updateCurrentReport(event.report)
            }
        },

        sortTable(rows, sortBy, descending) {
            let parentKey = `${this.report_type}_parent`;
            if (sortBy === this.report_type) {
                if (!this.activateBreakdown) {
                    rows = sortStringRow(rows)
                }
            } else if (sortBy === parentKey) {
                let dataRows = rows.filter(row => row.parentRow && !row.noParentRow);
                let mappedRows = sortStringRow(dataRows);

                let orderedRows = [];
                mappedRows.forEach(row => {
                    let childItems = getBreakdownItems(row[parentKey], false);

                    orderedRows = [
                        ...orderedRows,
                        row,
                        ...childItems
                    ]
                });

                rows = [ ...orderedRows, ...orderRowsWithNoBreakdown(false) ]
            } else {
                if (!this.activateBreakdown) {
                    if (descending) {
                        rows = rows.sort((a, b) => b[sortBy] - a[sortBy])
                    } else {
                        rows = rows.sort((a, b) => a[sortBy] - b[sortBy])
                    }
                } else {
                    let tagParents = rows.filter(row => row.parentRow && !row.noParentRow);

                    if (descending) {
                        tagParents = tagParents.sort((a, b) => b[sortBy] - a[sortBy])
                    } else {
                        tagParents = tagParents.sort((a, b) => a[sortBy] - b[sortBy])
                    }

                    let orderedRows = [];
                    tagParents.forEach(parentRow => {
                        let childItems = getBreakdownItems(parentRow[parentKey])

                        orderedRows = [
                            ...orderedRows,
                            parentRow,
                            ...childItems
                        ]
                    })

                    rows = [ ...orderedRows, ...orderRowsWithNoBreakdown() ]
                }
            }

            function getNoParentRowIndex() {
                let rowsWithNoTagParentIndex = -1;

                rows.forEach((row, index) => {
                    if (row.noParentRow) {
                        rowsWithNoTagParentIndex = index
                    }
                });

                return rowsWithNoTagParentIndex
            }

            function sortStringRow(dataRows) {
                let sortID = uuidv4();
                let mappedRows = dataRows.map(row => {
                    let modifiedRow = { key: row.key }
                    modifiedRow[sortBy] = row[sortBy];
                    return modifiedRow
                }).map(item => ( `${item[sortBy]} sddSortKey-${sortID}:${item.key}` ));


                mappedRows = descending ? mappedRows.sort().reverse() : mappedRows.sort();
                return mappedRows.map(item => {
                    let itemData = {};
                    let itemKey = item.split(`sddSortKey-${sortID}:`);
                    itemData[sortBy] = itemKey[0];
                    itemData.key = itemKey[1]
                    return itemData
                }).map(item => {
                    return rows.find(row => row.key === item.key)
                })
            }


            function getBreakdownItems(breakdown, sort = true) {
                let childItems = rows.filter(breakdownRow => breakdownRow[parentKey].toLowerCase() === breakdown.toLowerCase() && !breakdownRow.parentRow);

                if (sort) {
                    if (descending) {
                        childItems = childItems.sort((a, b) => b[sortBy] - a[sortBy])
                    } else {
                        childItems = childItems.sort((a, b) => a[sortBy] - b[sortBy])
                    }
                }

                return childItems
            }

            function orderRowsWithNoBreakdown(sort = true) {
                let slicedRows = [], noTagParentRow = {};
                let rowsWithNoTagParentIndex = getNoParentRowIndex();


                if (rowsWithNoTagParentIndex !== -1) {
                    slicedRows = rows.slice(rowsWithNoTagParentIndex+1);
                    noTagParentRow = rows[rowsWithNoTagParentIndex];

                    if (sort) {
                        if (descending) {
                            slicedRows = slicedRows.sort((a, b) => b[sortBy] - a[sortBy])
                        } else {
                            slicedRows = slicedRows.sort((a, b) => a[sortBy] - b[sortBy])
                        }
                    }
                }


                return [ noTagParentRow, ...slicedRows ]
            }

            return rows
        },

        async updateCustomMetrics() {
            return await new Promise((resolve, reject) => {
                let customMetrics = _.cloneDeep(this.customMetrics);
                let store = this.dataStore;
                let savedReport = this.savedReport.report;

                updateMetric(0)
                function updateMetric(index) {
                    if (index < 0 || index >= customMetrics.length) {
                        resolve({ customMetrics });
                        return
                    }

                    let metric = customMetrics[index];

                    let requestMetric = _.cloneDeep(metric);
                    delete requestMetric.id;

                    let requestData = {
                        customMetrics: requestMetric
                    }

                    store.sendMetricUpdateRequest(savedReport.id, metric.id, requestData).then(() => {
                        updateMetric(index+1)
                    }).catch(error => {
                        reject(error)
                    })


                }
            })
        },

        updateCustomMetric(event) {

            let editedData = event.data;
            if (editedData.format === 'numeric') {
                editedData.format = ''
            }
            editedData.field = replaceSpaceWithUnderscores(editedData.name);
            editedData.name = capitalizeFirstLetters(editedData.name);

            let previousMetric;
            this.customMetrics = [...this.customMetrics].map(metric => {
                if (metric.id === event.data.id) {
                    previousMetric = _.cloneDeep(metric)
                    metric = editedData
                }
                return metric
            })

            this.updateCustomMetricProp(this.customMetrics)

            this.visible_columns = this.visible_columns.map(column => {
                if (column === previousMetric.field) {
                    column = editedData.field
                }
                return column
            })

            this.filter_items = this.dataStore.updateMetricList(this.filter_items, previousMetric, editedData);

            let columns = [];
            let filterData = [
                ...this.filterData.breakdown,
                ...this.filterData.metrics,
            ]
            filterData.forEach(item => {
                if (item.dropdown.length === 0) {
                    let column = getColumn(this.columns, item.column_name, item.column_name === editedData.field);
                    if (column){
                        columns.push(column)
                    }
                } else {
                    item.dropdown.forEach(dropdownItem => {
                        let column = getColumn(this.columns, dropdownItem.column_name, dropdownItem.column_name === editedData.field);
                        if (column) {
                            columns.push(column)
                        }
                    })
                }
            })

            this.columns = columns

            this.init_kpi_row = this.init_kpi_row.map(kpiItem => {
                if (kpiItem.name === previousMetric.field) {
                    kpiItem.name = editedData.field
                }

                return kpiItem
            })

            this.modifyReportData(editedData).then(() => {
                this.modifySavedRows(editedData.field, previousMetric.field);
            });

            this.scrollToColumn(editedData.field);
            let starredMetrics = _.cloneDeep(this.starredMetrics)
            let existingOverviewMetric = starredMetrics.find(item => item.column_name === previousMetric.field);
            this.resetMetrics()

            if (existingOverviewMetric) {
                let metricIndex = starredMetrics.indexOf(existingOverviewMetric);

                if (metricIndex === -1) {
                    return
                }

                starredMetrics[metricIndex].label = editedData.name;
                starredMetrics[metricIndex].column_name = editedData.field;

                this.$emit('update:starredMetrics', starredMetrics)

                this.updateStoreMetrics(starredMetrics);
            }

            if (event.template) {
                this.updateCurrentTemplate(event.template)
            }

            if (event.report) {
                this.updateCurrentReport(event.report)
            }


            function getColumn(columns, columnName, usePrevMetric) {
                let columnNameValue = usePrevMetric ? previousMetric.field : columnName;
                let existingColumn = columns.find(column => column.field === columnNameValue);

                if (existingColumn) {
                    if (columnName === editedData.field) {
                        existingColumn = {
                            ...existingColumn,
                            field: editedData.field,
                            name: editedData.field,
                            label: editedData.name,
                            column_name: editedData.field
                        }
                    }
                }

                return existingColumn
            }

        },

        setFilterState(event) {
            let { state } = event;
            this.filter_state = state;

            if (state === 'breakdown') {
                this.filter_items = this.filterData.breakdown;
            } else {
                this.filter_items = this.filterData.metrics;
            }
        },


        modifyStarredColumns(/*column*/) {
            /*let isDropdownItem = column.dropdown && column.dropdown.length > 0
            this.filter_items = this.filter_items.map((item) => {
                if (item.dropdown.length === 0) {
                    if (!isDropdownItem && item.column_name === column.column_name) {
                        item.starred = column.starred
                    }
                } else {
                    if (isDropdownItem) {
                        item.dropdown = item.dropdown.map(dropdownItem => {
                            if (dropdownItem.column_name === column.column_name) {
                                dropdownItem.starred = column.starred
                            }
                            return dropdownItem
                        })
                    }
                }

                return item
            })*/

             //console.log({ starredColumns: this.starredColumns })
            let starredMetrics = [...this.starredMetrics];
            this.starredColumns.forEach(column => {
                let existingMetric = starredMetrics.find(metric => metric.column_name === column.column_name);
                if (!existingMetric) {
                    let value = this.calculateAverages()[column.column_name];
                    column = { label: column.item ? column.item : column.label, column_name: column.column_name, selected: { label: 'Average', value } }
                    starredMetrics.push(column)
                }
            });

            starredMetrics = starredMetrics.filter(metric => this.starredColumns.find(column => metric.column_name === column.column_name))

            this.$emit('update:starredMetrics', starredMetrics)

            this.updateStoreMetrics(starredMetrics);
            this.updateStoreSavedFilters();
        },

        displayReportCreator(column) {
            this.currentColumn = column;
            // console.log({ column })
            this.showReportCreator = true;
        },

        scrollToColumn(columnName) {
            setTimeout(() => {
                let column = this.$refs[`table_header_${columnName}`];
              //  console.log({ column, columnName })
                if (!column) {
                    return
                }
                let columnRef = column.$el;
                let dataTable = this.$refs.data_table.$el;


                if (columnRef && dataTable) {
                    let headerItems = dataTable.querySelectorAll('.q-table th');
                    let headerIndex = Array.from(headerItems).indexOf(columnRef);

                    let firstRow = dataTable.querySelector('.q-table tbody tr');
                    let stickyColumn = firstRow.querySelector('td');

                    let offsetLeft = columnRef.offsetLeft;
                    let stickyColumnOffset = stickyColumn.clientWidth;
                    if (this.activateBreakdown) {
                        let stickyBreakdownColumn = dataTable.querySelector('.q-table tbody tr').querySelector('td.tag-child');
                        stickyColumnOffset += stickyBreakdownColumn.clientWidth;
                    }

                    offsetLeft -= stickyColumnOffset;
                    dataTable.querySelector('.q-table__container .scroll').scrollTo({
                        left: offsetLeft,
                        behavior: "smooth"
                    })

                    columnRef.classList.add('within-view');
                    let rows = dataTable.querySelectorAll('.q-table tr');

                    rows.forEach(row => {
                        let rowCells = row.querySelectorAll('td');
                        rowCells.forEach((cell, cellIndex) => {
                            if (cellIndex === headerIndex) {
                                cell.classList.add('within-view');
                            }
                        })
                    })

                    setTimeout(function () {
                        rows.forEach(row => {
                            row.querySelectorAll('td').forEach((cell) => {
                                if (cell.classList.contains('within-view')) {
                                    cell.classList.remove('within-view')
                                }

                            })
                        })
                    }, 6000)
                }
            }, 0)
        },

        modifySavedRows(currentField, prevField) {
            this.tableData.rows = this.modifySavedRowData(this.tableData.rows, currentField, prevField)
        },

        modifySavedRowData(rows, currentField, prevField) {
            return _.cloneDeep(rows).map(tableRow => {
                let clonedData = _.cloneDeep(tableRow);
                delete clonedData[currentField];
                delete clonedData.key;
                delete clonedData[prevField];

                this.rows.forEach(row => {
                    let clonedRow = _.cloneDeep(row);
                    delete clonedRow[currentField];
                    delete clonedRow.key;

                    if (rowIsExactMatch(clonedData, clonedRow)) {
                        tableRow = row;
                    }
                })

                return tableRow
            });
            function rowIsExactMatch(referenceRow, checkedRow) {
                let rowKeys = Object.keys(referenceRow)
                let matchCheck = rowKeys.filter(key => referenceRow[key] !== undefined && checkedRow[key] !== undefined && referenceRow[key] === checkedRow[key])

                return matchCheck.length === rowKeys.length
            }
        },



        updateCurrentTemplate(template) {
            if (template && this.viewingTemplate) {
                this.$emit('update:template', template);
                this.updateStoreSavedFilters();
                this.emitMetrics()
            }
        },


        checkForVisibleColumns() {
            let visibleColumns = []
            this.filterData.breakdown.forEach(item => {
                if (item.visible) {
                    visibleColumns.push(item.column_name)
                }
            })

            this.filter_items = this.filter_items.map(item => {
                if (item.dropdown.length === 0) {
                    if (item.visible) {
                        visibleColumns.push(item.column_name)
                    }
                } else {
                    item.dropdown.forEach(dropdown_item => {
                        if (dropdown_item.visible) {
                            visibleColumns.push(dropdown_item.column_name)
                        }
                    })

                    item.visible = item.dropdown.filter(dropdown_filter_item => dropdown_filter_item.visible).length === 0
                }

                return item
            })

            this.visible_columns = visibleColumns
        }
    }
}
</script>

<style scoped>
@import "../assets/css/report-table.css";
</style>
