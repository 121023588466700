<template>
<div class="image-file-picker">
    <input type="file"
           :id="'image-picker-'+id"
           @change="getFile($event)"
           :accept="acceptedFormats"
           :multiple="allowMultiple"
           ref="filePicker"
           @click="clearData()"
    >
    <label :for="'image-picker-'+id">
    <span class="placeholder-section" v-if="!fileData.displayUrl">
        <i class="bx bx-images images-icon"></i>
        <span class="label-text">Click to upload. Max file size: {{ sizeLimit }}MB</span>
    </span>

    <span class="display-image" v-if="fileData.displayUrl">
      <span class="image-data">
            <img :src="fileData.displayUrl" :alt="fileData.fileName"/>
      </span>
    </span>


        <span :class="['data-section', { 'visible': fileData.visible, 'error-section': fileData.errorText }]">
            <span class="load-section" v-if="fileData.loading && !fileData.fileError">
                <q-spinner/>
            </span>

            <span class="file-data-display" v-if="!fileData.loading && !fileData.fileError">
                <span class="file-name">{{ fileData.fileName }}</span>
                <i class="bx bx-image"></i>
            </span>

            <span class="file-error" v-if="!fileData.loading && fileData.fileError">
                <span class="error-text">
                    {{ fileData.errorText }}
                </span>

                <i class="bx bx-image"></i>
            </span>
        </span>
    </label>
</div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
    name: "ImagePicker",
    props: {
        defaultImage: {
            required: false,
            default: null
        },
        allowMultiple: {
            type: Boolean,
            required: false,
            default: false
        },
        sizeLimit: {
            type: Number,
            required: false,
            default: 5
        }
    },
    emits: [
        'change'
    ],
    mounted() {
        if (this.defaultImage && !this.fileData.displayUrl) {
            let fileType = this.defaultImage instanceof File || this.defaultImage instanceof Blob;
            let fileUrl, fileName;
            if (fileType) {
                let isFile = this.defaultImage instanceof File;
                if (isFile) {
                    fileName = this.defaultImage.name;
                }
                fileUrl = new URL.createObjectURL(this.defaultImage);
            } else {
                fileUrl = this.defaultImage
            }


            this.fileData = {
                ...this.fileData,
                displayUrl: fileUrl,
                fileName: fileName ? fileName : '',
                visible: true
            }
        }
    },
    computed: {
        acceptedFormats() {
            return this.imageExtensions.map(extension => {
                if (extension[0] !== '.') {
                    extension = '.'+extension
                }

                return extension
            }).toString();
        }
    },
    data() {
        return {
            id: uuidv4(),
            imageExtensions: ['jpg', 'jpeg', 'png', 'webp', 'gif'],
            files: null,
            fileData: {
                loading: false,
                fileName: '',
                displayUrl: '',
                visible: false,
                fileError: false,
                errorText: ''
            }
        }
    },
    methods: {
        getFile(event) {
            this.fileData.loading = true;

            let imageFiles = event.target.files;
            console.log({ imageFiles })
            let parsedFiles = [];
            let filesExceedingSizeLimit = 0;

            Array.from(imageFiles).forEach(file => {
                let fileSizeMB =  file.size / 1000000
                if (fileSizeMB <= this.sizeLimit) {
                    parsedFiles.push(file)
                } else {
                    filesExceedingSizeLimit += 1
                }
            })

            if (filesExceedingSizeLimit > 0) {
                this.returnFileError(`${this.allowMultiple ? filesExceedingSizeLimit + ' files' : 'File size'} exceeded the size limit`)
                return
            }

            if (parsedFiles.length === 0) {
                this.returnFileError(`Error uploading file${this.allowMultiple ? 's' : ''}`)
                return
            }

            let displayFile = parsedFiles[0];


            this.fileData = {
                ...this.fileData,
                loading: false,
                fileError: false,
                visible: true,
                displayUrl: URL.createObjectURL(displayFile),
                fileName:`${ displayFile.name } ${ parsedFiles.length > 1 ? `and ${parsedFiles.length-1} others` : ''}`
            }

            this.$emit('change', parsedFiles)

            this.$refs.filePicker.value = ''


        },

        returnFileError(errorText) {
            this.fileData = {
                ...this.fileData,
                fileError: true,
                visible: true,
                loading: false,
                errorText
            }
        },

        clearData() {
            if (this.fileData.fileError) {
                this.fileData.visible = false;
            }
            this.fileData = {
                ...this.fileData,
                errorText: '',
                fileError: false
            }
        }
    }
}
</script>

<style scoped>
.image-file-picker{
    padding: .4rem 0;
}
input[type="file"] {
    width: 0;
    height: 0;
    display: none;
}

input[type="file"] + label{
    width: 100%;
    height: 150px;
    display: block;
    position: relative;
    overflow-y: hidden;
    cursor: pointer;
}

.placeholder-section,
.display-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.display-image .image-data{
    display: block;
    width: 100%;
    height: 100%;
    filter: brightness(60%);
}

.display-image .image-data img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.placeholder-section{
    background: rgba(80, 148, 221, .09);
    cursor: pointer;
    transition: ease-in-out .2s;
}

.placeholder-section:hover{
    background: rgba(80, 148, 221, .05);
}

/*.placeholder-section:hover .images-icon,
.placeholder-section:hover .label-text{
    opacity: .5;
}*/

.placeholder-section .label-text{
    font-weight: 500;
    font-size: .8rem;
    opacity: .75;
    margin-top: .5rem;
    transition: ease-in-out .2s;
}

.images-icon{
    width: 40px;
    height: 40px;
    color: var(--theme-blue-alt);
    font-size: 40px;
    transition: ease-in-out .2s;
}

.data-section{
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    background: var(--theme-blue-alt);
    height: 35px;
    width: 100%;
    transition: ease-in-out .4s;
}

.data-section.error-section {
    background: #ff6d6d;
}

.data-section:not(.visible) {
    transform: translateY(35px);
}

.data-section.visible{
    transform: translateY(0px);
}

.data-section .load-section{
    margin: 0 auto;
}

.data-section .load-section .q-spinner{
    color: #FFFFFF;
    font-size: 1.05rem;
}

.file-data-display{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 1rem;
    color: #FFFFFF;
}

.file-data-display .file-name{
    font-weight: 500;
    font-size: .85rem;
    justify-content: space-between;
}

.file-data-display i{
    font-size: 24px;
    transition: ease-in-out .2s;
}

.file-data-display i:hover{
    cursor: pointer;
    opacity: .5;
}

.file-error{
    display: flex;
    width: 100%;
    height: 100%;
    color: var(--white);
    font-weight: 500;
    padding: 0 1rem;
    align-items: center;
    justify-content: space-between;
}

.file-error .error-text{
    font-size: .85rem;
    color: var(--white);
}

.file-error .error-text,
.file-data-display .file-name{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 32px);
}

.file-error i{
    font-size: 24px;
    transition: ease-in-out .2s;
    cursor: pointer;
}

.file-error i:hover{
    opacity: .5;
}
</style>
