<template>
<!--      <div class="animated-circle"></div>-->

        <div :class="['flexbox search-group search-bar-group', { 'edit-mode': inEditMode }]"
        >
         <div class="form-group input-group">
          <div class="input-holder flexbox">
              <dropdown
                :items="value_options"
                @valueChange="setSearchType($event)"
                :searchable="false"
                class="search-type-selector"
               ></dropdown>


           <form class="relative-position" @submit.prevent="generateFilterQuery">
             <input
                 type="text"
                 :name="search_field.ref"
                 :ref="search_field.ref"
                 v-model="searchValue"
                 :placeholder="search_field.placeholder"
                 class="form-field search-field"
                 @input="getSearchValue($event)"
             >
             <div v-if="searchState.activated" class="search-results">
              <div class="load-section" v-if="searchState.loading">
                <span class="right-margin-sm">{{ searchState.loadText }}...</span>
                <q-spinner-dots size="18px" color="#5094DD"/>
              </div>

               <div class="result-section" v-if="!searchState.loading">
                 <div class="no-result" v-if="searchState.results.length === 0">
                   {{ searchState.noResultText }}
                 </div>

                 <div class="data-container" v-if="searchState.results.length > 0">
                   <div class="data-item"
                        v-for="(item, index) in searchState.results"
                        :class="item.type"
                        :key="index"
                        @click="runQuery(item)"
                   >
                    <i class="bx bx-search search-icon"></i>
                      <span class="filter-value"> {{ capitalize(item.value) }}</span>
                      <span class="separator">in</span>
                     <span class="filter-type">{{ capitalize(item.type) }}</span>
                   </div>
                 </div>
               </div>
             </div>
           </form>
          <transition name="slid">
            <div class="tooltip position-bottom error-tooltip" v-if="search_error">
              <div class="tooltip-content">{{ search_error_text }}</div>
            </div>
          </transition>
          </div>

           <div class="search-options flexbox flex-align-center top-margin-sm">
             <div class="flexbox option-check">
               <div
                   v-for="(item, index) in search_options"
                   :key="index"
                  >
                 <div v-if="item.visible"  class="flexbox flex-align-center option-item right-margin-md">
                   <checkbox :checked="item.checked"
                             :color="item.color"
                             @change="checkBox(index, $event)"
                   />
                   <div :style="{color: item.color}" class="left-margin-sm text-sm">{{ item.name }}</div>
                 </div>

               </div>
             </div>

             <div class="flexbox action-buttons fit-content">
               <button class="button button-blue search-btn" @click="generateFilterQuery">
                 <span v-if="!searchingFilters">Search</span>
                 <q-spinner-dots
                     color="#FFFFFF"
                     size="20px" :thickness="5"
                     v-if="searchingFilters"
                 />
               </button>
               <button class="button button-blue-transparent" v-if="!running_in_iframe">Save search results</button>
             </div>
           </div>
         </div>

           <edit-overlay
                   :visible="inEditMode"
                   v-model:hide="hideComponent"
           />
        </div>
</template>

<script>
import CustomCheckbox from "./CustomCheckbox";
import CustomDropDown from "@/components/CustomDropDown";
import axios from "axios";
import {baseURL} from "../api/apiBase";
import _ from 'lodash';
import {capitalizeFirstLetters} from "../helpers/helper_functions";
import EditOverlay from "./EditOverlay.vue";

export default {
  name: "SearchBar",
  components: {
      EditOverlay,
    'checkbox': CustomCheckbox,
    'dropdown': CustomDropDown
  },
  props:{
    inEditMode: {
        type: Boolean,
        default: false
    },
    searchingFilters: {
      type: Boolean
    },
    noResultsFound: {
      type: Boolean
    },
    reportType: {
      type: String
    },
    location: {
      type: String,
      required: true
    },
    pipeline: {
      type: String,
      required: true
    },
    dateRange: {
      required: true
    },
    value: {
        type: String
    },
    hidden: {
        type: Boolean,
        default: false
    }
  },
  emits: [
      'input',
      'filterQueryGenerated',
      'update:filters',
      'update:value',
      'update:hidden',
      'delete'
  ],
  data() {
    return {
      checked: false,
      search_field: {
        type: "input",
        ref: "search_field",
        placeholder: "Search for anything: use commas to search for multiple values; " +
            "use the selectors to search by a specific field type",
      },
      search_error: this.noResultsFound,
      search_error_text: "No results found for this search query",
      search_options: [
        {
          name: "Tag",
          color: '#5094DD',
          checked: false,
          visible: true
        },

        {
          name: "Source",
          color: "#D8804C",
          checked: false,
          visible: true
        },

        {
          name: "Salesrep",
          color: "#27AE60",
          checked: false,
          visible: true
        }
      ],

      search_params: [],
      data_list: [],
      value_options: [
        {
          value: true,
          label: "Includes"
        },
        {
          value: false,
          label: "Excludes"
        }
      ],
      inclusive_search: true,
      searchState: {
          loading: false,
          activated: false,
          results: [],
          loadText: "Looking for matches",
          noResultText: "No matches found."
      },
      searchValue: '',
      hideComponent: false
    }
  },
  mounted() {
    this.setReportOption();
  },
  watch: {
    reportType() {
       this.setReportOption()
    },

    noResultsFound(state) {
      this.search_error =state;

      if (state) {
        this.search_error_text = 'No results found for this search query';
      }
    },

    value(state) {
        this.searchValue = state;
    },

    hideComponent(state){
      //  console.log('hideComponentState', state)
        this.$emit('update:hidden', state)
    },

    hidden(state) {
        this.hideComponent = state
    },

    inEditMode: {
        handler(state) {
           // console.log('In edit mode', state, this.hidden)
            if (state) {
                this.hideComponent = this.hidden
            }
        },
        immediate: true
    }
    /*searchingFilters: {
      deep: true,
      handler(state) {
          let searchComplete = !state && !this.noResultsFound;
          if (searchComplete) {
              console.log({ searchComplete, state, noresults: this.noResultsFound })
              this.search_field.value = ''
          }
      }
    }*/
  },
  methods: {
      getState(state) {
          console.log(state, this.hidden)
      },
    getSearchValue(event) {
      this.$emit('update:value', event.target.value)
      if (this.search_error) {
        this.search_error = false;
      }

      if (this.search_params.length > 0) {
        this.debounceRequest(event)
      } else {
        this.$emit('input', this.generateSearchParam())
      }
    },

    generateSearchParam() {
      return {
        value: this.searchValue.toString(),
        has_filters: this.search_params.length !== 0,
        inclusiveSearch: this.inclusive_search
      }
    },
    checkBox(index, state) {
      this.search_options[index].checked = state

      let option_name = this.search_options[index].name.toLowerCase();

        let option_value = option_name.replace(/\s/g, "");
        let option_index = this.search_params.indexOf(option_value);
        option_index === -1 ?
            this.search_params.push(option_value) : this.search_params.splice(option_index, 1);
    },

    generateFilterQuery() {
      this.searchState.activated = false;
      if (!this.searchValue) {
        this.search_error = true;
        this.search_error_text = 'A search parameter is required';

        setTimeout(() => {
          this.search_error = false;
        }, 3000)

      } else if (this.searchValue && this.search_params.length === 0) {
        this.search_error = true;
        this.search_error_text = 'You need to select at least one filter';

        setTimeout(() => {
          this.search_error = false;
        }, 3000)
      } else {
        let filters = this.createQuery(this.searchValue.toString())
        this.$emit('filterQueryGenerated', filters);
      }
    },



    setReportOption() {
      this.search_options = this.search_options.map(item => {
        item.visible =  item.name.toLowerCase() !== this.reportType;
        return item
      })
    },


    createQuery(searchValue) {
      let filterQuery = []
     /* searchValue.split(",")
          .map(value => {
            value = value[0] === ' ' ? value.slice(1) : value
            return value
          })
          .filter(value => value)
          .map(value => {
            this.search_params.forEach(param => {
              filterQuery = [
                ...filterQuery,
                {
                  type: param,
                  query: value,
                  exact: false
                }
              ]
            })
          })*/

      let exactSearch = false, searchValueLength = searchValue.length;
      if (searchValue && searchValueLength > 1 && searchValue[0] === '[' && searchValue[searchValueLength-1] === ']') {
        searchValue = searchValue.slice(1, searchValueLength-1);
        exactSearch = true
      }
      this.search_params.forEach(param => {

        filterQuery = [
          ...filterQuery,
          {
            type: param,
            query: searchValue,
            exact: exactSearch
          }
        ]
      })

      return filterQuery
    },

    debounceRequest:_.debounce(function (event) {
      let searchValue = event.target.value;

      if (searchValue && this.search_params.length > 0) {
        let filterQuery = this.createQuery(searchValue)
        this.searchState = {
          ...this.searchState,
          activated: true,
          loading: true
        }


        this.findDataMatches(filterQuery)
            .then(response => {
           //   console.log({ response })
              this.searchState.loading = false
              let results = response.data.results;

              let listData = [];

              let filterTypes = Object.keys(results);
              filterTypes.forEach(type => {
                let resultData = results[type];
                let filteredList = [];
                resultData.forEach(data => {
                  if (filteredList.indexOf(data) === -1) {
                    filteredList = [ ...filteredList, data ]
                  }
                })
                listData = [
                    ...listData,
                    ...filteredList.map(item => ( { value: item, type }))
                ]
              })

             // console.log({ listData });
              this.searchState.results = listData

              if (listData.length === 0) {
                setTimeout(() => {
                 this.clearSearchState()
                }, 1500)
              }
            }).catch(() => {
              // console.log({ error })
              this.clearSearchState()
        })
      } else {
        if (this.searchState.activated) {
          this.clearSearchState()
        }
      }


    }, 800),

    clearSearchState() {
      this.searchState = {

        ...this.searchState,
        activated: false,
        loading: false
      }
    },

    async findDataMatches(filters) {
      let requestObject = {
        fromDate: this.dateRange.from,
        toDate: this.dateRange.to,
        filters
      }

      return await axios.post(`${baseURL}/locations/${this.location}/pipelines/${this.pipeline}/search/${this.reportType}`, requestObject, {
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 60000
      })
    },


    setSearchType(data) {
      this.inclusive_search = data.value;
      this.$emit('input', this.generateSearchParam());
    },

    capitalize(string) {
      return capitalizeFirstLetters(string)
    },

    runQuery(item) {
      let query =  `[${item.value}]`;
      this.searchValue = query;
      this.$emit('update:value', query);

      this.searchState = {
        ...this.searchState,
        activated: false,
        loading: true
      }

      let filters = [
        {
          type: item.type,
          query: item.value,
          exact: true
        }
      ]

      this.$emit('filterQueryGenerated', filters)
    }
  }
}
</script>

<style scoped>
@import url("../assets/css/search_bar.css");
</style>

<style>
.q-date__header-title-label{
  font-size: 1.1rem;
}

.search-type-selector .dropdown-menu{
  min-width: 136px !important;
}
</style>
