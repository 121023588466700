export const dollarFields = ["lead_cost",  "average_lead_cost" , "revenue", "average_gross_revenue",
    "total_spent", "total_spent_modified", "total_spent_created",
    "average_lead_cost_modified", "average_lead_cost_created",
    "lead_cost_created", "lead_cost_modified",
    "average_cost_per_sale", "profit"];

export const costFields = ["lead_cost", "cost_per_sale", "average_cost_per_sale",
    "total_spent","total_spent_created", "total_spent_modified",
    "average_lead_cost", "average_lead_cost_created", "average_lead_cost_modified"];

export const percentFields = ["roi", "close_rate"];
