let date = new Date(),
    day = date.getDate(),
    current_month = date.getMonth()+1, current_year = date.getFullYear(),
    date_day = day.toString().length === 1 ? `0${day}`: day,
    months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    months_with_30_days = ['September', 'April', 'June', 'November'];


export const getCurrentDate = () => {
    return `${date.getFullYear()}/${addDateZeros(date.getMonth()+1)}/${date_day}`;
}

export const getDateRangeForCurrentMonth = () => {
    let month_start = `${current_year}/${addDateZeros(current_month)}/01`;
    let current_date = getCurrentDate();

    return {
        start: month_start,
        end: current_date
    };
}

export const getDateRangeForPrevMonth = () => {
    let prev_month_object = getDateObjectForPreviousMonth(),
        month = prev_month_object.month, year = prev_month_object.year;

    let prev_month_start = `${year}/${addDateZeros(month)}/01`;
    let max_day = getMaxDay(month),
        prev_month_end = `${year}/${addDateZeros(month)}/${addDateZeros(max_day)}`;

    return {
        start: prev_month_start,
        end: prev_month_end
    };

}

export const getLastWeekDate = () => {
    let last_week_date, month, year;

    if (day - 7  > 0) {
        last_week_date = addDateZeros(day-7);
        month = addDateZeros(current_month);
        year = current_year;
    } else{
        let prev_month_object = getDateObjectForPreviousMonth();
        month = prev_month_object.month;
        year = prev_month_object.year;
        let max_day = getMaxDay(month);
        last_week_date =(day - 7) + max_day;
    }

    return `${year}/${month}/${last_week_date}`;
}

export const getNextWeekDate = () => {
    let current_day = date.getDate();

    let next_week_date;

   let max_day = getMaxDay(current_month);

    if (current_day + 7 > max_day) {
        next_week_date = (current_day + 7) - max_day;
        current_month += 1;
    } else if (current_day + 7 === max_day) {
        next_week_date = 1;
        current_month += 1;
    } else{
        next_week_date = current_day + 7
    }

    next_week_date = addDateZeros(next_week_date)
    //console.log(next_week_dat);

    if (current_month > 12) {
        current_month = current_month - 12;
        current_year += 1;
    }

    current_month = addDateZeros(current_month);

    return `${current_year}/${current_month}/${next_week_date}`;
}

export const getNextMonthDate = () => {
    let next_month = date.getMonth() + 2 > 12 ? (date.getMonth() + 2) - 12 : date.getMonth()+1,
        year = date.getMonth() + 2 > 12 ? date.getFullYear() + 1 : date.getFullYear();

        return `${year}/${addDateZeros(next_month)}/${date_day}`;
}

export const getLastMonthDate = () => {
    let prev_month_default = (date.getMonth() + 1) - 1;
    let prev_month = prev_month_default >= 1 ? prev_month_default : prev_month_default + 12;

    let year = prev_month_default >= 1 ? date.getFullYear() : date.getFullYear() - 1;
    let prev_day, day_data

    if ( date_day > getMaxDay(prev_month) ) {
        day_data = parseInt(date_day) - getMaxDay(prev_month);
        prev_month += 1
    } else{
        day_data =  parseInt(date_day);
    }

    prev_day = day_data.toString().length === 1 ? `0${day_data}` : day_data;

    return `${year}/${addDateZeros(prev_month)}/${prev_day}`;
}

export const getMaxDay = (month_index) => {
    // console.log(months[month_index-1])

    return month_index === 2 ? 28 :
        months_with_30_days.indexOf(months[month_index - 1]) === -1 ? 31 : 30;
}

const addDateZeros = (date) =>{
    return date.toString().length === 1 ? `0${date}` : date;
}

const getDateObjectForPreviousMonth = () => {
    let month, year;
    if (current_month -1 >= 1) {
        month = current_month - 1;
        year = current_year;
    } else{
        month = (current_month - 1) + 12;
        year = current_year - 1;
    }

    return {
        month: month,
        year: year
    };
}


export const getNextDay = (dateString) => {
    let endDate = new Date(dateString);
    let dateData = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()+1);

    return dateData.toISOString().slice(0, 10).replace(/-/g, "/");
}

export const setTimeValue = (milliseconds, date_string = true) => {
    let date_value = [];
    let date_object = {};
    let days = Math.floor(milliseconds / (24 * 60 * 60 * 1000));
    pushDateValue('d', days)
    let day_modulo = milliseconds % (24 * 60 * 60 * 1000);
    let hours = Math.floor(day_modulo / (60 * 60 * 1000));
    pushDateValue('h', hours)
    let hour_modulo = milliseconds % (60 * 60 * 1000);
    let minutes = Math.floor(hour_modulo / (60 * 1000));
    pushDateValue('m', minutes)
    let minute_modulo = milliseconds % (60 * 1000);
    let seconds = Math.floor(minute_modulo / 1000);
    date_value.push(` ${seconds}s`)
    date_object['s'] = seconds;

    date_object['totalInMilliseconds'] = milliseconds;


    return date_string ? date_value.filter(value => parseInt(value) !== 0).toString() : date_object

    function pushDateValue(key, value) {
       /* if (key.toLowerCase() === 'days') {
            value !== 0   ? date_value.push(` ${value} ${key}`) : null;
        } else {
            date_value[date_value.length-1] ? date_value.push(` ${value} ${key}`) : null
        }*/
       // let dateArrayKey = value === 1 ? key.slice(0, key.length-1) : key
        date_value.push(` ${value}${key}`)
        date_object[key.toLowerCase()] = value
    }
}

export const presetValues = {
    last_7_days: {start: getLastWeekDate(), end: getCurrentDate()},
    last_30_days: {start: getLastMonthDate(), end: getCurrentDate()},
    this_month: getDateRangeForCurrentMonth(),
    last_month: getDateRangeForPrevMonth()
}
