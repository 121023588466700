<template>
    <q-dialog class="template-editor-dialog" v-model="open" position="top">
        <div class="dialog-box editor-section">
            <div class="modal-header">
                <h4 class="header-text">Update template</h4>
                <i class="bx bx-x close-icon" @click="open = false"></i>
            </div>

            <div class="top-padding-md">
                <form method="post">
                    <div v-for="(field, fieldIndex) in fields"
                         class="form-group"
                         :key="fieldIndex"
                    >
                        <label>{{ field.label }}</label>
                        <input
                                :class="['form-field', {
                            'input-error': field.hasError
                        }]"
                                :placeholder="field.placeholder"
                                v-model="field.value"
                                :type="field.type"
                                @input="getFieldValue(field)"
                                v-if="field.fieldType === 'input'"
                        />

                        <textarea
                            :class="['form-field', {
                            'input-error': field.hasError
                        }]"
                            :placeholder="field.placeholder"
                            :name="field.name"
                            v-model="field.value"
                            @input="getFieldValue(field)"
                            v-if="field.fieldType === 'textarea'"
                        />

                        <location-selector
                            v-if="field.fieldType === 'locationSelector' && locationId"
                            :location-id="locationId"
                            v-model:locations="field.value"
                            @change="getLocations($event)"
                        />
                        <span class="error-text">
                        {{ field.errorText }}
                    </span>
                    </div>
                </form>
            </div>

            <div v-if="state.errorText && state.updateError" class="y-padding-sm error-section error-text flex flex-align-center justify-center text-center">
                <i class="bx bx-error-circle"></i>
                <span>
             {{ state.errorText }}
            </span>
            </div>

            <div class="button-section">
                <button @click="open = false"
                        class="button button-red-transparent ">
                    Cancel
                </button>

                <button
                        @click="updateTemplate()"
                        class="button button-blue-alt"
                        :disabled="hasEmptyFields || hasFieldsWithErrors || state.updating"
                >
                    {{ state.buttonText }}
                    <q-spinner v-if="state.updating" class="load-spinner"/>
                </button>
            </div>
        </div>
    </q-dialog>
</template>

<script>
import {useStore} from "../store/dataStore";
import LocationSelector from "./LocationSelector.vue";
export default {
    name: "TemplateEditor",
    components: {
        LocationSelector
    },
    setup() {
        return {
            store: useStore()
        }
    },
    props: {
        visible: {
            type: Boolean
        },
        template: {
            type: Object,
            required: true
        }
    },
    emits: [
        'update:visible',
        'complete'
    ],
    watch: {
        visible(state) {
            this.open = state
        },
        open(state) {
            if (!state) {
                this.$emit('update:visible', state);
            } else {
                this.addFields();
                this.preFillFields();
            }
        }
    },
    mounted() {
        this.open = this.visible;
        this.addFields();
        this.preFillFields()
    },
    data() {
        return {
            open: false,
            fields: [],
            state: {
                updating: false,
                updateError: false,
                errorText: '',
                buttonText: 'Update template'
            },
            locationId: ''
        }
    },
    computed: {
        hasEmptyFields() {
            return this.fields.filter(field => field.required && !field.value).length > 0
        },

        hasFieldsWithErrors() {
            let fieldsHaveError = false
            fieldsHaveError = this.fields.filter(field => field.hasError).length > 0;

            this.fields.forEach(field => {
                if (field.name === 'templateName' && this.templateExists(field.value) && !fieldsHaveError) {
                    fieldsHaveError = true
                }
            })
            return fieldsHaveError
        },
    },
    methods: {
        getLocations(event ) {
            console.log({ data: event })
        },

        addFields() {
            this.fields = this.store.createTemplateFields(this.template && this.template['isTopLevel'])
        },
        updateTemplate() {
            let format = {};

            this.fields.forEach(field => {
                format[field.name] = field.fieldType === 'locationSelector' ? field.value.map(location => location.id) : field.value
            })

            if (this.template['isTopLevel']) {
                format['sharedLocations'] = []
            }

            let { customMetrics, savedMetrics, templateData, locationId, id } = this.template;

            let metricData = {
                metrics: savedMetrics['savedMetrics'],
                colors: savedMetrics['colors']
            }

            format = {
                ...format,
                templateData,
                customMetrics,
                savedMetrics: metricData
            }


            // console.log({ format, template: this.template })
            this.state = {
                ...this.state,
                updating: true,
                updateError: false,
                errorText: '',
                buttonText: 'Updating...'
            }

            this.store.sendTemplateUpdateRequest(locationId, id, format).then(response => {
                let responseData = response.data['reportTemplate'];
                this.store.updateTemplate(responseData)

                this.state = {
                    ...this.state,
                    updating: false,
                    buttonText: 'Template updated!'
                }

                setTimeout(() => {
                    this.state = {
                        buttonText: 'Update template'
                    }

                    this.$emit('complete', responseData)
                }, 1500)
            }).catch(error => {
                this.state = {
                    ...this.state,
                    updating: false,
                    updateError: true,
                    errorText: 'Error updating template : ' + error.message,
                    buttonText: 'Update template'
                }

                setTimeout(() => {
                    this.state = {
                        ...this.state,
                        updateError: false,
                        errorText: ''
                    }

                }, 3000)
            })
        },
        getFieldValue(field) {
            if (field.name === 'templateName') {
                if (this.templateExists(field.value)) {
                    field.hasError = true;
                    field.errorText = 'This name is already taken. Try using a different one.'
                } else {
                    if (field.hasError) {
                        field.hasError = false;
                        field.errorText = ''
                    }
                }
            }
        },

        templateExists(templateName) {
            return this.store.templateNameExists(templateName) && (this.template && templateName !== this.template['templateName'])
        },

        preFillFields() {
            this.fields = this.fields.map(field => {
                if (this.template[field.name]) {
                    if (field.name !== 'sharedLocations') {
                        field.value = this.template[field.name]
                    } else {
                        let locations = [];

                        this.template[field.name].forEach(locationId => {
                            let location = this.store.locations.find(location => location.id === locationId);

                            if (location) {
                                locations.push({
                                    name: location.name,
                                    id: locationId
                                })
                            }
                        })

                        field.value = locations
                    }
                }

                return field
            })

            if (this.template && this.template.locationId) {
                this.locationId = this.template.locationId
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/template-dialog.scss";
</style>

