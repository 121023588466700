<template>
<q-dialog v-model="open" position="top" class="share-error-dialog">
  <div class="dialog-box">
      <div class="modal-header">
          <i class="fas fa-times close-icon" @click="open = false"></i>
      </div>

      <div class="error-table">
          <q-table
              :rows="rows"
              :columns="columns"
              row-key="name"
              :pagination="pagination"
          >
              <template v-slot:body="props">
                  <q-tr
                      :props="props"
                  >
                      <q-td :props="props"
                            v-for="col in props.cols"
                            :key="col.name"
                      >
                          <span class="cell-value">
                              {{ col.value }}
                          </span>
                      </q-td>
                  </q-tr>
              </template>
          </q-table>
      </div>
  </div>


</q-dialog>
</template>

<script>
export default {
    name: "ShareErrorComponent",
    props: {
        visible: {
            type: Boolean
        },
        errors: {
            type: Array,
            required: true
        }
    },
    emits: [
        'update:visible'
    ],
    watch: {
        visible(state) {
            this.open = state;
            if (state) {
                this.createRows();
            }
        },
        open(state) {
            this.$emit('update:visible', state);
            this.createRows()
        }
    },
    mounted() {
        this.open = this.visible
    },
    data() {
        return {
           open: false,
           columns: [
               { name: "location", label: "Location", field: "location", sortable: false, align: "left" },
               { name: "error", label: "Error", field: "error", sortable: false, align: "left" }
           ],
            rows: [],
            pagination: {
               sortBy: 'desc',
               descending: false,
               page: 1,
               rowsPerPage: 0
            }
        }
    },
    methods: {
        createRows() {
            this.rows = this.errors.map(error => {
                return {
                    location: error.location.name,
                    error: error.error
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.error-table{
  margin: 1.2rem 0;
}
</style>

<style lang="scss">
.error-table{
  .q-table__container{
    &.q-table__card{
      box-shadow: none;

      .q-table__middle{
        padding-left: 0;

        .q-table{
          th{
            font-size: .9rem;
            font-weight: 700;
          }

          tr{
            td{
              .cell-value{
                display: block;
                line-height: 1.2rem;
                max-width: 280px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
