<template>
    <div
            class="dropdown-container"
            ref="dropdownItem"
            :class="{
      'dropdown-open': open
    }">
        <div class="dropdown-input flexbox flex-align-center"
             @click="toggleState()"
             ref="dropdownInput"
        >
            <div class="dropdown-current" >{{ value }}</div>
            <i class="bx bx-chevron-down arrow-icon" :class="{'open': open}"></i>
        </div>
        <Teleport to="body">
            <div v-if="displayDropdown" class="dropdown-menu" ref="dropdownMenu" :class="{'out-of-view': !open}">
                <div class="top-menu" ref="searchMenu" v-if="searchable">
                    <div class="search-bar">
                        <i class="bx bx-search search-icon"></i>

                        <form @submit="checkForAutoSubmit($event)">
                            <input class="form-field" v-model="search_value" placeholder="Search..">
                            <input type="submit" name="submit">
                        </form>
                        <div class="auto-suggest" v-if="autoSuggestValue.matching !== '' && autoSuggestValue.toBeCompleted !== ''">
                            <span class="matching">{{ autoSuggestValue.matching }}</span>
                            <span>{{ autoSuggestValue.toBeCompleted }}</span>
                        </div>
                    </div>
                </div>
                <ul
                    class="dropdown-options"
                    v-if="select_data.length > 0"
                    ref="dropdownOptions"
                >
                    <li
                        v-for="(item, index) in select_data"
                        :key="index"
                        :data-value="item.value"
                        :class="{
                            'current-selected': index === getCurrentIndex()
                          }"
                        :ref="'dropdown-option-'+index"
                        @click.prevent="detectOptionClick($event, item)">
                        {{ item.label }}
                    </li>
                </ul>

                <div class="error-response" v-if="select_data.length === 0">
                    No results.
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script>
//import {capitalizeFirstLetters} from "../helpers/helper_functions";

export default {
    name: "CustomDropDown",
    props: {
        items: Array,
        defaultValue: String,
        searchable: {
            type: Boolean,
            default: true
        }
    },
    mounted() {
        this.select_data = [...this.items];
        this.setState();
        // this.detectMutation();
        document.addEventListener("click", this.handleOutsideClick);
    },
    unmounted() {
        if (this.observer) {
            this.observer.disconnect()
        }
        document.removeEventListener("click", this.handleOutsideClick);
    },
    data() {
        return {
            value: this.defaultValue !== undefined ? this.defaultValue : this.items[0].label,
            open: false,
            inView: false,
            search_value: '',
            select_data: [],
            autoSuggestValue: {
                matching: '',
                toBeCompleted: '',
                matchingWord: ''
            },
            observer: null
        }
    },
    watch: {
        defaultValue() {
            this.value = this.defaultValue;
        },

        items() {
            // console.log('Item changed')
            this.select_data = this.items
        },

        search_value() {
            if (this.search_value === '') {
                this.select_data = this.items
                this.autoSuggestValue = ''
            } else{
                this.search_value = this.search_value.toLowerCase()
                this.select_data = this.items.filter(item => item.label.toLowerCase().includes(this.search_value.toLowerCase()))



                if (this.select_data.length > 0) {
                    let matchingWord = this.select_data[0].label;
                    let autoSuggestWord = matchingWord.toLowerCase(), autoSuggestSlice =  autoSuggestWord.slice(0, this.search_value.length);

                    if (autoSuggestSlice === this.search_value) {

                        let autoSuggestObject = {
                            matching: autoSuggestSlice,
                            toBeCompleted: autoSuggestWord.slice(this.search_value.length),
                            matchingWord
                        }

                        this.autoSuggestValue = {...autoSuggestObject}
                    } else{
                        this.clearAutoSuggest()
                    }


                } else {
                    this.clearAutoSuggest()
                }
            }
        },
        open(state) {

            this.setState();
            if (state) {

                let index = this.getCurrentIndex();

                if (index !== -1) {
                    setTimeout(() => {
                        let optionsList = this.$refs['dropdownOptions'];
                        let currentOption = this.$refs['dropdown-option-'+index];

                        if (optionsList && currentOption) {
                            let searchMenu = this.$refs['searchMenu'];
                            let topOffset = 0;
                            if (searchMenu) { topOffset = searchMenu.clientHeight }
                            optionsList.scrollTop = currentOption.offsetTop - topOffset;
                        }
                    }, 0)
                }
            }
        }
    },

    computed: {
        displayDropdown() {
            return this.open ? this.open : this.inView
        }
    },

    methods: {
        detectOptionClick(event, item) {
            event.stopPropagation();
            this.emitValue(item)
        },
        setState() {
            let state = this.open;
            let timeout = state ? 0 : 200;
            setTimeout(() => {
                this.inView = state
            }, timeout)

        },
        /*detectMutation() {
            let target = this.$el
            const config = {
                attributes: true,
            }


            const callbackFunction = (mutationList) => {
                for (const mutation of mutationList) {
                    if (mutation.target === this.$el) {
                        if (!mutation.target.classList.contains('dropdown-open') && this.open) {
                            this.open = false
                        }
                    }
                }
            }
            let observer = new MutationObserver(callbackFunction);
            this.observer = observer

            if (target) {
                observer.observe(target, config)
            }

        },*/

        getScrollParent() {
            let dropdown = this.$refs.dropdownItem;
            const checkForScrollParent = (node) => {
                if (node === document) {
                    return node
                }
                if (!node) {
                    return null
                }


                if (node.scrollHeight > node.clientHeight) {
                    let style = getComputedStyle(node);
                    let overflow = style.overflowY;

                    if (overflow === "auto" || overflow === "scroll") {
                        return node;
                    } else {
                        return checkForScrollParent(node.parentElement);
                    }
                } else {
                    return checkForScrollParent(node.parentElement);
                }
            }

          return checkForScrollParent(dropdown.parentElement);

        },
        toggleState() {
            this.open = !this.open;
            this.setPosition();
        },

        detectScroll(/*event*/) {
            this.setPosition(false);
        },

         setPosition(transition = true) {
           let dropdownInput = this.$refs.dropdownInput;
           if (!dropdownInput) { return }

           let { top, left, height } = dropdownInput.getBoundingClientRect();
           let topOffset = top + height;


           setTimeout(() => {
             let dropdownMenu = this.$refs.dropdownMenu;
             //  console.log({ topOffset, dropdownMenu })
             if (dropdownMenu) {
                 let menuHeight = dropdownMenu.clientHeight;
                 if (topOffset + menuHeight > window.innerHeight) {
                     topOffset = top  - menuHeight;
                     // console.log({ topOffset })
                 }
                 let container = this.getScrollParent();

                 if (!container) {
                     container = document;
                 }

                 // console.log({ scrollParent: this.getScrollParent() })
                 if (this.open) {
                     dropdownMenu.style.cssText = `
                           width: ${dropdownInput.clientWidth}px;
                           top: ${topOffset}px;
                           left: ${left}px;
                           position: fixed;
                           transition: none;
                       `

                     if (transition) {
                         dropdownMenu.style.transform = 'translateY(-20px)'
                         setTimeout(() => {
                             dropdownMenu.style.transition = 'ease .2s';
                             dropdownMenu.style.transform = `translateY(0)`;
                         }, 0)
                     }

                     container.removeEventListener('scroll', this.detectScroll)
                     container.addEventListener('scroll', this.detectScroll)
                 } else {
                     dropdownMenu.style.transform = 'translateY(-20px)';
                     container.removeEventListener('scroll', this.detectScroll)
                 }
             }
           }, 0)
         },

        handleOutsideClick(event) {
            let dropdownElement = event.target.closest(".dropdown-container");

            if (!dropdownElement) {
                this.open = false
            } else {
                if (dropdownElement !== this.$refs.dropdownItem) {
                    this.open = false;
                   // console.log({ el: dropdownElement === this.$refs.dropdownItem })
                }
            }
        },

        clearAutoSuggest() {
            this.autoSuggestValue = {
                matching: '',
                toBeCompleted: '',
                matchingWord: ''
            }
        },

        emitValue(item) {
            this.value = item.label;
            this.$emit('valueChange', item)
            this.select_data = this.items;
            this.search_value = '';

            this.toggleState();
            this.clearAutoSuggest()
        },

        checkForAutoSubmit(event) {
            event.preventDefault();

            if (this.autoSuggestValue.matchingWord !== '') {
                let selectableOption = this.select_data[0];
                this.emitValue(selectableOption)
            }
            // console.log('Submitted')
        },

        getCurrentIndex() {
            let currentIndex = -1;

            this.items.forEach((item, index) => {
                if (item.label === this.value && currentIndex === -1) {
                    currentIndex = index
                }
            })

            return currentIndex
        }


    }
}
</script>

<style scoped>
.dropdown-input{
    padding: 8px 14px;
    background: #FFFFFF;
    border: 1px solid rgba(80, 148, 221, .35);
    border-radius: 8px;
    justify-content: space-between;
    min-height: 37px;
}

.dropdown-input i{
    font-size: 18px;
}

.dropdown-container{
    width: 100%;
    position: relative;
    margin-top: 4px;
    color: var(--theme-blue-alt);
    cursor: pointer;
}

.dropdown-current{
    font-size: .85rem;
    margin-right: .8rem;
    width: calc(100%);
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dropdown-menu{
    position: absolute;
    width: 100%;
    min-width: 200px;
    background: #FFFFFF;
    z-index: 99999;
    border: 1px solid rgba(80, 148, 221, .35);
    opacity: 1;
    transition: ease-in-out .2s;
}


.dropdown-menu.out-of-view{
    opacity: 0;
}


.top-menu{
    padding: .8rem 0;
    border-bottom: 1px solid rgba(80, 148, 221, .2);
    /* position: sticky;
     position: -webkit-sticky;
     top: 0;
     left: 0;*/
    background: #FFFFFF;
}

.search-bar{
    margin: 0 auto;
    width: calc(100% - 1rem);
    position: relative;
}

.search-bar input{
    position: relative;
    z-index: 2;
}

.search-bar form input[type="submit"] {
    display: none;
}

.auto-suggest{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: .85rem;
    left: 32px;
    opacity: .5;
    z-index: 1;
    width: calc(100% - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.auto-suggest .matching{
    opacity: 0;
}

.search-icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    z-index: 99;
    font-size: 16px;
    font-weight: 700;
}

.form-field{
    padding: 4px 32px;
    margin-top: 0;
    font-size: .85rem;
}

.dropdown-options{
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
    max-height: 11.6rem;
    overflow-y: auto;
}

.dropdown-options::-webkit-scrollbar{
    width: 4px;
    background: rgba(80, 148, 221, .15);
}

.dropdown-options::-webkit-scrollbar-thumb{
    background: var(--theme-blue-alt);
    border-radius: 3px;
}

.dropdown-options li, .error-response{
    padding: 8px 22px;
    white-space: initial;
}

.dropdown-options li:hover{
    background: rgba(80, 148, 221, .65);
    color: #FFFFFF;
    cursor: pointer;
}
.dropdown-options li.current-selected{
    background: rgba(80, 148, 221, .12);
    color: var(--theme-blue-alt);
    font-weight: 700;
}

.arrow-icon{
    transform: rotate(0deg);
    transition: all .25s;
}

.arrow-icon.open{
    transform: rotate(180deg);
}
</style>
