<template>
  <label :for="'switch-'+ componentId"
         class="switch"
         ref="switchContainer"
         :class="{
          'checked': checkState,
          'disabled': disabled
         }"
  >
    <input type="checkbox"
           :id="'switch-'+ componentId"
           :disabled="disabled"
           v-model="checkState"
           @change="getStatus($event)"
    >
    <span class="slider"></span>
  </label>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "Switch",
  props: {
    checked: {
      type: Boolean,
    },
    disabled: {
      type: Boolean
    },
    size: {
      type: Number
    }
  },
  emits: [
      'update:checked',
      'change'
  ],
  mounted() {
    this.checkState = this.checked;
    this.setComponentSize()
  },
  watch: {
    checked(state) {
      this.checkState = state
    },
    size() {
      this.setComponentSize()
    }
  },
  data() {
    return {
      checkState: false,
      componentId: uuidv4()
    }
  },
  methods: {
    getStatus(event) {
      let state = event.target.checked;
      this.$emit('update:checked', state);
      this.$emit('change', state)
    },

    setComponentSize() {
      if (this.size) {
        this.$refs.switchContainer.style.setProperty('--switch-height', `${this.size}px`);
        this.$refs.switchContainer.style.setProperty('--switch-width', `${(this.size * 2) + 5}px`)
      }
    }
  }
}
</script>

<style scoped>
.switch{
  --switch-width: 45px;
  --switch-height: 20px;
  position: relative;
  display: flex;
  width: var(--switch-width);
  height: var(--switch-height);
  background: #e8eced;
  border-radius: calc(var(--switch-height) / 2);
  cursor: pointer;
  transition: ease .3s;
}

.switch.disabled{
    opacity: .4 !important;
}

.switch.checked{
  background: rgb(18, 120, 212);
}

.switch input{
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  width: var(--switch-height);
  height: var(--switch-height);
  top: 0;
  left: 0;
  background: #FFFFFF;
  transition: ease .3s;
  transform: translateX(0);
  border-radius: 50%;
}

.switch:not(.checked):hover .slider{
  box-shadow: 0 0 1px 2px rgba(168, 168, 168, .2),
  0 0 2px 1px rgba(168, 168, 168, .15),  0 0 1px 6px rgba(168, 168, 168, .09);
}

.switch.checked:hover .slider{
  box-shadow: 0 0 1px 2px rgba(18, 120, 212, .2),
  0 0 2px 1px rgba(18, 120, 212, .15),  0 0 1px 8px rgba(18, 120, 212, .09);
}

.switch input + .slider{
  border: 1px solid rgba(168, 168, 168, .5);
  box-shadow: 0 0 1px 2px rgba(168, 168, 168, .09);
}
.switch input:checked + .slider{
  transform: translateX(calc(100% + 5px));
  border: 1px solid rgba(18, 120, 212, .5);
  box-shadow:  0 0 1px 2px rgba(18, 120, 212, .09);
}
</style>
