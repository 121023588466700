<template>
<div class="page-not-found text-center flexbox flex-column flex-align-center justify-center">
<h3 class="bold-text">404</h3>
<h5 class="top-margin-sm">Page not found.</h5>

<p class="text-md top-margin-sm">
  Looks like the page you are looking for does not exist.
</p>
</div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>
.page-not-found{
  height: 100vh;
}

.page-not-found h3,
.page-not-found h5{
  margin: 0;
}

.page-not-found h3{
  color: var(--theme-blue-alt);

}
.page-not-found h5{
  opacity: .75;
  font-size: .85rem;
}
</style>
