<template>
    <div class="data-options-list options-section" :id="componentId">
        <button @click="toggleOptions($event)">
            <i class="bx bx-dots-vertical-rounded options-dots"></i>
        </button>

        <div ref="optionsList" :class="[ 'options-list', { 'visible': open } ]" >
            <ul>
                <li v-for="(option, optionIndex) in options"
                    :key="optionIndex"
                    :class="[ optionClass ? optionClass(option) : '']"
                    @click="setAction($event, option)"
                >
                   <span v-if="!$slots.option">{{ option.label }}</span>
                    <slot v-if="$slots.option" name="option" :option="option"></slot>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
    name: "OptionsList",
    props: {
      options: {
          type: Array,
          required: true,
      },
      optionClass: {
          type: Function,
          required: false
      }
    },
    emits: [
        'select'
    ],
    data() {
        return {
            open: false,
            componentId: uuidv4(),
            observer: null
        }
    },
    mounted() {
        this.detectMutation();
        document.addEventListener('click', this.detectOutsideClick)
    },
    unmounted() {
        if (this.observer) {
            this.observer.disconnect();
        }

        document.removeEventListener('click', this.detectOutsideClick)
    },
    methods: {
        toggleOptions() {
            this.open = !this.open;


            document.querySelectorAll('.data-options-list').forEach(component => {
                if (component.id !== this.componentId) {
                    component.querySelector('.options-list').classList.remove('visible')
                }
            })
        },

        setAction(event, option) {
            this.$emit('select', { originalEvent: event, action: option.action })
        },

        detectMutation() {
            const options = {
                attributes: true
            };

            let target = this.$refs.optionsList;

            const functionCallback = (mutationList) => {
                for (const mutation of mutationList) {
                    if (mutation.target === target) {
                        if (!target.classList.contains('visible') && this.open) {
                            this.open = false
                        }
                    }
                }
            }

            let observer = new MutationObserver(functionCallback);
            this.observer = observer

            if (target) {
                observer.observe(target, options)
            }
        },

        detectOutsideClick(event) {
            if (!event.target.closest('.data-options-list')) {
                this.open = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.options-section{
    position: relative;
    margin-left: auto;
    height: 25px;

    button{
        padding: 0;
        background: transparent;
        border: 1px solid transparent;
        cursor: pointer;

        &:hover{
            opacity: .5;
        }

        .options-dots{
            font-size: 24px;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .options-list{
        right: 0;
        position: absolute;
        background: var(--white);
        width: 180px;
        transition: ease-in-out .15s;
        z-index: 9;

        &:not(.visible){
            top: 90%;
            visibility: hidden;
            opacity: 0;
        }

        &.visible{
            visibility: visible;
            top: 100%;
            opacity: 1;
        }

        ul{
            padding-left: 0;
            margin: 0;
            list-style: none;
            width: 100%;
            border: 1px solid rgba(80, 148, 221, 0.25);
            position: relative;

            &:before{
                position: absolute;
                top: -8px;
                right: 4px;
                content: "";
                width: 0;
                height: 0;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
                border-bottom: 8px solid rgba(80, 148, 221, 0.25);
            }

            li{
                line-height: 1.3rem;
                padding: .5rem .6rem;
                transition: ease-in-out .15s;
                color: var(--theme-blue-alt);

                &:hover{
                    cursor: pointer;
                    background: rgba(80, 148, 221, 0.15);
                }

                &.delete-text{
                    color: var(--red);

                    &:hover{
                        background: rgba(255, 0, 0, .1);
                    }
                }
            }
        }

    }
}
</style>
