<template>
    <div class="date-field" :class="{'date-open': open}">
        <div
                class="form-field"
                @click="togglePicker()"
                ref="dateField"
        >{{ picker_value }}</div>
        <div class="date-picker"
             ref="datePicker"
             :class="{
          'picker-top': position === 'top',
          'picker-bottom': position === 'bottom',
          'open': open
       }"
        >

            <q-date
                    v-model=" picker_value "
                    minimal
                    @update:model-value="(value) => updateValue(value)"
            ></q-date>
        </div>
    </div>

</template>

<script>
export default {
    name: "DatePicker",
    props: ['open', 'date', 'position'],
    emits: [
        'update:open',
        'dateSelect'
    ],
    data() {
        return {
            picker_value: this.date,
            lastDateValue: this.date,
        }
    },
    mounted() {
        document.body.addEventListener('click', this.detectOutsideClick);
        //  window.addEventListener('resize', this.detectResize);
    },
    unmounted() {
        document.body.removeEventListener('click', this.detectOutsideClick);
        // window.removeEventListener('resize', this.detectResize);
    },
    methods: {
        updateValue(value) {
            //  console.log(value)
            if (value != null) {
                this.lastDateValue = value
            } else{
                this.picker_value = this.lastDateValue
                value = this.lastDateValue
            }

            this.$emit('dateSelect', value)

        },


        detectOutsideClick(event) {
            if (!event.target.closest('.date-field')) {
                this.$emit('update:open', false)
            } else {
                let target = event.target.closest('.date-field');
                let currentElement = this.$el;

                let matchingTarget = target === currentElement;
                if (!matchingTarget) {
                    this.$emit('update:open', false)
                }
            }
        },

        togglePicker() {
            let state = !this.open;
            // this.setDatePickerPosition(state);
            this.$emit('update:open', state);
        },


        /*    setDatePickerPosition(state) {
              let datePicker = this.$refs.datePicker;

              if (state) {
                let dateField = this.$refs.dateField;

                let { top, left, width, height } = dateField.getBoundingClientRect();

            /!*    let height = dateField.clientHeight;
                let width = dateField.clientWidth;
                datePicker.style.cssText = `
                    position: fixed;
                    top: ${top + height}px;
                    left: ${left - width}px;
                `*!/

                console.log({ top, left , width, height})
              } else {
                datePicker.removeAttribute('style')
              }
            },

            detectResize() {
              this.setDatePickerPosition(this.open);
            }*/
    }
}
</script>

<style scoped>
.date-picker{
    display: none;
    transition: ease-in-out .15s;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0;
}

.picker-bottom{
    top: 90%;
}
.picker-bottom.open{
    top: 100%;
}

.picker-top{
    top: -270px;
}

.picker-top.open{
    top: -290px;
}
.date-picker.open {
    display: block;
    opacity: 1;
}
</style>
