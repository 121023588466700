<template>
<q-dialog class="warning-dialog" v-model="open" position="top">
  <div class="dialog-box shadowed">
    <div class="modal-header">
      <h4 class="header-text">{{ header }}</h4>
      <i class="bx bx-x close-icon" @click="open = false"></i>
    </div>

    <div class="warning-text text-center">
      {{ text }}
    </div>

    <div class="action-section flex justify-center">
      <button class="button button-blue-alt" @click="$emit('confirm'); open = false">
        Confirm
      </button>

      <button class="button button-red-transparent" @click="$emit('cancel'); open = false">
        Cancel
      </button>
    </div>
  </div>
</q-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: {
    visible: {
      type: Boolean
    },
    header: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  emits: [
      'update:visible',
      'confirm',
      'cancel'
  ],
  mounted() {
    this.open = this.visible
  },
  watch: {
    visible(state) {
      this.open = state
    },

    open(state) {
      if (!state) {
        this.$emit('update:visible', state)
      }
    }
  },

  data() {
    return {
      open: false
    }
  }
}
</script>

<style>
.warning-dialog .q-dialog__backdrop{
  background: rgba(150, 167, 186, .25);
}
</style>

<style lang="scss" scoped>
.warning-dialog{
  .dialog-box{
    border-radius: 12px;
    box-shadow: 0 0 2px 4px rgba(80, 148, 221, .09);
  }

  .modal-header{
    padding: .25rem 0 .35rem;
    border-bottom: 1px solid rgba(80, 148, 221, .2);

    .header-text{
      font-size: 1rem;
    }
  }

  .warning-text{
    font-size: .9rem;
    padding: 2rem 1.5rem;
    width: calc(100% - 1rem);
    margin: 0 auto;
    line-height: 1.5rem;
    color: var(--theme-blue-alt);
  }

  .action-section{
    padding: 1rem 0;
    border-top: 1px solid rgba(80, 148, 221, .2);

    button{
      min-width: 120px;
      max-width: 100%;
      &:first-child{
        margin-right: .6rem;
      }
    }
  }
}

</style>
