<template>
<div :class="[
    'modal metric-creator-modal',
    { 'modal-open': open }
    ]"
     v-if="visible">
  <div class="modal-content">
    <div class="modal-header">
       <h5 class="header-text">
         {{ metricEdit ? 'Edit' : 'Create' }} Custom Metric
       </h5>
        <i class="bx bx-x close-icon"
           @click="closeModal()"
        ></i>
    </div>

    <div class="flexbox metric-creator">
      <div class="creator-section creator-settings">
          <div
              v-for="(field, index) in fields"
              :key="index"
              :class="['form-group', { 'select-group': field.fieldType === 'dropdown' }]">
            <label class="field-label">{{ field.label }} <span v-if="!field.required">(Optional)</span></label>
            <input
                :type="field.type"
                :placeholder="field.placeholder"
                v-model="field.value"
                v-if="field.fieldType === 'input'"
                :class="['form-field', { 'input-error': field.hasError }]"
                :name="field.name"
                @input="getInput($event, field)"
            >

            <dropdown
                :items="field.options"
                :searchable="false"
                :default-value="field.defaultValue"
                @valueChange="getDropdownValue($event, field)"
                v-if="field.fieldType === 'dropdown'"
            ></dropdown>

            <span class="error-text" v-if="field.errorText && field.hasError">
              {{ field.errorText }}
            </span>
          </div>

        <div class="formula-calculator form-group">
          <label class="field-label">Formula</label>

          <div class="calculator-group">
            <div :class="['calculator-box', { 'placeholder-visible' : placeholderVisible }]" @click="focusOnCalculator($event)">
              <div class="formula">
              <span v-for="(formulaItem, formulaIndex) in calculator.formula"
                    :key="formulaIndex"
                    :class="[
                        'formula-item',
                        {
                          'sign-value' : formulaItem.signValue,
                          'metric-value': formulaItem.metricValue
                        }]"
              >
                <span class="field-section" v-if="calculator.formula.length > 0 && formulaIndex === 0 && formulaIndex === calculator.input.positionIndex">
                    <input
                        type="text" ref="calculatorInput"
                        @input="getCalculatorInput($event.target.value)"
                        @blur="blurField()"
                        @keydown="getKeyInput($event)"
                        v-model="calculator.input.value"
                        :class="['calc-field', { focused: calculator.input.focused }]"
                    >
                </span>
                <span class="formula-value"
                      @click="setPositionIndex(formulaIndex)"
                >
                  {{ formulaItem.value }}
                </span>
                <span class="field-section" v-if="calculator.formula.length > 0 && formulaIndex === calculator.input.positionIndex-1">
                  <input
                      type="text" ref="calculatorInput"
                      v-model="calculator.input.value"
                      @input="getCalculatorInput($event.target.value)"
                      @blur="blurField()"
                      @keydown="getKeyInput($event)"
                      :class="['calc-field', { focused: calculator.input.focused }]"
                  >
                </span>
              </span>
                <span class="field-section" v-if="calculator.formula.length === 0">
                  <input
                      :class="['calc-field', { focused: calculator.input.focused }]"
                      type="text" ref="calculatorInput"
                      v-model="calculator.input.value"
                      @input="getCalculatorInput($event.target.value)"
                      @blur="blurField()"
                      @keydown="getKeyInput($event)"
                      class="calc-field"
                  >
                </span>
              </div>

            </div>

            <div class="flex calculator-actions">
              <div class="creator-section list-section">
                <q-expansion-item
                    v-for="(metricType, metricIndex) in metricTypeList"
                    header-class="metric-type-header"
                    :key="metricIndex"
                    expand-separator
                    :label="metricType.label"
                    group="accordion"
                >

                  <div class="expansion-content">
                    <div v-if="metricType.list.length === 0" class="empty-list-data text-center">
                      No {{ metricType.label.toLowerCase() }} available
                    </div>

                    <div v-else class="list-container">
                      <div v-if="metricType.label !== 'Stage Metrics'" class="data-listing">
                        <div
                            v-for="(listItem, listIndex) in metricType.list"
                            :key="listIndex"
                            class="listing-item"
                            @click="toggleMetricSelection(listItem, metricType.type)"
                        >
                          <i :class="['bx toggle-icon',
                            {
                              'bx-plus': !listItem.selected,
                              'bx-minus': listItem.selected
                            }
                            ]"></i>
                          <span>
                      {{ listItem.label }}
                   </span>
                        </div>
                      </div>

                      <div v-else class="expansion-dropdown">
                        <q-expansion-item
                            v-for="(listItem, listIndex) in metricType.list"
                            :key="listIndex"
                            header-class="metric-dropdown-header"
                            :label="listItem.label"
                            group="accordion-dropdown"
                        >
                          <div
                              v-for="(stageMetric, stageIndex) in listItem.stageMetrics"
                              :key="stageIndex"
                              class="listing-item"
                              @click="toggleMetricSelection(stageMetric, metricType.type)"
                          >
                            <i :class="['bx toggle-icon',
                                {
                                  'bx-plus': !stageMetric.selected,
                                  'bx-minus': stageMetric.selected
                                }
                                ]"></i>

                            <span>
                          {{ stageMetric.label }}
                          </span>
                          </div>
                        </q-expansion-item>
                      </div>
                    </div>
                  </div>

                </q-expansion-item>
              </div>

              <div class="calculator-signs">
                <button
                    v-for="(sign, signIndex) in calculatorSigns"
                    class="sign"
                    :key="signIndex"
                    @click="addCalculatorSign(sign)"
                >
                  <img v-if="sign.icon" :src="sign.icon" :alt="sign.action">
                  <span v-else>{{ sign.value }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="(state.saveError && state.errorText) || (deleteState.deleteError && deleteState.errorText)" class="error-text error-section text-center full-width">
          {{ state.errorText ? state.errorText : deleteState.errorText }}
        </div>
      </div>


    </div>
    <div class="flex action-section">
      <button v-if="metricEdit"
              @click="triggerDelete()"
              class="button button-red-transparent"
              :disabled="deleteState.deleting"
      >
        {{  deleteState.buttonText }}

           <span v-if="deleteState.deleting" class="process-spinner">
             <q-spinner
                 :thickness="4"
             />
           </span>
      </button>
       <div class="button-sections">
         <button
             @click="closeModal()"
             class="button button-blue-transparent right-margin-sm">
           Cancel
         </button>

         <button :disabled="disableCreateButton || state.saving"
                 class="button button-blue-alt create-button"
                 @click="getCreatedMetric()"
         >
           {{ state.buttonText }}

           <span v-if="state.saving" class="process-spinner">
             <q-spinner
                 :thickness="4"
             />
           </span>

         </button>
       </div>
    </div>
  </div>
</div>

</template>

<script>
import CustomDropDown from "./CustomDropDown.vue";
import plusIcon from "../assets/icons/plus-solid.svg";
import subtractIcon from "../assets/icons/minus-solid.svg";
import multiplyIcon from "../assets/icons/xmark-solid.svg";
import divideIcon from "../assets/icons/divide-solid.svg";
import _ from "lodash";
import {useStore} from "../store/dataStore";
import {capitalizeFirstLetters, replaceSpaceWithUnderscores} from "../helpers/helper_functions";

export default {
  name: "CustomMetricCreator",
  mounted() {
    this.open = this.visible;
    this.renderData()
    document.addEventListener('click', this.handleOutsideClick);
  },
  unmounted() {
    document.removeEventListener('click', this.handleOutsideClick)
  },
  components: {
    'dropdown': CustomDropDown
  },
  setup() {
    let store = useStore();
    return {
      store
    }
  },
  props: {
    visible: {
      type: Boolean
    },
    metricList: {
      type: Array,
      required: true
    },
    report: {
      type: Object,
      required: false
    },
    customMetrics: {
      type: Array,
      required: true
    },
    activeMetric: {
      type: Object,
      required: false
    },
    template: {
        type: Object,
        required: false
    }
  },
  emits: [
      'update:visible',
      'create',
      'update',
      'delete'
  ],
  watch: {
    visible(state) {
      setTimeout(() => {
        this.open = state;

        if (state) {
         this.renderData();
        }
      }, 0)
    }
  },
  data() {
    return {
        open: false,
        metricEdit: false,
        fields: [
          {
            label: 'Name',
            value: '',
            placeholder: 'Name of the metric',
            type: 'text',
            hasError: false,
            errorText: '',
            required: true,
            fieldType: "input",
            name: 'name'
          },
          {
            label: 'Format',
            value: 'numeric',
            placeholder:  '',
            type: 'dropdown',
            name: 'format',
            defaultValue: 'Numeric (123)',
            options: [
              {
                label: 'Numeric (123)',
                value: 'numeric'
              },
              {
                label: 'Dollars ($)',
                value: '$'
              },
              {
                label: 'Percentage (%)',
                value: '%'
              }
            ],
            hasError: false,
            errorText: '',
            required: true,
            fieldType: "dropdown"
          },
         /* {
            label: 'Description',
            value: '',
            placeholder: 'Describe this metric',
            type: 'text',
            hasError: false,
            errorText: '',
            required: false,
            fieldType: "input",
            name: 'description'
          }*/
        ],
        metricTypeList: [],
        calculatorSigns: [
          { icon: plusIcon, value: "+", action: 'add' },
          { icon: subtractIcon, value: "-", action: 'subtract' },
          { icon: divideIcon,  value: "/", action: "divide" },
          { icon: multiplyIcon, value: "*", action: "multiply" },
          { icon: null, value: "(", action: "parentheses-before" },
          { icon: null, value: ")", action: "parentheses-after" }
        ],
        calculator: {
          input: {
            value: '',
            positionIndex: 0,
            focused: false
          },
          formula: [],
          parsedFormula: ''
        },
        state: {
          saving: false,
          saveError: false,
          errorText: '',
          buttonText: 'Create Metric'
        },
      deleteState: {
          deleting: false,
          deleteError: false,
          errorText: '',
          buttonText: 'Delete metric'
      },
      selectedMetrics: []
    }
  },
  computed: {
    disableCreateButton() {
      let disabled = false;
      disabled = this.fields.filter(field => field.required && !field.value).length > 0;

      if (this.calculator.formula.length === 0) {
        disabled = true
      }

      return disabled
    },

    placeholderVisible() {
      return this.calculator.formula.length === 0
    },

    reportFormat() {
      let format = {};
      if (this.report && Object.keys(this.report).length > 0) {
        format = this.store.createReportFormat(this.report)
      }

      return format
    }
  },
  methods: {
    blurField() {
      this.calculator.input.focused = false;
    },
    renderData() {
      this.createMetricTypeList();
      this.autoFillFields();

      this.state.buttonText = this.metricEdit ? 'Edit metric' : 'Create Metric';
    },

    closeModal() {
      this.open = false;

      setTimeout(() => {
        this.$emit('update:visible', false)
        this.fields = this.fields.map(field => {
          if (field.fieldType === 'input') {
            field.value = ''
          }
          return field
        })

        this.calculator.formula = [];
        this.calculator.parsedFormula = '';
      }, 300)
    },

    handleOutsideClick(event) {
      if (!event.target.closest('.metric-creator-modal .modal-content') && this.open) {
        this.closeModal()
      }

     /* if (!event.target.closest('.formula-calculator')) {
        this.focusOnCalculator()
      }*/
    },

    getDropdownValue(selection, field) {
      field.value = selection.value
    },

    createMetricTypeList() {
      let selectedMetrics = [];
      if (this.activeMetric && "formula" in this.activeMetric) {
        selectedMetrics = this.activeMetric.formula.entryList.filter(entry => entry.metricValue).map(entry => entry.column_name);
      }
      this.selectedMetrics = selectedMetrics
      this.metricTypeList = [
        {
          label: 'Lead Metrics',
          type: 'lead_metrics',
          list: this.createMetricList('lead_metric')
        },
        {
          label: 'Combined Metrics',
          type: 'combined_metrics',
          list: this.createMetricList('combined_metric')
        },
        {
          label: 'Stage Metrics',
          type: 'stage_metrics',
          list: this.createMetricList('stage_metric')
        },
        {
          label: 'Summary Metrics',
          type: 'summary_metrics',
          list: this.createMetricList('summary_metric')
        }
      ]
    },

    createMetricList(metricKey) {
      let metricList = [];
      this.metricList.forEach(metric => {
        if (metric.dropdown.length === 0) {
          if (metric.metric_type && metric.metric_type === metricKey) {
            let { column_name, label } = metric;
            metricList.push({ column_name, label, selected: this.selectedMetrics.indexOf(column_name) !== -1 })
          }
        } else {
          metric.dropdown.forEach(dropdownItem => {
            if (dropdownItem.metric_type && dropdownItem.metric_type === metricKey) {
              if (metricKey !== 'stage_metric') {
                let listData = {
                  column_name: dropdownItem.column_name,
                  label: dropdownItem.item,
                  selected: this.selectedMetrics.indexOf(dropdownItem.column_name) !== -1
                }

                metricList.push(listData)
              }
            }
          })

          if (metricKey === 'stage_metric') {
            let stageMetricItems = metric.dropdown.filter(dropdownItem => dropdownItem.metric_type && dropdownItem.metric_type === metricKey);

            if (stageMetricItems.length > 0) {
              let { label, column_name, index } = metric
              let stageData = {
                label,
                column_name,
                stageMetrics: stageMetricItems.filter(metricItem => metricItem.key !== 'avg_time' && metricItem.key !== 'avg_total_time')
                                              .map(metricItem => ({
                                                  label: metricItem.item,
                                                  column_name: metricItem.column_name,
                                                  selected: this.selectedMetrics.indexOf(metricItem.column_name) !== -1,
                                                  index,
                                                  key: metricItem.key
                                              }))
              }

              metricList.push(stageData)
            }
          }
        }
      })
      if (metricKey === 'combined_metric' && this.activeMetric && "field" in this.activeMetric) {
        metricList = metricList.filter(metric => metric.column_name && metric.column_name !== this.activeMetric.field)
      }

      return metricList;
    },

    toggleMetricSelection(metric, type) {
      metric.selected = !metric.selected;
      let { label, column_name } = metric;
      let metricObject = {
        value: label,
        column_name,
        metricValue: true,
        type
      }

      if (type === 'stage_metrics') {
          let { index, key } = metric;
          metricObject = {
              ...metricObject,
              index,
              key
          }
      }

      if (metric.selected) {
        this.addCalculatorValue(metricObject)
      } else {
        this.calculator.formula = this.calculator.formula.filter(item => !(item.metricValue && item.column_name === column_name))
      }

    },

    focusOnInput() {
      let inputField = this.$refs.calculatorInput;

      if (inputField) {
        inputField.focus();
        this.calculator.input.focused = true
      }
    },

    getCalculatorInput(value) {
      if (!value) {
        return
      }

      let signValue = this.calculatorSigns.find(sign => sign.value === value)
      if (!signValue) {
        let parsableValue = false;
        if (value !== '.') {
          parsableValue = !isNaN(parseInt(value))
        } else {
          let signItems = this.calculator.formula.filter(item => "signValue" in item && item.signValue).reverse();
          let prevSignIndex = signItems.length > 0 ? this.calculator.formula.indexOf(signItems[0]) : 0
          let lastSignIndex = signItems.length > 1 ? this.calculator.formula.indexOf(signItems[1]) : this.calculator.formula.length;

          let preceedingEntries = this.calculator.formula.slice(prevSignIndex, lastSignIndex);
         // console.log({ prevSignIndex, lastSignIndex, preceedingEntries })
          if (!preceedingEntries.find(entry => entry.value === '.')) {
            parsableValue = true;
          }
        }

        if (parsableValue) {
          this.addCalculatorValue({ value, singularValue: true })
        }
      } else {
        this.addCalculatorSign(signValue)
      }

      this.calculator.input.value = ''


      setTimeout(() => { this.focusOnInput() }, 0)
    },

    getValue: _.debounce(function () {
      this.createFormula().then(formula => {
        this.calculator.parsedFormula = formula
      })
    }, 500),

    async createFormula() {
      return await this.store.createFormulaExpression(this.calculator.formula)
    },

    addCalculatorValue(valueObject) {
      let inputPosition = this.calculator.input.positionIndex;
      if (inputPosition === this.calculator.formula.length) {
        inputPosition += 1
      }

      this.calculator.formula.splice(inputPosition, 0, valueObject);

      let positionIndex = this.calculator.formula.slice(0, inputPosition).length;
      if (inputPosition !== this.calculator.formula.length) {
        positionIndex = this.calculator.input.positionIndex + 1
      }

      this.calculator.input.positionIndex = positionIndex
      this.getValue()

      setTimeout(() => { this.focusOnInput() }, 0)
    },

    removeSelectedMetric(metricObject) {

      this.metricTypeList = this.metricTypeList.map(metricList => {
        if (metricList.type === metricObject.type) {
          metricList.list = metricList.list.map(listItem => {
            if (metricList.type === 'stage_metrics') {
              listItem.stageMetrics = listItem.stageMetrics.map(stageMetric => {
                if (stageMetric.column_name === metricObject.column_name) {
                  stageMetric.selected = false
                }
                return stageMetric
              })
            } else {
              if (listItem.column_name === metricObject.column_name) {
                listItem.selected = false
              }
            }
            return listItem
          })
        }
        return metricList
      })
    },


    getKeyInput(event) {
      let keyCode = event.which;
      // backspace button
       if (keyCode === 8) {
         let deleteIndex = this.calculator.input.positionIndex - 1;
         if (deleteIndex >= 0) {
           let metricObject = this.calculator.formula[deleteIndex];
           if (metricObject.metricValue) {
             this.removeSelectedMetric(metricObject)
           }

           this.calculator.formula = this.calculator.formula.filter((item, itemIndex) => itemIndex !== deleteIndex)
           this.calculator.input.positionIndex = this.calculator.input.positionIndex - 1;

           this.getValue()


           setTimeout(() => { this.focusOnInput() }, 0)
         }
       }

      if (keyCode === 37) {
        // back arrow button
        let reducedIndex = this.calculator.input.positionIndex - 1
        if (reducedIndex >= 0) {
          this.calculator.input.positionIndex = reducedIndex
          setTimeout(() => { this.focusOnInput() }, 0)
        }
      }

      if (keyCode === 39) {
        // forward arrow button
        let increasedIndex = this.calculator.input.positionIndex + 1;
        if (increasedIndex <= this.calculator.formula.length) {
          this.calculator.input.positionIndex = increasedIndex
          setTimeout(() => { this.focusOnInput() }, 0)
        }
      }
    },

    setPositionIndex(formulaIndex) {
        // console.log({ positionIndex, formulaIndex })
      this.calculator.input.positionIndex = formulaIndex + 1
    },

    focusOnCalculator(event) {
      // console.log({ target: event.target })
      if (!event.target.closest('.formula-value') && !event.target.closest('.calc-field')) {
        this.calculator.input.positionIndex = this.calculator.formula.length;
      }

      setTimeout(() => { this.focusOnInput() }, 0)
    },

    addCalculatorSign(sign){
      let { value, action } = sign
      let signObject = {
        value,
        action,
        signValue: true
      }

      // console.log({ signObject })
      this.addCalculatorValue(signObject)
    },

    metricExists(metricName) {
      metricName = metricName.toLowerCase();
      let metricExists = false;

      this.metricList.forEach(metric => {
        if (metric.dropdown.length === 0) {
          if (typeof metric.label === 'string' && metric.label.toLowerCase() === metricName) {
            metricExists = true
          }
        } else {
          metric.dropdown.forEach(dropdownItem => {
            if (dropdownItem.item.toLowerCase() === metricName) {
              metricExists = true
            }
          })
        }
      })

      return metricExists
    },


    getCreatedMetric() {
      let fieldsWithErrors = 0;
      let fieldObject = {
        formula: {
          entryList: this.calculator.formula,
          expression: this.calculator.parsedFormula
        }
      }
      this.fields = this.fields.map(field => {
        field.hasError = false;
        field.errorText = ''
        fieldObject[field.name] = field.value

        if (field.required) {
          if (!field.value) {
            fieldsWithErrors += 1
            field.hasError = true;
            field.errorText = 'This field is required';
          } else {
            if (field.name === 'name' && this.metricExists(field.value) && !this.metricEdit) {
              field.hasError = true;
              field.errorText = 'Metric already exists';
              fieldsWithErrors += 1
            }
          }
        }
        return field
      })

      if (fieldsWithErrors === 0 && this.calculator.parsedFormula) {
        let emitType = this.metricEdit ? 'update' : 'create';

        let emitData = {
          data: fieldObject,
        }

        this.state = {
          ...this.state,
          saving: true,
          saveError: false,
          errorText: ''
        }
          let request = emitType === 'create' ? this.addMetric(fieldObject) : this.updateMetric(fieldObject);

            request.then(response => {

              // console.log({ response, emitData })
              let { report, metric, template } = response;

              if (report) {
                  this.store.updateReport(report);
                  emitData.report = report
              }
              if (template) {
                  this.store.updateTemplate(template);
                  emitData.template = template
              }

             emitData.data.id = metric.id;

             this.closeModal();
             this.$emit(emitType, emitData);
             this.clearState();
            }).catch(error => {
                this.state = {
                  ...this.state,
                  saving: false,
                  saveError: true,
                  errorText: `Error ${ this.metricEdit ? 'editing': 'creating' } custom metric : ` + error.message
                }

                setTimeout(() => {
                 this.clearState()
                }, 3000)
            })
      }
    },

    clearState() {
      this.state = {
        ...this.state,
        saving: false,
        saveError: false,
        errorText: this.metricEdit ? 'Edit metric' : 'Create metric'
      }
    },




    async addMetric(field) {
      let data = this.store.createMetricFieldFormat(field);

      if (this.template && "id" in this.template) {
          let usedStageMetrics = this.store.getUsedStageMetrics(data.formula);
          let templateData = _.cloneDeep(this.template['templateData']);

          if (usedStageMetrics.length === 1) {
              templateData = templateData.map(item => {
                  if (item.index && item.index === usedStageMetrics[0].index) {
                      item.dropdown.push({
                          visible: true,
                          starred: false,
                          metric_type: 'combined_metric',
                          column_name: data.field,
                          item: data.name
                      })
                  }
                  return item
              })
          } else {
              templateData.push({
                  column_name: data.field,
                  dropdown: [],
                  starred: false,
                  visible: true,
                  metric_type: 'combined_metric'
              })
          }

          let templateRequestData = {
              customMetrics: data
          }

          let templateMetricRequest = await this.store.sendTemplateCustomMetricRequest(this.template.id, templateRequestData);
          let metric = templateMetricRequest.data;

          data.id = metric.id;
          let updatedTemplate = await this.updateTemplate(templateData);

          return { metric, template: updatedTemplate }
      } else if (this.report && "id" in this.report) {
          let metricObject = {
              customMetrics: data
          }

          let reportData = _.cloneDeep(this.report['report_data']);
          let metricRequest = await this.store.sendCustomMetricRequest(this.report.id, metricObject);
          let metric = metricRequest.data;

          data.id = metric.id
          let { filterItems } = this.store.addNewCustomMetric(data, reportData)
          reportData = filterItems;

          let format = _.cloneDeep(this.reportFormat);
          format.reportData = reportData;
          let reportRequest = await this.store.updateSavedReport(this.report.location_id, this.report.pipeline_id, this.report.id, format);
          let report = reportRequest.data.report;

          return { report, metric }
      }
    },


   async updateTemplate(templateData) {
       let templateDataRequest = await this.store.getTemplateData(this.template.locationId, this.template.id);
       let template = templateDataRequest.data['reportTemplate'];


       let { templateName, sharedLocations, customMetrics, savedMetrics } = template
       let requestData = {
           templateName,
           sharedLocations,
           customMetrics,
           savedMetrics,
           templateData
       }

       let templateUpdateRequest = await this.store.sendTemplateUpdateRequest(template.locationId, template.id, requestData);
       return templateUpdateRequest.data['reportTemplate'];
   },

   async updateMetric(field) {
       let currentMetric = _.cloneDeep(this.activeMetric);
       currentMetric = {
         ...currentMetric,
         name: capitalizeFirstLetters(field.name),
         formula: field.formula,
         format: field.format === 'numeric' ? '' : field.format,
         field: replaceSpaceWithUnderscores(field.name)
       }

      delete currentMetric.id;
       let requestData = {
           customMetrics: currentMetric
       }

       let metrics = _.cloneDeep(this.customMetrics);
       let previousMetric;

       metrics.forEach(metric => {
           if (metric.id === this.activeMetric.id) {
               previousMetric = _.cloneDeep(metric)
           }
           return metric
       })

       if (this.template && "id" in this.template) {
           let metricUpdateRequest = await this.store.sendTemplateCustomMetricUpdateRequest(this.template.id, this.activeMetric.id, requestData);
           let metric = metricUpdateRequest.data;

           let templateData = this.updateTemplateData(this.template, previousMetric, currentMetric)
           let template = await this.updateTemplate(templateData);

           return { metric, template }

       } else if (this.report && "id" in this.report) {
           let updateRequest = await this.store.sendMetricUpdateRequest(this.report.id, this.activeMetric.id, requestData);
           let metric = updateRequest.data;

           let reportData = _.cloneDeep(this.report['report_data'])
           reportData = this.store.updateMetricList(reportData, previousMetric, currentMetric)

           let format = _.cloneDeep(this.reportFormat);
           format.reportData = reportData;

           let reportRequest = await this.store.updateSavedReport(this.report.location_id, this.report.pipeline_id, this.report.id, format);
           let report = reportRequest.data.report;

           return { report, metric }
       }
    },


    updateTemplateData(template, previousMetric, currentMetric) {
       let templateData = _.cloneDeep(template['templateData']);
       let usedStageMetrics = this.store.getUsedStageMetrics(currentMetric.formula)

        if (usedStageMetrics.length === 1) {
            let updatedData = [];

            templateData.forEach(item => {
                if (item.dropdown.length === 0) {
                    if (item.column_name !== previousMetric.field) {
                        updatedData.push(item)
                    }
                } else {
                    item.dropdown = item.dropdown.filter(dropdownItem => dropdownItem.column_name !== previousMetric.field);
                    if (item.index && usedStageMetrics[0].index === item.index) {
                          item.dropdown.push({
                              visible: true,
                              starred: false,
                              metric_type: 'combined_metric',
                              column_name: currentMetric.field,
                              item: currentMetric.name
                          })
                    }

                    updatedData.push(item)
                }
            })

            // console.log({ updatedData, currentMetric })
            templateData = updatedData
        } else {
            let usedStagesForPreviousMetric = this.store.getUsedStageMetrics(previousMetric.formula);

            if (usedStagesForPreviousMetric.length === 1) {
                let updatedTemplate = templateData.map(item => {
                    item.dropdown = item.dropdown.filter(dropdownItem => dropdownItem.column_name !== previousMetric.field);
                    return item
                })

                updatedTemplate.push({
                    column_name: currentMetric.field,
                    dropdown: [],
                    starred: false,
                    visible: true,
                    metric_type: 'combined_metric'
                })

                templateData = updatedTemplate;
                // console.log({ updatedTemplate })
            } else {
                templateData = templateData.map(item => {
                    if (item.dropdown.length === 0) {
                        if (item.column_name === previousMetric.field) {
                            item.column_name = currentMetric.field
                        }
                    } else {
                        item.dropdown = item.dropdown.map(dropdownItem => {
                            if (dropdownItem.column_name === previousMetric.field) {
                                dropdownItem = {
                                    ...dropdownItem,
                                    item: currentMetric.name,
                                    column_name: currentMetric.field
                                }
                            }
                            return dropdownItem
                        })
                    }

                    return item
                })
                // console.log({ mod: templateData })
            }
        }


        // console.log({ templateData, prevData: this.template['templateData'] })
        return templateData
    },

    getInput(event, field) {
      let value = event.target.value;

      if (value) {
        field.hasError = false;
        field.errorText = ''

        this.debounceValueCheck(field, value)
      }
    },


    debounceValueCheck: _.debounce(function (field, value) {
      if (field.name === 'name' ) {
          if (!this.metricEdit) {
              if (this.metricExists(value)) {
                  field.hasError = true;
                  field.errorText = 'Metric already exists'
              }
          } else {
             if (this.metricExists(value) && value.toLowerCase() !== this.activeMetric.name.toLowerCase()) {
                 field.hasError = true;
                 field.errorText = 'Metric already exists'
             }
          }
      }
    }, 500),

    autoFillFields() {
      if (this.activeMetric && Object.keys(this.activeMetric).length > 0) {
        this.fields = this.fields.map(field => {
          let metricValue = this.activeMetric[field.name]
          if (metricValue) {
            field.value = metricValue;

            if (field.name === 'format') {
              field.value = metricValue === '' ? 'numeric' : metricValue;

              let fieldLabel = field.options.find(option => option.value === field.value);
              if (fieldLabel) { field.defaultValue = fieldLabel.label }
            }
          }
          return field
        })

        this.metricEdit = true;

        let { entryList, expression } = this.activeMetric.formula

        this.calculator.formula = entryList;
        this.calculator.parsedFormula = expression;
      } else {
        this.fields = this.fields.map(field => {
          if (field.name === 'format') {
            field.defaultValue = 'Numeric (123)';
            field.value = 'numeric'
          } else {
            field.value = ''
          }
          return field
        })

        this.metricEdit = false;
      }
    },


    triggerDelete() {
      let { id, field } = this.activeMetric
      let emitData = {
        id,
        field
      }

      this.deleteState = {
        ...this.deleteState,
        deleting: true,
        deleteError: false,
        buttonText: 'Deleting metric...',
        errorText: ''
      }

      this.deleteMetric().then(response => {
            // console.log({ response, emitData })
            let { report, template } = response;
            if (report) {
                this.store.updateReport(report);
                emitData.report = report;
            }

            if (template) {
                this.store.updateTemplate(template);
                emitData.template = template
            }

            this.closeModal();
            this.$emit('delete', emitData)
            this.clearDeleteState()
          }).catch(error => {
            this.deleteState = {
              ...this.deleteState,
              deleting: false,
              deleteError: true,
              buttonText: 'Delete metric',
              errorText: 'Error deleting metric: ' + error.message
            }

            setTimeout(() => {
              this.clearDeleteState()
            }, 3000)
      })
    },

    async deleteMetric() {
        if (this.template && "id" in this.template) {
            let metricDeleteRequest = await this.store.sendTemplateCustomMetricDeleteRequest(this.template.id, this.activeMetric.id);
            let deleted = metricDeleteRequest.data.success;

            let updatedTemplate = this.deleteMetricFromTemplate(this.template, this.activeMetric);
            let template = await this.updateTemplate(updatedTemplate);

            return { deleted, template }
        } else if (this.report && "id" in this.report) {
            let deleteRequest = await this.store.sendMetricDeleteRequest(this.report.id, this.activeMetric.id);
            let deleted = deleteRequest.data.success;

            let reportData = _.cloneDeep(this.report['report_data']);
            reportData = this.store.deleteCustomMetric(reportData, this.activeMetric);

            let format = _.cloneDeep(this.reportFormat);
            format.reportData = reportData;

            let reportRequest = await this.store.updateSavedReport(this.report.location_id, this.report.pipeline_id, this.report.id, format);
            let report = reportRequest.data.report;

            return { deleted, report }
        }
    },

   deleteMetricFromTemplate(template, metric) {
        let updatedTemplate = [];
        let templateData = _.cloneDeep(template['templateData']);

        templateData.forEach(item => {
            if (item.dropdown.length === 0) {
                if (item.column_name !== metric.field){
                    updatedTemplate.push(item)
                }
            } else {
                item.dropdown = item.dropdown.filter(dropdownItem => dropdownItem.column_name !== metric.field);
                updatedTemplate.push(item)
            }
        })

        return updatedTemplate
    },

    clearDeleteState() {
      this.deleteState = {
        ...this.deleteState,
        deleting: false,
        deleteError: false,
        buttonText: 'Delete metric',
        errorText: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/custom-metric-creator";
</style>

<style lang="scss">
.metric-creator{
  .creator-section{


      .metric-type-header{
        color: var(--theme-blue-alt);
        padding: 4px 8px;
        min-height: 42px;
        font-weight: 700;

        &:hover{
          background: rgba(80, 148, 221, .03);
        }
      }

      .q-separator{
        background: transparent;
      }
      .q-expansion-item__toggle-icon{
        font-size: 20px;
        color: var(--theme-blue-alt);
      }

      .expansion-dropdown{
        .metric-dropdown-header{
          min-height: 38px;
          padding: 8px;

          &:hover{
            background: rgba(80, 148, 221, .05);
          }
        }
        .q-expansion-item__toggle-icon{
          font-size: 18px;
          color: var(--theme-blue-alt);
        }
      }

    }
}

.select-group{
  .dropdown-menu{
    min-width: 100%;
    max-width: 100%;
  }
}
</style>
