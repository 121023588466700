<template>

    <div :class="['nav-present', {
      'multiple-report-dash': viewingMultipleReports,
      'alternate-report': singleReportFromDifferentLocation
  }]">

        <div class="overview-metrics-section" v-if="!loading && !error_occurred && overviewMetricList.length > 0">
            <div class="dashboard-overview-metrics" v-for="(metric, index) in overviewMetricList" :key="index">

                <overview-metrics
                        :ref="'overview-metric-report-'+metric.reportId"
                        v-show="!overviewMetricDeletedOrHidden(metric)"
                        :report-id="metric.reportMetricId"
                        :location-id="metric.locationId"
                        :data="metric.rows"
                        :report-name="getReportName(metric.reportId)"
                        :report-type="metric.reportType"
                        :totals="metric.totals"
                        :averages="metric.averages"
                        :kpis="metric.kpis"
                        :is-editable="true"
                        :columns="metric.columns"
                        :custom-metrics="metric.customMetrics"
                        v-model:colors="metric.colors"
                        :in-edit-mode="editMode.triggered"
                        :has-report="metric.overviewMetricReport"
                        :hidden="getComponentState(metric.reportId, 'overviewMetrics', 'hidden')"
                        @hide="updateComponentState(metric.reportId, 'overviewMetrics', 'hidden', $event)"
                        @delete="updateComponentState(metric.reportId, 'overviewMetrics', 'deleted', true)"
                        @update-filters="updateFilters($event, metric.reportId)"
                        @update-values="updateValues($event, metric)"
                >
                    <template #top>
                        <div v-if="metric.overviewMetricReport && !editMode.triggered" class="full-width overview-metric-top">
                            <div v-if="!metricHasReportData(metric)"  class="no-report-data-section">
                                <span class="error-text"> There is no overview metric data available for the current date range.</span>
                                <button class="range-switch-btn"
                                        @click="displayDataModal(getOverviewMetricReport(metric).report, getOverviewMetricReport(metric).index, true, true)">
                                    Switch date range
                                </button>
                            </div>

                            <div class="metric-top-actions flex justify-end items-center" v-else>
                                <button class="range-switch-btn"
                                        @click="displayDataModal(getOverviewMetricReport(metric).report, getOverviewMetricReport(metric).index, true, true)">
                                    Switch date range
                                </button>

                                <button @click="updateOverviewMetrics(metric)"
                                        class="button button-blue update-btn relative-position"
                                        :disabled="metricUpdateState.updating"
                                >
                                    {{ metricUpdateState.updating ? 'Updating...' : 'Update' }}

                                    <q-spinner
                                            v-if="metricUpdateState.updating"
                                            class="load-spinner"
                                            size="18"
                                    />
                                </button>
                            </div>
                        </div>
                    </template>
                </overview-metrics>
            </div>
        </div>


        <div class="dashboard-search-bar">
            <div :class="['search-bar-holder', /*{ 'reversed': !dataGeneratorHidden }*/]">
                <data-generator
                        v-if="dashboardReports.filter(report => report.reportData.length > 0).length === 0"
                        v-show="!dataGeneratorHidden"
                        @pipeline-generated="getPipeline($event)"
                        @error-detected="displayError($event)"
                        @generate-report="refreshReport($event)"
                        :locationId="current_location"
                        v-model:date-range="current_range"
                        v-model:report-type="report_type"
                        v-model:pipeline="current_pipeline"
                        v-model:preset="datePreset"
                        :in-edit-mode="editMode.triggered"
                        v-model:hidden="editMode.dataGenerator.hidden"
                />

                <div class="search-bar-section"
                     v-if="!(loading || error_occurred) && !searchBarHidden"
                >
                    <!--
                                  <search-bar
                                          @input="getQuery($event)"
                                          @filter-query-generated="generateFilterQuery($event)"
                                          v-model:value="searchBar.value"
                                          :searching-filters="searching_filters"
                                          :no-results-found="no_results_found"
                                          :report-type="generatedReport.reportType"
                                          :date-range="generatedReport.range"
                                          :location="generatedReport.location"
                                          :pipeline="generatedReport.pipeline.id"
                                          :in-edit-mode="editMode.triggered"
                                          v-model:hidden="editMode.searchBar.hidden"
                                          v-if="!viewingMultipleReports"
                                  />
                    -->

                    <report-search
                            v-model:value="query.modelValue"
                            v-if="viewingMultipleReports"
                            @search="getSearchValue($event)"
                            :in-edit-mode="editMode.triggered"
                            v-model:hidden="editMode.searchBar.hidden"
                    />
                </div>
            </div>
        </div>

        <loading-sequence v-if="loading" :text="loading_text"/>

        <error-handler v-if="!loading && error_occurred" :error="error_text">
            <template #body>
                <div class="flex flex-align-center applied-filters" v-if="search_filters.length > 0">
                    <span>({{ search_filters.length }} applied filter{{ search_filters.length === 1 ? '': 's' }})</span>
                    <button class="button clear-filter-btn" @click="search_filters = []">
                        Remove filters
                    </button>
                </div>
            </template>
        </error-handler>


        <div class="dashboard-main" v-if="!loading && !error_occurred" ref="dashboard_main">
            <div class="dashboard-report" v-for="(report, index) in filteredDashboardReportsList" :key="index">
                <div class="metric-report" :ref="'dashboard-report-'+report.id"
                     v-if="!report.overviewMetricReport"
                     v-show="!isDeletedOrHidden(report)"
                >
                    <report-table
                            :location="report.location"
                            :pipeline="report.pipeline"
                            :report-data="report.reportData"
                            :report-type="report.reportType"
                            :agency-location="agencyLocation"
                            :search-query="report.query"
                            :refresh-table="report.refresh"
                            :date-range="report.range"
                            :metric-colors="getMetricColors(report)"
                            :preset="report.preset"
                            :current-range="current_range"
                            v-model:template="report.template"
                            v-model:saved-report="report.report"
                            v-model:saved-metric-id="report.savedMetricId"
                            v-model:starred-metrics="report.starredMetrics"
                            v-model:filters="report.filters"
                            v-model:report-metric-id="report.reportMetricId"
                            :hidden="getComponentState(report.id,  'reports', 'hidden')"
                            :in-edit-mode="editMode.triggered"
                            @hide="updateComponentState(report.id, 'reports', 'hidden', $event)"
                            @delete="updateComponentState(report.id, 'reports','deleted', true)"
                            @metric-change="getChangedMetric(report, $event)"
                            @table-data-change="report.tableData = $event"
                            @filter-delete="deleteFilter($event, index)"
                            @filter-clear="clearFilters(index)"
                            @regen="initiateTableRefresh(index)"
                            @add-saved-report="updateReportState($event, report)"
                            @template-update="applyTemplate($event)"
                    >
                        <template v-slot:tableHeader>
                            <div class="table-slot-row">
                                <div class="search-row">
                                    <div class="row-container" :ref="'rowContainer-'+index">
                                        <button :class="['row-icon magnifier-right', { 'visible': !report.searchVisible }]" @click="expandSearch(true, index)">
                                            <img  src="../assets/icons/magnifying-glass-right.svg" alt="magnifier right" />
                                        </button>

                                        <div :class="['row-search', { 'visible': report.searchVisible }]">
                                            <search-bar
                                                    :report-type="report.reportType"
                                                    :date-range="report.range"
                                                    :pipeline="report.pipeline.id"
                                                    :location="report.location"
                                                    :searching-filters="report.searchingForFilters"
                                                    :no-results-found="report.noResultsFound"
                                                    v-model:value="report.searchValue"
                                                    @input="report.query = $event"
                                                    @filter-query-generated="generateReportFilter(report, $event)"
                                            />
                                        </div>

                                        <button @click="expandSearch(false, index)" :class="['row-icon magnifier-left', { 'visible': report.searchVisible }]">
                                            <img  src="../assets/icons/magnifying-glass-right.svg" alt="magnifier right" />
                                        </button>
                                    </div>

                                    <button class="filter-segment" @click="displayDataModal(report, index)">
                                        <i class='bx bx-slider slider-icon'></i>
                                    </button>
                                </div>

                            </div>
                        </template>

                        <template #emptyTable>
                            <div class="empty-reports-section">
                                <h3 class="report-header bold-text">{{ capitalizeFirstLetter(getReportName(report.id)) }}</h3>
                                <span class="text error-text">There is no report data available for the current date range.</span>

                                <button @click="displayDataModal(report, index)">
                                    Generate new report
                                </button>
                            </div>
                        </template>
                    </report-table>
                </div>
            </div>

            <saved-reports
                    v-model:visible="showSavedReports"
                    :location-reports="locationReports"
                    :location="current_location"
                    :agency-location="agencyLocation"
                    :is-admin-user="adminUser"
                    @update="updateCurrentReport($event)"
                    @get="getSelectedReport($event)"
                    @template-select="applyTemplate($event)"
                    @template-delete="getDeletedTemplates($event)"
                    @template-update="getUpdatedTemplate($event)"
                    @auto-create="autoPopulateLocations($event)"
            />

            <feedback-popup
                    v-model:visible="metricUpdateState.showPopup"
                    icon-class="save-popup-icon"
                    position="center"
            >
                <template #icon>
                    <i :class='["bx", {
                      "bxs-x-circle": metricUpdateState.updateError,
                      "bxs-check-circle": !metricUpdateState.updateError
                   }]'></i>
                </template>

                <template #text>
                <span>
                    {{ metricUpdateState.popupText }}
                </span>
                </template>
            </feedback-popup>

            <feedback-popup
                    :icon-class="popupClass"
                    v-model:visible="popupState.visible"
                    class="feedback-popup"
                    position="right"
                    :can-minimize="popupState.saveWarning"
                    v-model:minimized="popupState.minimized"
            >
                <template #icon>
                    <q-spinner class="popup-spinner"
                               v-if="popupState.saving && !(popupState.saveError || popupState.saveWarning)"
                    />
                    <i :class="['bx', {
                    'bxs-error': !popupState.saving && popupState.saveWarning,
                    'bx-x-circle': !popupState.saving && popupState.saveError,
                    'bx-check-circle': !popupState.saving && !popupState.warning && !popupState.saveError
                }]"
                    ></i>
                </template>

                <template #text>
                    <span v-if="popupState.saveErrors.length === 0">{{ popupState.text }}</span>
                    <span v-else>
                    <span>The report has been created in <span class="bold-text">{{ popupState.savedLocations.length }}</span> locations.
                        Encountered errors in <span class="bold-text">{{ popupState.saveErrors.length }}</span> locations.
                        <button class="save-error-btn"
                                @click="popupState.errorComponentVisible = true">
                            View errors
                        </button>
                    </span>
                </span>
                </template>
            </feedback-popup>

            <auto-create-process
                    v-model:visible="popupState.autoCreateVisible"
            />

            <share-error-component
                    v-model:visible="popupState.errorComponentVisible"
                    :errors="popupState.saveErrors"
            />

            <dashboard-editor
                    v-model:edit-mode="editMode.triggered"
                    @report-select="showSavedReports = true"
                    @edit="enterEditMode()"
                    @cancel="clearEditState()"
                    @complete="performEdit()"
            />

            <data-generator-modal
                    :preset="selectedReport.data.preset"
                    :date-range="selectedReport.data.dateRange"
                    :report-type="selectedReport.data.reportType"
                    :pipeline-id="selectedReport.data.pipelineId"
                    :location-id="selectedReport.data.locationId"
                    :report-name="selectedReport.data.reportName"
                    :filters="selectedReport.data.filters"
                    v-model:visible="selectedReport.visible"
                    @generated="getGeneratedReport($event, selectedReport.index)"
                    :hide-report-type="selectedReport.data.hideReportType"
                    :hide-pipelines="selectedReport.data.hidePipelines"
            />
        </div>

        <report-getter
                v-model:visible="reportGetter.visible"
                :overview-metric-report="reportGetter.overviewMetricReport"
                :dashboard-reports="editStateReports"
                :agency-location="agencyLocation"
                :location-id="current_location"
                @fetch="getCreatedReports($event, reportGetter.overviewMetricReport)"
        />

        <div :class="['report-options-selector', { 'display': editMode.triggered && !reportGetter.visible  }]">
            <div class="options-content">
                <button class="button button-blue-alt" @click="displayReportGetter(true)">
                    Add Overview Data
                </button>

                <button class="button button-orange" @click="displayReportGetter()">
                    Add Report Data
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import SearchBar from "../components/SearchBar";
import {
    capitalizeFirstLetters,
    formatTemplateName,
    replaceSlashes
} from "../helpers/helper_functions";
import LoadingSequence from "../components/LoadingSequence";
import ErrorHandler from "../components/ErrorHandler";
import 'boxicons';
import _ from "lodash";
import {useStore} from "../store/dataStore";
import OverviewMetrics from "../components/OverviewMetrics";
import {getCurrentDate, getLastMonthDate} from "../helpers/date_functions";
import SavedReports from "../components/SavedReports.vue";
import DataGenerator from "../components/DataGenerator.vue";
import FeedbackPopup from "../components/FeedbackPopup.vue";
import AutoCreateProcess from "../components/AutoCreateProcess.vue";
import ShareErrorComponent from "../components/ShareErrorComponent.vue";
import ReportTable from "../components/ReportTable.vue";
import DashboardEditor from "../components/DashboardEditor.vue";
import {v4 as uuidv4} from "uuid";
import DataGeneratorModal from "../components/DataGeneratorModal.vue";
import ReportGetter from "../components/ReportGetter.vue";
import ReportSearch from "../components/ReportSearch.vue";

export default {
    setup() {
        const dataStore = useStore();

        return {
            dataStore
        }
    },
    name: "DashboardView",
    components: {
        ReportSearch,
        ReportGetter,
        DataGeneratorModal,
        DashboardEditor,
        ReportTable,
        ShareErrorComponent,
        AutoCreateProcess,
        FeedbackPopup,
        DataGenerator,
        SavedReports,
        OverviewMetrics,
        LoadingSequence,
        SearchBar,
        ErrorHandler
    },
    data() {
        return {
            reportGenerated: false,
            query: {
                modelValue: '',
                searchValue: ''
            },
            loading: true,
            error_occurred: false,
            error_text: "",
            report_type: "source",
            current_pipeline: '',
            current_location: '',
            current_pipeline_name: '',
            current_range: {
                from: replaceSlashes(getLastMonthDate()),
                to:  replaceSlashes(getCurrentDate())
            },
            generatedReport: {
                location: '',
                range:  {
                    from: replaceSlashes(getLastMonthDate()),
                    to:  replaceSlashes(getCurrentDate())
                },
                pipeline: {
                    name: '',
                    id: ''
                },
                reportType: '',
                presets: 'No preset'
            },
            search_filters: [],
            loading_text: "Generating your report",
            searching_filters: false,
            no_results_found: false,
            starredMetrics: {
                visible: true,
                data: []
            },
            refreshingTable: false,
            savedColors: {},
            currentReport: {},
            savedMetricId: null,
            showSavedReports: false,
            datePreset: 'No preset',
            customMetrics: [],
            tableData: {
                rows: [],
                columns: [],
                visible_columns: [],
                filter_items: []
            },
            agencyLocation: false,
            adminUser: false,
            activeTemplate: {},
            popupState: {
                visible: false,
                minimized: false,
                autoCreateVisible: false,
                errorComponentVisible: false,
                saving: false,
                saveWarning: false,
                saveError: false,
                text: '',
                savedLocations: [],
                saveErrors: [{
                    location: { name: 'Harris & Partners Inc', id:'aNwqNMrT1bjQRDE5i58L'},
                    error: 'No report data available'
                }],
                displayTimeout: 0
            },
            reportData: [],
            savedReport: {},
            metricData: {
                totals: {},
                averages: {},
                columns: [],
                kpis: [],
                customMetrics: []
            },
            searchQuery: {},
            dashboardReports: [],
            overviewMetrics: [],
            selectedReport: {
                id: -1,
                index: -1,
                visible: false,
                data: {
                    reportName: '',
                    locationId: '',
                    pipelineId: '',
                    reportType: '',
                    reportMetricId: '',
                    reportData: [],
                    dateRange: null,
                    preset: '',
                    hidePipelines: false,
                    hideReportType: false
                }
            },
            searchBar: {
                hidden: false,
                value: ''
            },
            dataGenerator: {
                hidden: false
            },
            editMode: {
                triggered: false,
                searchBar: {
                    hidden: false
                },
                dataGenerator: {
                    hidden: false
                },
                reports: [],
                overviewMetrics: []
            },
            reportGetter: {
                visible: false,
                overviewMetricReport: false
            },
            metricUpdateState: {
                updating: false,
                updateError: false,
                showPopup: false,
                popupText: ""
            }
        }
    },
    mounted() {
        let url_parameters = new URLSearchParams(window.location.search);

        if (!url_parameters.has('location_id')) {
            this.displayError('No location detected')
        } else{
            this.current_location = url_parameters.get('location_id');
            this.loading_text = 'Fetching pipelines';
        }
    },
    unmounted() {
        document.body.removeAttribute('style')
    },
    computed: {
        filteredDashboardReportsList() {
            return this.dashboardReports.filter(report => this.getReportName(report.id).toLowerCase().includes(this.query.searchValue.toLowerCase()))
        },
        dataGeneratorHidden() {
            let hidden = false;
            if (!this.editMode.triggered) {
                hidden = this.dataGenerator.hidden
            }

            return hidden
        },

        locationReports() {
            return this.dashboardReports.filter(report => {
                let reportData = report.report.report;
                if (!reportData) {
                    return false
                }

                if (!(report.savedReport && !report.overviewMetricReport)) {
                    return false
                }

                return reportData.location_id === this.current_location
            }).map(report => report.report.report)
        },

        searchBarHidden() {
            let hidden = false;
            if (!this.editMode.triggered) {
                hidden = this.searchBar.hidden
            }

            return hidden
        },
        pipeline() {
            return {
                id: this.current_pipeline,
                name: this.current_pipeline_name
            }
        },

        viewingMultipleReports() {
            return this.dashboardReports.filter(report => !report.overviewMetricReport).length > 1
        },

        singleReportFromDifferentLocation() {
            let reportValue = false;
            if (this.dashboardReports.length === 1) {
                let report = this.dashboardReports[0];
                if (report.location !== this.current_location) {
                    reportValue = true
                }
            }

            return reportValue
        },

        popupClass(){
            let dataClass = '';
            if (this.popupState.saving) {
                dataClass = 'initiate-class'
            } else {
                if (this.popupState.saveWarning) {
                    dataClass = 'warning-class'
                } else {
                    if (this.popupState.saveError) {
                        dataClass = 'error-class'
                    } else {
                        dataClass = 'success-class'
                    }
                }

            }

            return dataClass
        },

        overviewMetricList() {
            return this.overviewMetrics.filter(overviewMetric => this.dashboardReports.find(report => report.id === overviewMetric.reportId)).map(overviewMetric => {
                let report = this.dashboardReports.find(report => report.id === overviewMetric.reportId);
                let { overviewMetricReport, tableData, metricData, metricColors } = report;

                if (overviewMetricReport) {
                    //   console.log({ dashboardReport: report.report })
                    let { totals, averages, columns, kpis, customMetrics, colors, rows } = this.dataStore.createDefaultOverviewMetricData(report.report);

                    //  console.log({ rows, report })
                    overviewMetric = {
                        ...overviewMetric,
                        rows,
                        totals,
                        averages,
                        columns,
                        kpis,
                        customMetrics,
                        colors
                    }
                } else {
                    let { totals, averages, columns, kpis, customMetrics }  = metricData
                    overviewMetric = {
                        ...overviewMetric,
                        rows: tableData.rows,
                        totals,
                        averages,
                        columns,
                        kpis,
                        customMetrics,
                        colors: metricColors
                    }
                }

                return overviewMetric
            })
        },
        viewingTemplate() {
            return this.activeTemplate && "templateData" in this.activeTemplate;
        },

        editStateReports() {
            let reports = _.cloneDeep(this.dashboardReports);
            return reports.map(report => {
                report.deleted = this.isDeletedOrHidden(report);
                return report
            })

        },
    },
    methods: {
        getOverviewMetricReport(metric) {
            let report, index;
            this.dashboardReports.forEach((dashboardReport, reportIndex) => {
                if (dashboardReport.id === metric.reportId) {
                    report = dashboardReport;
                    index = reportIndex;
                }
            })

            return { report, index }
        },
        getSearchValue: _.debounce(function (event) {
            let { value } = event;
            this.query.searchValue = value
        }, 250),
        updateReportState(state, report) {
            report.savedReport = state
        },
        getReportName(reportId) {
            let dashboardReport = this.dashboardReports.find(report => report.id === reportId);
            if (!dashboardReport) {
                return ''
            }

            let reportName = '';
            if (dashboardReport.template && "templateName" in dashboardReport.template) {
                let templateName = dashboardReport.template["templateName"]
                reportName = formatTemplateName(templateName)
            } else {
                let report = dashboardReport.report;
                if (report && report.report) {
                    report = report.report;

                    if (report) {
                        reportName =  report['report_name'] ? report['report_name'] : report['report_type'] + ' report'
                    }

                }
            }

            return reportName;
        },
        getMetricColors(report) {
            let overviewMetric = this.overviewMetricList.find(metric => metric.reportId === report.id);
            return overviewMetric ? overviewMetric.colors : report.colors;
        },

        getChangedMetric(report, event) {
            let { metric, value } = event;
            report.metricData[metric] = value;
        },
        autoPopulateLocations(data) {
            let { template, actions, locations, report } = data;
            let { templateName, templateData, customMetrics } = template;

            let templateFormat = {
                name: templateName,
                data: templateData,
                customMetrics,
                report
            }

            console.log({ templateFormat, actions })

            this.popupState = {
                ...this.popupState,
                visible: true,
                autoCreateVisible: false,
                saving: true,
                minimized: false,
                text: `Auto-creating reports in ${locations.length} location${locations.length > 1 ? 's': ''}.
                    You will be notified when the process is complete.`,
                saveWarning: false,
                saveError: false,
                savedLocations: [],
                saveErrors: []
            }

            let component = this;
            this.popupState.displayTimeout = window.setTimeout(function () {
                component.popupState = {
                    ...component.popupState,
                    visible: false,
                    autoCreateVisible: true
                }
            }, 5000);



            /*this.dataStore.autoPopulateLocations(locations, templateFormat, report, actions['overrideDefaults'], !actions['removeFilters'])
                .then(response => {
                    let { locations, locationErrors } = response;
                    if (this.popupState.displayTimeout) {
                        window.clearTimeout(this.popupState.displayTimeout)
                    }

                    let state = {
                        ...this.popupState,
                        visible: true,
                        autoCreateVisible: false,
                        errorComponentVisible: false,
                        saving: false,
                        saveWarning: locationErrors && locationErrors.length > 0,
                        saveError: false,
                        savedLocations: locations,
                        saveErrors: locationErrors ? locationErrors : []
                    }

                    if (!state.saveWarning) {
                        state.text = 'Successfully created reports in all selected locations.'
                    }

                    this.popupState = state;
                    this.popupState.displayTimeout = window.setTimeout(function () {
                          if (!state.saveWarning) {
                              component.popupState.visible = false
                          } else {
                              component.popupState.minimized = true;
                          }
                      }, 8000)
                }).catch(error => {
                      if (this.popupState.displayTimeout) {
                          window.clearTimeout(this.popupState.displayTimeout)
                      }
                      let { message, locationErrors } = error
                      this.popupState = {
                          ...this.popupState,
                          visible: true,
                          autoCreateVisible: false,
                          saving: false,
                          saveError: true,
                          text: 'Error creating reports: ' + message,
                          saveErrors: locationErrors ? locationErrors : []
                      }

                      this.popupState.displayTimeout = window.setTimeout(function () {
                          component.popupState.visible = false
                      }, 8000)
                })*/
        },


        scrollToSection(ref) {
            if (ref) {
                window.scrollTo({
                    top: ref.offsetTop,
                    left: 0,
                    behavior: "smooth"
                })
            }
        },

        getSelectedReport(event) {
            let { data, addReport } = event;
            let { dashboardReport } = this.addMetricAndReport(data, true, false, !addReport);
            let component = this;

            if (!addReport) {
                // console.log({ dashboardReport })
                let { pipeline, range, reportType } = dashboardReport;
                this.current_pipeline = pipeline.id;
                this.current_pipeline_name = pipeline.name;

                this.current_range = range;
                this.report_type = reportType;
            }

            setTimeout(function () {
                let reportIndex = component.dashboardReports.length-1;
                let reportItem = component.dashboardReports[reportIndex];
                let reportRef = component.$refs['dashboard-report-'+reportItem.id];
                component.scrollToSection(reportRef)
            }, 0)
        },
        applyTemplate(template) {
            this.dashboardReports = this.dashboardReports.map(report => {
                report.template = template;
                report.metricColors = template.savedMetrics.colors;
                return report
            })

            this.activeTemplate = template
        },
        updateCurrentReport(event) {
            let { id, location_id, report } = event;
            let existingReport = null;

            this.dashboardReports.forEach(dashboardReport => {
                let reportData = dashboardReport.report.report;
                if (reportData) {
                    if (reportData.id === id && reportData.location_id === location_id) {
                        existingReport = dashboardReport;
                        // console.log({ dashboardReport })
                    }
                }
            })

            if (!existingReport) {
                return
            }
            if (Object.keys(report).length === 0) {
                this.dashboardReports = this.dashboardReports.filter(report => report.id !== existingReport.id);
            } else {
                this.dashboardReports = this.dashboardReports.map(dashboardReport => {
                    if (dashboardReport.id === existingReport.id) {
                        dashboardReport.report.report = report;
                    }
                    return dashboardReport
                })
            }

            if (this.dashboardReports.length === 0) {
                this.regenerateReport();
            }

        },

        regenerateReport() {
            if (!this.current_range) {
                this.current_range = this.generatedReport.range;
            }
            let report_details = {
                type: this.report_type,
                pipeline_id: this.current_pipeline,
                start_date: this.current_range.from,
                end_date: this.current_range.to,
                pipeline_name: this.current_pipeline_name,
                presets: this.datePreset
            }

            // console.log('Source generated', report_details)
            this.refreshReport(report_details)
        },

        getDeletedTemplates(templates) {
            if (this.activeTemplate && "id" in this.activeTemplate) {
                let templateIds = templates.map(template => template.id);
                if (templateIds.indexOf(this.activeTemplate.id) !== -1) {
                    this.dashboardReports = this.dashboardReports.map(report => {
                        report.template = {};
                        return report
                    })
                    this.activeTemplate = {};
                    //this.clearAppliedData();
                }
            }
        },

        getUpdatedTemplate(template) {
            if (this.viewingTemplate && "id" in this.activeTemplate && this.activeTemplate.id === template.id) {
                // console.log({ template, activeTemplate: this.activeTemplate });
                let updatedTemplate = {
                    ...this.activeTemplate,
                    templateName: template.templateName,
                    sharedLocations: template.sharedLocations
                }


                this.dashboardReports = this.dashboardReports.map(report => {
                    report.template = updatedTemplate;
                    return report
                })

                this.activeTemplate = updatedTemplate
            }
        },


        initiateTableRefresh(index) {
            this.dashboardReports[index].refresh = true
            let { location, pipeline, reportType, filters } = this.dashboardReports[index]

            let range = this.dashboardReports[index].range;
            this.dataStore.getReportTableData(location, pipeline.id, reportType, range, filters).then(response => {
                let results = response.data.results;

                this.dashboardReports[index] = {
                    ...this.dashboardReports[index],
                    refresh: false,
                    reportData: results
                }
            }).catch(error => {
                this.dashboardReports[index] = {
                    ...this.dashboardReports[index],
                    refresh: false,
                    errorText: error.message
                }
                let component = this;
                setTimeout(function () {
                    component.dashboardReports[index].errorText = ""
                }, 3000)
            })
        },


        getDefaultAndGenerateReport() {
            let savedReports = this.dataStore.savedReports;

            let defaultReport = savedReports.find(report => report.default);
            if (defaultReport) {
                this.setRangeTypeAndPipeline(defaultReport);
                let reportName = `${capitalizeFirstLetters(defaultReport['report_name'])}`
                this.loading_text = `Loading ${reportName}`

                /*   this.getKpiData().then(response => {
                     this.createKpiRow(response.data);
                   })*/

                this.dataStore.getSavedReport(defaultReport.location_id, defaultReport.id)
                    .then(data => {
                        //this.setSavedReportData(data);
                        let hasReport = data.reportData.length > 0
                        this.displayResponse(!hasReport)

                        this.addMetricAndReport(data)
                    }).catch(error => {
                    this.displayError(`Error loading ${reportName} : `  + error.message)
                })
            } else {
                this.loading_text = 'Generating your report';
                const pipeline = { id: this.current_pipeline, name: this.current_pipeline_name }
                this.generateStaticReport(this.report_type, this.current_location, pipeline, this.current_range, [])
            }
        },

        getPipeline(pipeline) {
            this.current_pipeline = pipeline.id;
            this.current_pipeline_name = pipeline.name;
            this.current_location = pipeline.location_id;

            this.dataStore.currentLocation = pipeline.location_id;
            /*this.current_range = {
              from: '2022-07-01',
              to: '2022-07-31'
            }*/

            this.generatedReport = {
                location: this.current_location,
                range: _.cloneDeep(this.current_range),
                pipeline: {
                    name: this.current_pipeline_name,
                    id: this.current_pipeline
                },
                reportType: this.report_type,
                presets: "No preset"
            }

            this.dataStore.getSavedReportsTemplatesAndLocations(this.current_location)
                .then(data => {
                    let { isAgencyLocation, isAdminUser } = data;
                    this.agencyLocation = isAgencyLocation;
                    this.adminUser = isAdminUser

                    this.clearCurrentData();
                    this.getDefaultAndGenerateReport();
                }).catch(error => {
                this.displayError('Error fetching reports : ' + error.message)
            })
        },

        getRangeAndPreset(report) {
            let dateRange = {
                from: report.from_date,
                to: report.to_date
            }

            if ("date_preset" in report && report.date_preset && this.dataStore.getPresetRange(report.date_preset)) {
                dateRange = this.dataStore.getPresetRange(report.date_preset);
            }

            let preset = !report['date_preset'] || !this.dataStore.getPresetRange(report['date_preset']) ? 'No preset' : report['date_preset'];
            return { dateRange, preset }
        },

        setRangeTypeAndPipeline(report) {
            this.report_type = report.report_type;

            let { preset, dateRange } = this.getRangeAndPreset(report);
            this.datePreset = preset;
            this.current_range = dateRange

            this.generatedReport = {
                location: report.location_id,
                reportType: report.report_type,
                pipeline: { name: '', id: report.pipeline_id },
                range: _.cloneDeep(this.current_range),
                presets: this.datePreset
            }

            this.current_pipeline = report.pipeline_id;
        },

        createCustomMetricList(metrics) {
            return metrics.map(metric => {
                let { id, customMetrics } = metric
                return { id, ...customMetrics }
            })
        },

        /* setSavedReportData(data, setRangeAndPipeline = true) {
           let { report, reportData, savedMetricsId, metrics } = data;
           let { colors } = metrics;
           this.savedReport = _.cloneDeep(data);

           if (colors && typeof colors === 'object') {
               this.savedColors = colors;
           }

           this.activeTemplate = {};
           this.savedMetricId = savedMetricsId;
           this.search_filters = report.filters;

          if (setRangeAndPipeline) {
            this.setRangeTypeAndPipeline(report);
          }

          this.displayResponse(reportData.length === 0)
         },*/

        metricHasReportData(metric) {
            let dashboardReport = this.dashboardReports.find(report => report.id === metric.reportId);
            if (!dashboardReport) {
                return false
            }

            return dashboardReport.reportData && dashboardReport.reportData.length > 0
        },

        addMetricAndReport(data, savedReport = true, overviewMetricReport = false, singleReport = false) {
            //  console.log({ data, reports: this.dataStore.savedReports })
            let { report, metrics, savedMetricsId, reportData, kpiData } = data;
            let { location_id, pipeline_id, report_type } = report;
            let { preset, dateRange } = this.getRangeAndPreset(report);
            let { colors } = metrics;
            let pipeline = this.dataStore.getPipelineData(location_id, pipeline_id);

            let dataId = uuidv4();
            let dashboardReport = {
                id: dataId,
                reportMetricId: dataId,
                location: location_id,
                pipeline: { name: pipeline && "name" in pipeline ? pipeline.name : "", id: pipeline_id },
                reportType: report_type,
                refresh: false,
                range: dateRange,
                metricColors: colors,
                preset,
                template: {},
                report: data,
                savedMetricId: savedMetricsId,
                starredMetrics: [],
                reportData,
                kpiData,
                filters: report.filters,
                tableData: {
                    rows: [],
                    columns: [],
                    visible_columns: [],
                    filter_items: []
                },
                metricData: {
                    totals: {},
                    averages: {},
                    columns: [],
                    kpis: [],
                    customMetrics: []
                },
                overviewMetricReport,
                errorText: "",
                savedReport,
                searchingForFilters: false,
                noResultsFound: false,
                searchVisible: true,
                query: {},
                searchValue: '',
                hidden: false
            };


            let savedReportId = report && report.id ? report.id : null;
            let metric = {
                reportId: dataId,
                reportMetricId: dataId,
                locationId: report.location_id,
                savedReportId,
                rows: [],
                reportType: report_type,
                totals: {},
                averages: {},
                columns: [],
                kpis: [],
                customMetrics: [],
                colors,
                hidden: false,
                overviewMetricReport
            }

            let created = true;
            if (singleReport) {
                this.dashboardReports = [ dashboardReport ];
                this.overviewMetrics = [ metric ];

                // console.log({ dashboardReport })
            } else {
                let existingReport = null;

                let dashboardReportData = dashboardReport.report.report
                if (dashboardReportData) {
                    existingReport = this.dashboardReports.find(reportItem => reportItem.report.report && (reportItem.report.report.id === dashboardReportData.id && reportItem.report.report.location_id === dashboardReportData.location_id));
                }

                if (!existingReport) {
                    this.dashboardReports = [
                        ...this.dashboardReports,
                        dashboardReport
                    ]

                    this.overviewMetrics = [
                        ...this.overviewMetrics,
                        metric
                    ]
                } else {
                    created = false;
                    this.dashboardReports = this.dashboardReports.map(reportItem => {
                        if (reportItem.id === existingReport.id) {
                            reportItem = {...dashboardReport}
                        }
                        return reportItem
                    })

                    this.overviewMetrics = this.overviewMetrics.map(metricItem => {
                        if (metricItem.reportId === existingReport.id) {
                            metricItem = {...metric}
                        }
                        return metricItem
                    })
                }



                if (overviewMetricReport) {
                    let { rows, customMetrics, totals, averages } = this.dataStore.createDefaultOverviewMetricData(data);

                    let savedMetricData = this.dataStore.createSavedMetricData(metrics.savedMetrics, report.report_type, rows, totals, averages, customMetrics);
                    let savedMetrics = savedMetricData.overviewMetrics;
                    //  console.log({ savedMetricData })
                    if (savedMetricData.unmatched.length > 0) {
                        savedMetricData.unmatched.forEach(metric => {
                            let value = averages[metric.column_name];

                            savedMetrics.push({
                                label: this.getMetricLabel(metric.column_name, customMetrics),
                                column_name: metric.column_name,
                                selected: { label: metric.label, value }
                            })
                        })
                    }

                    this.dataStore.updateSavedMetrics(report.report_type, savedMetrics, report.location_id, dataId);
                }

            }

            //  console.log({ dashboardReport })
            return { dashboardReport, metric, created }
        },

        getMetricLabel(columnName, customMetrics) {
            let label = '';
            if (this.dataStore.isACustomMetric(customMetrics, columnName)) {
                let customMetric = customMetrics.find(metric => metric.field === columnName);
                label = customMetric.name
            } else {
                label = columnName.split("_").map(columnSplit => {
                    if (columnSplit === 'created' || columnSplit === 'modified') {
                        columnSplit = `(${capitalizeFirstLetters(columnSplit)})`
                    } else {
                        columnSplit = capitalizeFirstLetters(columnSplit)
                    }
                    return columnSplit;
                }).toString().replace(/,/g, " ")
            }

            return label
        },

        generateStaticReport(reportType, locationId, pipeline, range, filters = [], preset = "No preset") {
            this.generatedReport = {
                location: locationId,
                range: _.cloneDeep(range),
                pipeline,
                reportType: reportType,
                presets: preset
            }

            console.log('Generating static report');
            this.makeApiCallRun(reportType, locationId, pipeline.id, range, filters).then(data => {
                let { reportData, kpiData } = data
                this.displayResponse(reportData.length === 0);


                if (reportData.length > 0) {
                    let staticReport = {
                        report: {
                            location_id: locationId,
                            pipeline_id: pipeline.id,
                            report_type: reportType,
                            id: uuidv4(),
                            from_date: replaceSlashes(range.from),
                            to_date: replaceSlashes(range.to),
                            date_preset: preset,
                            filters: []
                        },
                        metrics: {
                            colors: this.dataStore.overviewColors
                        },
                        savedMetricsId: null,
                        reportData,
                        kpiData
                    }

                    this.addMetricAndReport(staticReport, false)
                }
            }).catch(error => {
                this.handleError(error)
            })
        },

        async makeApiCallRun(reportType, locationId, pipeline, range, filters = []) {
            let kpiData = [];

            this.dataStore.getKpiData(reportType, locationId, pipeline).then(response => {
                kpiData = this.dataStore.createInitKpiData(response.data, reportType).kpiRow
            }).catch(error => {
                console.log({ error })
            })
            let response = await this.dataStore.getReportTableData(locationId, pipeline, reportType, range, filters);
            return { reportData: response.data.results, kpiData }
        },

        /** Gets Source Report from API endpoint, requires parameters as arguments (It is a promise based function) **/
        async getReport (filters, type) {
            return this.dataStore.getReportTableData(this.current_location, this.current_pipeline, type, this.current_range, filters)
        },

        displayResponse(errorResponse = false) {
            this.loading = false; /** Removes loading attribute from the reports table */
            this.refreshingTable = false;

            this.error_occurred = errorResponse;
            if (errorResponse) {
                this.error_text = 'There are no reports available for this time period';
            }

            this.loading_text = 'Generating your report';
        },

        /** Returns string passed as argument with first letters after space capitalized **/
        capitalizeFirstLetter(string) {
            return capitalizeFirstLetters(string)
        },

        displayError(message) {
            // console.log({ message })
            this.loading = false;
            this.error_occurred = true;
            this.error_text = message;
        },

        /** Returns error message if there was an error generating the report **/
        handleError(error) {
            this.loading = false;
            this.error_occurred = true;
            if (error.response) {
                this.error_text =  error.response.status === 400 ? 'There are no reports available for this time period':
                    `${error.response.data.error}. Status code: ${error.response.status}`;
            } else{
                //console.log('Error: ', error.toJSON())
                this.error_text = error.code === "ECONNABORTED" ? 'Connection timed out' : `${error.message}`;
            }
        },

        clearCurrentData() {
            this.loading = true;
            this.error_text = '';
            this.error_occurred = false;
            this.dashboardReports = [];
            this.overviewMetrics = [];
        },

        async refreshDashboardReports(range, preset, pipeline, reportType) {
            return await new Promise((resolve, reject) => {

                let store = this.dataStore;
                let reports = this.dashboardReports;
                let updatedReports = [];
                let singleReportFromDifferentLocation = this.singleReportFromDifferentLocation;

                refreshReport(0)
                function refreshReport(index) {
                    if (index >= reports.length) {
                        if (updatedReports.length === 0) {
                            reject({ message: 'No results found for reports' })
                        } else {
                            resolve(updatedReports)
                        }
                        return
                    }

                    let report = _.cloneDeep(reports[index]);
                    let requestPipeline = pipeline, requestReportType = reportType;
                    if (reports.length > 1) {
                        requestPipeline = report.pipeline.id;
                        requestReportType = report.reportType;
                    } else {
                        if (singleReportFromDifferentLocation) {
                            requestPipeline = report.pipeline.id;
                            requestReportType = report.reportType;
                        }
                    }

                    store.getReportTableData(report.location, requestPipeline, requestReportType, range, report.filters).then(response => {
                        let pipelineData = store.getPipelineData(report.location, requestPipeline);
                        let pipeline = pipelineData ? pipelineData : { name: "", id: requestPipeline };

                        updatedReports = [
                            ...updatedReports,
                            {
                                id: report.id,
                                reportData: response.data.results,
                                reportType: requestReportType,
                                pipeline,
                                range,
                                preset
                            }
                        ]
                        refreshReport(index+1)
                    }).catch(() => {
                        let reportData = report.report.report;
                        let reportName = reportData ? reportData['report_name'] : report.reportType + ' report';
                        reject({ message: `Cannot re-generate ${reportName}`})
                    })
                }
            })
        },

        /** Refreshes reports when pipeline or report type is changed **/
        refreshReport(object_data) {
            // console.log({ object_data })
            let newRange = {
                from:  replaceSlashes(object_data.start_date),
                to:  replaceSlashes(object_data.end_date)
            }

            this.loading_text = `Re-generating report${this.dashboardReports.length > 1 ? 's' : ''}` ;
            this.loading = true;

            this.current_range = {
                from: object_data.start_date,
                to: object_data.end_date
            }
            this.current_pipeline = object_data.pipeline_id;
            this.current_pipeline_name = object_data.pipeline_name;
            this.report_type = object_data.type;
            this.datePreset = object_data.presets;
           // console.log({ presets: object_data.presets })

           // console.log({ object_data })

            this.query = {
                modelValue: '',
                searchValue: ''
            }

            if (this.dashboardReports.length > 0) {

                this.refreshDashboardReports(newRange, object_data.presets, object_data.pipeline_id, object_data.type).then(reports => {


                    this.dashboardReports = this.dashboardReports.map(report => {
                        let updatedReport = reports.find(updatedReport => updatedReport.id === report.id);
                        if (updatedReport) {

                            let currentReport = _.cloneDeep(report);
                            let pipelineChanged = currentReport.pipeline.id !== updatedReport.pipeline.id;
                            let reportTypeChanged = currentReport.reportType !== updatedReport.reportType;

                            let savedReport = report.report;
                            let isSavedReport = report.savedReport;
                            if (pipelineChanged || reportTypeChanged) {
                                savedReport = {};
                                isSavedReport = false;
                                this.overviewMetrics = this.overviewMetrics.filter(metric => metric.reportId !== currentReport.id)
                            }


                            //  console.log({ isSavedReport })
                            if (isSavedReport) {
                                savedReport.reportData =  _.cloneDeep(updatedReport.reportData);
                            }


                            report = {
                                ...report,
                                reportType: updatedReport.reportType,
                                pipeline: updatedReport.pipeline,
                                range: updatedReport.range,
                                report: savedReport,
                                savedReport: isSavedReport,
                                preset: updatedReport.preset
                            }
                        }
                        return report
                    })

                    let reportsWithData = 0;
                    const updateDashboardReports = (index) => {
                        if (index >= this.dashboardReports.length) {
                            this.displayResponse(reportsWithData === 0);
                            return
                        }

                        let dashboardReport = this.dashboardReports[index]
                        let updatedReport = reports.find(updatedReport => updatedReport.id === dashboardReport.id);

                        setTimeout(() => {
                            //  console.log({ updatedReport })
                            if (updatedReport) {
                                if (updatedReport.reportData.length > 0) {
                                    reportsWithData += 1;
                                }
                                this.dashboardReports[index].reportData = updatedReport.reportData;
                            }

                            updateDashboardReports(index + 1)
                        }, 0)
                    }

                    updateDashboardReports(0)

                }).catch(error => {
                    this.displayError(`Error re-generating report${this.dashboardReports.length > 1 ? 's' : ''} : ${error.message}`)
                })
            } else {
               // console.log({ object_data })
                this.generateStaticReport(object_data.type, this.current_location, { id: object_data.pipeline_id, name: object_data.pipeline_name }, newRange, [], object_data.presets);
            }
        },

        modifyFilters(filters, query) {
            filters = _.cloneDeep(filters);
            query.forEach(item => {
                let existingFilter = filters.find(filter => filter.type === item.type && filter.query === item.query)
                if (!existingFilter) {
                    filters = [
                        ...filters,
                        item
                    ]
                } else {
                    let filterIndex = filters.indexOf(existingFilter);
                    if (filterIndex !== -1) {
                        filters[filterIndex].exact = item.exact
                    }
                }
            })

            return filters
        },

        async filterDashboardReports(query) {
            return await new Promise((resolve) => {

                let filteredReports = [];
                let component = this;
                let store = this.dataStore

                filterReport(0)
                function filterReport(index) {
                    if (index >= component.dashboardReports.length) {
                        resolve({ reports: filteredReports })
                        return
                    }

                    let dashboardReport = _.cloneDeep(component.dashboardReports[index]);

                    let queryFilters = [
                        ...dashboardReport.filters,
                    ]

                    queryFilters = component.modifyFilters(queryFilters, query)

                    let reportItem = {
                        id: dashboardReport.id,
                        filters: queryFilters,
                        reportData: []
                    }

                    component.dashboardReports[index].errorText = ""

                    store.getReportTableData(dashboardReport.location, dashboardReport.pipeline.id, dashboardReport.reportType, dashboardReport.range, queryFilters)
                        .then(response => {
                            reportItem.reportData = response.data.results;

                            filteredReports = [
                                ...filteredReports,
                                reportItem
                            ]

                            filterReport(index+1)
                        }).catch(() => {
                        filteredReports = [
                            ...filteredReports,
                            reportItem
                        ]

                        filterReport(index+1)
                    })

                }
            })
        },

        generateFilterQuery(query) {
            if (this.viewingMultipleReports) {
                return
            }

            this.searching_filters = true;

            this.filterDashboardReports(query).then(response => {
                let reports = response.reports;
                reports.forEach((report, index) => {
                    let existingReport = this.dashboardReports.find(dashboardReport => dashboardReport.id === report.id)
                    if (existingReport && report.reportData && report.reportData.length > 0) {
                        this.dashboardReports[index] = {
                            ...this.dashboardReports[index],
                            filters: report.filters,
                            reportData: report.reportData
                        }
                    }
                })

                if (reports.filter(report => report.reportData.length > 0).length > 0) {
                    let scrollTop = this.$refs.dashboard_main.getBoundingClientRect().top;
                    window.scrollTo({
                        top: scrollTop,
                        behavior: 'smooth'
                    });

                    this.searching_filters = false;
                    this.no_results_found = false;
                    this.searchBar.value = ''
                } else {
                    this.showNoSearchResultFeedback()
                }
            }).catch(() => {
                // console.log('Error fetching data: ', error)
                this.showNoSearchResultFeedback()
            })
        },

        showNoSearchResultFeedback() {
            //  console.log('No results found')
            this.no_results_found = true;
            this.searching_filters = false;

            setTimeout(() => {
                this.no_results_found = false
            }, 5000)
        },

        generateReportFilter(report, query) {
            report.searchingForFilters = true
            let filters = this.modifyFilters(report.filters, query)
            report.refresh = true



            this.dataStore.getReportTableData(report.location, report.pipeline.id, report.reportType, report.range, filters)
                .then(response => {
                    let results = response.data.results
                    if (results.length > 0) {
                        report.filters = filters;
                        report.reportData = results;
                        report.refresh = false;
                        report.searchValue = ''
                    } else {
                        displayError()
                    }
                }).catch(() => {
                displayError()
            })

            function displayError() {
                report.noResultsFound = true;
                report.searchingForFilters = false;
                report.refresh = false

                setTimeout(function () {
                    report.noResultsFound = false
                }, 5000)
            }
        },


        clearFilters(index) {
            this.search_filters = [];
            this.initiateTableRefresh(index)
        },

        deleteFilter(filter, index) {
            this.debounceRefresh(index);
        },

        debounceRefresh: _.debounce(function (index) {
            this.initiateTableRefresh(index)
        }, 500),

        getReportIndex(reportId) {
            let report = this.dashboardReports.find(dashboardReport => dashboardReport.id === reportId);
            if(!report) { return }

            return this.dashboardReports.indexOf(report)
        },

        getUncheckedFields(reportType) {
            reportType = reportType.toLowerCase();

            let unchecked = [ reportType ];

            if (reportType === 'tag') {
                unchecked = [
                    ...unchecked,
                    `${reportType}_parent`
                ]
            }

            return unchecked;
        },

        updateValues(data, metric) {
            let reportId = metric.reportId;
            let reportIndex = this.getReportIndex(reportId);
            if (reportIndex === -1) {
                return
            }

            this.dashboardReports[reportIndex].starredMetrics = data
        },

        updateOverviewMetrics(metric) {
            let reportId = metric.reportId;
            let reportIndex = this.getReportIndex(reportId);
            if (reportIndex === -1) {
                return
            }

            let dashboardReport = this.dashboardReports[reportIndex];
            let report = dashboardReport.report;
            let filterData = report.filterData;
            let reportType = report.report.report_type;

            let format = this.dataStore.createMetricFormat(dashboardReport.starredMetrics, filterData.metrics, this.getUncheckedFields(reportType));
            this.metricUpdateState = {
                ...this.metricUpdateState,
                updating: true,
                updateError: false,
                popupText: "",
                showPopup: false
            }

            format.colors = metric.colors;

            //  console.log({ format, report, metric });
            this.dataStore
                .updateMetrics(report.savedMetricsId, format, report.report.location_id)
                .then(() => {
                    //   console.log({ response });
                    this.metricUpdateState = {
                        ...this.metricUpdateState,
                        updating: false,
                        showPopup: true,
                        popupText: "Overview metrics updated successfully."
                    }

                    this.resetMetricPopupState();
                }).catch(error => {
                this.metricUpdateState = {
                    ...this.metricUpdateState,
                    updating: false,
                    showPopup: true,
                    popupText: `Error updating store metrics: ${error.message}`,
                    updateError: true
                }

                this.resetMetricPopupState(5000)
            })
            //console.log({ format, report, filterData, reportType, dashboardReport})
        },

        resetMetricPopupState(timeout = 3000) {
            setTimeout(() => {
                this.metricUpdateState = {
                    ...this.metricUpdateState,
                    showPopup: false,
                    popupText: ""
                }
            }, timeout)
        },

        updateFilters(data, reportId) {
            let reportIndex = this.getReportIndex(reportId);
            if (reportIndex === -1) {
                return
            }

            this.dashboardReports[reportIndex].starredMetrics = this.dataStore.getStarredMetrics(data.reportType, data.locationId, data.reportId);
        },

        displayDataModal(report, index, hidePipelines = false, hideReportType = false) {
           // console.log({ report })
            let reportData = report.report.report;
            let reportName = reportData && "name" in reportData ? reportData['report_name'] : report['reportType'] + ' report'
            let selectedData = {
                id: report.id,
                index,
                data: {
                    locationId: report.location,
                    pipelineId: report.pipeline.id,
                    reportType: report.reportType,
                    dateRange: report.range,
                    preset: report.preset,
                    filters: report.filters,
                    reportMetricId: report.reportMetricId,
                    reportData: report.reportData,
                    reportName,
                    hidePipelines,
                    hideReportType
                }
            }

            this.selectedReport = {
                visible: true,
                ...selectedData
            }
        },

        getGeneratedReport(data, index)  {
            //console.log({ data, index })
            let { pipeline, range, reportData, reportType, presets, filters, location } = data;
            let currentReport = _.cloneDeep(this.dashboardReports[index]);
           // console.log({ data, location })

            let pipelineChanged = pipeline.id !== currentReport.pipeline.id;
            let reportTypeChanged = reportType !== currentReport.reportType;
            let savedReport = currentReport.report;
            let isASavedReport = currentReport.savedReport;
            let removeSavedMetricId = false;
            if (pipelineChanged || reportTypeChanged) {
                savedReport = {};
                isASavedReport = false;
                removeSavedMetricId = true;
                this.overviewMetrics = this.overviewMetrics.filter(metric => metric.reportId !== currentReport.id);
                let reportMetricId = this.selectedReport.data.reportMetricId;
                this.dataStore.updateSavedMetrics(reportType, [], location, reportMetricId);

                if (reportTypeChanged) {
                    let selectedReportData = this.selectedReport.data;

                    let prevReportType = selectedReportData.reportType;
                    let prevReportData = selectedReportData.reportData;

                    let savedFilters = this.dataStore.getSavedFilters(prevReportType, location, reportMetricId);
                    let defaultData = this.dataStore.createColumnsAndFilterItems(prevReportType, prevReportData);

                    let currentDefaultData = this.dataStore.createColumnsAndFilterItems(reportType, reportData);

                    let currentFilterItems = currentDefaultData.filterItems;
                    let defaultFilterItems = defaultData.filterItems;
                 //   console.log({ savedFilters, currentFilterItems })

                    // Filters out custom metrics from saved filters
                    savedFilters.metrics = savedFilters.metrics.filter(filter => {
                        let existingFilter = defaultFilterItems.find(item => item.column_name === filter.column_name);
                        return !!existingFilter;
                    }).map(filter => {
                        let defaultFilter = defaultFilterItems.find(item => item.column_name === filter.column_name);
                        if (filter.dropdown.length > 0) {
                            let dropdownItems = defaultFilter.dropdown.map(item => item.column_name);
                            filter.dropdown = filter.dropdown.filter(item => dropdownItems.indexOf(item.column_name) !== -1)
                        }
                        return filter;
                    })



                    currentFilterItems = currentFilterItems.map(filter => {
                        let columnName = filter.column_name;
                        if (columnName.includes('lead_cost')) {
                            let columnPrefix  = prevReportType === 'source' ? '': 'average_';
                            let columnSuffix = columnName.includes('created') ? 'created' : 'modified';
                            columnName = columnPrefix + 'lead_cost_' + columnSuffix;
                           // console.log({ columnName, prevReportType, savedFilters })
                        }

                        let existingFilter = savedFilters.metrics.find(item => item.column_name === columnName);
                        if (existingFilter) {

                            if (filter.dropdown.length === 0) {
                                filter.visible = existingFilter.visible
                            } else {
                                filter.apply_to_all = existingFilter.apply_to_all;
                                filter.dropdown = filter.dropdown.map(item => {
                                    let existingDropdownItem = existingFilter.dropdown.find(dropdownItem => dropdownItem.dataKey === item.dataKey)
                                    if (existingDropdownItem) {
                                        item.visible = existingDropdownItem.visible;
                                    }
                                    return item;
                                })

                                let visibleItems = filter.dropdown.filter(item => item.visible);
                                filter.all_items_visible = visibleItems.length === filter.dropdown.length;
                            }
                        }


                        return filter;
                    })



                    let savedFilterData = this.dataStore.generateFilterData(currentFilterItems, reportType);
                    console.log({ savedFilterData })

                    this.dataStore.updateSavedFilters(reportType, savedFilterData, location, reportMetricId);

                  //  let matchedMetrics = this.matchColumnVisibility(savedFilters, reportType, prevReportType);
                }
            } else {
                // console.log({ generatedSaveReport: savedReport })
                savedReport.reportData = reportData;
            }

            this.dashboardReports[index] = {
                ...this.dashboardReports[index],
                pipeline,
                range,
                reportType: reportType,
                preset: presets,
                filters,
                report: savedReport,
                savedReport: isASavedReport
            }


            if (removeSavedMetricId) {
                this.dashboardReports[index].savedMetricId = null;
            }



            setTimeout( () => {
                this.dashboardReports[index].reportData = reportData;

                if (this.dashboardReports[index].overviewMetricReport) {
                    //    console.log({ savedReport, reportData })
                    /* let rows = [];

                     let customMetrics = savedReport.report['custom_metrics'] ? savedReport.report['custom_metrics'] : [];
                     customMetrics = this.dataStore.createCustomMetricList(customMetrics);

                     reportData.forEach(item => {
                         rows.push(this.dataStore.createRow(reportData, item, reportType, customMetrics).row);
                     })*/
                }
            }, 0)

            if (this.dashboardReports.length === 1) {
                this.current_range = range;
                this.current_pipeline = pipeline.id;
                this.current_pipeline_name = pipeline.name;
                this.report_type = reportType;

                this.generatedReport = {
                    ...this.generatedReport,
                    location,
                    range,
                    pipeline,
                    reportType,
                    presets
                }
            }
        },

        matchColumnVisibility(filters, currentReportType, prevReportType) {

        },

        getQuery(data) {
            this.dashboardReports = this.dashboardReports.map(report => {
                report.query = data;
                if (this.viewingMultipleReports) {
                    report.searchValue = data.value;
                }
                return report
            })
        },

        expandSearch(visible, index) {
            let searchRow = this.$refs['rowContainer-'+index];
            if (!searchRow) {
                return
            }

            let rowElement = searchRow.querySelector('.row-search')
            if (!rowElement) {
                return
            }

            let width = visible ? searchRow.clientWidth - 24 : 0;
            this.dashboardReports[index].searchVisible = visible;

            rowElement.style.cssText = `width: ${width}px;overflow:hidden;transition: ease .2s;`

            setTimeout(function () {
                rowElement.removeAttribute('style')
            }, 600)
        },

        getComponentState(id, dataType, state) {
            if (!this.editMode[dataType]) {
                return false
            }
            let report = this.editMode[dataType].find(report => report.id === id)
            return report && report[state] !== undefined ? report[state] : false
        },

        updateComponentState(id, dataType, state, value) {
            // console.log({ id, state, value })
            if (!this.editMode[dataType]) {
                return
            }

            this.editMode[dataType] = this.editMode[dataType].map(item => {
                if (item.id === id && item[state] !== undefined) {
                    item[state] = value
                }
                return item
            })

            if (dataType === 'reports' && state === 'deleted') {
                this.editMode.overviewMetrics = this.editMode.overviewMetrics.map(metric => {
                    if (metric.id === id) {
                        metric.deleted = value
                    }
                    return metric
                })
            }
        },

        isDeletedOrHidden(report) {
            let reportId = report.id
            if (!this.editMode.triggered) {
                return report.hidden
            }

            let editedReport = this.editMode.reports.find(report => report.id === reportId);
            return editedReport ? editedReport.deleted : false
        },

        enterEditMode() {
            let reports = _.cloneDeep(this.dashboardReports);
            let overviewMetrics = _.cloneDeep(this.overviewMetrics);

            reports = reports.map(report => {
                return {
                    id: report.id,
                    deleted: false,
                    hidden: report.hidden
                }
            })

            overviewMetrics = overviewMetrics.map(metric => {
                return {
                    id: metric.reportId,
                    hidden: metric.hidden,
                    deleted: false
                }
            })


            this.showSavedReports = false;
            this.editMode = {
                ...this.editMode,
                triggered: true,
                searchBar: { hidden: this.searchBar.hidden },
                dataGenerator: { hidden: this.dataGenerator.hidden },
                reports,
                overviewMetrics
            }
            // console.log({ editMode: this.editMode })

        },

        performEdit() {
            let currentMode = _.cloneDeep(this.editMode);
            // console.log({ currentMode })
            let { reports, overviewMetrics, searchBar, dataGenerator } = currentMode;
            this.searchBar.hidden = searchBar.hidden;
            this.dataGenerator.hidden = dataGenerator.hidden;



            if (reports && reports.length > 0) {
                let deletedReports = reports.filter(report => report.deleted).map(report => report.id);

                if (deletedReports.length > 0) {
                    this.dashboardReports = this.dashboardReports.filter(report => deletedReports.indexOf(report.id) === -1);
                    this.overviewMetrics = this.overviewMetrics.filter(metric => deletedReports.indexOf(metric.reportId) === -1);
                }

                let hiddenReports = reports.filter(report => report.hidden).map(report => report.id);
                // console.log({ hiddenReports })
                this.dashboardReports = this.dashboardReports.map(report => {
                    report.hidden = hiddenReports.indexOf(report.id) !== -1
                    return report
                })
            }

            if (overviewMetrics && overviewMetrics.length > 0) {
                let hiddenMetrics = overviewMetrics.filter(metric => metric.hidden).map(metric => metric.id);
                this.overviewMetrics = this.overviewMetrics.map(metric => {
                    metric.hidden = hiddenMetrics.indexOf(metric.reportId) !== -1
                    return metric
                })
            }

            if (this.dashboardReports.length === 1) {
                this.updateDashboardData()
            }

            let component = this;
            setTimeout(function () {
                component.clearEditState();
            }, 0)
        },

        updateDashboardData() {
            if (this.dashboardReports.length !== 1) {
                return
            }

            let dashboardReport = this.dashboardReports[0];
            let { range, pipeline, reportType, preset, location } = dashboardReport;
            this.report_type = reportType;
            this.current_range = range;
            this.current_pipeline = pipeline.id;
            this.current_pipeline_name = pipeline.name;

            this.generatedReport = {
                ...this.generatedReport,
                range,
                pipeline,
                reportType,
                presets: preset,
                location
            }
        },

        clearEditState() {
            let component = this;
            setTimeout(function () {
                component.editMode = {
                    ...component.editMode,
                    triggered: false,
                    searchBar: { hidden: false },
                    dataGenerator: { hidden: false },
                    reports: [],
                    overviewMetrics: []
                }
            }, 0)
        },

        overviewMetricDeletedOrHidden(metric) {
            if (this.editMode.triggered) {
                let editedMetric = this.editMode.overviewMetrics.find(overviewMetric => overviewMetric.id === metric.reportId)
                return editedMetric ? editedMetric.deleted : false
            }

            /* let metricReport = this.dashboardReports.find(report => report.id === metric.reportId);
            // console.log({ metricReport })

             if (metricReport && metricReport.reportData && metricReport.reportData.length === 0) {
                 return true;
             }*/

            return metric.hidden
        },


        displayReportGetter(overviewMetricReport = false) {
            this.reportGetter = {
                ...this.reportGetter,
                visible: true,
                overviewMetricReport
            }
        },


        getCreatedReports(reports, overviewMetricReport) {
            // console.log({ createdReports: reports })
            let createdReports = reports.map(report => {
                return this.addMetricAndReport(report, true, overviewMetricReport)
            })


            // console.log({ reports: this.dashboardReports, metrics: this.overviewMetrics })
            let editStateReports = [], editStateMetrics = [];
            if (createdReports.length > 0) {
                let report = createdReports[0].dashboardReport;
                let refElement = overviewMetricReport ? 'overview-metric-report' : 'dashboard-report';

                let component = this;



                setTimeout(function () {
                    let refData = component.$refs[refElement+'-'+report.id];
                    component.scrollToSection(refData);
                }, 0)
            }
            createdReports.forEach((report) => {
                let { dashboardReport, metric, created } = report;
                if (dashboardReport && created) {
                    editStateReports = [
                        ...editStateReports,
                        {
                            id: dashboardReport.id,
                            hidden: false,
                            deleted: false
                        }
                    ];
                }

                if (metric && created) {
                    editStateMetrics = [
                        ...editStateMetrics,
                        {
                            id: metric.reportId,
                            hidden: false,
                            deleted: false
                        }
                    ]
                }
            })

            this.editMode.reports = [
                ...this.editMode.reports,
                ...editStateReports
            ]

            this.editMode.overviewMetrics = [
                ...this.editMode.overviewMetrics,
                ...editStateMetrics
            ]

            let newReports = createdReports.filter(report => report.created);
            if (newReports.length > 0){
                newReports = newReports.map(report => report.dashboardReport)
                let locationReports = newReports.filter(report => !report.pipeline.name)
                if (locationReports.length > 0) {
                    let locationList = [];
                    locationReports.forEach(report => {
                        if (locationList.indexOf(report.location) === -1) {
                            locationList.push(report.location)
                        }
                    })

                    // console.log({ locationList })
                    this.getLocationPipelines(locationList).then(pipelines => {
                        console.log({ pipelines })
                        this.dashboardReports = this.dashboardReports.map(dashboardReport => {
                            dashboardReport.pipeline = this.dataStore.getPipelineData(dashboardReport.location, dashboardReport.pipeline.id)
                            return dashboardReport
                        })
                    })
                }
            }
        },

        async getLocationPipelines(locations) {
            return await new Promise((resolve, reject) => {
                let store = this.dataStore;
                let pipelines = [];

                getPipelines(0)
                function getPipelines(index) {
                    if (index < 0 || index >= locations.length) {
                        resolve(pipelines)
                        return
                    }
                    let location = locations[index];
                    store.getPipelines(location).then(response => {
                        let dataPipelines = response.data['pipelines'];
                        store.addPipelines(location, dataPipelines)
                        pipelines = [
                            ...pipelines,
                            {
                                locationId: location,
                                pipelines: dataPipelines
                            }
                        ]

                        getPipelines(index+1)
                    }).catch(error => {
                        reject(error)
                    })
                }
            })
        }
    },



}
</script>
<style lang="scss">
.feedback-popup{
  .popup-icon{
    &.initiate-class{
      background: var(--theme-blue-alt);
    }
    &.warning-class{
      background: var(--warning);
    }
    &.error-class{
      background: var(--red);
    }
    &.success-class{
      background: var(--theme-green);
    }
  }
  .popup-text{
    .bold-text{
      color: var(--theme-blue-alt);
    }

    button{
      &.save-error-btn{
        background: transparent;
        border: 1px solid transparent;
        color: var(--theme-blue-alt);
        font-weight: 700;

        &:hover{
          transition: ease-in-out .2s;
          cursor: pointer;
          opacity: .75;
        }
      }
    }
  }
}

.table-slot-row{
  .search-row{
    .row-container{
      .row-search{
        .search-bar-group{
          .form-group{
            display: flex;
            flex-direction: row-reverse;
            margin-bottom: 0;
          }
          .search-options{
            width: fit-content;
            margin-top: 0;
          }

          .input-holder{
            width: calc(100% - 200px);
          }
          .action-buttons{
            display: none;
          }
        }
      }
    }
  }
}

.multiple-report-dash{

  .search-bar-holder{
    .search-bar-section{
      .search-bar-group{
        .date-group{
          padding-top: 1.5rem;
        }
        .search-options{
          display: none;
        }
      }
    }

    /* .search-bar-section,
     .range-picker-group{
       height: 12rem;
     }*/

    .range-picker-group{
      .select-options{
        display: none;
      }

      .group-container{
        padding-top: 1.5rem;
      }

      .generate-action-container{
        margin-top: 0;
      }
    }
  }
}

.alternate-report{
  .search-bar-holder{
    .range-picker-group {
      .select-options {
        display: none;
      }
    }
  }
}

.save-popup-icon{
  .icon-container{
    i{
      font-size: 28px;

      &.bxs-check-circle{
        color: var(--theme-green);
      }

      &.bxs-x-circle{
        color: var(--red);
      }
    }
  }
}
</style>

<style scoped>
@import url('../assets/css/dashboard.css');
</style>

