<template>
    <div :class="['overview', {'in-full-view': inFullscreen }]">
        <div class="metrics-section relative-position">

            <div class="full-width
            flexbox
            flex-column flex-align-center
            justify-center text-center
            load-section
          "
                 v-if="state.loading || state.loadError">
                <div class="section-data flexbox flex-align-center" v-if="state.loading && !state.loadError">
                    <q-spinner-ios
                            :color="spinnerColor"
                            size="22px"
                    />
                    <span>{{ state.phaseText }}...</span>
                </div>

                <div v-if="!state.loading && state.loadError">
                    <div class="section-data error-section flexbox flex-align-center">
                        <i class="bx bx-x-circle"></i>  <span>{{ state.errorText }}</span>
                    </div>

                    <div>
                        <button
                                class="button button-retry centered-item"
                                @click="loadMetrics(currentRange)"
                        >
                            <i class="bx bx-refresh"></i> Retry
                        </button>
                    </div>
                </div>

            </div>
            <div class="flexbox flex-column" v-show="!state.loading && !state.loadError">
                <!--    <div class="flex setting-container">
                      <button class="setting-button flexbox flex-align-center"
                              id="modifyReportBtn"
                              @click="sidebarOpen = true">
                       <span>Modify report</span> <i class="bx bx-slider-alt"></i>
                      </button>
                    </div>-->


                <div class="full-width
            flexbox
            flex-column flex-align-center
            justify-center text-center
            load-section empty-data-section"
                     v-if="data.length === 0"
                >
     <span>
        {{ noDataText }}
     </span>
                </div>

                <overview-metrics
                        v-if="data.length > 0"
                        :report-id="reportId"
                        :location-id="locationId"
                        :data="data"
                        :report-type="reportType"
                        :show-empty-text="true"
                        :columns="columns"
                        :kpis="kpis"
                        :custom-metrics="customMetrics"
                        v-model:visible="displayMetrics"
                        v-model:colors="savedColors"
                        @color-update="getUpdatedColors()"
                        @update-filters="updateData()"
                        @update-values="updateValues($event)"
                        @edit-open="emitIframeFullscreenChange(true)"
                        @edit-close="emitIframeFullscreenChange(false)"
                />
                <!--    <div
                        class="data-gen-section padding-sm"
                        :class="{
                          'open': sidebarOpen
                      }"
                    >
                      <button class="close-arrow-icon" @click="sidebarOpen = false">
                        <i class="bx bx-chevrons-right "></i>
                      </button>

                      <div class="y-margin-sm">
                        <data-generator
                            :location-id="locationId"
                            :report-type="reportType"
                            v-model:date-range="dateRange"
                            @pipeline-generated="getPipeline($event)"
                            @error-detected="handleError($event)"
                            @generate-report="updateMetrics($event)"
                        />
                      </div>
                    </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import {useStore} from "../store/dataStore";
import {getCurrentDate, getLastMonthDate} from "../helpers/date_functions";
import axios from "axios";
import {baseURL} from "../api/apiBase";
import OverviewMetrics from "../components/OverviewMetrics";
import _ from "lodash";

export default {
    name: "Overview",
    components: {
        OverviewMetrics
    },
    setup() {
        const dataStore = useStore();

        return {
            dataStore
        }
    },
    mounted() {
        let route = this.$route;
        let locationId = route.params.locationId

        if (locationId) {
            this.locationId = locationId
            this.dataStore.currentLocation = locationId
        } else {
            this.state = {
                ...this.state,
                loadError: true,
                loading: false,
                errorText: "Location not detected"
            }
        }

        console.log('Overview mounted');
        window.parent.postMessage({
            action: 'dashboardMount',
            mounted: true
        }, this.turnoverOrigin);
        document.addEventListener('click', this.detectOutsideClick);
        window.addEventListener("message", this.listenForRangeChange)
        this.loadMetrics()
    },
    unmounted() {
        document.removeEventListener('click', this.detectOutsideClick)
        window.removeEventListener("message", this.listenForRangeChange)
    },
    data() {
        return {
            locationId: "",
            reportType: "source",
            state: {
                loading: true,
                errorText: '',
                phaseText: 'Fetching default report',
                loadError: false
            },
            pipeline: "",
            sidebarOpen: false,
            spinnerColor: '#24588D',
            dateRange: {
                from: getLastMonthDate(),
                to: getCurrentDate()
            },
            currentRange: {},
            /*dateRange: {
              from: '2022-07-01',
              to: '2022-07-31'
            },*/
            noDataText: 'There is no data available for the current date range.',
            displayMetrics: true,
            unchecked: [],
            data: [],
            columns: [],
            kpis: [],
            overviewMetrics: [],
            filterItems: [],
            savedColors: {},
            filterData: {
                metrics: [],
                breakdown: []
            },
            savedMetricsId: null,
            customMetrics: [],
            reportId: -1,
            inFullscreen: false,
            turnoverOrigin: "https://app.turnovercrm.com"
        }
    },
    methods: {
        listenForRangeChange(event) {
            // console.log({ rangeChangeEvent: event })
            let { data, origin } = event;

            if (origin === this.turnoverOrigin) {
                if (data.action === "rangeChange") {
                    this.currentRange = data.range;
                    this.loadMetrics(data.range)
                }
            }
        },

        emitIframeFullscreenChange(inFullscreen) {
            this.inFullscreen = inFullscreen;

            window.parent.postMessage({
                action: 'iframe-fullscreen-change',
                inFullscreen
            }, "https://app.turnovercrm.com")
        },
        loadMetrics(range = {}) {
            this.state = {
                ...this.state,
                loading: true,
                errorText: '',
                phaseText: 'Fetching default report',
                loadError: false
            }
            this.dataStore.getDefaultReport(this.locationId).then(response => {
                let defaultReport = response.data.report;


                if (defaultReport && defaultReport.id) {
                    this.pipeline = defaultReport.pipeline_id;
                    this.reportType = defaultReport['report_type'].toLowerCase();
                    this.savedMetricsId = defaultReport['saved_metrics_id'];

                    this.unchecked = [ this.reportType, `${this.reportType}_parent` ]

                    // console.log({ metricsId: this.savedMetricsId, defaultReport })
                    this.dataStore.getSavedReport(defaultReport.location_id, defaultReport.id, range)
                        .then(data => {
                            let { metrics, reportData, filterData, report, kpiData } = data;
                            let { savedMetrics, colors } = metrics;

                            this.savedColors = colors;
                            let filterItems = [ ...filterData.breakdown, ...filterData.metrics ];
                            let customMetrics = report['custom_metrics'] ? report['custom_metrics'] : [];

                            customMetrics = customMetrics.map(metric => {
                                let { id, customMetrics } = metric
                                return { id, ...customMetrics }
                            })

                            this.filterData = filterData;
                            this.customMetrics = customMetrics;
                            this.reportId = report.id;
                            this.currentRange = {};

                            this.createMetricData(reportData, kpiData, savedMetrics, filterItems, customMetrics)
                        }).catch(error => {
                        this.state = {
                            ...this.state,
                            loading: false,
                            loadError: true,
                            errorText: 'Error fetching overview metrics : ' + error.message
                        }
                    })
                } else {
                    this.state = {
                        ...this.state,
                        loading: false,
                        loadError: false,
                        errorText: ''
                    }

                    window.parent.postMessage('iframe_not_loaded','*')

                    this.noDataText = `There are no overview metrics available in this location.
          You can have them displayed here by creating a default report with overview metrics from your sales data dashboard.`
                }
            }).catch(error => {
                this.state = {
                    ...this.state,
                    loading: false,
                    loadError: true,
                    errorText: 'Could not fetch default report : ' + error.message
                }

                window.parent.postMessage('iframe_not_loaded','*')
            })
        },

        async fetchKpis() {
            return await this.dataStore.getKpiData(this.reportType, this.locationId, this.pipeline)
        },

        createMetricData(results, kpiData, metrics, filterItems, customMetrics) {
            if (results.length > 0) {
                let data = results.map(item => {
                    return this.dataStore.createRow(results, item, this.reportType, customMetrics).row
                })

                let tableData = this.dataStore.createColumnsAndFilterItems(this.reportType, results);
                this.filterItems = filterItems;
                let columns = tableData.columns.filter(column => this.unchecked.indexOf(column.field) === -1);
                this.columns = this.dataStore.setColumnOrder(this.filterData, columns, customMetrics)

                kpiData = this.dataStore.completeKpiData(kpiData, filterItems, results, this.reportType, customMetrics);
                this.kpis = kpiData;

                let totals = this.dataStore.calculateTotals(data, this.unchecked);
                let averages = this.dataStore.calculateAverages(data, this.unchecked);

                let { overviewMetrics } = this.dataStore.createSavedMetricData(metrics, this.reportType, data, totals, averages, customMetrics);
                this.overviewMetrics = overviewMetrics;
                this.data = data;

                this.updateStoreMetrics();
            } else {
                this.resetMetrics()
            }

            this.state = {
                ...this.state,
                loading: false,
                loadError: false,
                phaseText: 'Fetching default report'
            }
        },

        updateStoreMetrics() {
            if (this.reportId === -1) { return }
            this.dataStore.updateSavedMetrics(this.reportType, this.overviewMetrics, this.locationId, this.reportId)
        },

        handleError(errorText) {
            this.state = {
                ...this.state,
                loading: false,
                loadError: true,
                errorText
            }
        },

        detectOutsideClick(event) {
            if (!event.target.closest('.data-gen-section') && !event.target.closest('#modifyReportBtn') && !event.target.closest('.date-check')) {
                this.sidebarOpen = false
            }
        },

        async getReport () {
            let request_data = {
                "fromDate": this.dateRange.from.replace(/\//g, "-"),
                "toDate": this.dateRange.to.replace(/\//g, "-"),
                "filters": []
            }

            return await axios.post(`${baseURL}/locations/${this.locationId}/pipelines/${this.pipeline}/report/${this.reportType}`, request_data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 90000
            })
        },

        resetMetrics() {
            this.data = [];
            this.columns = [];
            this.kpis = [];
            this.overviewMetrics = [];
            this.filterItems = []
        },

        updateMetrics(data) {
            this.pipeline = {
                ...this.pipeline,
                id: data.pipeline_id,
                name: data.pipeline_name
            };

            this.dateRange = {
                from: data.start_date,
                to: data.end_date
            }

            this.sidebarOpen = false;

            setTimeout(() => {
                this.reportType = data.type;

                this.state = {
                    ...this.state,
                    loading: true,
                    loadError: false,
                    phaseText: 'Loading metrics'
                }

                this.resetMetrics()
                this.loadMetrics()
            }, 300)
        },

        updateData() {
            if (this.reportId === -1) {
                return
            }

            this.overviewMetrics = this.dataStore.getStarredMetrics(this.reportType, this.locationId, this.reportId);
            this.updateOverviewMetrics();
        },

        updateOverviewMetrics: _.debounce(function () {
            let format = this.dataStore.createMetricFormat(this.overviewMetrics, this.filterItems, this.unchecked);
            format.colors = this.savedColors;
            this.dataStore.updateMetrics(this.savedMetricsId, format, this.locationId)
            /*  .then(response => {
                console.log({ response })
              }).catch(error => {
                console.log({ error })
              })*/
        }, 1000),

        updateValues(data) {
            this.overviewMetrics = data;
            this.updateOverviewMetrics();
        },

        getUpdatedColors() {
            this.updateOverviewMetrics()
        }
    }
}
</script>

<style>
.data-gen-section .range-picker-group .date-group{
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
}

.data-gen-section .range-picker-group .date-group .date-field{
    margin-left: 0;
    max-width: 100%;
}
.data-gen-section .select-holders .select-options{
    flex-direction: column;
}

.data-gen-section .select-holders{
    margin-top: .4rem;
}

.data-gen-section .select-holders .select-options .form-group{
    width: 100%;
}

.data-gen-section .select-holders .select-options .form-group:not(:last-child) {
    margin-bottom: .4rem;
}

.data-gen-section .range-picker-group .form-group label{
    font-size: .9rem;
}

.data-gen-section .generate-button{
    font-size: .75rem;
    padding: 6px 16px;
}

.overview .metric-settings .setting-section{
    display: none;
}

.overview .metric-settings .setting-section.visible{
    display: block;
}


.overview.in-full-view .starred-metrics-section{
    display: none;
}
</style>

<style scoped>
.overview{
    height: auto;
}

.metrics-section{
    width: calc(100% - 1.5rem);
    margin: 1rem auto;
    min-height: 150px;
}

.setting-button{
    margin-left: auto;
    font-size: 22px;
    background: transparent;
    border: none;
    color: var(--theme-blue-alt);
    transition: ease-in-out .25s;
}

.setting-button span{
    font-size: .85rem;
    margin-right: .4rem;
    font-weight: 700;
}

.setting-button i{
    font-size: 18px;
}
.setting-button:hover,
.close-arrow-icon:hover{
    cursor: pointer;
    opacity: .5;
}

.data-gen-section{
    width: 300px;
    height: 100%;
    min-height: 300px;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--white);
    box-shadow: 0 0 2px 2px rgba(80 ,148 ,221, .1);
    transition: ease-in-out .3s;
    opacity: 0;
    z-index: 1;
    visibility: hidden;
}

.data-gen-section.open{
    opacity: 1;
    visibility: visible;
}

.close-arrow-icon{
    font-size: 20px;
    padding: 0;
    background: transparent;
    border: none;
    color: var(--theme-blue-alt);
}

.load-section{
    height: 150px;
}

.load-section.empty-data-section{
    background: var(--white);
    border-radius: 12px;
    color: var(--theme-blue-alt);
}

.load-section.empty-data-section span{
    margin: 0 auto;
    line-height: 1.5rem;
    font-size: .9rem;
}

@media screen and (min-width: 1024px) {
    .load-section.empty-data-section span{
        max-width: 500px;
    }
}


.load-section .section-data span{
    margin-left: .4rem;
    font-size: .9rem;
    font-weight: 700;
    color: var(--theme-blue-alt);
}

.section-data.error-section,
.section-data.error-section span{
    color: #ff0000;
}

.setting-container{
    margin-bottom: .6rem;
}
.section-data.error-section i{
    font-size: 24px;
}
</style>
