<template>
<q-dialog class="report-share-dialog" v-model="open" position="top">
    <div class="dialog-box share-section" >
        <div class="modal-header">
            <h4 class="header-text">Share report</h4>
            <i class="bx bx-x close-icon" @click="this.open = false"></i>
        </div>

        <form method="post">
            <div class="top-padding-md">
                <div
                        v-for="(field, fieldIndex) in fields"
                        :key="fieldIndex"
                        class="form-group"
                >
                    <div v-if="field.visible">
                        <label v-if="field.fieldType !== 'checkbox'" class="field-label">
                            {{ field.label }}
                        </label>

                        <input
                                :class="['form-field', {
                            'input-error': field.hasError
                        }]"
                                :placeholder="field.placeholder"
                                :name="field.name"
                                v-model="field.value"
                                :type="field.type"
                                @input="getFieldValue(field)"
                                v-if="field.fieldType === 'input'"
                        />

                        <location-selector
                                v-if="field.fieldType === 'locationSelector'"
                                :location-id="report['location_id']"
                                v-model:locations="field.value"
                        />

                        <span class="error-text">
                            {{ field.errorText }}
                        </span>


                        <div v-if="field.fieldType === 'checkbox'"
                             class="flex flex-align-center check-group">
                            <checkbox
                                :checked="field.value"
                                @change="updateCheckState($event, field)"
                            />

                            <span class="field-label">{{ field.label }}</span>
                        </div>

                        <div v-if="field.note" class="note-section">
                            <span class="bold-text">
                                Note:
                            </span>
                            {{ field.note }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="button-section">
                <button @click="this.open = false"
                        class="button button-red-transparent ">
                    Cancel
                </button>


                <button class="button button-blue-alt"
                        :disabled="disableButton"
                        @click="shareReport($event)"
                >
                    Share
                </button>
            </div>
        </form>
    </div>
</q-dialog>
</template>

<script>
import _ from "lodash";
import { useStore } from "../store/dataStore";
import LocationSelector from "./LocationSelector.vue";
import checkbox from "./CustomCheckbox.vue";

export default {
    name: "ReportShare",
    components: {checkbox, LocationSelector},
    setup() {
        return {
            store: useStore()
        }
    },
    props: {
        visible: {
            type: Boolean
        },
        report: {
            type: Object,
            required: true
        }
    },
    emits: [
      'update:visible',
      'share'
    ],
    mounted() {
        this.open = this.visible;
        this.setFieldDataAndVisibility();
    },
    watch: {
        visible(state) {
            this.open = state
        },
        open(state) {
            if (!state) {
                this.$emit('update:visible', state)
            } else {
                this.setFieldDataAndVisibility()
            }
        }
    },
    data() {
        return {
            open: false,
            fields: [
                {
                    type: 'text',
                    value: '',
                    name: 'templateName',
                    required: true,
                    label: 'Name',
                    errorText: '',
                    placeholder: 'Name of shared report',
                    hasError: false,
                    fieldType: 'input',
                    visible: true
                },
                {
                    ...this.store.locationField,
                    visible: true
                },
                ..._.cloneDeep(this.store.reportOptionFields).filter(field => field.name !== 'saveAsReport')
            ]
        }
    },
    computed: {
        disableButton() {
            let disabled = false;
            this.fields.forEach(field => {
                if (field.name === 'reportName' || field.name === 'sharedLocations') {
                    let emptyValue = field.name === 'sharedLocations' ? field.value.length === 0 : !field.value;
                    if (emptyValue && !disabled) {
                        disabled = true;
                    }
                }
            })

            return disabled
        }
    },
    methods: {
        setFieldDataAndVisibility() {
            let { filters, report_name } = this.report;

            this.fields = this.fields.map(field => {
                if (field.name === 'removeFilters' && filters && filters.length > 0) {
                    field.visible = true;
                }

                if (report_name && field.name === 'templateName') {
                    field.value = report_name
                }
                return field
            })

        },
        getFieldValue(field) {
            if (!field.value && field.required) {
                field.hasError = true;
                field.errorText = 'This field is required.'
            } else {
                field.hasError = false;
                field.errorText = ''
            }
        },

        updateCheckState(value, field) {
            field.value = value
        },

        shareReport(event)  {
            event.preventDefault();
            let { report_data, custom_metrics } = this.report;

            let template = {
                templateName: '',
                templateData: this.store.createTemplateFormat(report_data),
                customMetrics: this.store.createTemplateCustomMetrics(custom_metrics).map(metric => {
                    return {
                        customMetrics: metric
                    }
                })
            }, sharedLocations = [], actions = {};


            this.fields.forEach(field => {
                if (template[field.name] !== undefined) {
                    template[field.name] = field.value
                } else if (field.name === 'sharedLocations') {
                    sharedLocations = field.value
                } else {
                    actions[field.name] = field.value
                }
            })

            this.$emit('share', {
                template,
                actions,
                locations: sharedLocations,
                report: this.report
            })

            this.$emit('update:visible', false)
            //console.log({ template, sharedLocations, actions })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/template-dialog.scss";
</style>
