<template>
    <div class="load-previewer shadowed">
        <div class="load-item stretched"></div>
        <div class="load-item stretched-small"></div>
        <div class="load-item stretch-box"></div>
        <div class="load-item load-btn"></div>
    </div>
</template>

<script>
export default {
    name: "LoadPreviewer"
}
</script>

<style lang="scss" scoped>
.load-previewer{
  max-width: 100%;
  border-radius: 8px;
  padding: .75rem;
  flex: 0 0 auto;

  .load-item{
    background: rgba(80, 148, 221, .15);

    &.stretched{
      width: 15%;
      height: 6px;
      animation: stretch 1.3s linear infinite;

      @keyframes stretch{
        0%{ width: 15%}
        50%{ width: 60% }
        100%{ width: 15% }
      }

      @-webkit-keyframes stretch{
        0%{ width: 15%}
        50%{ width: 60% }
        100%{ width: 15% }
      }

      @-o-keyframes stretch{
        0%{ width: 15%}
        50%{ width: 60% }
        100%{ width: 15% }
      }

      @-moz-keyframes stretch{
        0%{ width: 15%}
        50%{ width: 60% }
        100%{ width: 15% }
      }
    }

    &.stretched-small{
        width: 10%;
        height: 6px;
        animation: stretchSmall 1.3s linear infinite;
        margin-top: .45rem;

        @keyframes stretchSmall{
            0%{ width: 10%}
            50%{ width: 50% }
            100%{ width: 10% }
        }

        @-webkit-keyframes stretchSmall{
            0%{ width: 10%}
            50%{ width: 50% }
            100%{ width: 10% }
        }

        @-o-keyframes stretchSmall{
            0%{ width: 10%}
            50%{ width: 50% }
            100%{ width: 10% }
        }

        @-moz-keyframes stretchSmall{
            0%{ width: 10%}
            50%{ width: 50% }
            100%{ width: 10% }
        }
    }

    &.stretch-box{
      margin: 1.25rem 0;
      height: 180px;
      border-radius: 20px;
      position: relative;
      z-index: 1;
      overflow: hidden;

      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: -50%;
        width: 50%;
        height: 100%;
        background: rgba(80, 148, 221, .03);
        z-index: 2;
        animation: slideToEnd .6s linear infinite;

        @keyframes slideToEnd {
          0%{ left: -50% }
          100%{ left: 100% }
        }

        @-webkit-keyframes slideToEnd {
          0%{ left: -50% }
          100%{ left: 100% }
        }

        @-moz-keyframes slideToEnd {
          0%{ left: -50% }
          100%{ left: 100% }
        }

        @-o-keyframes slideToEnd {
          0%{ left: -50% }
          100%{ left: 100% }
        }
      }
    }


    &.load-btn{
      width: calc(60% - .5rem);
      max-width: 250px;
      padding: .85rem 1rem;
      border-radius: 12px;
      margin: 0 auto;
      animation: fadeLoadBtn 5s linear infinite;

      @keyframes fadeLoadBtn{
        0%{ opacity: .3 }
        50%{ opacity: 1 }
        100%{ opacity: .3 }
      }

        @-webkit-keyframes fadeLoadBtn{
            0%{ opacity: .3 }
            50%{ opacity: 1 }
            100%{ opacity: .3 }
        }

        @-moz-keyframes fadeLoadBtn{
            0%{ opacity: .3 }
            50%{ opacity: 1 }
            100%{ opacity: .3 }
        }
        @-o-keyframes fadeLoadBtn{
            0%{ opacity: .3 }
            50%{ opacity: 1 }
            100%{ opacity: .3 }
        }
    }
  }
}
</style>
