<template>
<q-dialog class="template-creator-dialog" v-model="open" position="top">
    <div class="dialog-box creator-section">
        <div class="modal-header">
            <h4 class="header-text">Create template</h4>
            <i class="bx bx-x close-icon" @click="open = false"></i>
        </div>

    <form method="post">
        <div class="top-padding-md">
            <div
                v-for="(field, fieldIndex) in fields"
                 :key="fieldIndex"
                class="form-group"
            >
                <div
                >
                    <label>{{ field.label }}</label>
                    <input
                        :class="['form-field', {
                            'input-error': field.hasError
                        }]"
                        :placeholder="field.placeholder"
                        :name="field.name"
                        v-model="field.value"
                        :type="field.type"
                        @input="getFieldValue(field)"
                        v-if="field.fieldType === 'input'"
                    />


                    <textarea
                        :class="['form-field', {
                            'input-error': field.hasError
                        }]"
                        :placeholder="field.placeholder"
                        :name="field.name"
                        v-model="field.value"
                        @input="getFieldValue(field)"
                        v-if="field.fieldType === 'textarea'"
                    />


                    <image-picker
                        v-if="field.fieldType === 'imagePicker'"
                        @change="updateFileValue($event, field)"
                    />

                    <location-selector
                        v-if="field.fieldType === 'locationSelector'"
                        :location-id="report['location_id']"
                        v-model:locations="field.value"
                    />

                    <span class="error-text">
                        {{ field.errorText }}
                    </span>
                </div>
            </div>


                <div v-if="selectedLocations > 0">
                    <div v-for="(field, index) in autoReportCreationFields"
                         class="form-group"
                         :key="index">
                        <div
                            v-if="field.visible"
                        >
                            <div v-if="field.fieldType === 'checkbox'"
                                 class="flex flex-align-center check-group">
                                <checkbox
                                    :checked="field.value"
                                    @change="updateCheckState($event, field)"
                                />

                                <span class="field-label">{{ field.label }}</span>
                            </div>

                            <div v-if="field.note" class="note-section">
                            <span class="bold-text">
                                Note:
                            </span>
                                {{ field.note }}
                            </div>
                        </div>
                    </div>
                </div>
        </div>

        <div v-if="state.errorText && state.createError" class="y-padding-sm error-section error-text flex flex-align-center justify-center text-center">
         <i class="bx bx-error-circle"></i>
            <span>
             {{ state.errorText }}
            </span>
        </div>

        <div class="button-section">
            <button @click="open = false"
                    class="button button-red-transparent ">
                Cancel
            </button>

            <button
                class="button button-blue-alt"
                @click="createTemplate($event)"
                :disabled="fieldsWithErrorExist || state.creating"
            >
                {{ state.buttonText }}
                <q-spinner v-if="state.creating" class="load-spinner"/>
            </button>
        </div>
    </form>
    </div>


</q-dialog>
</template>

<script>
import {useStore} from "../store/dataStore";
import ImagePicker from "./ImagePicker.vue";
import LocationSelector from "./LocationSelector.vue";
import CustomCheckbox from "./CustomCheckbox.vue";

export default {
    name: "TemplateCreator",
    components: {
        LocationSelector,
        ImagePicker,
        'checkbox': CustomCheckbox
    },
    setup() {
        return {
            store: useStore()
        }
    },
    props: {
        visible: {
            type: Boolean
        },
        report: {
            type: Object,
            required: true
        },
        topLevel: {
            type: Boolean,
            required: false,
            default: false
        },
        adminUser: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'update:visible',
        'autoCreate'
    ],
    watch: {
        visible(state) {
            this.open = state
        },
        open(state) {
            if (!state) {
                this.$emit('update:visible', state);
                this.clearValues();
            } else {
                this.createFields()
            }
        },
        selectedLocations(value) {
            if (value === 0) {
                this.clearReportOptionValues()
            }
        }
    },
    mounted() {
        this.open = this.visible;
        if (this.fields.length === 0) {
            this.createFields()
        }
    },
    data() {
        return {
            open: false,
            fields: [],
            state: {
                creating: false,
                createError: false,
                errorText: 'An error occurred.',
                buttonText: 'Create'
            },
            autoReportCreationFields: [],
            createdTemplate: null
        }
    },
    computed: {
        selectedLocations() {
            let selected = 0;

            this.fields.forEach(field => {
                if (field.name === 'sharedLocations' && field.value && Array.isArray(field.value)) {
                    selected = field.value.length
                }
            })

            return selected
        },
        fieldsWithErrorExist() {
            let errorsExist = false;
            errorsExist = this.fields.filter(field => field.required && (field.hasError || !field.value)).length > 0;

            let templateNameField = this.fields.find(field => field.name === 'templateName');
            if (templateNameField) {
                if (this.store.templateNameExists(templateNameField.value) && !errorsExist) {
                    errorsExist = true;
                }
            }

            return errorsExist;
        },

        templateFormat() {
           return this.store.createTemplateFormat(this.report['report_data'])
        }
    },
    methods: {
        updateCheckState(value, field) {
            field.value = value;

            if (field.name === 'saveAsReport') {
                let filtersFieldVisible = field.value && this.report.filters.length > 0;


                this.autoReportCreationFields = this.autoReportCreationFields.map(field => {
                    if (field.name === 'removeFilters') {
                        field.visible = filtersFieldVisible
                    }
                    return field
                })
            }


        },
        createFields() {
            this.fields = this.store.createTemplateFields(this.topLevel);

            if (!this.topLevel && this.autoReportCreationFields.length === 0) {
                this.autoReportCreationFields = this.store.reportOptionFields;
            }

            /*if (this.report && this.report['report_data']) {
                let locations = [ 'DiAO1ta8lTtZ6EnRO1WV', 'aNwqNMrT1bjQRDE5i58L' ];
                let template = {
                    data: this.templateFormat,
                    name: 'Test template report',
                    customMetrics: [...this.createTemplateCustomMetrics()].map(metric => {
                        return {
                            customMetrics: metric
                        }
                    })
                };

                this.store.autoPopulateLocations(locations, template, this.report)
                    .then(response => {
                        console.log({ response })
                    }).catch(error => {
                        console.log({ error })
                    })
            }*/
        },
        clearValues() {
            this.fields = this.fields.map(field => {
                field.value = field.fieldType === 'locationSelector' ? [] : ''
                return field
            })

            this.clearReportOptionValues();
        },

        clearReportOptionValues() {
            this.autoReportCreationFields = this.autoReportCreationFields.map(field => {
                if (field.fieldType === 'checkbox') {
                    field.value = false;
                }
                return field
            })
        },

        getFieldValue(field) {
            if (field.name === 'templateName') {
                if (this.store.templateNameExists(field.value)) {
                    field.hasError = true;
                    field.errorText = 'This name is already taken. Try using a different one.'
                } else {
                    if (!field.value) {
                        field.hasError = true;
                        field.errorText = 'This field is required.'
                    } else {
                        if (field.hasError) {
                            field.hasError = false;
                            field.errorText = ''
                        }
                    }
                }
            }
        },

        createTemplate(event) {
           event.preventDefault();
           let format = {};

           let selectedLocations;
           this.fields.forEach(field => {
               let fieldValue = field.value;

               if (field.name === 'sharedLocations') {
                   selectedLocations = fieldValue
                   fieldValue = [...fieldValue].map(location => location.id)
               }

               format[field.name] = fieldValue
           })

            if (this.topLevel) {
                format['sharedLocations'] = []
            }

           format['templateData'] = this.templateFormat;

           // console.log({ report: this.report['report_data'], template: this.templateFormat , selectedLocations })

           this.state = {
               ...this.state,
               creating: true,
               createError: false,
               buttonText: 'Creating...'
           }

            // console.log({ format, selectedLocations, reports: this.report })
            this.store.makeTemplate(format, this.report).then(template => {
               // console.log({ template })
               this.store.addNewTemplate(template);
                let optionalFields = this.autoReportCreationFields
                if (optionalFields.length > 0 && !this.topLevel)  {
                    let actions = {};
                    optionalFields.forEach(field => {
                        actions[field.name] = field.value;
                    })


                    if (actions['saveAsReport'] && selectedLocations.length > 0) {
                        this.$emit('autoCreate', {
                            template,
                            actions,
                            locations: selectedLocations,
                            report: this.report
                        })
                    }
                }


               this.state = {
                   ...this.state,
                   creating: false,
                   buttonText: 'Template created!'
               }

               setTimeout(() => {
                  this.state = {
                      ...this.state,
                      buttonText: 'Create'
                  }

                  this.open = false;
               }, 1000)
           }).catch(error => {
               this.state = {
                   ...this.state,
                   creating: false,
                   createError: true,
                   buttonText: 'Create',
                   errorText: 'Error creating template: ' + error.message
               }

               setTimeout(() => {
                  this.state = {
                      ...this.state,
                      createError: false,
                      errorText: ''
                  }
               }, 3000)
           })
        },

        createTemplateCustomMetrics() {
            let customMetrics = this.report['custom_metrics'];
            return this.store.createTemplateCustomMetrics(customMetrics);
        },

        updateFileValue(files, field) {
            field.value = files[0]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/template-dialog.scss";
</style>
