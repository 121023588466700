<template>
  <div class="nav-present">
    <LoadingSequence v-if="loading" :text="loading_text"></LoadingSequence>
    <div class="form-group search-group">
      <i class="fas fa-search search-icon"
      ></i>
      <input class="form-field" name="search-tags" v-model="field_value" placeholder="Search">
    </div>

    <div class="tag-actions action-row">
      <div class="bulk-actions">
        <div class="select-container">
          <label>Bulk actions</label>
          <dropdown
              :name="bulk_actions.ref"
              :ref="bulk_actions.ref"
              :items="bulk_actions.options"
          ></dropdown>
        </div>
        <button class="button button-blue-alt apply-bulk-action">Apply</button>
      </div>

      <div class="action-row">
        <button class="button button-blue-transparent action-item">
          <img src="../assets/icons/plus-icon.svg">
          <span>New Tag</span>
        </button>

        <button class="button button-blue-transparent action-item">
          <img src="../assets/icons/plus-icon.svg">
          <span>New Category</span>
        </button>

        <button class="button button-blue-alt action-item">
          <img src="../assets/icons/column-stack.svg">
          <span>Columns</span>
        </button>

        <div>
          <img class="refresh-icon" src="../assets/icons/refresh-icon-alt.svg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import CustomDropDown from "../components/CustomDropDown";
import LoadingSequence from "../components/LoadingSequence";

export default {
  name: "FieldManagementView",
  components: {
    LoadingSequence,
    'dropdown': CustomDropDown
  },
  data() {
    return {
      api_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6IkRpQU8x' +
          'dGE4bFR0WjZFblJPMVdWIiwiY29tcGFueV9pZCI6IkxiY1FqN2VmSTBJMlN2YmdPWUh4Iiwidm' +
          'Vyc2lvbiI6MSwiaWF0IjoxNjM2ODYzM' +
          'TY0MTYyLCJzdWIiOiIyZkhKVlNxSmVxUWduZlF2a3dSSCJ9.DwIjxHjG2_0WE7CK' +
          'jibpmSriGQsOuE5kWZSyePs7RNI',
      field_value: '',
      bulk_actions: {
          options: [
            {label: 'Merge', value: 'merge'},
            {label: 'Delete', value: 'delete'}
         ],
        ref: 'bulk_action',
        value: 'merge',
       },
      loading: true,
      loading_text: 'Loading tags...',
      tags: []
    }
  },
  mounted() {
    console.log('Sending request...')
    axios.get('https://rest.gohighlevel.com/v1/tags/', {
      headers: {
        'Authorization': `Bearer ${this.api_key}`
      }
    }).then(response => {
      this.loading = false
      const tags = response.data.tags;
      let tag_data = tags.map((tag) => {
        let tag_name = tag.name.split(":");

        let tag_object = {};

        tag_object['tag_name'] = tag_name[0];
        tag_object['category'] = tag_name.slice(1).toString().replace(/,/g, " ").replace(/\s+/g, " ");

        return tag_object
      });



      console.log({
        'Tags': tags,
        'Tag names': tag_data
      });
    }).catch(error => {
      this.loading = false;
      console.log('Request error:', error)
    })
  },
}
</script>

<style scoped lang="scss">
.search-group{
  max-width: calc(100% - 45rem);
  margin: 0 auto;
  position: relative;

  .form-field{
    padding: 8px 48px;
    margin-top: 0;
    background: var(--white);
  }

  .search-icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    color: var(--theme-blue-alt);
  }
}

.action-row{
  display: flex;
  align-items: center;

  .action-item:not(last-child){
    margin-right: .7rem;
  }

  img{
    &.refresh-icon{
      width: 24px;
      height: 24px;
      margin-left: 1rem;
    }

    &.refresh-icon:hover{
      transform: scale(1.05);
      opacity: .7;
      transition: .3s ease;
      cursor: pointer;
    }
  }

  button{
    &.button-blue-transparent:hover img{
      filter: invert(100%) sepia(1%) saturate(0%) hue-rotate(191deg) brightness(105%) contrast(101%);
    }

    &.button-blue-transparent img{
      filter: invert(31%) sepia(9%) saturate(4660%) hue-rotate(172deg) brightness(93%) contrast(90%);
    }

    &.button-blue-alt:hover{
      background: var(--theme-blue-alt);
      color: var(--white);
      opacity: .7;
    }

    img{
      width: 20px;
      height: 20px;
      margin-right: .6rem;
    }
  }
}

.tag-actions{
  justify-content: space-between;
  margin: 2rem 0;
  .bulk-actions{
    display: flex;
    .select-container{
      min-width: 10rem;

      label{
        font-weight: 700;
        color: var(--theme-blue-alt);
      }
    }
    .apply-bulk-action{
      margin-left: .7rem;
      margin-top: auto;
      margin-bottom: .2rem;
      padding: .4rem 1.2rem;
      font-size: .75rem;
    }
  }
}
</style>
