<template>
<div class="edit-overlay" v-if="visible">
    <div :class="['edit-content', {'faded': hide }]">
        <div class="overlay-section">
            <div class="overlay-content main" ref="container">
                <div class="overlay-line secondary" v-for="(item, index) in items" :key="index"></div>
            </div>

            <div class="overlay-content secondary">
                <div class="overlay-line" v-for="(item, index) in items" :key="index"></div>
            </div>
        </div>
    </div>

    <div :class="['overlay-bg']"></div>

    <button @click="dialog.visible = true" v-if="this.deletable" class="button action-btn delete-btn">
        <img src="../assets/icons/xmark-solid.svg" alt="x">
    </button>

    <button @click="toggleState()" class="button action-btn hide-btn">
        <img v-if="!hide" src="../assets/icons/eye.svg" alt="eye"/>
        <img v-if="hide" src="../assets/icons/eye-cancelled.svg" alt="eye cancelled"/>
    </button>

    <confirmation-dialog
        v-model:visible="dialog.visible"
        :text="overviewMetricReport ? dialog.metricText : dialog.reportText"
        :header="dialog.header"
        @confirm="$emit('deleted')"
    />


</div>
</template>

<script>
import ConfirmationDialog from "./ConfirmationDialog.vue";

export default {
    name: "EditOverlay",
    components: {ConfirmationDialog},
    props: {
        hide: {
            type: Boolean
        },
        deletable: {
            type: Boolean,
            default: false
        },
        visible: {
            type: Boolean,
            required: true
        },
        overviewMetricReport: {
            type: Boolean,
            required: false
        }
    },
    emits: [
        'update:hide',
        'deleted',
        'open',
        'close'
    ],
    data() {
        return {
            items: 0,
            display: false,
            dialog: {
                visible: false,
                header: "Remove report",
                reportText: "Are you sure you want to remove this report and its associated overview metrics?",
                metricText: "Are you sure you want to remove this set of overview metrics?"
            }
        }
    },
    mounted() {
        this.setItems()
        let component = this;

        setTimeout(function () {
            component.toggleParentClass(component.hide)
        }, 0)
    },
    watch: {
        hide(state) {
            this.toggleParentClass(state)
        },

        visible(state) {
            // console.log({ state })

            if (state) {
                this.setItems();
            } else {
               if (this.dialog.visible) {
                   this.dialog.visible = false;
               }
            }

            let component = this;

            setTimeout(function () {
                component.$emit(state ? 'open': 'close')
                component.toggleParentClass(component.hide);
                component.display = state
            }, 0)
        }
    },
    methods: {
        setItems() {
            let component = this;
            setTimeout(function () {
                component.items = component.getTotalItems();
                let parentElement = component.$el.parentElement;
                if (!parentElement.classList.contains('overlay-parent')) {
                    parentElement.classList.add('overlay-parent');
                }

            }, 0)
        },

        getTotalItems() {
            let container = this.$refs.container;
            if (!container) {
                return 0;
            }

            return Math.ceil(container.clientWidth / 120)
        },

        toggleState() {
            let state = !this.hide;
            this.toggleParentClass(state)

            this.$emit('update:hide', state)
        },

        toggleParentClass(state) {
            let parentElement = this.$el.parentElement;
            if (state) {
                if (!parentElement.classList.contains('faded-overlay')) {
                    parentElement.classList.add('faded-overlay')
                }
            } else {
                if (parentElement.classList.contains('faded-overlay')) {
                    parentElement.classList.remove('faded-overlay')
                }
            }
        }
    }
}
</script>

<style lang="scss">
.overlay-parent{
  position: relative !important;
  transition: ease-in-out .15s;

  &.faded-overlay{
    opacity: .55;
  }

}
</style>

<style lang="scss" scoped>
.edit-overlay{
  width: 100%;
  border-radius: inherit;
  .edit-content{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: inherit;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;


    .overlay-section{
      width: 100%;
      height: 100%;
      position: relative;

      .overlay-content{
        width: 100%;
        height: 100%;
        flex: 0 0 auto;
        display: flex;
        flex-wrap: nowrap;
        position: absolute;
        top: 0;

        .overlay-line{
          width: 60px;
          height: 100%;
          background: #f9f9f9;
          position: relative;
          transform: skew(-20deg);
          flex: 0 0 auto;



          &:not(:last-child) {
            margin-right: 60px;
          }



          /*&:before{
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 100%;
            background: red;

          }*/
        }

        &.main{
          transform: translateX(-100%);
          animation: slideRight 8s linear infinite;
          @keyframes slideRight {
            0% { transform: translateX(-100%)}
            100%{ transform: translateX(0)}
          }

            @-webkit-keyframes slideRight {
                0% { transform: translateX(-100%)}
                100%{ transform: translateX(0)}
            }

            @-moz-keyframes slideRight {
                0% { transform: translateX(-100%)}
                100%{ transform: translateX(0)}
            }

            @-o-keyframes slideRight {
                0% { transform: translateX(-100%)}
                100%{ transform: translateX(0)}
            }
        }

        &.secondary{
          animation: slideLeft 8s linear infinite;
          transform: translateX(0);
          @keyframes slideLeft {
            0%{ transform: translateX(0) }
            100%{ transform: translateX(100%)}
          }

            @-webkit-keyframes slideLeft {
                0%{ transform: translateX(0) }
                100%{ transform: translateX(100%)}
            }

            @-moz-keyframes slideLeft {
                0%{ transform: translateX(0) }
                100%{ transform: translateX(100%)}
            }
            @-o-keyframes slideLeft {
                0%{ transform: translateX(0) }
                100%{ transform: translateX(100%)}
            }
        }

      }
    }

    &.faded{
      opacity: .6;
    }
  }

  .overlay-bg{
    background: rgba(80, 148, 221, .05);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    border-radius: inherit;
  }

  .action-btn{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    z-index: 99;
    top: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-in-out .2s;
    color: var(--white);

      &.hide-btn{
          right: -10px;
          background: var(--theme-orange);
          box-shadow: 0 0 2px rgba(216, 128, 76, .25), 0 0 2px 4px rgba(216, 128, 76, .3);

          &:hover{
              background: #e88c58;
          }
      }

      &.delete-btn{
          left: -10px;
          background: var(--red);
          box-shadow: 0 0 2px rgba(255, 0, 0, .25), 0 0 2px 4px rgba(255, 0, 0, .3);

          &:hover{
              background: #ff2323;
          }
      }



    img{
      width: 18px;
      height: 18px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(19%) saturate(2%) hue-rotate(41deg) brightness(107%) contrast(101%);
    }
  }
}
</style>
