<template>
  <div class="source-stage" v-if="sourceCostData && sourceCostData['fields']">
    <header class="text-center bold-text">{{ sourceCostData['heading'] }}</header>
    <div class="input-content centered-container">
      <div
          v-for="(field, index) in sourceCostData['fields']"
          class="form-group"
          :key="index"
      >
        <div class="bold-text group-content label">{{ field.label }}</div>
        <div
            class="group-content"
            v-if="field.type === 'input'"
        >
          <input type="text"
                 class="form-field"
                 v-model="field.value"
                 @input="getValue($event, field)"
          >
        </div>

        <div
            class="group-content date-content"
            v-if="field.type === 'date'"
        >
          <date-picker
              :date="field.value"
              :position="'bottom'"
              v-model:open="field.open"
              @date-select="field.value = $event;"
          > </date-picker>
        </div>


      </div>
    </div>

    <div v-if="error_exists" class="error-content">
      <img src="../assets/icons/exclamation-icon.svg" alt="exclamation icon"/>
      {{ error_message }}
    </div>

    <div class="button-container text-center">
      <button class="button button-blue generate-button"
              @click="addSourceCost( sourceCostData['type'] )"
              :disabled="generating_source"
      >
        <span>{{ !lead_cost_added ? sourceCostData['button_text'] : success_text }}</span>
        <q-spinner
            color="#FFFFFF"
            v-if="generating_source"
            size="20px" :thickness="5"
            ></q-spinner>
      </button>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import {replaceSlashes} from "@/helpers/helper_functions";
import {getNextDay} from "../helpers/date_functions";
import axios from "axios";
import {baseURL} from "../api/apiBase";
import {validateInput} from "../helpers/helper_functions";

export default {
  components: {
    'date-picker': DatePicker
  },
  name: "SourceCostGenerator",
  props: {
    sourceCostData: Object,
    sourcePipelineId: String,
    location: String,
    source: String,
    leadCostType: String
  },
  data() {
    return {
      error_exists: false,
      error_message: '',
      generating_source: false,
      lead_cost_added: false,
      success_text: 'Lead cost added!'
    }
  },
  methods: {
    getValue(event, field) {
      let value = event.target.value;
      field.value = validateInput(value)
    },
    addSourceCost(type) {
      //console.log(type)
     let empty_fields = this.sourceCostData.fields.filter(item => item.value === '');

     if (empty_fields.length > 0) {
       this.error_exists = true;
       this.error_message = 'All fields are required';
     } else {
       let unitary_value = type === 'assign_lead_cost';
       let request_object = {
         fromDate: '',
         sourceCost: '',
         source: this.source,
         toDate: '',
         unitary: unitary_value
       }

     //  console.log('Source name:', this.source)

       this.sourceCostData.fields.map(item => {

         if (item.type === 'date') {
           request_object[item.api_key] = item.api_key === 'toDate' ? getNextDay(item.value).replace(/\//g, "-") : replaceSlashes(item.value);
         } else{

           let item_value = Math.round(parseFloat(item.value) * 100) / 100;
           request_object[item.api_key] = Math.round(item_value * 10) / 10;
         }

       });

       let start_date = new Date(request_object.fromDate);
       let end_date = new Date(request_object.toDate);

       const time_diff = end_date - start_date;
       const time_diff_days = Math.ceil(time_diff / (1000 * 60 * 60 * 24));
       //console.log('Time difference days:', time_diff_days);

       if (time_diff_days <= 0)  {
         this.error_exists = true;
         this.error_message = 'Date should be progressive. ' +
             'Start date cannot be at a date ahead( or on the same day)  ' +
             'of the end date';
       } else{
         this.error_exists = false; this.error_message = '';
         //console.log('Fields:', request_object);
         this.generating_source = true;

        // console.log({ request_object })


         axios.post(`${ baseURL }/locations/${this.location}/pipelines/${this.sourcePipelineId}/source-cost`, request_object)

             .then(response => {
              // console.log(response)
               if (response.status === 200) {
                 this.error_exists = false; this.error_message = '';
                 this.generating_source = false;
                 this.lead_cost_added = true;
                 setTimeout(() => {
                   this.$emit('sourceGenerated', this.leadCostType);
                 }, 1000)

               }
             }).catch(() => {
               //console.log(error.response)
                this.generating_source = false
                this.error_exists = true;
                this.error_message = 'Error calculating lead cost. Please try again';
         })
       }





     }
    }
  }
}
</script>

<style scoped>
  .error-content{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff0000;
    padding: .8rem;
    background: rgba(255,0,0, .15);
    width: calc(100% - 12rem);
    margin: 0 auto .8rem;
    border-radius: 8px;
  }

  .error-content img {
    width: 18px;
    height: 18px;
    margin-right: .65rem;
    filter: brightness(0) saturate(100%) invert(16%) sepia(56%) saturate(7084%) hue-rotate(2deg) brightness(101%) contrast(127%);
  }

  .generate-button{
    min-width: 225px;
    display: flex;
    justify-content: center;
    position: relative;
  }
</style>

<style>
.generate-button .q-spinner{
  position: absolute;
  top: 5px;
  right: 6px;
}

.source-action-buttons button{
     text-transform: uppercase;
 }

.source-stage .input-content{
    width: calc(100% - 5rem);
}


.source-stage header{
    margin: 1.5rem 0 2rem;
    font-size: 1.05rem;
}

.source-stage .form-group{
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.source-stage .form-group .group-content{
    width: 50%;
}

.source-stage .form-group .group-content.label{
    font-size: .95rem;
    color: var(--theme-blue-alt);
}

.source-stage .form-group .group-content .form-field{
    padding: 12px 20px;
}

.source-stage .form-group .group-content.date-content{
    position: relative;
    cursor: pointer;
}

.source-stage .button-container{
    border-top: 1px solid rgba(80, 148, 221, .35);
    padding: 1.2rem 0;
    display: flex;
    justify-content: center;
}

.source-stage .button-container button{
    text-transform: uppercase;
}

.source-stage{
    padding: .6rem 0;
}
</style>
