<template>
    <label ref="checkbox_main"
           :class="['custom-checkbox-container', { 'disabled': disabled }]"
           :for="'data-checkbox-'+id"
           :style="{'--color-rgb': color ? rgbValue(color, .9) : defaultColorRgb}"

    >
      <input type="checkbox"
             v-model="ticked"
             :disabled="disabled"
             :id="'data-checkbox-'+id"
             @change="getState($event)"
      >
      <span :style="{border: '1px solid ' + color}" class="checkmark"></span>
    </label>
</template>

<script>
//import {onMounted} from "vue";
import { v4 as uuidv4 } from "uuid";
import { getRgbValue } from "../helpers/helper_functions";

export default {
  name: "CustomCheckbox",
  props: {
    checked: Boolean,
    color: {
      type: String
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  emits: [
      'change'
  ],
  data() {
    return {
      ticked: this.checked,
      id: uuidv4(),
      defaultColorRgb: `rgba(80, 148, 221, .9)`,
      defaultColor: '#5094DD'
    }
  },
  computed: {
    checkboxColor() {
        return this.color ? this.color : this.defaultColor
    }
  },
  mounted() {
    this.$refs.checkbox_main.style.setProperty('--checkbox-bg', this.checkboxColor)
  },
  methods: {
    getState(event) {
      let checked = event.target.checked;
      this.$emit('change', checked);

      this.$refs.checkbox_main.style.setProperty('--checkbox-bg', this.checkboxColor)
    },

    rgbValue(color, opacity) {
      return getRgbValue(color, opacity)
    }
  },
  watch: {
    checked(state) {
      this.ticked = state;
      this.$refs.checkbox_main.style.setProperty('--checkbox-bg', this.checkboxColor)
    }
  }
}
</script>

<style scoped>
.custom-checkbox-container{
  --checkbox-bg: #5094DD;
  --color-rgb: rgba(80, 148, 221, .9)
}

.custom-checkbox-container{
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 16px;
  height: 16px;
}

.custom-checkbox-container.disabled{
  opacity: .5;
}

.custom-checkbox-container input{
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  cursor: pointer;
}

.custom-checkbox-container .checkmark{
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  background: transparent;
  border: 1px solid var(--theme-blue);
}

.custom-checkbox-container .checkmark:hover{
  box-shadow: inset 0 0 1px 1px var(--color-rgb), 0 0 1px 1px var(--color-rgb);
  cursor: pointer;
}

.checkmark:after{
  content: '';
  position: absolute;
  top: -.05rem;
  left: .235rem;
  width: .42rem;
  height: .75rem;
  border: solid white;
  border-width: 0 .12rem .12rem 0;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  display: none;
}

.custom-checkbox-container input:checked ~ .checkmark{
  background: var(--checkbox-bg);
}

.custom-checkbox-container input:checked ~ .checkmark:after{
  display: block;
}

</style>
