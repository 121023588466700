<template>
    <div class="content-holder nav-present">
      <error-handler v-if="locationId === ''" :error="locationErrorText"></error-handler>

      <div
          class="visualization-section"
          ref="visualizationSection"
          :class="{
          'fullscreen': inFullscreen
          }"
          v-if="locationId !== ''"
      >
        <loading-sequence v-if="loadingData" :text="sequenceText" />

        <div v-if="!loadingData">
          <error-handler v-if="loadingError" :error="errorText"></error-handler>

          <div v-if="!loadingError">
            <header class="screen-heading flexbox flex-align-center">
              <span class="header-text">Visualization mode</span>

              <button class="button button-blue" @click="enterFullscreen()">Enter fullscreen mode</button>
            </header>

            <div class="screen-container" ref="container">
              <div class="stage-items" ref="stageItems">
                <div class="stage-listing" :class="{open: stagesOpen}" ref="stageListing">
                  <div class="content-items">
                    <div class="listing-header" @click="stagesOpen = !stagesOpen">
                      <span>Stages</span>

                      <img src="../assets/icons/arrow_down.svg" class="arrow-icon">
                    </div>

                    <div class="main-items">
                                        <dropdown :items="pipelineData.options" :default-value="pipelineData.defaultValue" class="pipeline-selector" @valueChange="setActivePipeline($event)"></dropdown>

                                        <div v-if="stageData.length === 0" class="empty-stages"> {{ emptyStagesText }}</div>

                                        <ul class="main-stage-list" v-if="stageData.length > 0">
                                          <li
                                              v-for="(stage, index) in stageData"
                                              :key="index"
                                              draggable="true"
                                              @drag="detectItemPosition($event)"
                                              @dragstart="initiateDrag($event, stage.id)"
                                          >{{ stage.name }}</li>
                                        </ul>
                    </div>
                  </div>
                </div>
              </div>



              <div class="main-visualizer">
                <div class="flexbox visualizer-actions">
                  <button class="compress-section" @click="exitFullScreen">
                    <img src="../assets/icons/compress-icon.svg"/>
                  </button>
                  <div class="action-section insights">
                    <header>Insight filters</header>
                    <div class="action-types flexbox">
                      <div class="action-item" v-for="(filter, index) in filterTypes" :key="index">
                        <div @click="filter.checked = !filter.checked"><checkbox :checked="filter.checked" color="#5094DD" /></div>
                        <div class="action-label">{{ filter.label }}</div>
                      </div>
                    </div>
                  </div>

                  <div class="action-section stage-creator" @click="createNewStep()">
                    <span>Create step</span>
                    <img src="../assets/icons/plus-icon.svg"/>
                  </div>
                </div>
<!--                <canvas class="stage-canvas" ref="stageCanvas" @mousemove="debouncePositionDetect($event)" @click="detectClick($event)">
                </canvas>-->

                <div class="main-board"
                     @dragenter.prevent
                     @dragover.prevent
                     @drop="handleItemDrop($event)">

                  <svg class="visualizer-section"
                       ref="visualizerSection"
                       xmlns='http://www.w3.org/2000/svg'
                       :key="visualizerSectionKey"
                  >

                    <!--                  <svg xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 0 L120 20" stroke="#ff0000" fill="transparent"/>
                                      </svg>-->
                    <g v-for="(stage, index) in stages" :ref="'stage-group-'+index" :key="index">
                      <rect class="stage-item"
                            :class="{
                              'active-hover-element': activeHoverElement === index
                            }"
                            :ref="'steps-'+index"
                            :y="(index * (stageHeight + stageMargin))"
                      ></rect>
                      <text class="stage-name"
                            @click="togglePopup(index)"
                            :ref="'stage-name-'+index"
                            :y="(index * (stageHeight + stageMargin)) + (stageHeight / 2) + 5"
                            :x="leftMargin"
                      >
                        {{ stage.stageName }}
                      </text>

                      <g class="step-list" :data-index="index">
                        <g
                            class="step-data"
                            v-for="(step, stepIndex) in stage.steps"
                            :key="stepIndex"
                            @mouseup="clearDragAction()"
                            @mousedown="activateDrag(index, stepIndex, $event)"
                            :class="{
                              'active-group': this.activeBoxElement.index === index && this.activeBoxElement.stepIndex === stepIndex
                            }"
                        >



                          <rect
                              class="step-data-item data-box"
                              @click="step.showPoints = !step.showPoints"
                              :rx="stepBorderRadius"
                              :ry="stepBorderRadius"
                              :x="step.coords.x"
                              :data-index="index"
                              :y="step.coords.y"
                              :class="[`${step.status}`, {
                                'active-item': this.activeBoxElement.index === index && this.activeBoxElement.stepIndex === stepIndex,

                              }]"
                          >

                          </rect>
                          <text class="step-name"
                                :x="step.coords.x + (stepWidth / 2)"
                                :y="(index * (stageHeight + stageMargin)) + 40"
                                @click="step.showPoints = !step.showPoints"
                          >{{ step.stepId.slice(0, 18) }}{{ step.stepId.length > 18 ? '...' : ''}}</text>

                          <g class="option-group-trigger">
                            <circle
                                r="2"
                                v-for="(circle, circleIndex) in 3"
                                :key="circleIndex"
                                class="vertical-options"
                                :cx="step.coords.x + 15"
                                :cy="step.coords.y + (stepHeight / 2.8) + (circleIndex * 6)"
                            />
                            <rect
                                :x="step.coords.x + 7.5"
                                :y="step.coords.y + (stepHeight / 2.8) - 7.5"
                                fill="transparent"
                                :width="15"
                                :height="30"
                                @click="toggleOptions(step, index, stepIndex)"
                            />
                          </g>

                          <g>
                            <circle
                                v-for="(point, pointIndex) in dataPositions.slice(0, step.dataPoints)"
                                :key="pointIndex"
                                class="data-point"
                                :class="[step.status, {
                                  'display': step.showPoints
                                }]"
                                :r="circleRadius"
                                :cx="getCirclePosition(index, stepIndex, point).x"
                                :cy="getCirclePosition(index, stepIndex, point).y"
                                @mousedown="startDrag(index, stepIndex, point, $event)"
                            ></circle>
                          </g>


                          <g class="step-options" v-if="step.showOptions">
                            <g
                                class="option-group"
                                v-for="(option, optionIndex) in boxOptions"
                                :key="optionIndex"
                                @click="performAction(option.action, index, stepIndex, step.stepId)"
                            >
                              <rect
                                  :x="getPointIndex(index, stepIndex, 'x') - (optionItemWidth - 15)"
                                  :y="getPointIndex(index, stepIndex, 'y') + (optionItemHeight - 15)"
                                  :width="optionItemWidth"
                                  :height="optionItemHeight"
                                  class="option-item"
                              />
                              <text
                                  :x="(getPointIndex(index, stepIndex, 'x') - (optionItemWidth - 15)) + 15"
                                  :y="getPointIndex(index, stepIndex, 'y') + (optionItemHeight - 10) + (optionItemHeight / 2)"
                              >{{ option.name }}</text>
                            </g>
                          </g>

                        </g>
                      </g>
                    </g>
                    <g class="visualization-paths">
                      <path
                          :d="path.path"
                          v-for="(path, index) in visualizationPaths"
                          :class="path.status"
                          :key="index"/>

                    </g>


                    <g v-if="draggingBox">
                      <rect
                          :rx="stepBorderRadius"
                          :ry="stepBorderRadius"
                          class="data-box"
                          :x="offsetPosition.x"
                          :y="offsetPosition.y"
                      />
                      <text
                          :x="offsetPosition.x + (this.stepWidth / 2)"
                          :y="offsetPosition.y + (this.stepHeight / 2)"
                      >{{ draggedBox.data.stepId }}</text>
                    </g>

                  </svg>

                  <div
                      class="action-popup"
                      :class="{
                           'closed': !showPopup
                      }"
                       :style="{
                  top: `${popupOffsetTop}px`,
                  left: `${popupOffsetLeft}px`
                  }">
                    <div class="main-popup">
                        <div class="action-button" :class="{
                          'delete': action.type === 'delete'
                        }" v-for="(action, index) in stepActions" :key="index" @click="performStepAction(action.type)">
                          <img :src="action.icon"/>
                        </div>
                    </div>
                  </div>
                 <div class="modifiable-action" v-if="modifiableBox.modifiable">
                   <step-modifier
                       :default-status="modifiableBox.status"
                       :step-name="modifiableBox.name"
                       @modified="activateModification($event)"
                       @close="modifiableBox.modifiable = false"
                   />
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import CustomCheckbox from "../components/CustomCheckbox";
import ErrorHandler from "../components/ErrorHandler";
import LoadingSequence from "../components/LoadingSequence";
import axios from "axios";
import {baseURL} from "../api/apiBase";
import {generateKey, replaceSlashes} from "../helpers/helper_functions";
import {getCurrentDate, getLastMonthDate} from "../helpers/date_functions";
import CustomDropDown from "../components/CustomDropDown";
import StepModifier from "../components/StepModifier";
import _ from "lodash";

export default {
  name: "VisualizationV2",
  components: {
    'checkbox': CustomCheckbox,
    LoadingSequence,
    ErrorHandler,
    'dropdown': CustomDropDown,
    StepModifier
  },
  mounted() {
    // let params = new URLSearchParams(window.location.search);
    let location = this.$route.params.locationId;
    console.log({ location })
    if (location) {
        this.locationId = location;

        this.stages = this.createStages()

        this.updateVisualizerHeight()

        this.fetchPipelines().then(response => {
            this.loadingError = false
            console.log('Pipeline response:', response)

            this.pipelineData.options = response.data['pipelines'].map(pipeline => { return { value: pipeline.id, label: pipeline.name } });
            this.pipelineData.value = this.pipelineData.options[0].value;
            this.pipelineData.defaultValue = this.pipelineData.options[0].label;

            this.fetchReports(this.locationId, this.pipelineData.value, "source")


        }).catch(error => {
            this.loadingData = false;
            this.loadingError = true;
            this.errorText = `There was an error fetching your pipelines.`
            console.log('Pipeline error:', error)
        })
    }

    document.addEventListener('click', this.detectOutsidePopUpClick)
  },
  unmounted() {
    document.removeEventListener('mousemove', this.detectArrowPosition)
    document.removeEventListener('mouseup', this.clearDrag);
    document.removeEventListener('mouseup', this.clearBoxDrag);
    document.removeEventListener('click', this.detectOutsidePopUpClick);
    document.removeEventListener('mousemove', this.detectMousePosition)
    document.removeEventListener('click', this.detectOutsideClick);
    document.removeEventListener('fullscreenchange', this.detectFullScreenChange)
  },
  watch: {
   modifiableBox: {
     deep: true,
     handler(currentState) {
       if (currentState.modifiable) {
         document.addEventListener('click', this.detectOutsideClick)
       } else {
         document.removeEventListener('click', this.detectOutsideClick)
       }
     }
   }
  },
  data() {
    return {
      locationId: '',
      locationErrorText: 'Location not detected',
      stagesOpen: true,
      loadingData: true,
      sequenceText: 'Fetching pipelines...',
      pipelineData: {
        options: [],
        value: '',
        defaultValue: ''
      },
      stageHeight: 75,
      stageMargin: 50,
      leftMargin: 25,
      stepMargin: 135,
      stepWidth: 200,
      stepHeight: 45,
      circleRadius: 4,
      circleMargin: 12,
      stepBorderRadius: 8,
      stageDataMargin: 150,
      optionItemWidth: 160,
      optionItemHeight: 40,
      filterTypes: [
        /* {
           label: "Select all",
           name: "select_all",
           type: "checkbox",
           checked: false,
         },*/
        {
          label: "Salesrep",
          name: "salesrep",
          type: "checkbox",
          checked: false
        },
        {
          label: "Source",
          name: "source",
          type: "checkbox",
          checked: false
        },
        {
          label: "Tags",
          name: "tags",
          type: "checkbox",
          checked: false
        },
        /* {
           label: "Field",
           name: "field",
           type: "checkbox",
           checked: false
         }*/

      ],
      stageItems: [],
      stageData: [],
      loadingError: false,
      errorText: "",
      emptyStagesText: "No stages found in current pipeline",
      stages: [],
      dataPositions: [
          'top',
          'left',
          'right',
          'bottom'
      ],
      visualizationPaths: [

      ],
      activePoint: {
        stageIndex: null,
        stepIndex: null,
        position: null
      },
      dragPoint: {},
      dataPoints: [

      ],
      activeDropPoint: {
        x: null,
        y: null
      },
      stepActions: [
        {
          type: 'delete',
          icon: require('../assets/icons/trash-icon.svg')
        }
      ],
      popupOffsetTop: 0,
      popupOffsetLeft: 0,
      showPopup: false,
      activeStepIndex: -1,
      activeHoverElement: -1,
      activeBoxElement: {
        index: -1,
        stepIndex: -1
      },
      draggedBox: {
        index: -1,
        stepIndex: -1,
        data: {}
      },
      offsetPosition: {
        x: 0,
        y: 0
      },
      draggingBox: false,
      boxDragActivated: false,
      statusIndicatorColors: {
        abandoned: '#F41616',
        lost: '#FF9B12',
        open: '#5094DD',
        won: '#157811'
      },
      defaultStatus: 'open',
      boxActionActivated: true,
      modifiableBox: {
        modifiable: false,
        index: -1,
        stepIndex: -1,
        name: '',
        status: 'open'
      },
      boxOptions: [
        {
          name: 'Set status/tag',
          action: 'set_status'
        }
      ],
      visualizerSectionKey: generateKey(),
      inFullscreen: false
    }
  },
  methods:  {
    detectFullScreenChange() {
     this.exitFullScreen();
    },
    exitFullScreen() {
      let fullScreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
      let exitFullscreen = true
      if (fullScreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }  else if (document.mozExitFullscreen) {
          document.mozExitFullscreen()
        } else {
          exitFullscreen = false
        }

      }

      if (exitFullscreen) {
        document.removeEventListener('fullscreenchange', this.detectFullScreenChange)
      }

      this.inFullscreen = !exitFullscreen

    },
    enterFullscreen() {
      let visualizerSection = this.$refs['visualizationSection'];
      let fullScreen = true

      if (visualizerSection.requestFullscreen) {
        visualizerSection.requestFullscreen()
      } else if (visualizerSection.msRequestFullscreen) {
        visualizerSection.msRequestFullscreen()
      } else if (visualizerSection.mozRequestFullscreen) {
        visualizerSection.mozRequestFullscreen()
      } else if (visualizerSection.webkitRequestFullscreen) {
        visualizerSection.webkitRequestFullscreen()
      } else {
        fullScreen = false
      }

      setTimeout(() => {
        if (fullScreen) {
          document.addEventListener('fullscreenchange', this.detectFullScreenChange)
        }
      }, 1000)

      this.inFullscreen = fullScreen


    },
    detectOutsideClick(event) {
      if (event.target.closest('.step-modifier') == null && event.target.closest('.option-item') == null) {
        this.modifiableBox.modifiable = false
      }
    },
    closeOptions() {
      this.stages = this.stages.map(stage => {
        stage.steps = stage.steps.map(step => {
          step.showOptions = false
          return step
        })
        return stage
      })
    },
    toggleOptions(step, index, stepIndex) {

      this.stages = this.stages.map((stage, stageIndex) => {
        stage.steps = stage.steps.map((step, boxIndex) => {
          if (!(index === stageIndex && boxIndex === stepIndex)) {
            step.showOptions = false
          }
          return step
        })
        return stage
      })
      step.showOptions = !step.showOptions
    },
    performAction(type, index, stepIndex, name) {
      switch (type){
        case 'set_status':
          this.showTagStatusModal(index, stepIndex, name)
      }
    },

    showTagStatusModal(index, stepIndex, name) {
      let stepData = this.stages[index].steps[stepIndex];
       stepData.showOptions = false

      this.modifiableBox = {
        modifiable: true,
        index,
        stepIndex,
        status: stepData.status,
        name
      }


      console.log({ paths: this.visualizationPaths })
    },
    activateModification(data) {
      let modifiableBox = this.stages[this.modifiableBox.index].steps[this.modifiableBox.stepIndex]

      let boxStatus = data.find(item => item.type === 'status');

      if (boxStatus) {

        if (boxStatus.applied) {
          modifiableBox.status = boxStatus.value
          this.modifiableBox.modifiable = false

          if (this.visualizationPaths.length > 0) {
            this.visualizationPaths = this.visualizationPaths.map(path => {
              if (path.data.index === this.modifiableBox.index && path.data.stepIndex === this.modifiableBox.stepIndex) {
                path.status = boxStatus.value
              }
              return path
            })
          }
        //  console.log({ modifiableBox, boxStatus })
        }

      }

    },
    activateDrag(index, stepIndex, event) {
      event.preventDefault();
      event.stopPropagation();
      this.boxDragActivated = true
      if (event.target.closest('.data-point') == null) {
        this.dragBox(index, stepIndex, event)
      }


    },
    clearDragAction() {
      this.boxDragActivated = false
    },
    detectMousePosition(event) {
      this.setOffsetPosition(event)
     // console.log({ position: this.offsetPosition })
    },

    setOffsetPosition(event) {
      let containerPosition = this.$refs['visualizerSection'].getBoundingClientRect();
      let xPosition = event.clientX - containerPosition.left
      let yPosition = event.clientY -  containerPosition.top;

      let coords = { x: xPosition, y: yPosition };

      this.setActiveHoveredStep( coords , false)
      this.setActiveHoveredBox(coords)


      this.offsetPosition = { x: xPosition, y: yPosition }
    },
    /**
     * Modifies arrows connected to other boxes in a step
     * **/
    modifyConnectors(index, stepIndex, deleteReferenceBox = false) {
      let stageSteps = this.stages[index].steps, filteredSteps = [];

      let pathsToBeAltered = [], arrowConnectors = [];
      stageSteps.forEach((step, stepPosition) => {

        if (stepPosition !== stepIndex) {
          let connectedArrows = this.findConnectedArrows(step.id);

          if (connectedArrows.length > 0) {
            let currentCoords = {
              x: this.getPointIndex(index, stepPosition, 'x'),
              y: this.getPointIndex(index, stepPosition, 'y')
            }
            arrowConnectors = [...arrowConnectors, ...connectedArrows]
            pathsToBeAltered = [...pathsToBeAltered, {
              stepId: step.id,
              coords: currentCoords,
              connectedPath: connectedArrows
            }]
          }
          filteredSteps = [...filteredSteps, step]
        }
      })


      // console.log({ arrowConnectors, pathsToBeAltered })

      if (arrowConnectors.length > 0) {
        let arrowIds = arrowConnectors.map(arrow => arrow.pathId);

        this.visualizationPaths = this.visualizationPaths.filter(path => arrowIds.indexOf(path.pathId) === -1)
      }


      if (deleteReferenceBox) {
        this.stages[index].steps = [...filteredSteps]
      }

      //   console.log({ filteredSteps, pathsToBeAltered, arrowConnectors })

      if (pathsToBeAltered.length > 0) {
        let alteredPaths = []
        let remainingSteps = this.stages[index].steps


        pathsToBeAltered.forEach(path => {
          let newStep = remainingSteps.find(step => step.id === path.stepId);

          if (newStep) {
            let newIndex = remainingSteps.indexOf(newStep);
            let modifiedSteps = {
              index,
              stepIndex: newIndex
            }

            let newPaths = this.createNewConnectedPaths(path.coords, path.connectedPath, modifiedSteps)
            alteredPaths = [...alteredPaths, ...newPaths]
          }
        })

        this.visualizationPaths = [...this.visualizationPaths, ...alteredPaths ]
        //console.log({ alteredPaths, paths: this.visualizationPaths })
      }

    },
    dragBox: _.debounce( function (index, stepIndex, event) {
      event.preventDefault();
      if (this.boxDragActivated) {
        let draggedBox = this.stages[index].steps[stepIndex];

        console.log({ draggedBox })

        this.draggedBox = {
          index,
          stepIndex,
          data: draggedBox,
          status: draggedBox.status,
          boxCoords: draggedBox.coords
        }


        console.log({ draggedBox })

        this.draggingBox = true
        let connectedArrows = this.findConnectedArrows(draggedBox.id)
      //  console.log({ connectedArrows, draggedBox, id: draggedBox.id, paths: this.visualizationPaths })
        if (connectedArrows.length > 0) {
          this.draggedBox.connectedArrows = connectedArrows
        } else {
          this.draggedBox.connectedArrows = []
        }

        if (this.draggedBox.connectedArrows)  {
          let connectedArrowIds = this.draggedBox.connectedArrows.map(arrow => arrow.pathId);
          this.visualizationPaths = this.visualizationPaths.filter(path => connectedArrowIds.indexOf(path.pathId) === -1)
        }

        this.modifyConnectors(index, stepIndex, true)


        this.setOffsetPosition(event)

        document.addEventListener('mousemove', this.detectMousePosition);
        document.addEventListener('mouseup', this.clearBoxDrag)
      }
    }, 200),
    detectOutsidePopUpClick(event) {
      if (event.target.closest('.action-popup') == null && (event.target.closest('.stage-name') == null && this.showPopup)) {
        this.showPopup = false;
      }

      if (event.target.closest('.option-group') == null && event.target.closest('.option-group-trigger') == null) {
        this.closeOptions()
      }
    },

    findConnectedArrows(id) {
      return this.visualizationPaths.filter(path => path.data.id === id || path.startBox.id === id)
     /* return this.visualizationPaths.map(path => {
        let pathValue = '';

        if (path.data.index === index && path.data.stepIndex === stepIndex) {
          pathValue = path
        } else {
          if (path.startBox.index === index && path.startBox.stepIndex === stepIndex) {
              console.log({ path, index, stepIndex })
            pathValue = path
          }
        }

        return pathValue
      }).filter(value => value !== '');*/
    },



    modifySteps(stageName, stageId = null, id, event) {
      let data = {
        stepsModified: false,
        index: -1,
        stepIndex: -1,
      }

      const getBoxPosition = () => {
          let visualizerSection = this.$refs.visualizerSection;


          if (!visualizerSection) {
              return { top: 0, left: 0 }
          }

          let { top, left } = visualizerSection.getBoundingClientRect();

          let coordLeft = event.clientX - left - (this.stepWidth / 2);
          let coordTop = event.clientY - top;

          console.log({
              top, left,
              coordLeft, coordTop,
              eventX: event.clientX, eventY: event.clientY,
              event
          })

          return { x: event.clientX - left, y: event.clientY - top }
      }



      let dataObject =  {
        stepId: `${stageName}`,
        dataPoints: 4,
        showPoints: false,
        status: this.defaultStatus,
        showOptions: false,
        id,
        coords: { x: 0, y: 0 }
      }

      console.log({
          boxElement: this.activeBoxElement,
          hoverElement: this.activeHoverElement
      })


      if (this.activeBoxElement.index !== -1 && this.activeBoxElement.stepIndex !== -1) {
        let stageIndex = this.activeBoxElement.index, stepIndex = this.activeBoxElement.stepIndex;


        if (this.draggedBox.status) {
          dataObject.status = this.draggedBox.status
        }


        dataObject = {
            ...dataObject,
            coords: {
                x: getBoxPosition().x,
                y: this.getPointIndex(stageIndex, stepIndex, 'y')
            }
        }
        this.stages[stageIndex].steps.splice(stepIndex, 0, dataObject)
        data = {
          stepsModified: true,
          index: stageIndex,
          stepIndex: stepIndex
        }


        this.activeBoxElement = {
          index: -1,
          stepIndex: -1
        }

        this.activeHoverElement = -1


      } else {

        if (this.activeHoverElement !== -1) {
          let index = this.activeHoverElement
          if (stageId != null) {
            let stageData = this.stageData.find(data => data.id === stageId);

            if (stageData) {
              let stepIndex = this.stages[index].steps.length;
              dataObject = {
                  ...dataObject,
                  coords: {
                      x: getBoxPosition().x,
                      y: this.getPointIndex(index, stepIndex, 'y')
                  }
              }
              this.stages[index].steps = [...this.stages[index].steps, dataObject];

              data = {
                stepsModified: true,
                index: index,
                stepIndex
              }




            }
          } else {

            if (this.draggedBox.status) {
              dataObject.status = this.draggedBox.status
            }

            let stepIndex = this.stages[index].steps.length;
              dataObject = {
                  ...dataObject,
                  coords: {
                      x: getBoxPosition().x,
                      y: this.getPointIndex(index, stepIndex, 'y')
                  }
              }

            this.stages[index].steps = [...this.stages[index].steps, dataObject];

            data = {
              stepsModified: true,
              index: index,
              stepIndex
            }

          }


          this.activeHoverElement = -1
        } else {
          if (this.draggedBox.index !== -1 && this.draggedBox.stepIndex !== -1) {
            let stepIndex = this.draggedBox["stepIndex"];
            let index = this.draggedBox['index'];

            if (this.draggedBox.status) {
              dataObject.status = this.draggedBox.status
            }

              dataObject = {
                  ...dataObject,
                  coords: {
                      x: getBoxPosition().x,
                      y: this.getPointIndex(index, stepIndex, 'y')
                  }
              }


            this.stages[index].steps.splice(stepIndex+1, 0, dataObject)
            data = {
              stepsModified: true,
              index,
              stepIndex: stepIndex
            }

          }


        }
      }



      return data
    },
    handleItemDrop(event) {
       let stageId = event.dataTransfer.getData('stageId');

       let stageData = this.stageData.find(data => data.id === stageId);

       if (stageData) {
         let modifiedData = this.modifySteps(stageData.name, stageId, stageData.dataId, event);
         if (modifiedData.stepsModified) {
           this.stageData = this.stageData.filter(data => data.id !== stageId);

           let totalSteps = this.stages[modifiedData.index].steps.length;
           let totalWidth = (totalSteps * this.stepWidth) + (totalSteps * this.stepMargin)

            let visualizerSection = this.$refs['visualizerSection'];
            let visualizerWidth = visualizerSection.clientWidth;

          //  console.log({ totalWidth, visualizerWidth })

             if (totalWidth > visualizerWidth) {
              // let extraSteps = ( 2 * this.stepWidth ) + ( 2 * this.stepMargin)
               let extraSteps = this.stepWidth + this.stepMargin

              visualizerSection.style.width = `${visualizerWidth + extraSteps}px`;

               let alterStages = [...this.stages]
               this.stages = []

               setTimeout(() => {
                 this.stages = [ ...alterStages ]
               })

               //console.log({ visualizerWidth, extraSteps })
             }


         }

       }

     /* this.activeHoverElement = -1

      let testArray = [];
      testArray[3] = 0;

      testArray.forEach(entry => {
        console.log({ entry })
      })*/

     // console.log({ stageId, event })
    },

    setActiveHoveredBox(coords) {
      this.activeBoxElement = {
        index: -1,
        stepIndex: -1
      }
      let boxPositions = [];

      //  console.log({ xPosition, yPosition })



      this.stages.forEach((stage, index) => {
        stage.steps.forEach((step, stepIndex) =>{
          let boxPosition = {
            x: this.getPointIndex(index, stepIndex, 'x'),
            y: this.getPointIndex(index, stepIndex, 'y')
          }

          boxPositions.push({
            index,
            stepIndex,
            position: boxPosition
          })
        })
      })
      // console.log({ boxPositions })

      boxPositions.forEach(box => {
        let withinHorizontal = coords.x > box.position.x && coords.x <  box.position.x + this.stepWidth;
        let withinVertical = coords.y > box.position.y && coords.y < box.position.y + this.stepHeight;

        if (withinVertical && withinHorizontal) {
          this.activeBoxElement = {
            index: box.index,
            stepIndex: box.stepIndex
          }
        }

      })
    },

    detectItemPosition(event) {



      //console.log({ positionLeft, positionTop })
      let visualizerSection = this.$refs['visualizerSection'];
      let visualizerPosition = visualizerSection.getBoundingClientRect();
      let xPosition = event.clientX - visualizerPosition.left , yPosition =  event.clientY - visualizerPosition.top;
      let boxCoords = { x: xPosition, y: yPosition }
      this.setActiveHoveredBox(boxCoords)

      let stepCoords = { x: event.clientX, y: event.clientY }
      this.setActiveHoveredStep(stepCoords)


    },

    setActiveHoveredStep(coords, pageRelative = true) {
      if (this.activeHoverElement !== -1) {
        this.activeHoverElement = -1
      }
      let visualizerSection = this.$refs['visualizerSection'];
      let visualizerPosition = visualizerSection.getBoundingClientRect();

      this.stages.forEach((step, index) => {
        let stepItem = this.$refs[`steps-${index}`];
        let itemPosition = stepItem.getBoundingClientRect();
        itemPosition = {
          left: itemPosition.left,
          top: itemPosition.top
        }

        if (!pageRelative) {
          itemPosition.left = itemPosition.left - visualizerPosition.left;
          itemPosition.top = itemPosition.top - visualizerPosition.top;
        }

        //console.log({stepItem, index, itemPosition, top: event.clientY, left: event.clientX, height: this.stageHeight,  width: visualizerSection.clientWidth })

        if (stepItem) {




          let withinHorizontal = coords.x > itemPosition.left && coords.x < itemPosition.left + visualizerSection.clientWidth;
          let withinVertical = coords.y > itemPosition.top && coords.y < itemPosition.top + this.stageHeight;


          if (withinVertical && withinHorizontal) {
            // console.log({ index })
            this.activeHoverElement = index
            /*console.log({ index })
            stepItem.style.strokeWidth = '3px'*/
          }
        }
      })
    },
    initiateDrag(event, stageId) {
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'

      event.dataTransfer.setData('stageId', stageId)
    },
    performStepAction(actionType) {
      switch (actionType) {
        case 'delete':
          // console.log({ stages: this.stageData })
        this.stages = this.stages.map((stage, index) => {
            if (index === this.activeStepIndex) {
              let stageData = stage.steps;

              if (stageData.length > 0) {
                stageData.forEach((step) => {
                  let connectedArrows = this.findConnectedArrows(step.id);
                  let ids = connectedArrows.map(arrow => arrow.pathId);

                  this.visualizationPaths = this.visualizationPaths.filter(path => ids.indexOf(path.pathId) === -1)
                  let stageItem = this.stageItems.find(item => item.dataId === step.id);

                  if (stageItem) {
                   this.stageData = [...this.stageData, stageItem]
                  }

                })
              }
             // console.log({ stageData })
              stage = null
            }

            return stage
          }).filter(stage => stage != null);
          this.showPopup = false
      }
    },
    togglePopup(index) {
      let stageName = this.$refs[`stage-name-${index}`], namePosition = stageName.getBoundingClientRect();
      let visualizerPosition = this.$refs['visualizerSection'].getBoundingClientRect()
      this.popupOffsetLeft = namePosition.left - visualizerPosition.left;
      this.popupOffsetTop = (namePosition.top - visualizerPosition.top) + 20 ;

      if (this.activeStepIndex === index) {
        this.showPopup = !this.showPopup
      } else {
        this.showPopup = false
        this.showPopup = true
      }

      this.activeStepIndex = index



     // console.log({  offsetLeft: this.popupOffsetLeft, offsetTop: this.popupOffsetTop })
    },
    showOptions() {

    },
    updateVisualizerHeight() {
      let visualizerHeight = (this.stages.length * this.stageHeight) + (this.stageMargin * this.stages.length);

      let visualizerSection = this.$refs['visualizerSection'];

      if (visualizerSection) {
        visualizerSection.style.height = `${visualizerHeight}px`
      }

    },
    async fetchPipelines() {
      return await axios.get(`${ baseURL }/locations/${this.locationId}/pipelines`)
    },
    setActivePipeline(data) {
      let pipelineId = data.value
      this.pipelineData.value = pipelineId;
      let valueOption = this.pipelineData.options.find(option => option.value === pipelineId);

      if (valueOption) {
        this.pipelineData.defaultValue = valueOption.label;
      }

      this.stages = this.createStages();
      this.visualizationPaths = [];
      this.fetchReports(this.locationId, pipelineId, "source")
    },
    fetchReports(locationId, pipelineId, type) {
      if (!this.loadingData) {
        this.loadingData = true;
      }

      this.sequenceText = "Fetching reports..."

      const requestData = {
        "fromDate": replaceSlashes(getLastMonthDate()),
        "toDate": replaceSlashes(getCurrentDate()),
        "filters": []
      }
      axios.post(`${ baseURL }/locations/${locationId}/pipelines/${pipelineId}/report/${type}`, requestData, {
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 60000
      }).then(response => {
        this.loadingData = false;
        this.sequenceText = "Fetching pipelines..."


        if (response.data.results.length > 0) {
          let resultStages = []
          // console.log({results: response.data.results })
          response.data.results.forEach(result => {
            result.report.stages.forEach(stage => {
              let existingStage = resultStages.filter(stageData => stageData.id === stage.id).length;
              if (existingStage === 0) {
                resultStages = [
                    ...resultStages, {
                      name: stage.name,
                      id: stage.id,
                      dataId: generateKey()
                  }
                ]
              }
            })
          })

         // console.log({ resultStages })
          this.stageData = [...resultStages]
          this.stageItems = [...resultStages]

          // console.log('Stage data:', this.stageData)

        }
      }).catch(error => {
        this.loadingData = false;
        this.loadingError = true;

        this.errorText = `There was error fetching your reports`;
        console.log('Error fetching reports:', error);

      })
    },
    getPointIndex(index, stepIndex, coordinate) {
      let lastIndex = index - 1 < 0 ? 0 : index-1;
      let pointIndex = (this.stages[lastIndex].steps.length * index) + stepIndex;

      let coord;
      if (coordinate === 'x') {
        coord = this.stageDataMargin + (stepIndex * this.stepMargin  * 2)
      } else {
        coord = (index * (this.stageHeight + this.stageMargin)) + (this.stepHeight / 3)
      }

      if (!this.dataPoints[pointIndex]) {
        this.dataPoints[pointIndex] = {}
      }

      this.dataPoints[pointIndex][coordinate] = coord
     // console.log({ coord, pointIndex })

      return coord

    },
    getCirclePosition(index, stepIndex, position) {
      let leftPosition = this.stages[index].steps[stepIndex].coords.x;
      let leftOffset = leftPosition + (this.stepWidth / 2);
      let xMargin = this.circleMargin;
      let topOffset = (index * (this.stageHeight + this.stageMargin)) + (this.stepHeight / 3) + (this.stepHeight / 2);
      switch (position) {
        case 'top':
          return {
            x: leftOffset,
            y: (index * (this.stageHeight + this.stageMargin)) + 5
          }
        case 'bottom':
          return {
            x: leftOffset,
            y: (index * (this.stageHeight + this.stageMargin)) + this.stepHeight + (this.stepHeight / 3) + (this.circleMargin)
          }
        case 'left':
          return {
            x: leftPosition - xMargin,
            y: topOffset
          }
        case 'right':
          return {
            x: leftPosition + this.stepWidth + xMargin,
            y: topOffset
          }
        default:
          return { x: leftOffset, y: topOffset }
      }
    },
    createStages() {
      return new Array(3).fill('').map((stage, index) => {
        return {
          stageName: `Step ${index+1}`,
          steps: []/*new Array(3).fill('').map((step, stepIndex) => {
            return {
              stepId: `Step ${stepIndex + 1}`,
              dataPoints: 4,
              showPoints: false
            }
          })*/
        }
      })
    },

    createNewStep() {
      this.stages = [...this.stages, {
        stageName: `Step ${this.stages.length + 1}`,
        steps: []
      }]


      this.updateVisualizerHeight();

      setTimeout(() => {
        let lastStep = this.$refs[`steps-${this.stages.length-1}`];
      //  console.log({ lastStep, refs: this.$refs['steps-3'] , length: this.stages.length - 1 })
      //  console.log({ lastStep })
        if (lastStep) {
          let offsetTop = document.body.scrollTop + lastStep.getBoundingClientRect().top;

          window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
          })
        }
      }, 500)


    },
/*    getPointIndex(index, stepIndex, pointIndex) {
      return ((index * this.stages[index].steps.length) + (stepIndex * this.stages[index].steps[stepIndex].dataPoints) + pointIndex)
    },*/
    createConnectingPath(startCoordinates, endCoordinates) {
      /**
       * Draws line path to current mouse position
       */

      let points = [
        {
          type: 'M',
          coords: [startCoordinates.x, startCoordinates.y]
        }
      ]

      points = [
        ...points,
        {
          type: 'L', coords: [startCoordinates.x, endCoordinates.y]
        }
      ]

     let xPosition = endCoordinates.x, yPosition = endCoordinates.y;


      let pathDifference =  Math.abs(xPosition - startCoordinates.x )
      let activePoint, pointCoords;
      let circlePositions = [], boxPositions = [];

      if (pathDifference > this.circleRadius) {
        points = [...points, ...[
          {
            type: 'M', coords: [startCoordinates.x, yPosition]
          },

          {
            type: 'L', coords: [xPosition, yPosition]
          }
        ]]
      }
      let pathAttribute = this.convertToPath(points);



      /**
       *
       * Extracts coordinates of step boxes and circles and checks if
       * cursor is close to a step circle
       *
       */


      this.stages.forEach((stage, index) => {
        stage.steps.forEach((step, stepIndex) => {
          let boxPosition = {
            x: this.getPointIndex(index, stepIndex, 'x'),
            y: this.getPointIndex(index, stepIndex, 'y')
          }

          boxPositions.push({
            index,
            stepIndex,
            position: boxPosition
          })

          this.dataPositions.slice(0, step.dataPoints).forEach((position, positionIndex) => {
            let circlePosition = this.getCirclePosition(index, stepIndex, position);
            circlePositions.push({ index, stepIndex, position: circlePosition })
            let offset = 10;


            let withinX = (xPosition + offset) > circlePosition.x && xPosition < (circlePosition.x + ( this.circleRadius * 2 ) + offset);
            let withinY = (yPosition + offset)  > circlePosition.y  && yPosition < (circlePosition.y + (this.circleRadius * 2) + offset);

            if (withinX && withinY) {
              activePoint = {}
              activePoint.index = index;
              activePoint.stepIndex = stepIndex;
              activePoint.id = step.id;
              activePoint.position = this.dataPositions[positionIndex]


              pointCoords = circlePosition
            }
          })
        })
      })

      //  console.log({ circlePositions })

      /**
       * Performs actions if cursor is within a step circle
       * */
      if (activePoint && !(pointCoords.x === startCoordinates.x && pointCoords.y === startCoordinates.y)) {


        //  this.activeDropPoint = pointCoords
        // console.log({ activePoint, pointCoords, dragPoint: this.dragPoint })


        this.stages[activePoint.index].steps[activePoint.stepIndex].showPoints = true



        let yDiff = Math.abs(pointCoords.y - startCoordinates.y);
        let xDiff = Math.abs(pointCoords.x - startCoordinates.x);
        let intersectingBoxes = this.findIntersectingBoxes(boxPositions, pointCoords, 'y');
        let intersectingItems = this.findIntersectingBoxes(boxPositions, pointCoords, 'x');
        let stepDiffY = this.stepWidth + 2 * (2 * this.circleRadius);
        let stepDiffX = this.stepHeight + 2 * (2 * this.circleRadius);

        // console.log({ xDiff, yDiff })
        /**
         *
         * Connects circles if they are both on the same y-axis
         *
         * */
        if (yDiff < (this.circleRadius * 2)) {

         // console.log({ activePoint, startCoordinates })



          //  console.log({ xDiff })

          /**
           * Checks if matching circle is not on the step as where the drag started
           * **/
          if (!(activePoint.index === startCoordinates.index && activePoint.stepIndex === startCoordinates.stepIndex)){
            this.activeDropPoint = pointCoords

            /**
             * Draws a straight line if it does not intersect
             * with any boxes
             * */
            if (intersectingBoxes.length === 0 || xDiff < stepDiffY) {

              //  console.log({ intersectingBoxes })
              let pathData = [
                {
                  type: 'M',
                  coords: [startCoordinates.x, startCoordinates.y]
                },

                {
                  type: 'L',
                  coords: [pointCoords.x, startCoordinates.y]
                }
              ]

              pathAttribute = this.convertToPath(pathData)

            } else {
              /**
               * Draws alternate path if it intersects with any step box
               * **/
              let yOffset = startCoordinates.y - (this.stepHeight / 2) - 10
             // console.log({ yOffset })
              let pathData = [
                {
                  type: 'M',
                  coords: [startCoordinates.x, startCoordinates.y]
                },

                {
                  type: 'L',
                  coords: [startCoordinates.x, yOffset]
                },

                {
                  type: 'M',
                  coords: [startCoordinates.x, yOffset]
                },

                {
                  type: 'L',
                  coords: [pointCoords.x, yOffset]
                },
                {
                  type: 'M',
                  coords: [pointCoords.x, yOffset]
                },
                {
                  type: 'L',
                  coords: [pointCoords.x, pointCoords.y]
                }
              ]

              pathAttribute = this.convertToPath(pathData)

            }

          }
        } else {
          /**
           * checks if start circle and current active circle are on the same x-axis
           * **/
          if (xDiff < (this.circleRadius * 2)) {




            /** checks if start and end circle are on the same step **/
            if (!(activePoint.index === startCoordinates.index && activePoint.stepIndex === startCoordinates.stepIndex)) {
           //   console.log({ startCoordinates })
              this.activeDropPoint = pointCoords
              //  console.log({ intersectingItems })

              /** draws straight line if it does not intersect any step boxes **/
              if (intersectingItems.length === 0 || yDiff < stepDiffX) {
                let pathData = []
                pathData = [
                  {
                    type: 'M',
                    coords: [startCoordinates.x, startCoordinates.y]
                  },
                  {
                    type: 'L',
                    coords: [startCoordinates.x, pointCoords.y]
                  }
                ]

                pathAttribute = this.convertToPath(pathData)
                // pathAttribute = `${pathAttribute} ${this.drawArrow({ x: this.dragPoint.left, y: pointCoords.y })}`
              } else {
                let pathData = []
                /** draws alternate path if end point does not intersect any step boxes **/
                let xOffset = startCoordinates.x - (this.stepWidth / 2) - 10
                pathData = [
                  {
                    type: 'M',
                    coords: [startCoordinates.x, startCoordinates.y]
                  },

                  {
                    type: 'L',
                    coords: [xOffset, startCoordinates.y]
                  },

                  {
                    type: 'M',
                    coords: [xOffset, startCoordinates.y]
                  },

                  {
                    type: 'L',
                    coords: [xOffset, pointCoords.y]
                  },

                  {
                    type: 'M',
                    coords: [xOffset, pointCoords.y]
                  },

                  {
                    type: 'L',
                    coords: [pointCoords.x, pointCoords.y]
                  }
                ]

                pathAttribute = this.convertToPath(pathData)
              }


            }
          } else {
            let minWidth = (this.stepWidth / 2) + (this.circleRadius * 2) + 10
            if (intersectingBoxes.length > 0) {
              if (xDiff > stepDiffY && pointCoords.x > startCoordinates.x) {
                this.activeDropPoint = pointCoords
                // console.log({ index: activePoint.index })
                let pathData = [];
                let topOffset = startCoordinates.y - ((this.stageHeight - 10) / 2);
                let offsetCoord =  {
                  x: pointCoords.x-30,
                  y: pointCoords.y
                }

                let intersectingDataX = this.findIntersectingBoxes(boxPositions, offsetCoord, 'x');

                if (intersectingDataX.length > 0) {
                  offsetCoord.x = pointCoords.x
                }

                //console.log({ intersectingDataX })

                pathData = [
                  {
                    type: 'M',
                    coords: [startCoordinates.x, startCoordinates.y]
                  },
                ]


                let offsetPath = [
                  {
                    type: 'L',
                    coords: [startCoordinates.x, topOffset]
                  },

                  {
                    type: 'M',
                    coords: [startCoordinates.x, topOffset]
                  },

                  {
                    type: 'L',
                    coords:[offsetCoord.x , topOffset]
                  },

                  {
                    type: 'M',
                    coords: [offsetCoord.x, topOffset]
                  },
                ]
                let straightPath = [
                  {
                    type: 'L',
                    coords: [offsetCoord.x, startCoordinates.y]
                  },

                  {
                    type: 'M',
                    coords: [offsetCoord.x, startCoordinates.y]
                  }
                ]

                let endPath = [
                  {
                    type: 'L',
                    coords: [offsetCoord.x, pointCoords.y]
                  },

                  {
                    type: 'M',
                    coords: [offsetCoord.x, pointCoords.y]
                  },

                  {
                    type: 'L',
                    coords: [pointCoords.x, pointCoords.y]
                  }
                ]

                let endLine =[{
                  type: 'L',
                  coords: [offsetCoord.x, pointCoords.y]
                }]


                let intersectCoord = { x: startCoordinates.x, y: topOffset };

                let intersectingPath = this.findIntersectingBoxes(boxPositions, intersectCoord, 'x');

                let endPoints = offsetCoord.x - pointCoords.x === 0 ? endLine : endPath

                if (startCoordinates.y > pointCoords.y && intersectingPath.length === 0) {

                  pathData = [ ...pathData, ...offsetPath, ...endPoints ]
                } else {
                  let lineEndCoords = { x:  offsetCoord.x, y: startCoordinates.y };
                  let intersections = this.findIntersectingBoxes(boxPositions, lineEndCoords, 'y');


                  if (intersections.length === 0) {
                    //  console.log({ diff: offsetCoord.x - pointCoords.x })
                    pathData = [...pathData, ...straightPath, ...endPoints ]
                  } else {
                    let topDiff = pointCoords.y - (this.stepHeight / 2) - 15;
                    let leftDiff = startCoordinates.x + 30;
                    let alternatePath = [
                      {
                        type: 'L',
                        coords: [leftDiff, startCoordinates.y]
                      },
                      {
                        type: 'M',
                        coords: [leftDiff, startCoordinates.y]
                      },
                      {
                        type: 'L',
                        coords: [leftDiff, topDiff]
                      },
                      {
                        type: 'M',
                        coords: [leftDiff, topDiff]
                      },
                      {
                        type: 'L',
                        coords: [pointCoords.x, topDiff]
                      },
                      {
                        type: 'M',
                        coords: [pointCoords.x, topDiff]
                      },
                      {
                        type: 'L',
                        coords: [pointCoords.x, pointCoords.y]
                      }
                    ]
                    pathData = [ ...pathData, ...alternatePath]
                  }
                }

                // pathData = [ ...pathData, ...endPath ]

                pathAttribute = this.convertToPath(pathData)
              } else {


                if (yDiff > stepDiffX){
                  this.activeDropPoint = pointCoords

                  // console.log({ yDiff, stepDiffY, stepDiffX })
                  let intersectingBoxes = this.findIntersectingBoxes(boxPositions, {x: pointCoords.x, y: startCoordinates.y}, 'y');
                  let pathData = [];
                  if (intersectingBoxes.length === 0){
                    pathData = [
                      {
                        type: 'M',
                        coords: [startCoordinates.x, startCoordinates.y]
                      },
                      {
                        type: 'L',
                        coords: [pointCoords.x, startCoordinates.y]
                      },
                      {
                        type: 'M',
                        coords: [pointCoords.x, startCoordinates.y]
                      },
                      {
                        type: 'L',
                        coords: [pointCoords.x, pointCoords.y]
                      }
                    ]
                  } else {
                    let topOffset = startCoordinates.y - (this.stepHeight / 2) - 10;
                    let offsetCoord = [startCoordinates.x, topOffset];
                    this.activeDropPoint = pointCoords

                    pathData = [
                      {
                        type: 'M',
                        coords: [startCoordinates.x, startCoordinates.y]
                      },
                      {
                        type: 'L',
                        coords: offsetCoord
                      },

                      {
                        type: 'M',
                        coords: offsetCoord
                      },

                      {
                        type: 'L',
                        coords: [pointCoords.x, offsetCoord[1]]
                      },

                      {
                        type: 'M',
                        coords: [pointCoords.x, offsetCoord[1]]
                      },

                      {
                        type: 'L',
                        coords: [pointCoords.x, pointCoords.y]
                      }
                    ]
                  }


                  pathAttribute = this.convertToPath(pathData)
                } else{


                  if (xDiff > minWidth) {
                    this.activeDropPoint = pointCoords
                    let pathData = [
                      {
                        type: "M",
                        coords: [startCoordinates.x, startCoordinates.y]
                      },
                      {
                        type: "L",
                        coords: [pointCoords.x, startCoordinates.y]
                      },
                      {
                        type: "M",
                        coords: [pointCoords.x, startCoordinates.y]
                      },
                      {
                        type: "L",
                        coords: [pointCoords.x, pointCoords.y]
                      }
                    ]

                    pathAttribute = this.convertToPath(pathData)
                  }
                }
              }

            }

            // console.log({ xDiff, stepDiffY, yDiff, intersectingItems })

            if (intersectingItems.length > 0 && (yDiff > this.stepHeight || xDiff > minWidth)) {


              this.activeDropPoint = pointCoords

              let editedPath = [
                {
                  type: 'M',
                  coords: [startCoordinates.x, startCoordinates.y]
                }
              ];


              pathAttribute = this.convertToPath(editedPath)
              // console.log({ intersectingItems })

              let leftOffset = pointCoords.x - (this.stepWidth / 2 ) - 30;


              let intersectCoord = {
                x: leftOffset,
                y: startCoordinates.y
              }

              let intersections = this.findIntersectingBoxes(boxPositions, { x: startCoordinates.x, y: startCoordinates.y }, 'x');

              // console.log({ intersections: intersections.length })
              if (intersections.length > 0) {

                let pathItems = [
                  {
                    type: 'L',
                    coords: [intersectCoord.x, intersectCoord.y]
                  },

                  {
                    type: 'M',
                    coords: [intersectCoord.x, intersectCoord.y]
                  },

                  {
                    type: 'L',
                    coords: [intersectCoord.x, pointCoords.y]
                  },

                  {
                    type: 'M',
                    coords: [intersectCoord.x, pointCoords.y]
                  },

                  {
                    type: 'L',
                    coords: [pointCoords.x, pointCoords.y]
                  }
                ]

                editedPath = [ ...editedPath, ...pathItems ]
              } else {
                let linearPath = [
                  {
                    type: 'L',
                    coords: [startCoordinates.x, pointCoords.y ]
                  },

                  {
                    type: 'M',
                    coords: [startCoordinates.x, pointCoords.y ]
                  },

                  {
                    type: 'L',
                    coords: [pointCoords.x, pointCoords.y ]
                  }
                ]

                editedPath = [...editedPath, ...linearPath ]
              }


              pathAttribute = this.convertToPath(editedPath)
            } /*else {
              console.log('Unmatched condition')
            }*/




          }
        }

      } else {
        this.clearDropPoints()
      }
      let lastPathLine = pathAttribute.slice(pathAttribute.lastIndexOf('M'));
      let lineAttribute = lastPathLine.lastIndexOf("L");
      let lastMoveCoords = lastPathLine.slice(1, lineAttribute).split(" ").filter(value => value !== '').map(coord => parseFloat(coord)),
          lastLineCoords = lastPathLine.slice(lineAttribute+1, lastPathLine.length).split(" ").filter(value => value !== '').map(coord => parseFloat(coord));

      let xDifference = lastLineCoords[0] - lastMoveCoords[0];
      let yDifference = lastLineCoords[1] - lastMoveCoords[1];


      // console.log({ pathAttribute, firstPath })


      let coords = {
        x: lastLineCoords[0],
        y: lastLineCoords[1]
      }


      let direction
      if (xDifference <= this.circleRadius && yDifference !== 0) {
        direction = yDifference > 0 ? 'down' : 'up'
      } else {
        direction = xDifference > 0 ? 'right' : 'left'
      }


      if (activePoint && !(pointCoords.x === startCoordinates.x && pointCoords.y === startCoordinates.y)) {

        let endPath = "", pathData = [
          {
            type: "M",
            coords: [coords.x, coords.y]
          }
        ], arrowPath, arrowDirection
        switch (activePoint.position) {
          case "left":
            arrowPath = {
              x: coords.x + this.circleMargin,
              y: coords.y
            }

            arrowDirection = "right"
            break
          case "right":
            arrowPath = {
              x: coords.x - this.circleMargin,
              y: coords.y
            }

            arrowDirection = "left"
            break
          case "bottom":

            arrowPath = {
              x: coords.x,
              y: coords.y - this.circleMargin
            }

            arrowDirection = "up"
            break
          case "top":
            arrowPath = {
              x: coords.x,
              y: coords.y + this.circleMargin
            }

            arrowDirection = "down"
            break
          default:
            arrowPath = {
              x: coords.x,
              y: coords.y + this.circleMargin
            }

            arrowDirection = "top"
            break
        }

        pathData = [...pathData, {
          type: 'L',
          coords: [arrowPath.x, arrowPath.y]
        }]

        endPath = `${pathAttribute} ${this.convertToPath(pathData)} ${this.drawArrow(arrowPath, arrowDirection)}`

        pathAttribute = endPath
      } else {
        pathAttribute = `${pathAttribute} ${this.drawArrow(coords, direction)}`
      }

      let pathData = {
        pathAttribute
      }

      if (activePoint && !(pointCoords.x === startCoordinates.x && pointCoords.y === startCoordinates.y)) {
     //  console.log({ activePoint })
        let data = {}
        data.boxCoords = {
          x: this.getPointIndex(activePoint.index, activePoint.stepIndex, 'x'),
          y: this.getPointIndex(activePoint.index, activePoint.stepIndex, 'y'),
        }

        data.circlePosition = activePoint.position
        data.index = activePoint.index;
        data.stepIndex = activePoint.stepIndex;
        data.id = activePoint.id;
        pathData.data = data
      }


      return pathData
    },

    detectArrowPosition(event) {


        let startBox = this.dragPoint;
        startBox.id = this.stages[startBox.index].steps[startBox.stepIndex].id;
        let containerPosition = this.$refs['visualizerSection'].getBoundingClientRect();
        let xPosition = event.clientX - containerPosition.left
        let yPosition = event.clientY -  containerPosition.top;

        let startCoords = {
          x: this.dragPoint.left,
          y: this.dragPoint.top,
          index: this.dragPoint.index,
          stepIndex: this.dragPoint.stepIndex
        }

        let endCoords = {
          x: xPosition,
          y: yPosition
        }


     //   console.log({ dragPoint: this.dragPoint })

        let pathData = this.createConnectingPath(startCoords, endCoords);
        let pathAttribute = pathData.pathAttribute

       // console.log({ xPosition, yPosition, dragPoint: this.dragPoint })




   //  console.log({ xDifference, yDifference, direction, lastPathLine, pathAttribute })



     // console.log({ lastMoveCoords, lastLineCoords })
        let existingStartPoint = this.visualizationPaths.find(path => path.pathId === this.dragPoint.pathId);



        if (!existingStartPoint) {
        let dataObject =  {
            path: pathAttribute,
            startBox,
            id: this.dragPoint.id,
            status: this.defaultStatus,
            pathId: this.dragPoint.pathId
          }

          console.log({ dataObject })

          if (pathData.data){
            dataObject['data'] = pathData.data
          }

          this.visualizationPaths.push(dataObject)
        } else {
          this.visualizationPaths = this.visualizationPaths.map(path => {
            if (path.pathId === this.dragPoint.pathId) {
              path.path = pathAttribute

              if (pathData.data) {
                path.data = {...pathData.data}
              }
            }


            return path
          })
        }
    },

    clearDropPoints() {
      if (this.activeDropPoint.x != null && this.activeDropPoint.y != null) {
        this.activeDropPoint = {
          x: null, y: null
        }
      }
    },

    drawArrow(coords, direction){
      let arrowWidth = 6, distanceFromTop = 8;

      let verticalOffset, horizontalOffset, horizontalEnd, verticalEnd;
      switch (direction){
        case 'down':
          verticalOffset =  coords.y - distanceFromTop;
          verticalEnd = coords.y - distanceFromTop;
          horizontalOffset = coords.x-arrowWidth;
          horizontalEnd = coords.x+arrowWidth;
          break
        case 'up':
          verticalEnd = coords.y + distanceFromTop;
          verticalOffset = coords.y + distanceFromTop;
          horizontalOffset = coords.x-arrowWidth;
          horizontalEnd = coords.x+arrowWidth
          break
        case 'right':
          verticalOffset = coords.y - arrowWidth;
          verticalEnd = coords.y + arrowWidth;
          horizontalOffset =  coords.x - distanceFromTop;
          horizontalEnd = coords.x - distanceFromTop;
          break
        case 'left':
          verticalOffset = coords.y - arrowWidth;
          verticalEnd = coords.y + arrowWidth;
          horizontalOffset =  coords.x + distanceFromTop;
          horizontalEnd = coords.x + distanceFromTop;
          break
        default:
          verticalEnd = coords.y - distanceFromTop;
          verticalOffset =  coords.y - distanceFromTop
          horizontalOffset = coords.x-arrowWidth
          horizontalEnd = coords.x+arrowWidth
      }
      let arrowPath = [
        {
          type: "M",
          coords: [coords.x, coords.y]
        },
        {
          type: 'L',
          coords: [horizontalOffset, verticalOffset]
        },
        {
          type: 'L',
          coords: [horizontalEnd, verticalEnd]
        }
      ]
      let path = this.convertToPath(arrowPath)
      path = `${path} Z`
      return path
    },


    findIntersectingBoxes(boxes, coords, axis) {
      let intersections = []
      if (axis === 'y'){
        intersections = boxes.filter(box =>  coords.y > box.position.y && coords.y < box.position.y + this.stepHeight)
      } else{
        intersections = boxes.filter(box =>  coords.x > box.position.x && coords.x < box.position.x + this.stepWidth)
      }
      return intersections
    },

    convertToPath(points) {
      return points.map(point => `${point.type}${point.coords[0]} ${point.coords[1]}`).toString().replace(/,/g, " ");
    },

    clearBoxDrag(event) {
      document.removeEventListener('mousemove', this.detectMousePosition)
      document.removeEventListener('mouseup', this.clearBoxDrag)

     // console.log({ event })
     // console.log({ box: this.draggedBox })
      let modifiedSteps = this.modifySteps(this.draggedBox.data.stepId, null, this.draggedBox.data.id, event);

      if (modifiedSteps.stepsModified) {
      //  console.log({ draggedBox: this.draggedBox })
        let connectedPaths = this.draggedBox.connectedArrows;

        if (connectedPaths && connectedPaths.length > 0) {
        //  console.log({ draggedBox: this.draggedBox, connectedPaths})
          let coords = {
            x: this.draggedBox.boxCoords.x,
            y: this.draggedBox.boxCoords.y
          }
          let alteredPaths = this.createNewConnectedPaths(coords, connectedPaths, modifiedSteps)
          this.visualizationPaths = [ ...this.visualizationPaths, ...alteredPaths ]
        }

        let stepBoxes = this.stages[modifiedSteps.index].steps;

      //  console.log({ paths: this.visualizationPaths })

        if (stepBoxes.length > 1) {
          this.modifyConnectors(modifiedSteps.index, modifiedSteps.stepIndex)
        }
      }
      this.draggingBox = false
    },

    createNewConnectedPaths(coords, connectedPaths, modifiedSteps) {
      return connectedPaths.map(path => {
        let startCoords, endCoords;

        let step = this.stages[modifiedSteps.index].steps[modifiedSteps.stepIndex];
        let boxCoords = step.coords;

        if (coords.x === path.startBox.boxCoords.x && coords.y === path.startBox.boxCoords.y) {
          startCoords = this.getCirclePosition(modifiedSteps.index, modifiedSteps.stepIndex, path.startBox.point);
          endCoords = this.getCirclePosition(path.data.index, path.data.stepIndex, path.data.circlePosition);

          path.startBox.boxCoords = boxCoords
          path.startBox.index = modifiedSteps.index;
          path.startBox.stepIndex = modifiedSteps.stepIndex;
          path.startBox.left = startCoords.x;
          path.startBox.top = startCoords.y;

        } else {
          startCoords = this.getCirclePosition(path.startBox.index, path.startBox.stepIndex, path.startBox.point);
          endCoords = this.getCirclePosition(modifiedSteps.index, modifiedSteps.stepIndex, path.data.circlePosition);

         // console.log({path, startCoords, endCoords})

          path.data.boxCoords = boxCoords
          path.data.index = modifiedSteps.index;
          path.data.stepIndex = modifiedSteps.stepIndex
        }

       // console.log({ coords, path, startCoords, endCoords })

        path.path = this.createConnectingPath(startCoords, endCoords).pathAttribute;

       // console.log({startBox: path.startBox})
        let circleCoords = {
          x: path.startBox.left,
          y: path.startBox.top
        }
        path.path = this.closePath(circleCoords, path.startBox.point, path.path)

        return path
      })
    },

    clearDrag(event) {
      document.removeEventListener('mousemove', this.detectArrowPosition);

      document.removeEventListener('mouseup', this.clearDrag)

      let visualizerPosition = this.$refs['visualizerSection'].getBoundingClientRect();
      let offsetX = Math.abs(event.clientX - visualizerPosition.left - this.dragPoint.left);
      let offsetY = Math.abs(event.clientY - visualizerPosition.top - this.dragPoint.top);

      let lastPathIndex = this.visualizationPaths.length -1
      if ((this.activeDropPoint.x == null && this.activeDropPoint.y == null) && !(offsetX < this.circleRadius && offsetY < this.circleRadius)) {
       this.visualizationPaths = this.visualizationPaths.filter((path, index) => index !== lastPathIndex)
      } else {

        if (this.visualizationPaths[lastPathIndex]) {
          let lastPath = this.visualizationPaths[lastPathIndex].path;
          let endBoxData = this.visualizationPaths[lastPathIndex].data;

          let stageIndex = endBoxData.index, stepIndex = endBoxData.stepIndex;


          //   console.log({ lastPath: stepStatus, stageIndex, stepIndex })
          this.visualizationPaths[lastPathIndex].status = this.stages[stageIndex].steps[stepIndex].status
          this.visualizationPaths[lastPathIndex].path =  this.closePath({ x: this.dragPoint.left, y: this.dragPoint.top}, this.dragPoint.point, lastPath)
        }

      }


      this.activeDropPoint = {
        x: null,
        y: null
      }
    },

    closePath(startCoords, circlePosition, existingPath) {
      let closedPath;
      let lastPath = existingPath
      let pointPosition = circlePosition
      if (pointPosition === 'left' || pointPosition === 'right') {
        let endPoint = pointPosition === 'left' ? startCoords.x + this.circleMargin : startCoords.x - this.circleMargin;
        let pathPrepend = [
          {
            type: 'M',
            coords: [endPoint, startCoords.y]
          },
          {
            type: 'L',
            coords: [startCoords.x, startCoords.y]
          }
        ]

        pathPrepend = this.convertToPath(pathPrepend);


        closedPath = `${pathPrepend} ${existingPath}`
      } else {
        //  console.log({ pointPosition })
        if (pointPosition === 'top' || pointPosition === 'bottom') {
          let endPoint = pointPosition === 'bottom' ? startCoords.y - this.circleMargin : startCoords.y + this.circleMargin;

          let pathStart = [
            {
              type: 'M',
              coords: [startCoords.x, endPoint]
            },
            {
              type: 'L',
              coords: [startCoords.x, startCoords.y]
            }
          ]

          pathStart = this.convertToPath(pathStart)
          closedPath = `${pathStart} ${lastPath}`
        }
      }

      return closedPath
      //    console.log({ lastPath, dragPoint: this.dragPoint })
    },

    startDrag(index, stepIndex, point, event) {
      event.stopPropagation()
      event.preventDefault()
    // console.log({ points: this.stages })
      let step = this.stages[index].steps[stepIndex];
      console.log({ step })
      let boxCoords = step.coords;

      console.log({ paths: this.visualizationPaths })
      let dragData = {
        left: this.getCirclePosition(index, stepIndex, point).x,
        top: this.getCirclePosition(index, stepIndex, point).y,
        index,
        stepIndex,
        point,
        boxCoords,
        pathId: Date.now() + Math.floor(Math.random() * 1000)
      };


      this.dragPoint = dragData

      console.log({ dragPoint: this.dragPoint, dragData })

      document.addEventListener('mousemove', this.detectArrowPosition);
      document.addEventListener('mouseup', this.clearDrag)
    }
  },

}
</script>

<style scoped lang="scss">
@import "../assets/css/visualization.scss";
</style>
