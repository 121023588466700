<template>
<div class="modal missing-source-modal"
     v-if="visible"
     :class="{
            'content-open': open
         }"
>
    <div :class="['modal-content padding-md br-mid']"

    >
      <div class="modal-header flexbox flex-align-center">
        <span class="modal-title">
          <span>Missing source costs for</span>
          <dropdown
              v-if="!show_all_sources"
              :name="select_options.ref"
              :ref="select_options.ref"
              :items="select_options.options"
              :defaultValue="select_options.default_value"
              @valueChange="changeActiveSource($event)"
          ></dropdown>
        </span>
        <i class="bx bx-x close-icon" @click="closeModal()"></i>
      </div>

      <div v-if="reportType === 'salesrep' || reportType === 'tag'" class="flexbox flex-align-center switch-to-source">
        <div class="flexbox flex-align-center">
          <Switch v-model:checked="switch_to_sources"/>
          <span>Switch to sources</span>
        </div>

        <div
            v-if="switch_to_sources"
            class="flexbox flex-align-center">
          <checkbox
              :checked="show_all_sources"
              @change="show_all_sources = $event"
              color="#5094DD"
          ></checkbox>
          <span>Show All</span>
        </div>

      </div>

      <div
          class="sales-sources"
          v-if="(report === 'salesrep' || report === 'tag') && !show_all_sources"
          >


        <div
            v-for="(data, index) in sales_rows"
            :key="index"
            class="source-data-item"
            :class="{'source-changed': this.source_changed}"
        >
          <header><b>Source: </b>{{ data.source }}</header>

          <div class="table-container">
            <q-table
                :rows="data.row_data"
                :columns="columns"
                row-key="name"
                :pagination="init_pagination"
            >
            </q-table>
          </div>
        </div>
      </div>

      <div class="table-container source-data" v-if="report === 'source' && !show_all_sources">
        <q-table
            :rows="rows"
            :columns="columns"
            row-key="name"
            :pagination="init_pagination"
        >
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td
                  :props="props"
                  v-for="col in props.cols"
                  :key="col.name"
                  :class="{'source-changed': this.source_changed}"
              >{{ col.value }}</q-td>
            </q-tr>
          </template>
        </q-table>

        <div v-if="activeSource && activeSource.key" class="text-center flexbox justify-center">
          <button
              class="button button-blue"
              @click="triggerEdit"
          >
            Add lead cost
          </button>
        </div>
      </div>

      <div
          class="sales-sources"
          v-if="report === 'source' && show_all_sources"
      >


        <div
            v-for="(data, index) in missing_source_data_all"
            :key="index"
            class="source-data-item"
        >
          <header><b>Source: </b>{{ data.source }}</header>

          <div class="table-container">
            <q-table
                :rows="data.rows"
                :columns="columns"
                row-key="name"
                :pagination="init_pagination"
            >
            </q-table>


          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import CustomDropDown from "./CustomDropDown";
import Switch from "./Switch";
import CustomCheckbox from "./CustomCheckbox";
import {capitalizeFirstLetters, generateKey} from "../helpers/helper_functions";

export default {
  name: "MissingSourceData",
  components: {
    'dropdown': CustomDropDown,
    Switch,
    'checkbox': CustomCheckbox
  },
  props: {
    missingSourceData: Array,
    index: Number,
    reportType: String,
    visible: {
      type: Boolean
    }
  },
  emits: [
      'update:visible',
      'closeModal'
  ],
  mounted() {
    document.addEventListener("click", this.handleClick)
    this.open = this.visible;
  },
  unmounted() {
    document.removeEventListener("click", this.handleClick)
  },
  data() {
    return {
      init_pagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 10
      },
      columns: [
        {
          name: 'from_date',
          label: 'From',
          field: 'from_date',
          align: 'left',
          sortable: true
        },


        {
          name: 'to_date',
          label: 'To',
          field: 'to_date',
          align: 'left',
          sortable: true
        }
      ],
      rows: [],
      select_options: this.generateDropdownOptions(this.missingSourceData),
      source_changed: false,
      sales_rep_missing_sources: [],
      missing_source_data_all: [],
      sales_rows: [],
      switch_to_sources: false,
      report: "",
      show_all_sources: false,
      open: false,
      activeSource: {},
      emitData: {
        modify: false,
        modifyType: '',
        data: null
      }
    }
  },
  watch: {
    switch_to_sources() {
      if (this.switch_to_sources) {
        this.report = 'source';

        const dropdown_options = this.generateDropdownOptions(this.sales_rep_missing_sources);
        let index = this.sales_rep_missing_sources[this.index] ? this.index : 0;
        const source_data = this.generateSourceRows(this.sales_rep_missing_sources[index]['missingCosts'])

        this.select_options = dropdown_options;
        this.rows = source_data;

      } else {
        this.report = 'salesrep';
        this.select_options = this.generateDropdownOptions(this.missingSourceData);
        this.show_all_sources = false
      }
    },

    visible(state) {
      setTimeout(() => {
        this.open = state

        if (state) {
          this.setSourceData()
        }
      }, 0)
    },
  },
  methods: {
    setSourceData() {
      this.report = this.reportType;
      this.select_options = this.generateDropdownOptions(this.missingSourceData);
      this.show_all_sources = false;
      this.switch_to_sources = false;
      this.emitData = {
        modify: false,
        modifyType: '',
        data: null
      }

      if (this.report === 'salesrep' || this.report === 'tag') {
        this.missingSourceData.forEach(item => {
          item.data.map(data => {
            let existingSources = this.sales_rep_missing_sources.filter(item => item.source === data.source).length;
            if (existingSources === 0) {
              data.key = generateKey();

              this.sales_rep_missing_sources.push(data)
            }
          });
        })

        this.sales_rep_missing_sources = this.sales_rep_missing_sources.map(data => {
          if (!data.source || data.source === '') {
            data.source = 'Unlabeled Source';
          }
          return data
        });

        this.missing_source_data_all = this.sales_rep_missing_sources.map(item => {
          return {
            source: capitalizeFirstLetters(item.source),
            rows: this.generateSourceRows(item['missingCosts'])
          }
        });

        this.sales_rows = this.generateSalesRows(this.index);
      } else {
        this.activeSource = this.missingSourceData[this.index];
        this.rows = this.generateSourceRows(this.activeSource.data);
      }
    },
    generateDropdownOptions(source_data) {
      let dataIndex = source_data[this.index] ? this.index : 0;
      const source_value = source_data && source_data[dataIndex] ? source_data[dataIndex].name || source_data[dataIndex].source : '';
      const select_data = source_data ? source_data.filter(data_item => (data_item.data && data_item.data.length > 0) || (data_item['missingCosts'] && data_item['missingCosts'].length > 0)) : [];

      if (this.report === 'source') {
        this.activeSource = source_data[dataIndex]
      }

      return {
        options: select_data.map(item => {
          const source_name = item.name || item.source;
          const source_value = !source_name ? 'Unlabeled Source' : source_name
          return {label: capitalizeFirstLetters(source_value), value: item.key}
        }),
        ref: 'source_select',
        value: capitalizeFirstLetters(source_value),
        default_value: capitalizeFirstLetters(source_value)
      }


    },

    generateSalesRows(index) {
     // console.log({ data: this.missingSourceData[index] })
      return this.missingSourceData[index].data.map(item => {
        let row_object = {};
        row_object['source'] = !item.source ? 'Unlabeled Source' : capitalizeFirstLetters(item.source);
        row_object['row_data'] = item['missingCosts'].map(missing_cost => {
          return {
              from_date: new Date(missing_cost['fromDateInclusive']).toDateString(),
              to_date: new Date(missing_cost['toDateInclusive']).toDateString()
           }
        });

        return row_object
      })
    },

    generateSourceRows(source_data) {
      return source_data.map(item => {
        return {
          from_date: new Date(item['fromDateInclusive']).toDateString(),
          to_date: new Date(item['toDateInclusive']).toDateString()
        }
      })
    },

    changeActiveSource(data) {
      let value = data.value
      this.source_changed = true
      let search_array = this.switch_to_sources ? this.sales_rep_missing_sources : this.missingSourceData;
      const selected_source = search_array.find(item => item.key === value);
      const value_index = search_array.indexOf(selected_source);

      if (this.report === 'source') {
        if (this.switch_to_sources) {
          this.rows = this.generateSourceRows(search_array[value_index]['missingCosts'])
        } else {
          this.rows = this.generateSourceRows(this.activeSource.data)
        }

        this.activeSource = selected_source;
      } else {
        this.sales_rows = this.generateSalesRows(value_index);
      }

      setTimeout(() => {this.source_changed = false}, 1000)
    },

    handleClick(event) {
      ///console.log(event.target.closest(".dropdown-menu"))
      if (event.target.closest(".modal-content") == null && event.target.closest(".dropdown-menu") == null && this.visible) {
       this.closeModal()
      }
    },

    findMissingSource(sourceKey) {
      let missingSource = this.missingSourceData.find(source => source.key === sourceKey);
      return missingSource ? missingSource.data.length : null
    },


    triggerEdit() {
      let source = this.activeSource;

      source['source'] = source.name || source.source

      this.emitData = {
        modify: true,
        modifyType: 'triggerLeadCostAdd',
        data: source
      }

      this.closeModal();
    },


    closeModal() {
      this.open = false;
      setTimeout(() => {
        this.$emit('update:visible', this.open)
        this.$emit('closeModal', this.emitData)
      }, 300)
    }
  }
}
</script>

<style scoped lang="scss">
.modal{
  overflow-y: auto;
  transition: ease-in-out .3s;
  background: rgba(150, 167, 186, .65);
  &.content-open{
    background: rgba(150, 167, 186, .85);
    .modal-content{
      opacity: 1;
      transform: scale(1);
    }
  }
  .modal-content{
    background: #FFFFFF;
    margin: 65px auto;
    max-height: calc(100% - 50px);
    opacity: 0;
    transition: ease-in-out .3s;
    transform: scale(.3);
    display: flex;
    flex-direction: column;



    .modal-header{
      justify-content: space-between;
      margin-bottom: 1.5rem;

      .modal-title{
        font-size: .9rem;
        color: var(--theme-blue-alt);
        display: flex;
        align-items: center;
        width: 75%;
        span{
          font-weight: 700;
          width: 50%;
        }

      }

      .close-icon{
        font-size: 24px;
      }
    }

    .dropdown-container{
      width: 50%;
      margin-top: 0;

      .dropdown-input{
        padding: 8px;
      }
    }

    .q-table__card{
      box-shadow: none;
    }


    .table-container{
      max-width: 100%;
      overflow-x: auto;
      padding: 1.2rem 0;

      .q-table{
        th{
          font-weight: 700;
          font-size: 14px;
        }
      }
    }

    .switch-to-source{
      margin-bottom: 1.5rem;
      justify-content: space-between;
      span{
        font-weight: 600;
        margin-left: .5rem;
        font-size: .8rem;
      }
    }

    .sales-sources{
      max-height: calc(75vh - 75px);
      margin-top: auto;
      overflow-y: auto;



      &::-webkit-scrollbar{
        background: rgba(80, 148, 221, .15);
        width: 4px;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb{
        background: var(--theme-blue-alt);
        border-radius: 4px;
      }

      .source-data-item{
          .q-table__middle{
            padding-left: 0;
          }
      }

      .q-table{
        thead {
          th{
            background: rgba(80, 148, 221, .05);
          }
        }
      }
    }


    .source-changed{
      animation: flicker .6s;
    }

    @keyframes flicker {
      0%{background: #FFFFFF; opacity: 0}
      25%{background: rgba(80, 148, 221, 0.15); opacity: .3}
      50%{background: rgba(80, 148, 221, 0.2); opacity: .6}
      75%{background: rgba(80, 148, 221, 0.15); opacity: .9}
      100%{background: rgba(80, 148, 221, 0.08); opacity: 1}
    }

    @-webkit-keyframes flicker {
      0%{background: #FFFFFF}
      25%{background: rgba(80, 148, 221, 0.15); opacity: .3}
      50%{background: rgba(80, 148, 221, 0.2); opacity: .6}
      75%{background: rgba(80, 148, 221, 0.15); opacity: .9}
      100%{background: rgba(80, 148, 221, 0.08); opacity: 1}
    }

    @-moz-keyframes flicker {
      0%{background: #FFFFFF}
      25%{background: rgba(80, 148, 221, 0.15); opacity: .3}
      50%{background: rgba(80, 148, 221, 0.2); opacity: .6}
      75%{background: rgba(80, 148, 221, 0.15); opacity: .9}
      100%{background: rgba(80, 148, 221, 0.08); opacity: 1}
    }

    @-o-keyframes flicker {
      0%{background: #FFFFFF}
      25%{background: rgba(80, 148, 221, 0.15); opacity: .3}
      50%{background: rgba(80, 148, 221, 0.2); opacity: .6}
      75%{background: rgba(80, 148, 221, 0.15); opacity: .9}
      100%{background: rgba(80, 148, 221, 0.08); opacity: 1}
    }



  }
  @media screen and (min-width: 990px) {
    .modal-content{
      width: 520px;
    }
  }
}
</style>

<style>
.missing-source-modal .q-table__card .q-table__middle{
  padding-left: 0;
}

.missing-source-modal .table-container .q-table__container .q-table__bottom{
  padding: 4px 0;
}
.missing-source-modal .table-container .q-table__container .q-table__bottom-item{
    margin-right: 0;
}

.missing-source-modal .modal-content .q-table__bottom .q-table__separator + .q-table__control{
    display: none;
}


.missing-source-modal .modal-content .q-select__dropdown-icon{
    display: none;
}

</style>
