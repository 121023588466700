<template>
  <div :class="['range-picker-group flexbox search-bar-group', { 'edit-mode': inEditMode }]">
    <div class="relative-position group-container">
        <div v-if="!hideDateRange" class="form-group date-group flexbox flex-align-center"
        >
            <label>Set date range:</label>
            <div
                    class="form-field date-field flexbox flex-align-center"
                    @click="show_date_picker = !show_date_picker"
            >
                <i class="bx bx-calendar-alt calendar-icon"></i>
                <div v-if="dateRange" class="date-range">{{ date_model && date_model.from ? date_model.from : '' }}  - {{ date_model && date_model.to ? date_model.to : '' }}</div>
                <i class="bx bx-x-circle cancel-icon" v-if="show_date_picker"></i>
            </div>
            <q-date
                    ref="date_picker"
                    class="date-range-picker"
                    v-if="show_date_picker"
                    v-model="date_model"
                    subtitle="Date range"
                    title="Select range for report"
                    @update:model-value="getDateValue($event)"
                    range>
                <template v-slot:default>
                    <div class="flexbox flex-align-center">
                        <div class="text-sm bold-text right-margin-sm">Presets</div>

                        <dropdown
                                :default-value="presets.value"
                                :items="presets.options"
                                @value-change="setPreset($event)"
                        >
                            <option
                                    v-for="(item, index) in presets.options"
                                    :key="index"
                                    :value="item.value"
                                    selected
                            >
                                {{ item.label }}
                            </option>
                        </dropdown>

                        <i class="bx bxs-check-circle date-check left-margin-sm"
                           @click="dateRange ? show_date_picker = false : null"></i>
                    </div>
                </template>
            </q-date>
        </div>

        <div class="select-holders">
            <div class="flexbox select-options">
                <div
                        class="form-group"
                        v-for="(field_item, index) in select_fields"
                        :key="index">

                    <dropdown
                            v-if="field_item.type === 'select' && !componentIsHidden(field_item.ref)"
                            :name="field_item.ref"
                            :ref="field_item.ref"
                            :items="field_item.options"
                            :key="field_item.key"
                            :searchable="field_item.ref === 'pipeline_select'"
                            :defaultValue="field_item.default_value ? field_item.default_value: ''"
                            @valueChange="setFieldValue($event, index)"
                    ></dropdown>
                </div>
            </div>
            <div class="generate-action-container text-right top-margin-sm">
                <button class="button generate-button button-blue-alt" @click="emitReportGeneration">Generate</button>
            </div>
        </div>
    </div>

      <edit-overlay :visible="inEditMode" v-model:hide="hideComponent" />
  </div>
</template>

<script>
import CustomDropDown from "./CustomDropDown";
import {getCurrentDate, getLastMonthDate, getNextDay, presetValues} from "../helpers/date_functions";
import {capitalizeFirstLetters, generateKey, replaceSlashes} from "../helpers/helper_functions";
import {useStore} from "../store/dataStore";
import EditOverlay from "./EditOverlay.vue";

export default {
  name: "DataGenerator",
  components: {
      EditOverlay,
    'dropdown': CustomDropDown
  },
  setup() {
    let dataStore = useStore();

    return {
      dataStore
    }
  },
  props: {
    hideDateRange: {
        type: Boolean,
        required: false,
        default: false
    },
      hideReportType: {
          type: Boolean,
          required: false,
          default: false
      },
    hidePipelines: {
        type: Boolean,
        required: false,
        default: false
    },
    locationId: {
      type: String,
      required: true
    },
    reportType: {
      type: String
    },
    pipeline: {
      type: String,
    },
    dateRange: {
      default: {}
    },
    preset: {
      type: String
    },
    hidden: {
        type: Boolean
    },
    inEditMode: {
        type: Boolean,
        required: false,
        default: false
    }
  },
  emits: [
      'dateModelChanged',
      'generateReport',
      'errorDetected',
      'pipelineGenerated',
      'update:dateRange',
      'update:reportType',
      'update:pipeline',
      'update:preset',
      'update:hidden'
  ],
  data() {
    return {
      date_model: {},
      show_date_picker: false,
      presets: {
        label: 'Date presets',
        value: 'No preset',
        options: [
          {
            label: 'No preset',
            value: null
          },
          {
            label: 'Last 7 days',
            value: 'last_7_days',
          },

          {
            label: 'Last 30 days',
            value: 'last_30_days'
          },

          {
            label: 'This month',
            value: 'this_month'
          },

          {
            label: 'Last month',
            value: 'last_month'
          }
        ]
      },
      select_fields: [
        {
          type: 'select',
          options: [
            {label: 'Salesrep', value: 'salesrep'},
            {label: 'Source', value: 'source'},
            {label: 'Tag', value: 'tag'}
          ],
          ref: "report_select",
          value: 'source',
          default_value: 'Source',
          key: generateKey()
        },

        {
          type: 'select',
          options: [
            {label: 'Select a pipeline', value: ''}
          ],
          ref: 'pipeline_select',
          value: '',
          default_value: '',
          key: generateKey()
        }
      ],
      preset_values: presetValues,
      hideComponent: false
    }
  },
  mounted() {
    // console.log({ range: this.dateRange, locationId: this.locationId })
    this.date_model = this.createDateModel(this.dateRange);

    if (this.preset) {
      this.presets.value = this.preset
    }

    if (this.reportType) {
        let value = this.reportType.toLowerCase();
        let label = capitalizeFirstLetters(value)
        this.updateSelectedOption('report_select', value, label)
    }

    if (this.locationId) {
      this.createPipelines();
    }
    document.addEventListener('click', this.detectOutsideDateClick)
  },
  unmounted() {
    document.removeEventListener('click', this.detectOutsideDateClick)
  },
  watch: {
    preset(value) {
      this.presets.value = !value ? 'No preset' : this.dataStore.getPresetRange(value) ? value : 'No preset'
    },

    show_date_picker(current) {
      // console.log({ showDatePicker: current })
      if (!current) {
        let dateModel = {
          from: getLastMonthDate(),
          to: getCurrentDate()
        }

        if (!this.dateRange) {
          this.date_model = dateModel;
          this.updateRange(dateModel)
        }
      }
    },

    locationId() {
      this.createPipelines()
    },

    dateRange: {
      deep: true,
      handler(range) {
        if (range && range.from && range.to) {
          let rangeModel = this.createDateModel(range)
          if (rangeModel.from !== this.date_model.from) {
            this.date_model.from = rangeModel.from
          }

          if (rangeModel.to !== this.date_model.to) {
            this.date_model.to = rangeModel.to
          }
        }
      }
    },

    pipeline(currentPipeline) {
      let pipelineName = '';

      this.select_fields.forEach(field => {
        if (field.ref === 'pipeline_select') {
          let pipelineData = field.options.find(option => option.value === currentPipeline);

          if (pipelineData) {
            pipelineName = pipelineData.label
          }
        }
      })

      this.updateSelectedOption('pipeline_select', currentPipeline, pipelineName)
    },

    reportType(type) {
      let value = type.toLowerCase();
      let label = capitalizeFirstLetters(value)
      this.updateSelectedOption('report_select', value, label)
    },

    hideComponent(state){
        this.$emit('update:hidden', state)
    },

    hidden(state) {
        this.hideComponent = state
    },

  inEditMode: {
      handler(state) {
          if (state) {
              this.hideComponent = this.hidden
          }
      },
      immediate: true
  }
  },
  methods: {
    componentIsHidden(componentRef) {
        let hidden = false;
        if (componentRef === 'pipeline_select') {
            hidden = this.hidePipelines
        }

        if (componentRef === 'report_select') {
            hidden = this.hideReportType
        }

        return hidden;
    },
    setPreset(option) {
      let optionValue = option.value;
      this.presets.value = option.label;

      this.$emit('update:preset', option.label)

      //console.log(this.preset_values[this.presets.value])
      if (optionValue) {
        let preset_object = this.preset_values[optionValue];
        let dateModel = {
          from:  preset_object.start,
          to: preset_object.end
        }

        this.date_model = dateModel;
        let date = new Date(dateModel.from);

        this.$refs.date_picker.setCalendarTo( date.getFullYear(), date.getMonth()+1 );
        this.updateRange(dateModel)

       // console.log({ optionValue })
      }
    },

    updateSelectedOption(optionRef, value, valueLabel) {
      this.select_fields = this.select_fields.map(field => {
        if (field.ref === optionRef) {
          field.value = value;
          field.default_value = valueLabel
        }
        return field
      })
    },
    createDateRange(model) {
      return {
        from: model && model.from ? replaceSlashes(model.from) : '',
        to: model && model.to ? replaceSlashes(model.to) : ''
      }
    },

    createDateModel(range) {
      return {
        from: range && range.from ? range.from.replace(/-/g, "/") : '',
        to: range && range.to ? range.to.replace(/-/g, "/") : ''
      }
    },
    detectOutsideDateClick(event) {
      if (!this.show_date_picker) {
        return
      }

      if (!event.target.closest('.date-field') && !event.target.closest('.date-range-picker')) {
        this.show_date_picker = false;
      }
    },

    toggleDatePicker() {
      this.show_date_picker = !this.show_date_picker;
    },

    setFieldValue(data, index) {
      this.select_fields[index].value = data.value;

    },

    emitReportGeneration() {
      let search_selectors = this.select_fields.filter(item => item.value !== '' && item.type === 'select');
      let setDateRange = this.dateRange && this.dateRange.from && this.dateRange.to
      if (search_selectors.length === 2 && setDateRange) {
        let report_details = {
          type: '',
          pipeline_id: '',
          start_date: this.dateRange.from,
          end_date: this.dateRange.to,
          pipeline_name: '',
          presets: this.preset
        }

        report_details['end_date'] = getNextDay(report_details['end_date'])

        search_selectors.map(item => {
          if ( item.ref === 'report_select' ) {
            report_details.type = item.value
          } else {
            report_details.pipeline_id = item.value;
            let pipeline_name;
            item.options.filter(option => option.value === item.value ? pipeline_name = option.label : null);

            report_details.pipeline_name = pipeline_name;
          }
        })

        if (report_details.type !== '' && report_details.pipeline_id !== '') {
          this.$emit('update:reportType', report_details.type);
          this.$emit('update:pipeline', report_details.pipeline_id);
          this.$emit('generateReport', report_details);
        }
      }
    },



    createPipelines() {
      let location_id = this.locationId;
      // console.log({ location_id })
      if (location_id) {
        // Default location id: 'j7wq94XmsDYIOK2KjQN9';



        this.dataStore.getPipelines(location_id).then(response => {
            let fetchedPipelines = response.data['pipelines']
            this.dataStore.addPipelines(location_id, fetchedPipelines);


          //  console.log(response)
          let item_index, pipeline_item;
          this.select_fields.forEach((item, index) => {
            if (item.ref === 'pipeline_select') {
              item_index = index;
              pipeline_item = {...item};
            }
          })

          fetchedPipelines.forEach((pipeline, index) => {
            if (index === 0) {
              pipeline_item['default_value'] = pipeline['name'];
              pipeline_item['value'] = pipeline['id'];
            }
            pipeline_item.options.push({label: pipeline['name'], value: pipeline['id']})
          });

          this.select_fields[item_index] = pipeline_item
          this.select_fields[item_index].options = [...this.select_fields[item_index].options];

          let pipelines  = this.select_fields.find(field => field.ref === 'pipeline_select');

          let pipelineOptions = [...pipelines.options], optionItems = pipelineOptions.slice(1);
          let intValues = [], regularValues = [];

          optionItems.forEach(field => {
            if (!isNaN(parseInt(field.label))) {
              intValues.push(field)
            } else {
              regularValues.push(field)
            }
          })


          if (intValues.length > 0) {
            intValues.sort((a, b) => parseInt(a.label[0]) - parseInt(b.label[0]));
          }
          pipelineOptions = [ pipelineOptions[0], ...intValues, ...regularValues ]
          let pipelineIndex = this.select_fields.indexOf(pipelines);
          this.select_fields[pipelineIndex].options = pipelineOptions;

          let firstPipelineItem = pipeline_item.options.find(option => option.value !== '')

          if (firstPipelineItem) {
            this.select_fields[item_index].default_value = firstPipelineItem.label
            this.select_fields[item_index].value = firstPipelineItem.value

            let pipeline_data = {
              name: firstPipelineItem['label'],
              id: firstPipelineItem['value'],
              location_id
            }


            this.$emit('pipelineGenerated', pipeline_data)
          } else {
              this.$emit('errorDetected', 'No pipelines found in this location')
          }
        }).catch((error) => {
       //   console.log('Pipeline error:', error);
          this.$emit('errorDetected', `Error fetching pipelines for current location ${ error.message ? ': '+error.message : ''}`);

          this.select_fields.filter(item => {
            if (item.ref === 'pipeline_select') {
              item.options[0].label = 'Error fetching your pipelines';
              item.options[0].value = '';
            }
          })
        })
      } else {
        this.$emit('errorDetected', 'No location detected.')
      }
    },

    getDateValue(value) {
      if (typeof value === 'string') {
        let dateObject = new Date(value);

        let nextMonth = dateObject.getMonth() + 1;
        let nextMonthDate = new Date(dateObject.getFullYear(), nextMonth, dateObject.getDate());
        let dateString = nextMonthDate.toISOString().slice(0, 10).replace(/-/g, "/");

        value = {
          from: value,
          to: dateString
        }

        this.date_model = value
      }

      this.presets.value = 'No preset';
      this.$emit('update:preset', this.presets.value)
      this.updateRange(value)
    },

    updateRange(value) {
      let dateRange = value ? this.createDateRange(value): null
      this.$emit('update:dateRange', dateRange)
      this.$emit('dateModelChanged', dateRange)
    }
  }
}
</script>

<style scoped>
.range-picker-group{
  flex-direction: column;
}

.range-picker-group.edit-mode .group-container{
    z-index: 2;
}

.range-picker-group .form-group{
  position: relative;
}

.range-picker-group .form-group .form-field{
  background: var(--white);
}

.range-picker-group .date-group .date-field{
  max-width: calc(100% - 10rem);
  margin-left: auto;
}

.range-picker-group .form-group .date-field{
  background: var(--white);
  cursor: pointer;
  position: relative;
}

.range-picker-group .form-group .date-field .date-range{
  margin-top: 4px;
}

.range-picker-group .form-group .date-field i{
  font-size: 22px;
}

.range-picker-group .form-group .date-field .calendar-icon{
  margin-right: .6rem;
}

.range-picker-group .form-group .date-field .cancel-icon{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;

}

.range-picker-group .form-group .date-range-picker{
  position: absolute;
  top: 100%;
  right: 15px;
  z-index: 999;
  cursor: pointer;
}


@media screen and (min-width: 1024px) and (max-width: 1270px) {
  .range-picker-group .form-group{
    flex-direction: column;
    align-items: flex-start;
  }

  .range-picker-group .date-group .date-field{
    margin-left: 0;
    max-width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .range-picker-group .form-group{
    flex-direction: column;
    align-items: flex-start;
  }

  .range-picker-group .date-group .date-field{
    margin-left: 0;
    max-width: 100%;
  }
}

.q-date__actions i{
    font-size: 22px;
    color: var(--theme-blue-alt);
}
</style>
