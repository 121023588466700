<template>
  <!--<nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>-->
  <navbar v-if="!hideNavigation" />
  <div class="flexbox app-main">
    <side-bar v-if="!hideNavigation"  :location="this.location_id" @currentState="sidebar_state = $event"></side-bar>
    <router-view class="dashboard-content-container"
                 :class="{
                      'sidebar-open': sidebar_state,
                      'sidebar-closed': !sidebar_state
    }" />
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar";
import NavigationHeader from "./components/NavigationHeader";

export default {
  name: 'App',
  components: {
    'side-bar': Sidebar,
    'navbar': NavigationHeader
  },
  mounted() {
    setTimeout(() => {
      let locationId = this.$route.params.locationId;
      let searchParams = new URLSearchParams(window.location.search);

     // console.log({ route: this.$route })
      if (!locationId && !searchParams.has('location_id') && this.$route.name !== '404 - Page Not Found' && this.$route.name !== 'Testing page') {
        this.hideNavigation = false
      } else {
        this.location_id = locationId || searchParams.get('location_id')
       // console.log({ locationId: this.location_id })
      }
    }, 0)

      function changeHeight() {
          window.parent.postMessage({
              'action': 'iframe-resize',
              'height': document.querySelector('body').offsetHeight
          }, '*')

      }

      changeHeight();
      const callback = (entries) => {
          for (const entry of entries) {
              if (entry.contentBoxSize) {
                  changeHeight();
              }
          }
      }

      let observer = new ResizeObserver(callback);
      observer.observe(document.body);

  },
  /*unmounted() {
    window.removeEventListener('load', this.changeHeight)
  },*/
  data() {
    return {
      location_id: '',
      sidebar_state: false,
      hideNavigation: true
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');
@import url('assets/css/base.css');

#app {
  font-family: var(--body-font-style);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app-main{
  max-width: 100%;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>
