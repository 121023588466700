<template>
    <Teleport to="body">
        <div class="modal"
             v-if="visible"
             :class="{
      'modal-open': open
     }"
        >
            <div class="modal-content metric-editor">
                <div class="modal-header">
                    <header class="header-text left-padding-sm">Edit Quick Metrics</header>
                    <i class="bx bx-x close-icon" @click="open = false"></i>
                </div>

                <div v-if="locationName && reportName" class="location-section">
                    <div class="location-label">
                        <b>{{ reportName }}</b>
                    </div>
                    <span class="data-separator"></span>
                    <div>
                        {{ locationName }}
                    </div>
                </div>


                <div class="search-container">
                    <div class="search-box">
                        <i class="bx bx-search-alt search-icon"></i>
                        <input type="text"
                               v-model="searchValue"
                               placeholder="Search for a metric"
                               class="form-field"
                        />
                    </div>
                </div>

                <div class="y-margin-sm metric-data">
                    <div class="data-grid" v-if="getFilteredList.length > 0">
                        <div
                            v-for="(item, index) in getFilteredList"
                            class="data-grid-item"
                            :key="index"
                        >
                            <div class="item-heading">
                                <div class="metric-options">
                                    <div class="metric-total" v-if="getTotalMetrics(item.field) > 1">
                                        <span> {{ getMetricIndex(index)  }}</span>
                                    </div>


                                    <button v-if="item.selected" @click="duplicateMetric(item)">
                                        <i class="bx bxs-duplicate"></i>
                                    </button>

                                    <button
                                        v-if="getTotalMetrics(item.field) > 1"
                                        @click="deleteMetric(item, $event)"
                                        class="close-icon"
                                    >
                                        <i class="bx bx-x"></i>
                                    </button>
                                </div>

                                <h4 class="heading">{{ item.label }}</h4>
                            </div>

                            <dropdown
                                :default-value="item.currentValue"
                                :items="options"
                                @value-change="modifyMetricData($event, item)"
                            />
                            <button
                                class="button full-width data-button"
                                :class="{
              'button-blue': !item.selected,
              'button-orange': item.selected
            }"
                                @click="modifySelectedMetrics(index, !item.selected)"
                            >
                                {{ item.selected ? 'Remove' : 'Add' }}
                            </button>
                        </div>
                    </div>

                    <div v-if="getFilteredList.length === 0 && searchValue.length > 0" class="flexbox flex-column flex-align-center flex-justify-center text-center full-height bold-text">
                        No metrics found.
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script>
import CustomDropDown from "./CustomDropDown";
import _ from "lodash";
import {v4 as uuidv4} from "uuid";
import { useStore } from "../store/dataStore";
import {computed} from "vue";

export default {
    name: "MetricsEditor",
    props: {
        visible: {
            type: Boolean
        },
        columns: {
            type: Array,
            required: true
        },
        options: {
            type: Array,
            required: true
        },
        reportType: {
            type: String,
            required: true
        },
        locationId: {
            type: String,
            required: true
        },
        reportId: {
            required: true,
            default: -1
        },
        reportName: {
            type: String,
            required: false
        }
    },
    components: {
        'dropdown': CustomDropDown
    },
    setup(props) {
        let store = useStore()
        return {
            store,
            overviewMetrics: computed(() => store.getStarredMetrics(props.reportType, props.locationId, props.reportId))
        }
    },
    emits: [
        'update:visible',
        'updateMetricValue',
        'updateMetricData'
    ],
    mounted() {
        this.open = this.visible;
        this.createEditorList();
        // console.log({ columnList: this.columnList })
        document.addEventListener('click', this.detectOutsideClick)
    },
    unmounted() {
        document.removeEventListener('click', this.detectOutsideClick)
    },
    computed: {
        getFilteredList() {
            return this.columnList.filter(column => column.label.toLowerCase().includes(this.searchValue.toLowerCase()))
        },

        selectedMetrics() {
            return this.columnList.filter(column => column.selected).map(column => ( { field: column.field, value: column.currentValue, label: column.label } ));
        },
        locationName() {
            return this.store.getLocationName(this.locationId)
        }
    },
    watch: {
        visible(state) {
            setTimeout(() => {
                this.open = state
            }, 0)
        },
        open(state) {
            if (!state) {
                setTimeout(() => {
                    this.searchValue = ''
                    this.$emit('update:visible', false)
                }, 300)
            }
        },
        columns: {
            deep: true,
            handler(data) {
                if (data.length === 0) {
                    this.open = false
                }

                this.checkForDataChange()
            }
        },
        overviewMetrics: {
            deep: true,
            handler() {
                // console.log({ data })
                this.checkForDataChange()
            }
        }
    },
    data() {
        return {
            open: false,
            columnList: [],
            searchValue: ''
        }
    },
    methods: {
        checkForDataChange() {
            this.createEditorList()
        },

        addDefaultValues(columns) {
            return columns.map(column => {
                return  {
                    ...column,
                    currentValue: column.value ? column.value : this.options.length > 0  ? this.options[0].label : ''
                }
            })
        },

        getColumns(columnList) {
            let columns = [];

            columnList.forEach(column => {
                if (columns.indexOf(column.field) === -1) {
                    columns.push(column.field)
                }
            });

            return columns
        },

        createEditorList() {
            let columns = [];
            let currentList = _.cloneDeep(this.columnList);
            // console.log({ columns: this.columns, overviewMetrics: this.overviewMetrics })

            if (this.columnList.length === 0) {
                currentList = this.columns.map(column => this.createColumnData(column))
            }

            columns = this.getColumns(currentList);
            if (columns.length !== this.columns.length) {
                currentList = this.columns.map(column => this.createColumnData(column));
                columns = this.getColumns(currentList)
            }


            // console.log({ columnData: this.columns, currentList, columns })

            let duplicatedColumns = columns.filter(column => this.columnList.filter(item => item.field === column).length > 1);
            let columnList = [];

            if (duplicatedColumns.length === 0 && this.overviewMetrics.length > 0) {
                let overviewMetricFields = [];
                this.overviewMetrics.forEach(metric => {
                    if (overviewMetricFields.indexOf(metric.column_name) === -1) {
                        overviewMetricFields.push(metric.column_name)
                    }
                })

                duplicatedColumns = overviewMetricFields.filter(field => this.overviewMetrics.filter(metric => metric.column_name === field).length > 1)
            }

            this.columns.forEach(column => {
                if (duplicatedColumns.indexOf(column.field) === -1) {
                    let dataColumn = currentList.find(listItem => listItem.field === column.field)
                    let metricColumn = this.createColumnData(column);

                    if (dataColumn && dataColumn.id) {
                        metricColumn.id = dataColumn.id
                    }

                    columnList.push(metricColumn)
                } else {
                    let columns = existingColumns(columnList, column.field);
                    let fieldColumns = existingColumns(currentList, column.field);
                    let metrics = this.overviewMetrics.filter(metric => metric.column_name === column.field);

                    if (columns.length === 0 && fieldColumns.length > 0) {
                        if (metrics.length > 0) {
                            if (metrics.length > fieldColumns.length) {
                                let updatedColumns = [];
                                metrics.forEach((metric, metricIndex) => {
                                    if (fieldColumns[metricIndex]) {
                                        updatedColumns.push(fieldColumns[metricIndex])
                                    } else {
                                        updatedColumns.push({
                                            ...fieldColumns[0],
                                            selected: true,
                                            value:  metric && metric.selected ? metric.selected.label : null,
                                            id: uuidv4()
                                        })
                                    }
                                })

                                fieldColumns = updatedColumns
                            }
                        } else {
                            fieldColumns = [ fieldColumns[0] ]
                        }

                        fieldColumns.forEach((fieldColumn, fieldIndex) => {
                            let metric = metrics[fieldIndex];
                            columnList.push({
                                label: fieldColumn.label,
                                field: fieldColumn.field,
                                selected: !!metric,
                                value: metric && metric.selected ? metric.selected.label : null,
                                id: fieldColumn && fieldColumn.id ? fieldColumn.id : uuidv4()
                            })
                        })

                    }
                }
            })

            columnList = this.addDefaultValues(columnList);
            this.columnList = columnList;
            function existingColumns(columns, field) {
                return columns.filter(column => column.field === field)
            }
        },

        createColumnData(column) {
            let metricValue = this.overviewMetrics.find(metric =>  metric.column_name === column.field)
            return {
                label: column.label,
                field: column.field,
                selected: !!metricValue,
                value: metricValue && metricValue.selected ? metricValue.selected.label : null,
                id: uuidv4()
            }
        },



        modifySelectedMetrics(index, value) {
            let metrics = _.cloneDeep(this.getFilteredList);

            this.columnList = this.columnList.map(column => {
                if (column.id === metrics[index].id) {
                    column.selected = value
                }
                return column
            })

            this.$emit('updateMetricData', this.selectedMetrics)
        },

        modifyMetricData(event, item) {
            item.currentValue = event.label;

            if (item.selected) {
                this.$emit('updateMetricData', this.selectedMetrics);
            }
        },

        detectOutsideClick(event) {
            if (!event.target.closest('.metric-editor')) {
                this.open = false;
            }
        },

        getTotalMetrics(metric) {
            return this.columnList.filter(column => column.field === metric).length
        },

        getMetricIndex(index) {
            let metric = this.getFilteredList[index]
            let slicedData = this.getFilteredList.slice(0, index);
            let existingMetrics = slicedData.filter(item => item.field === metric.field);

            return existingMetrics.length + 1
        },

        duplicateMetric(metric) {
            let metricColumns = [];

            let duplicatedMetric = _.cloneDeep(metric);
            duplicatedMetric.id = uuidv4();

            this.columnList.forEach(item => {
                metricColumns.push(item);

                if (item.id === metric.id) {
                    metricColumns.push(duplicatedMetric)
                }
            })

            this.columnList = metricColumns;
            if (metric.selected) {
                this.$emit('updateMetricData', this.selectedMetrics)
            }
        },

        deleteMetric(metric, event) {
            event.stopPropagation();
            event.preventDefault();
            this.columnList = this.columnList.filter(item => item.id !== metric.id);

            if (metric.selected) {
                this.$emit('updateMetricData', this.selectedMetrics)
            }

        }
    }
}
</script>

<style scoped>
.modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: ease-in-out .2s;
    backdrop-filter: blur(0);
}

.modal.modal-open{
    backdrop-filter: blur(2px);
}

.modal .modal-content{
    transition: ease-in-out .2s;
    opacity: 0;
    transform: scale(1.05);
    background: #F7F7F7;
}

@media screen and (min-width: 1024px) {
    .modal .modal-content{
        width: 990px;
    }
}

.modal.modal-open .modal-content{
    opacity: 1;
    transform: scale(1);
}

.modal-header .header-text{
    font-size: 1rem;
    margin-top: .5rem;
}

.metric-data{
    overflow-y: auto;
    white-space: nowrap;
    height: calc(75vh - 50px);
    padding: .6rem .5rem;
    max-height: calc(100% - 125px);
}

.metric-data::-webkit-scrollbar{
    height: 4px;
    width: 4px;
    background: rgba(80, 148, 221, .08);
    border-radius: 8px;
}

.metric-data::-webkit-scrollbar-thumb{
    background: var(--theme-blue-alt);
    border-radius: 4px;
}

.data-grid{
    display: flex;
    flex-wrap: wrap;
}

.data-grid .data-grid-item{
    background: var(--white);
    padding: .5rem;
    border-radius: 12px;
    box-shadow: 0 0 1px 1px rgba(80, 148, 221, .03),
    0 0 1px 3px rgba(80, 148, 221, .06);
    display: flex;
    flex-direction: column;
    min-height: 150px;
    flex: 0 0 auto;
}
@media screen and (min-width: 1024px) {
    .modal .modal-content{
        max-width: 990px;
    }
}

@media screen and (min-width: 1200px) {
    .data-grid .data-grid-item{
        width: calc(25% - .7rem);
    }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .data-grid .data-grid-item{
        width: calc(33% - .7rem);
    }
}

@media screen and (min-width: 750px) and (max-width: 1024px) {
    .data-grid .data-grid-item{
        width: calc(50% - .7rem);
    }
}

@media screen and (min-width: 750px) {
    .data-grid .data-grid-item {
        margin: 0 .35rem 1rem;
    }
}

@media screen and (max-width: 749px) {
    .data-grid .data-grid-item{
        width: 100%;
        max-width: 400px;
        margin: 0 auto 1rem;
    }
}

.data-grid-item .item-heading{
    margin: 0 0 1rem;
    display: flex;
    flex-direction: row-reverse;

}

.data-grid-item .item-heading .heading{
    margin: 0;
    font-size: .85rem;
    line-height: 1.2rem;
    font-weight: 600;
    white-space: initial;
    padding: .1rem 0;
}

.data-grid-item .item-heading .metric-options{
    display: flex;
    margin-left: auto;
}

.data-grid-item .item-heading .metric-options:empty + .heading{
    width: 100%;
}

.data-grid-item .item-heading .metric-options:not(:empty) + .heading {
    width: calc(65% - 1rem);
}

.metric-options .metric-total{
    margin-right: .5rem;
    padding-top: .07rem;
}

.metric-options .metric-total span{
    background: var(--theme-blue-alt);
    color: var(--white);
    font-weight: 700;
    font-size: .75rem;
    padding: .1rem .3rem;
    /*width: 20px;
    height: 20px;
    display: block;*/
    text-align: center;
    border-radius: 4px;
}

.metric-options button{
    margin: 0;
    padding: 0;
    border: 1px solid transparent;
    background: transparent;
    display: inline-block;
    font-size: 18px;
    color: var(--theme-blue-alt);
    height: 20px;
    width: 20px;
    cursor: pointer;
    transition: ease-in-out .15s;
}

.metric-options button:hover{
    transform: scale(1.1);
    opacity: .6;
}

.metric-options button.close-icon {
    margin-left: .3rem;
}


.data-grid-item .dropdown-container{
    margin-top: auto;
}

.data-grid-item button.data-button{
    margin-top: .6rem;
    border-radius: 17px;
    padding: 6px 18px;
    text-align: center;
}

.data-grid-item button.button-blue:hover{
    background: var(--theme-blue);
    color: var(--white);
    opacity: .5;
}

.search-container{
    margin: .5rem 0 1rem;
}

.search-container .search-box{
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 600px;
}

.search-container .search-box input{
    background: var(--white);
    padding: 6px 32px;
    margin-top: 0;
}

.search-container .search-box .search-icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
    font-size: 18px;
    color: var(--theme-blue-alt);
}

.location-section{
    color: var(--black);
    font-size: .8rem;
    justify-content: flex-end;
    margin-top: .75rem;
    padding: 0 .4rem .4rem;
}

.location-section .data-separator{
    background: var(--black);
}
</style>
