<template>
 <div :class="['template-container full-width full-height', { visible: visible === undefined ? true : visible }]">
     <div class="flex align-items-center select-section y-padding-sm">
         <div v-if="templates.length > 0" class="flex select-all flex-align-center">
             <checkbox
                 color="#5094DD"
                 :checked="selectAll"
                 @change="toggleSelectAll($event)"
             />

             <label class="field-label check-label">
                 Select All
             </label>
         </div>

         <div class="performable-actions" v-if="selectedTemplates.length > 0">
             <div class="bulk-action-section flex flex-align-center">
                 <label class="field-label right-margin-sm">Bulk actions:</label>
                 <dropdown
                     :items="bulkActions.options"
                     :default-value="bulkActions.defaultValue"
                     :searchable="false"
                     class="select-list"
                     @value-change="getSelectedAction($event)"
                 />
             </div>
         </div>
     </div>

     <div class="template-data-section" v-if="templateList.length > 0">
         <div class="y-padding-sm search-section">
             <div class="search-bar">
                 <i class="bx bx-search-alt search-icon"></i>
                 <input
                     type="text"
                     class="form-field"
                     v-model="query"
                     placeholder="Search for a template"
                     @input="filterTemplates($event)"
                 >
             </div>
         </div>
     </div>

     <div v-if="templates.length === 0" class="empty-template-section full-width flexbox flex-column flex-justify-center flex-align-center text-center">
         <div class="section-data">
             {{ !query ?
             'There are no templates available in this location. You can try making a saved report into a template.' : 'No templates found' }}
         </div>
     </div>

     <div class="template-view" v-if="templates.length > 0">
        <div v-if="topLevelTemplates.length > 0 && agencyLocation" class="template-type-section">
            <h3 class="heading">Top level templates</h3>
            <div class="template-section">
                <div v-for="item in topLevelTemplates"
                     :key="item.template.id"
                     :class="['template-item shadowed', {'selected': item.selected }]"
                >
                    <div class="bottom-margin-md flex flex-align-center">
                        <div class="name-data flex flex-align-center">
                            <checkbox
                                color="#5094DD"
                                :checked="item.selected"
                                @change="detectChange($event, item)"
                                class="data-checkbox"
                            />
                            <h5 class="report-name bold-text"> {{ item.template['templateName'] }}</h5>
                        </div>

                        <options-list
                            v-if="adminUser"
                            :options="item.optionsList"
                            :option-class="optionClass"
                            @select="performAction($event, item)"
                        />
                    </div>

                    <div class="report-image">
                        <div class="video-placer" v-if="item.template['videoUrl']">
                            <img alt="template thumbnail"
                                 :src="item.template['imageUrl']"
                                 @load="item.imageLoaded = true"
                                 v-show="item.imageLoaded"
                                 class="cover-image"
                            />
                            <button class="play-button"
                                    @click="setActiveVideo(item.template)"
                            >
                                <img alt="play icon"
                                     :src="playButton"
                                />
                            </button>
                        </div>


                        <i class="bx bxs-dashboard reload-icon" v-if="!item.template['videoUrl']"></i>
                    </div>

                    <div class="button-section">
                        <button :class="[ 'button view-btn', {
                              'button-blue': !item.state.loadError,
                              'button-red': item.state.loadError
                             }]"
                                @click="selectTemplate(item)"
                        >
                            {{ item.state.buttonText }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

         <div v-if="locationTemplates.length > 0" class="template-type-section">
             <h3 class="heading" v-if="agencyLocation">Location templates</h3>
             <div class="template-section">
                 <div v-for="item in locationTemplates"
                      :key="item.template.id"
                      :class="['template-item shadowed', {'selected': item.selected }]"
                 >
                     <div class="bottom-margin-md flex flex-align-center">
                         <div class="name-data flex flex-align-center">
                             <checkbox
                                 color="#5094DD"
                                 :checked="item.selected"
                                 @change="detectChange($event, item)"
                                 class="data-checkbox"
                             />
                             <h5 class="report-name bold-text"> {{ item.template['templateName'] }}</h5>
                         </div>

                         <options-list
                             v-if="agencyLocation"
                             :options="item.optionsList"
                             :option-class="optionClass"
                             @select="performAction($event, item)"
                         />
                     </div>

                     <div class="report-image">
                        <i class="bx bxs-dashboard reload-icon"></i>
                     </div>

                     <div class="button-section">
                         <button :class="[ 'button view-btn', {
                                      'button-blue': !item.state.loadError,
                                      'button-red': item.state.loadError
                             }]"
                                 @click="selectTemplate(item)"
                         >
                             {{ item.state.buttonText }}
                         </button>
                     </div>
                 </div>
             </div>
         </div>
     </div>

     <template-editor
         :template="templateEditor.template ? templateEditor.template : {}"
         v-model:visible="templateEditor.visible"
         @complete="getUpdatedTemplate($event)"
     />

     <template-deleter
         :templates="templateDeleter.templates"
         v-model:visible="templateDeleter.visible"
         @close="clearSelection()"
         @complete="refreshList()"
     />

     <video-window
         v-model:visible="activeVideo.visible"
         :video-link="activeVideo.videoUrl"
         :cover-image-link="activeVideo.imageUrl"
         :auto-play="activeVideo.autoPlay"
     >
         <template #header>
             {{ capitalizeText(activeVideo.templateName) }} Usage Guide
         </template>

         <template #body>
             <p v-if="activeVideo.description">
                 {{ activeVideo.description }}
             </p>
         </template>
     </video-window>
 </div>
</template>

<script>
import {useStore} from "../store/dataStore";
import checkbox from "./CustomCheckbox.vue";
import OptionsList from "./OptionsList.vue";
import TemplateEditor from "./TemplateEditor.vue";
import TemplateDeleter from "./TemplateDeleter.vue";
import dropdown from "./CustomDropDown.vue";
import {computed} from "vue";
import playIcon from "../assets/icons/play.svg"
import VideoWindow from "./VideoWindow.vue";
import {capitalizeFirstLetters} from "../helpers/helper_functions";
import axios from "axios";
export default {
    name: "TemplateSection",
    components: {
        VideoWindow,
        dropdown,
        TemplateDeleter,
        TemplateEditor,
        OptionsList,
        checkbox
    },
    setup() {
        return {
            store: useStore(),
            reportTemplates: computed(() => useStore().reportTemplates)
        }
    },
    props: {
        visible: {
            type: Boolean
        },
        agencyLocation: {
            type: Boolean,
            required: false,
            default: false
        },
        adminUser: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'templateSelect',
        'delete',
        'templateUpdate'
    ],
    watch: {
        visible(state) {
            if (state) {
                this.templateList.forEach(item => {
                    if (!this.templates.find(templateItem => templateItem.template.id === item.template.id)) {
                        this.templates.push(item)
                    }
                })
            }
        }
    },
    data() {
        return {
            query: '',
            templates: [],
            selectAll: false,
            options: [
                {
                    label: 'Edit template',
                    action: 'edit'
                },
                {
                    label: 'Delete template',
                    action: 'delete'
                }
            ],

            templateEditor: {
                visible: false,
                template: null
            },
            templateDeleter: {
                visible: false,
                templates: []
            },
            bulkActions: {
                defaultValue: '',
                options: [
                    { label: 'Delete', value: 'delete' }
                ]
            },
            playButton: playIcon,
            activeVideo: {
                videoUrl: '',
                imageUrl: '',
                templateName: '',
                description: '',
                visible: false,
                autoPlay: false
            }
        }
    },
    mounted() {
        this.templates = this.templateList
    },
    computed: {
        templateList() {
            return [...this.store.reportTemplates].map(template => {
                return {
                    template,
                    selected: false,
                    optionsList: [...this.options],
                    imageLoaded: false,
                    state: {
                        loading: false,
                        loadError: false,
                        buttonText: 'Use template',
                        errorText: ''
                    }
                }
            })
        },
        selectedTemplates() {
            return this.templates.filter(template => template.selected)
        },

        topLevelTemplates() {
            return this.templates.filter(templateItem => templateItem.template['isTopLevel'])
        },

        locationTemplates() {
            return this.templates.filter(templateItem => !templateItem.template['isTopLevel'])
        }
    },
    methods: {
        axios,
        capitalizeText(string) {
            return capitalizeFirstLetters(string)
        },

        setActiveVideo(template) {
            let { videoUrl, imageUrl, templateName, description } = template;

            // console.log({ template, description })
            this.activeVideo = {
                ...this.activeVideo,
                templateName,
                videoUrl: videoUrl ? videoUrl : '',
                imageUrl: imageUrl ? imageUrl : '',
                description,
                visible: true
            }
        },

        performAction(event, item) {
            let { action } = event;
            switch (action) {
                case 'edit':
                    this.templateEditor = {
                        ...this.templateEditor,
                        visible: true,
                        template: item.template
                    }
                    break
                case 'delete':
                    this.templateDeleter = {
                        ...this.templateDeleter,
                        visible: true,
                        templates: [ item.template ]
                    }
                    break
            }
        },
        optionClass(option) {
            return { 'delete-text': option.action === 'delete' }
        },

        getUpdatedTemplate(updatedTemplate) {
            this.templates = this.templates.map(item => {
                if (item.template.id === updatedTemplate.id) {
                    item.template = updatedTemplate
                }
                return item
            })

            this.$emit('templateUpdate', updatedTemplate)

            this.templateEditor = {
                ...this.templateEditor,
                visible: false,
                template: null
            }
        },

        toggleSelectAll(selected) {
            this.selectAll = selected;

            this.templates = this.templates.map(item => {
                item.selected = selected
                return item
            })
        },

        detectChange(selected, item) {
            item.selected = selected;
            this.selectAll = this.templates.filter(item => item.selected).length === this.templates.length
        },

        getSelectedAction(selection) {
            let selectedValue = selection.value;
            let templates = this.selectedTemplates.map(item => item.template);

            this.bulkActions.defaultValue = selection.label;

            switch (selectedValue) {
                case 'delete':
                    this.templateDeleter = {
                        templates,
                        visible: true
                    }
                    return
            }
        },

        clearSelection() {
            this.bulkActions.defaultValue = ''
        },

        refreshList() {
            let deletedTemplateIds = this.templateDeleter.templates.map(template => template.id);
            let deletedTemplates = [...this.templateDeleter.templates]
            this.$emit('delete', deletedTemplates)
            this.templates = this.templates.filter(item => deletedTemplateIds.indexOf(item.template.id) === -1)
            this.selectAll = false;
        },


        filterTemplates(event) {
            let searchValue = event.target.value;
            this.templates = this.templateList.filter(item => item.template['templateName'].toLowerCase().includes(searchValue.toLowerCase()))
        },

        selectTemplate(item) {
            let template = item.template;
            this.$emit('templateSelect', template)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/reports-templates.scss";
@import "../assets/css/template-section.scss";
</style>
