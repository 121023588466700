<template>
  <div :class="['process-sticker',
                {
                    'visible': visible,
                    'minimized': visible && minimized,
                    'expanded': visible && !minimized
                }]">
      <div class="flex flex-align-center process-section">
          <button class="toggle-btn" @click="minimized = !minimized">
              <i :class="['bx arrow-icon', {
                  'bx-chevron-right': !minimized,
                  'bx-chevron-left': minimized
              }]"></i>
          </button>

<!--          <q-spinner-dots class="dot-spinner"/>-->
          <div class="process-percentage"
               :style="{
                  background: `conic-gradient(rgb(255, 255, 255) ${gradient}deg, rgba(36, 88, 141) ${gradient}deg)`
               }"
          >
              <div class="main-box">
                  {{ percentage }}%
              </div>
          </div>

          <q-spinner-dots class="dot-spinner"/>

      </div>

<!--      <div class="process-text processed-data">
        Processed {{ store.autoCreateState.processed }} of {{ store.autoCreateState.total }}
      </div>-->

  </div>
</template>

<script>
import {useStore} from "../store/dataStore";
import {computed} from "vue";

export default {
    name: "AutoCreateProcess",
    props: {
        visible: {
            type: Boolean
        }
    },
    setup() {
        let store = useStore();
        return {
            store,
            processed: computed(() => store.autoCreateState.processed),
            total: computed(() => store.autoCreateState.total)
        }
    },
    data() {
        return {
            minimized: true,
            percentage: 0,
            gradient: 0
        }
    },
    emits: [
        'update:visible'
    ],
    computed: {
        valueDivision() {
            let division = this.processed / this.total;
            return isNaN(division) ? 0 : division;
        },
        gradientDegree() {
            return this.valueDivision * 360
        },

        gradientPercentage() {
            return this.valueDivision * 100
        }
    },
    watch: {
        gradientDegree(value) {
            this.increaseValue(value, 360, 'gradient')
        },

        gradientPercentage(value) {
            this.increaseValue(value, 100, 'percentage', 10)
        }
    },
    methods: {
        increaseValue(value, max, componentKey, interval = 0) {
            if (value < 0 || value > max) {
                return
            }

            let component = this;
            increment();

            function increment() {
                if (component[componentKey] >= value) {
                    return
                }

                component[componentKey] += 1;
                setTimeout(() => {
                    increment()
                }, interval)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.process-sticker{
    position: fixed;
    top: 75px;
    width: 140px;
    border-radius: 12px 0 0 12px;
    background: var(--theme-blue-alt);
    padding: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: ease-in-out .3s;
    z-index: 9998;
    box-shadow: 0 0 2px 2px rgba(80, 148, 221, .15), 0 0 2px 4px rgba(80, 148, 221, .25);

    &:not(.visible) {
      right: -140px;
    }

   &.visible{
     &.expanded{
       right: 0;
     }

     &.minimized{
       right: -100px;
     }

   }
    .process-text{
      color: var(--white);
      font-weight: 600;
      font-size: .75rem;
      line-height: .85rem;

      &.processed-data{
        width: calc(100% - 50px);
        margin-left: auto;
        text-align: right;
      }
    }

    .toggle-btn{
      margin: 0 .3rem 0 0;
      border: 1px solid transparent;
      background: transparent;
      padding: 0;
      display: flex;
      align-items: center;
      transition: ease-in-out .3s;

      &:hover{
        cursor: pointer;
        opacity:  .5;
      }

      .arrow-icon{
        color: var(--white);
        font-size: 20px;
      }
    }

  .dot-spinner{
    color: var(--white);
    font-size: 20px;
    margin-left: .3rem;
  }

    .process-section{
      justify-content: space-between;
      width: 100%;

      .process-percentage{
        width: 55px;
        height: 55px;
        border-radius: 50%;
        background: var(--white);
        position: relative;
        transition: ease-in-out .2s;

        .main-box{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: calc(100% - 6px);
          height: calc(100% - 6px);
          background: var(--theme-blue-alt);
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: var(--white);
          font-size: .85rem;
          font-weight: 700;
        }
      }
    }
}
</style>
