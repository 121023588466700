<template>
<div class="test-view">
    <div class="video-section">
        <video-player
            :video-link="videoLink"
            :cover-image-link="coverImage"
        />
    </div>

</div>
</template>

<script>
import VideoPlayer from "../components/VideoPlayer.vue";
import logo from "../assets/logo.png";
export default {
    name: "TestView",
    components: {
        VideoPlayer
    },
    data() {
        return {
            videoLink: require('../videos/sales_analysis.mp4'),
            //videoLink: 'https://player.vimeo.com/video/796420981?h=c516de3a18',
            coverImage: logo
        }
    }
}
</script>

<style scoped>
.test-view{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.video-section{
    width: 500px;
    height: 400px;
}
</style>
