<template>
  <div class="save-checkmark"
       ref="saveCheckmark"
       :style="{
        '--checkmark-size': size + 'px',
        '--checkmark-color': color,
        '--border-width': borderWidth,
        'background': `conic-gradient(${color} ${degree}deg, rgba(255, 255, 255, .1) ${degree}deg)`
      }">
    <div class="save-box">
      <i class="bx bx-check check-icon"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckMark",
  props: {
    size:{
      type: Number,
      default: 50
    },
    color: {
      type: String,
      default: '#408c67'
    },
    borderWidth: {
      type: String,
      default: '.5rem'
    }
  },
  mounted() {
   this.rotateCircle()
  },
  data() {
    return {
      degree: 0,
      incrementInterval: 0
    }
  },
  watch: {
    animationDuration() {
      this.rotateCircle()
    }
  },
  methods: {
    rotateCircle() {
      this.degree = 0;

      window.clearInterval(this.incrementInterval)

      this.incrementInterval = window.setInterval(() => {
        if (this.degree >= 360) {
          window.clearInterval(this.incrementInterval)
          return
        }
        this.degree += 2
      }, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
:root{
  --checkmark-size: 50px;
  --border-width: .5rem;
  --checkmark-color: rgb(39, 174, 96)
}
.save-checkmark{
  width: var(--checkmark-size);
  height: var(--checkmark-size);
  display: flex;
  position: relative;
  border-radius: 50%;
  transition: ease .3s;

  .save-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - var(--border-width));
    height: calc(100% - var(--border-width));
    background: var(--white);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .check-icon{
      font-size: calc(var(--checkmark-size) - 15px);
      color: var(--checkmark-color);
    }
  }
}

</style>
