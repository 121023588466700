<template>
  <q-dialog :class="['report-creator-dialog']" position="top" v-model="display">
    <div class="dialog-box report-dialog-box shadowed">
      <div class="modal-header">
        <h4 class="header-text">{{ header }}</h4>
        <i class="bx bx-x close-icon" @click="closeDialog()"></i>
      </div>

      <div class="form-group">
        <label class="field-label">{{ field.label }}</label>

        <input
            :type="field.type"
            :placeholder="field.placeholder"
            :class="['form-field', { 'input-error' : field.hasError }]"
            v-model="field.value"
            @input="checkForExistingReport($event, field)"
        />

        <span :class="[ 'error-text', { 'error-visible': field.hasError && field.errorText }]">
                    {{ field.errorText }}
        </span>
      </div>

      <div class="error-section error-text" v-if="state.createError && state.errorText">
        {{ state.errorText }}
      </div>


      <div class="action-section">
        <button :disabled="state.creating || foundExistingErrors()"
                class="button button-blue-alt"
                @click="performReportAction()"
        >
          {{ state.buttonText }}
        </button>

        <button @click="closeDialog()" class="button button-red-transparent">
          Cancel
        </button>
      </div>
    </div>
  </q-dialog>
</template>

<script>
import {useStore} from "../store/dataStore";
import _ from "lodash";

export default {
  name: "ReportEditor",
  props: {
    visible: {
      type: Boolean
    },
    pipeline: {
      type: String,
      required: true
    },
    location: {
      type: String,
      required: true
    },
    reportType: {
      type: String,
      required: true
    },
    reportData: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      default: "add"
    },
    defaultValue: {
      type: String,
      default: ''
    },
    screenshotUrl: {
      type: String
    }
  },
  setup() {
    let store = useStore();

    return {
      store
    }
  },
  mounted() {
    this.display = this.visible
  },
  computed: {
    header() {
      let headerText = ''
      switch (this.action) {
        case "add":
          headerText = "Create report";
          break
        case "rename":
          headerText = "Rename report";
          break
      }

      return headerText
    }
  },
  watch: {
    visible(state) {
      this.display = state
     /* if (state) {
        setTimeout(() => { this.display = state }, 0)
      }*/
    },

    display(state) {
      if (!state) {
        this.$emit('update:visible', this.display)
      } else {
        this.state.buttonText = this.header;
        this.field.value = this.defaultValue;
      }
    }
  },
 /* mounted() {
    document.addEventListener('click', this.detectOutsideClick)
  },
  unmounted() {
    document.removeEventListener('click', this.detectOutsideClick)
  },*/
  data() {
    return {
      display: false,
      reportName: '',
      field: {
        value: '',
        placeholder: 'What do you want to name the report?',
        type: "text",
        label: "Report name",
        hasError: false,
        errorText: ''
      },
      state: {
        creating: false,
        createError: false,
        errorText: '',
        buttonText: 'Create Report'
      },
      createdReport: null
    }
  },
  emits: [
      'update:visible',
      'complete'
  ],
  methods: {
    checkForExistingReport(event, field) {
      let value = event.target.value
      let savedReports = this.store.savedReports;

      let existingReport = savedReports.find(report => report['report_name'].toLowerCase() === value.toLowerCase());

      if (value && existingReport) {
        field.hasError = true;
        field.errorText = 'This report name is taken.'
      } else {
        if (field.hasError) {
          field.hasError = false;
          field.errorText = '';
        }
      }
    },

    foundExistingErrors() {
      return !this.field.value || this.field.hasError
    },

    closeDialog() {
      this.display = false
      /*this.display = false;

      setTimeout(() => {
        this.$emit('update:visible', false)
      }, 250)*/
    },

    performReportAction() {
      let requestFormat = _.cloneDeep(this.reportData);
      requestFormat.reportName = this.field.value;

      if (this.action === 'add') {
        delete requestFormat.metricId;
      } else {
        delete requestFormat.id;
      }


      this.state = {
        ...this.state,
        creating: true,
        createError: false,
        errorText: '',
        buttonText: this.action === 'add' ? 'Creating...' : 'Renaming...'
      }




          this.editReport(requestFormat).then(report => {
            this.createdReport = null
            /*console.log({ report });*/
            this.state = {
              ...this.state,
              creating: false,
              createError: false,
              buttonText: `Report ${this.action === 'add' ? 'created' : 'renamed'}!`
            }

            setTimeout(() => {
              this.display = false;
              this.$emit('complete', { report })
            }, 1000)
          }).catch(error => {
            this.state = {
              ...this.state,
              creating: false,
              createError: true,
              errorText: `Error ${ this.action === 'add' ? 'adding' : 'renaming' } report : ` + error.message,
              buttonText: this.header
            }
          })
    },

    async editReport(requestFormat) {
        let report = this.action === 'add' ? await this.addReport(requestFormat)
            : await this.updateReport(requestFormat);

        return await this.store.getSavedReport(report.location_id, report.id)
    },

    async updateReport(data) {
      let requestData = _.cloneDeep(data);
      delete requestData.screenshot;

      let request = await this.store.updateSavedReport(this.location, this.pipeline, this.reportData.id, requestData);
      let report = request.data.report;

      this.store.updateReport(report);
      return report;
    },

    async addReport(data) {
      let requestData = _.cloneDeep(data);
      let report = this.createdReport;
      let customMetrics = _.cloneDeep(requestData.customMetrics);


     // console.log({ requestData })

      if (!report) {
          delete requestData.metricId;
          delete requestData.customMetrics;

          requestData.screenshot = await this.store.getDashboardImage();
          let request = await this.store.saveReportWithMetrics(this.location, this.pipeline, this.reportType, requestData);
          report = request.data.report;

          this.createdReport = report;
          this.store.addNewSavedReport(report);
      }

      if (customMetrics.length > 0) {
          report = await this.store.addCustomMetricsToReport(customMetrics, report)
      }

      return report;
    },
    /*detectOutsideClick(event) {
      if (!event.target.closest('.report-dialog-box')) {

      }
    }*/
  }
}
</script>



<style lang="scss" scoped>
.report-creator-dialog{
  .report-dialog-box{
    border-radius: 12px;
    box-shadow: 0 0 2px 4px rgba(80, 148, 221, .09);

    @media screen and (min-width: 520px) {
      width: 480px
    }

    @media screen and (max-width: 520px) {
      width: calc(100% - 1rem);
    }

    .modal-header{
      padding: .25rem 0 .35rem;
      border-bottom: 1px solid rgba(80, 148, 221, .2);

      .header-text{
        font-size: 1rem;
      }
    }

    .form-group{
      padding: 1.35rem 0;
      margin-bottom: 0;

      .field-label{
        font-size: .9rem;
      }
      .error-text{
        margin: .4rem 0;
        visibility: hidden;
        opacity: 0;

        &.error-visible{
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .error-section{
      margin-bottom: .5rem;
    }

    .action-section{
      border-top: 1px solid rgba(80, 148, 221, .2);
      padding: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button{
        &:first-child{
          margin-right: .4rem;
          min-width: 140px;
        }
      }
    }
  }


}
</style>
