<template>
    <div :class="['overview-metric-data', { 'edit-mode': inEditMode }]"
         :style="{
         '--overview-bg-color': sectionColors.background,
         '--box-color': sectionColors.box,
         '--box-border': getRgbValue(setBorderColor(sectionColors.box, sectionColors.text), .25),
         '--box-shadow-bg': getRgbValue(setBorderColor(sectionColors.box, sectionColors.text), .1),
         '--label-color': setLabelColor(sectionColors.background, sectionColors.header_and_label),
         '--overview-text-color': sectionColors.text,
         '--overview-value-color': sectionColors.metric,
         '--icon-text-color': sectionColors.background.toUpperCase() === '#FFFFFF' ? '#FFFFFF' : setLabelColor(sectionColors.background, sectionColors.header_and_label),
         '--icon-bg-color': sectionColors.background.toUpperCase() === '#FFFFFF' ? '#24588D': 'transparent'
       }"
    >
        <div class="starred-metrics-container" v-if="overviewMetrics && overviewMetrics.length > 0">
            <div class="starred-metrics-section"
            >
                <div class="metric-action-section">
                    <h5 class="header">Quick Metrics</h5>
                    <div class="metric-actions">
                        <div v-if="isEditable" class="section-toggle" @click="editable = true">
                            <button class="toggle-icon">
                                <i class="bx bx-grid-alt"></i>
                            </button>

                            <span>Edit quick metrics</span>
                        </div>
                        <div class="kpi-switch flexbox flex-column flex-align-center">
                            <Switch
                                    v-model:checked="showKpis"
                                    :size="18"
                            />
                            <span class="text">Show KPIs</span>
                        </div>
                    </div>
                </div>

                <div class="starred-data">
                    <slot v-if="$slots.top" name="top"></slot>
                    <div v-if="locationName && reportName" class="location-section">
                        <div class="location-label">
                            <b>{{ reportName }}</b>
                        </div>
                        <span class="data-separator"></span>
                        <div>
                            {{ locationName }}
                        </div>
                    </div>
                    <div
                            :class="['starred-items', { 'collapsed-section': collapsed }]"
                    >
                        <div class="data-item flexbox"
                             v-for="(item, index) in overviewMetrics"
                             :key="index"
                             :ref="'data-item-'+index"
                             :class="{
                       'collapsed': collapsed
                     }"
                        >
                            <h5 class="overview-name">{{ item.label }}</h5>
                            <div
                                    class="data-section flexbox"
                                    :class="setKpiClass(item)"
                            >
                                <div class="value bold-text"
                                     :data-item="JSON.stringify(item)"
                                >
                                    {{  formatValue(item.column_name, item.selected.value) }}
                                </div>

                                <span v-if="showKpis" class="kpi-text">{{ setKpiText(item) }}</span>
                            </div>

                            <custom-drop-down
                                    :default-value="item.selected.label"
                                    :items="getReportRows(item)"
                                    @valueChange="changeValue($event, index)"
                            />
                        </div>
                    </div>
                </div>

                <div class="flexbox flex-column collapse-section">
                    <div class="setting-container">
                        <div class="metric-settings">
                            <button class="button setting-button" @click="displaySettings = !displaySettings">
                                <i class="bx bxs-palette setting-icon"></i>
                            </button>

                            <div class="setting-section"
                                 :class="{ 'visible': displaySettings }"
                            >
                                <div class="setting-list">
                                    <div class="flexbox">
                                        <button class="revise-button" @click="refreshColorState()">
                                            <i class="bx bx-revision revise-icon"></i>
                                        </button>
                                    </div>
                                    <div class="data-list">
                                        <div
                                                v-for="(setting, index) in colorSettings"
                                                :key="index"
                                                class="data-group"
                                                :class="{
                                            'picker-top': index > (colorSettings.length / 2)
                                          }"
                                        >
                                            <label class="field-label">{{ setting.label }}</label>
                                            <div class="field-section">
                                                <q-input
                                                        filled
                                                        v-model="setting.color"
                                                        :error="setting.hasError"
                                                        @update:model-value="getColor(setting, $event)"
                                                        @focus="focusColorField(index, $event)"
                                                        :ref="'color-setting-'+index"
                                                        :no-error-icon="true"
                                                        :error-message="setting.errorMessage"
                                                        :class="{
                                                      'error-state': setting.hasError
                                                    }"
                                                >
                                                    <template v-slot:append>
                                                    <span :style="{
                                                              'background-color': setting.color
                                                            }"
                                                          class="color-indicator"
                                                          :class="{
                                                            'colored-border': setting.color.toUpperCase() === '#FFFFFF'
                                                          }"

                                                    ></span>
                                                        <q-icon name="colorize" class="cursor-pointer" @click=" toggleColorPicker(index, !setting.showColorPicker);" />
                                                    </template>
                                                </q-input>

                                                <div class="picker-section" :class="{ 'visible': setting.showColorPicker }">
                                                    <q-color
                                                            no-header
                                                            v-model="setting.color"
                                                            @update:model-value="getColor(setting, $event)"
                                                    ></q-color>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="collapse-button"
                            :class="{
                'collapsed': collapsed
              }"
                            @click="toggleOverviewState()"
                    >
                        {{ collapsed ? 'Expand' : 'Collapse' }}
                        <i class="bx bx-chevron-up"></i>
                    </button>
                </div>
            </div>
            <edit-overlay
                    :visible="inEditMode"
                    v-model:hide="hideComponent"
                    :deletable="hasReport"
                    @deleted="$emit('delete')"
                    :overview-metric-report="true"
            />
        </div>

        <div v-if="overviewMetrics.length === 0 && showEmptyText"
             class="text-center empty-text-section">
            There are no saved overview metrics for this report. You can add one from
            your reports dashboard
            <span class="alt-text bold-text">OR</span>
            <div>
                You can <button class="metric-add-shortcut bold-text button" @click="editable = true">add a new metric</button>
            </div>
        </div>

        <metrics-editor
                v-model:visible="editable"
                :options="getMetricOptions"
                :columns="columns"
                :report-name="reportName"
                :report-type="reportType"
                :location-id="locationId"
                :report-id="reportId"
                @update-metric-value="getUpdatedMetrics($event)"
                @update-metric-data="updateMetricData($event)"
        />
    </div>
</template>

<script>
import CustomDropDown from "./CustomDropDown";
import {useStore} from "../store/dataStore";
import {formatValue, getRgbValue, isValidColor, removeSpecialChars} from "../helpers/helper_functions";
import _ from "lodash";
import Switch from "./Switch";
import {costFields} from "../helpers/field_data_types";
import MetricsEditor from "./MetricsEditor";
import {defineComponent} from "vue";
import EditOverlay from "./EditOverlay.vue";
/*import {v4 as uuidv4} from "uuid";*/

export default {
  name: "OverviewMetrics",
  components: {
      EditOverlay,
    MetricsEditor,
    Switch,
    CustomDropDown
  },
  setup() {
    const dataStore = useStore()

    return {
      dataStore
    }
  },
  props: {
    visible: {
      type: Boolean
    },
    locationId: {
      type: String,
      required: true
    },
    reportId: {
      required: true,
      default: -1
    },
    columns: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    kpis: {
      type: Array,
    },
    reportType: {
        type: String,
        required: true
    },
    totals: {
        type: Object
    },
    averages: {
        type: Object
    },
    isEditable: {
        type: Boolean,
        default: true
    },
    showEmptyText: {
        type: Boolean,
        default: false
    },
    colors: {
      type: Object
    },
    customMetrics: {
      default: []
    },
    inEditMode: {
        type: Boolean,
        default: false
    },
    hidden: {
        type: Boolean,
        default: false
    },
    hasReport: {
        type: Boolean,
        default: false
    },
    reportName: {
        type: String,
        required: false
    }
  },
  emits: [
      'update:visible',
      'hide',
      'delete',
      'updateFilters',
      'updateValues',
      'update:colors',
      'colorUpdate',
      'editOpen',
      'editClose'
  ],
  mounted() {
    this.setSelectedValue();
    this.setDefaultColors();

    document.addEventListener('click', this.detectOutsidePickerClick)
  },
  unmounted() {
    document.removeEventListener('click', this.detectOutsidePickerClick)
  },
  watch: {
    /**
     * ( Same logic works for both totals and averages )
     *  Checks if any value in the totals or averages object passed as a prop has changed
     *  then runs the function that checks if any of the selected overview metrics
     *  have totals or averages as selected values and updates the displayed value
     *  with the updated total or average value.
     * **/
    totals: {
      deep: true,
      handler() {
        this.setSelectedValue()
      }
    },
    averages: {
      deep: true,
      handler() {
        this.setSelectedValue()
      }
    },

    data: {
      deep: true,
      handler() {
        this.setSelectedValue()
      }
    },

    colors: {
      deep: true,
      handler(updatedColors, oldColors) {
        if (!_.isEqual(updatedColors, oldColors)) {
          this.setDefaultColors()
        }
      },
    },

    inEditMode: {
        handler(state) {
            if (state) {
                this.hideComponent = this.hidden;
            }
        },
        immediate: true
    },

    hideComponent(state){
        //  console.log('hideComponentState', state)
        this.$emit('hide', state)
    },

    hidden(state) {
        this.hideComponent = state
    },
    editable(state) {
        if (state) {
            this.$emit('editOpen')
        } else { this.$emit('editClose') }
    }
  },
  data() {
    return {
      hideComponent: false,
      starredMetrics: [],
      unchecked: ['key', `${this.reportType.toLowerCase()}`, `${this.reportType.toLowerCase()}_parent`],
      showKpis: false,
      collapsed: false,
      editable: false,
      displaySettings: false,
      sectionColors: this.dataStore['overviewColors'],
      defaultColorSettings: [
        {
          label: "Background color",
          name: "background",
          color: "#FFFFFF",
          hasError: false,
          errorMessage: "",
          showColorPicker: false
        },
        {
          label: "Header & label colors",
          name: "header_and_label",
          color: '#000000',
          hasError: false,
          errorMessage: "",
          showColorPicker: false
        },
        {
          label: "Box color",
          name: "box",
          color: '#FFFFFF',
          hasError: false,
          errorMessage: "",
          showColorPicker: false
        },
        {
          label: "Text color",
          name: "text",
          color: '#000000',
          hasError: false,
          errorMessage: "",
          showColorPicker: false
        },
        {
          label: "Metric color",
          name: "metric",
          color: "#D8804C",
          hasError: false,
          errorMessage: "",
          showColorPicker: false
        },
      ],
      colorSettings: [],
    }
  },
  computed: {
    getMetricOptions() {
      let overviewMetrics = this.overviewMetrics;
      if (!overviewMetrics || this.data.length === 0) {
        return []
      }

      let dataItem = Object.keys(this.data[0]).find(key => this.unchecked.indexOf(key) === -1);

      if (!dataItem) {
        return []
      }

      dataItem = { column_name: dataItem }

      let rows = this.getReportRows(dataItem);

      return rows.map(row => ({ label: row.label, value: row.label }));
    },

    overviewMetrics() {
        let overview = this.dataStore.getStarredMetrics(this.reportType, this.locationId, this.reportId);
       // console.log({ overview })
        return overview
    },

    locationName() {
        return this.dataStore.getLocationName(this.locationId)
    }
  },
  methods: {
    setSelectedValue() {
      let starredItems = this.overviewMetrics;
      starredItems.forEach((item, index) => {
        let selectableData = this.getReportRows(item);
         // console.log({ item: selectableData.some(data => data.label === item.selected.label), mainItem: item })

        selectableData.forEach(data => {
          if (data.label === item.selected.label) {
            this.changeValue(data, index)
          }
        })




      })
    },

    setDefaultColors() {
      if (!this.colors) {
        return
      }

      if (Object.keys(this.colors).length > 0) {
        this.sectionColors = this.colors;

        this.colorSettings = _.cloneDeep(this.defaultColorSettings).map(setting => {
          let activeColor = this.colors[setting.name];

          if (activeColor) {
            setting.color = activeColor
          }

          return setting
        })
      } else {
        this.colorSettings = _.cloneDeep(this.defaultColorSettings);

        this.$emit('update:colors', this.sectionColors);
        this.$emit('colorUpdate', this.sectionColors);
      }
    },

    formatValue(name, value) {
        let isCustomMetric = this.dataStore.isACustomMetric(this.customMetrics, name);
      return  isCustomMetric ?  this.dataStore.formatCustomMetricValue(this.customMetrics, name, value) : formatValue(name, value, this.unchecked)
    },

    getReportRows(item) {
      let column_name = item.column_name;
      let totals = this.totals ? this.totals : this.calculateTotals();
      let averages = this.averages ? this.averages : this.calculateAverages();

      let data = [
        { label: 'Average', value:  averages[column_name] },
        { label: 'Total', value: totals[column_name] }
      ]
      let rowData = this.data.filter(row => row[this.reportType] && row[this.reportType].toLowerCase() !== 'all')
          .map(row => {
            let rowLabel = row[this.reportType];
            return {label: rowLabel, value: row[column_name]}
          });

     // console.log({ rowData, column_name, data: this.data })

      data = [
        ...data,
        ...rowData
      ]

      return data
    },

    changeValue(data, index) {
      let starredMetrics = _.cloneDeep(this.overviewMetrics);
      starredMetrics = starredMetrics.map((metric, metricIndex) => {
        if (index === metricIndex) {
          metric.selected = data
        }
        return metric
      })

      this.starredMetrics = starredMetrics;
      this.$emit('updateValues', starredMetrics);
      this.updateStoreMetrics();
    },

    updateStoreMetrics() {
      let currentLocation = this.locationId;
      if (this.reportId === null || this.reportId === -1) {
          return
      }
      this.dataStore.updateSavedMetrics(this.reportType, this.starredMetrics, currentLocation, this.reportId)
    },

    calculateTotals() {
      return this.dataStore.calculateTotals(this.data, this.unchecked)
    },


    calculateAverages() {
      return this.dataStore.calculateAverages(this.data, this.unchecked)
    },

    toggleOverviewState() {
      this.collapsed = !this.collapsed;
    },


    setKpiText(item) {
      let kpiClass = this.setKpiClass(item);
      if (!kpiClass) {
        return ''
      }

      switch (kpiClass) {
        case 'above-kpi':
          return 'KPI Met'
        case 'below-kpi':
          return 'Below KPI'
        case 'matches-kpi':
          return 'Matches KPI'
        default:
          return ''
      }
    },

    setKpiClass(item) {
      let dataClass = '';
      if (!this.kpis || !this.showKpis) {
        return ''
      }


      let kpiData = this.kpis.find(data => data.name === item.column_name);


     /* let selectedMetric = item.selected.label.toLowerCase()
      let summaryField = selectedMetric === 'total' || selectedMetric === 'average';*/

      if (!kpiData) {
        return ''
      }

      let dataValue = kpiData.value;
      let value = kpiData.time_field ? dataValue[dataValue.length-1] : dataValue;
      value = !value ? 0 : parseFloat(removeSpecialChars(value));

      if (value === 0) {
        return ''
      }
      let metricValue = !item.selected.value ? 0 : parseFloat(item.selected.value);

      if (metricValue === 0) {
        return ''
      }

      if (metricValue > value) {
        dataClass = costFields.indexOf(item.column_name) !== -1 || item.column_name.includes('time') ? 'below-kpi' : 'above-kpi'
      } else if (metricValue < value) {
        dataClass = costFields.indexOf(item.column_name) !== -1 || item.column_name.includes('time') ? 'above-kpi' : 'below-kpi'
      } else {
        dataClass = 'matches-kpi'
      }

      return dataClass
    },

    getUpdatedMetrics(event) {
      let overviewMetrics = _.cloneDeep(this.overviewMetrics);

      overviewMetrics = overviewMetrics.map(metric => {
        if (metric.column_name === event.field) {
          let reportOptions = this.getReportRows(metric);
          let matchingMetric = reportOptions.find(option => option.label === event.metric);

          if (matchingMetric) {
            metric.selected = matchingMetric
          }
        }
        return metric
      })

      this.starredMetrics = overviewMetrics;
      this.updateStoreMetrics();
      this.$emit('updateValues', overviewMetrics);
    },

    updateMetricData(metrics) {
      this.starredMetrics = metrics.map(metric => {
        let dataObject = {
          label: metric.label,
          column_name: metric.field
        }

        let reportRows = this.getReportRows(dataObject);
        dataObject['selected'] = reportRows.find(item => item.label === metric.value)
       // console.log({ dataObject, reportRows, data: this.data })
        return dataObject
      });

      this.updateStoreMetrics();
      this.$emit('updateFilters', { metrics, reportType: this.reportType, locationId: this.locationId, reportId: this.reportId });
    },

    getColor(setting, color) {
      setting.hasError = !isValidColor(color);
      setting.errorMessage = 'Invalid color'

      if (!setting.showColorPicker) {
        setting.showColorPicker = true
      }

      if (!setting.hasError) {
        this.sectionColors[setting.name] = setting.color;
        this.$emit('update:colors', this.sectionColors)
        this.$emit('colorUpdate', this.sectionColors)
      }
    },
    toggleColorPicker(index, state){
      this.colorSettings = this.colorSettings.map((setting, settingIndex) => {
        if (settingIndex !== index && setting.showColorPicker) {
          setting.showColorPicker = false
        }

        if (settingIndex === index) {
          setting.showColorPicker = state
        }
        return setting
      })
    },

    detectOutsidePickerClick(event) {
      let openColorPickers = this.colorSettings.filter(setting => setting.showColorPicker).length > 0
      if (!event.target.closest('.data-group .field-section') && openColorPickers && event.target.nodeName.toLowerCase() !== 'html') {
          this.colorSettings = this.colorSettings.map(setting => {
            setting.showColorPicker = false
            return setting
          })
      }

      if (!event.target.closest('.metric-settings') && event.target.nodeName.toLowerCase() !== 'html' && this.displaySettings){
        this.displaySettings = false
      }
    },

    focusColorField(index, event) {

      if (event.target.closest('.q-field__native')) {
        this.toggleColorPicker(index, true)
      }
    },


    getRgbValue(color, opacity = 1) {
      return getRgbValue(color, opacity)
    },

    refreshColorState() {
      // console.log({ settings: this.defaultColorSettings })
      this.colorSettings = _.cloneDeep(this.defaultColorSettings);

      this.colorSettings.forEach(setting => {
        this.sectionColors[setting.name] = setting.color
      })

      this.$emit('update:colors', this.sectionColors);
      this.$emit('colorUpdate', this.sectionColors);
    },

    setBorderColor(boxColor, metricColor) {
      return boxColor.toUpperCase() === '#FFFFFF' ? '#5094DD' : metricColor
    },

    setLabelColor(boxColor, metricColor) {
      return boxColor.toUpperCase() === '#FFFFFF' ? '#24588D' : metricColor
    }
  }
}
</script>

<style scoped>
@import "../assets/css/overview-metrics.css";
</style>

<style>
.data-group .q-input .q-field__control{
    height: auto;
    background: #FFFFFF;
    border: 1px solid rgba(80, 148, 221, .35);
    border-radius: 0;
    z-index: 2;

}

.data-group .q-input .q-field__control .q-field__native{
    padding: 2px 0;
    font-size: .8rem;
    text-transform: uppercase;
}

.data-group .q-input .q-field__control .q-field__marginal{
    height: auto;
}

.data-group .q-field--filled .q-field__control:before{
    background: transparent;
    border: 1px solid transparent;
}

.data-group .q-field--filled .q-field__control:after{
    height: 0;
}
.data-group .q-input .q-field__control:before{
    transition: ease-in-out .25s;
}


.kpi-switch .switch.checked{
    background: #27AE60;
}

.kpi-switch .switch input:checked +.slider{
    border: 1px solid rgb(39, 174, 96, .5);
    box-shadow: 0 0 1px 2px rgb(39, 174, 96, .09);
}

.kpi-switch .switch.checked:hover .slider{
    box-shadow: 0 0 1px 2px rgba(39, 174, 96, .2),
    0 0 2px 1px rgba(39, 174, 96, .15),  0 0 1px 6px rgba(39, 174, 96, .09);
}


</style>
