<template>
<q-dialog class="template-delete-dialog" position="top" v-model="open">
  <div class="dialog-box">
      <div class="modal-header">
          <h4 class="header-text">Delete template{{ templates.length > 1 ? 's' : '' }}</h4>
          <i class="bx bx-x close-icon" @click="open = false"></i>
      </div>
      <div class="data-text text-center">
          Are you sure you want to delete {{ templates.length > 1 ? 'these' : 'the' }}
          <span class="bold-text">
              {{ templates.length === 1 ? templates[0]['templateName'] + ` ${templateLastWord !== 'template' ? 'template' : ''}` : templates.length + ' templates' }}
          </span>
          ?
      </div>

      <div v-if="state.errorText && state.deleteError" class="y-padding-sm error-section error-text flex flex-align-center justify-center text-center">
          <i class="bx bx-error-circle"></i>
          <span>
             {{ state.errorText }}
            </span>
      </div>


      <div class="button-section flex justify-center">
          <button class="button button-red"
                  :disabled="state.deleting"
                  @click="performDelete()"
          >
              <span>{{ state.buttonText }}</span>
              <span v-if="state.deleting" class="process-spinner">
                <q-spinner />
              </span>
          </button>

          <button class="button button-red-transparent" @click="open = false">
              Cancel
          </button>
      </div>
  </div>
</q-dialog>
</template>

<script>
import { useStore } from "../store/dataStore";

export default {
    name: "TemplateDeleter",
    setup() {
        return {
            store: useStore()
        }
    },
    props: {
        visible:{
            type: Boolean
        },
        templates: {
            type: Array,
            required: true
        }
    },
    emits: [
        'update:visible',
        'close',
        'complete'
    ],
    mounted() {
        this.open = this.visible
    },
    computed: {
        templateLastWord() {
            let template = this.templates[0]['templateName'].toLowerCase();
            let templateWords = template.split(" ")

            return templateWords[templateWords.length-1]
        }
    },
    watch: {
        visible(state) {
            this.open = state
        },

        open(state) {
            if (!state) {
                this.$emit('close')
                this.$emit('update:visible', state)
            }
        }
    },
    data() {
        return {
            open: false,
            state: {
                deleting: false,
                deleteError: false,
                errorText: '',
                buttonText: 'Delete'
            }
        }
    },
    methods: {
        performDelete() {
            // console.log({ templates: this.templates });
            this.state = {
                ...this.state,
                deleting: true,
                deleteError: false,
                errorText: '',
                buttonText: 'Deleting...'
            }

            this.deleteTemplates().then(() => {
                this.state = {
                    ...this.state,
                    deleting: false,
                    deleteError: false,
                    buttonText: 'Deleted!'
                }

                setTimeout(() => {
                    this.state = {
                        ...this.state,
                        buttonText: 'Delete'
                    }
                    this.$emit('complete');
                    this.open = false;
                }, 1000)
            }).catch(error => {
                this.state = {
                    ...this.state,
                    deleting: false,
                    deleteError: true,
                    errorText: `Error deleting template${this.templates.length > 1 ? 's' : ''} ` + error.message,
                    buttonText: 'Delete'
                }

                setTimeout(() => {
                    this.state = {
                        ...this.state,
                        deleteError: false,
                        errorText: ''
                    }
                }, 3000)
            })
        },
        async deleteTemplates() {
            await new Promise((resolve, reject) => {
                let templates = this.templates;
                let store = this.store;

                deleteTemplate(0)

                function deleteTemplate(index) {
                    if (index < 0 || index >= templates.length) {
                        resolve({ templates, deleted: true })
                        return
                    }
                    let template = templates[index]

                    let existingTemplate = store.reportTemplates.find(templateData => templateData.id === template.id);

                    if (!existingTemplate) {
                        deleteTemplate(index+1);
                        return
                    }

                    store.sendTemplateDeleteRequest(template.locationId, template.id).then(() => {
                        store.deleteTemplate(template.id);
                        deleteTemplate(index+1)
                    }).catch(error => {
                        reject(error)
                    })
                }
            })
        }
    }
}
</script>


<style scoped lang="scss">
.modal-header{
  padding: .25rem 0 .35rem;
  border-bottom: 1px solid rgba(80, 148, 221, .2);

  .header-text{
    font-size: 1rem;
  }
}

.dialog-box{
    box-shadow: 0 0 2px 4px rgba(80, 148, 221, .09);
    border-radius: 0 0 12px 12px;
}


.data-text{
  font-size: .95rem;
  padding: 2rem 1.5rem;
  width: calc(100% - 1rem);
  margin: 0 auto;
  line-height: 1.5rem;
  color: var(--theme-blue-alt);
}

.error-section {
  i {
    font-size: 20px;
    margin-right: .4rem;
  }
}

.button-section{
  padding: 1rem .3rem;
  border-top: 1px solid rgba(80, 148, 221, .2);

  button{
    position: relative;
    min-width: 150px;
    max-width: 100%;
    &:first-child{
      margin-right: .6rem;
    }


    .process-spinner{
      position: absolute;
      top: 45%;
      transform: translateY(-50%);
      right: 4px;
      font-size: 18px;

      svg{
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
</style>
