<template>
<div :class="['modal data-generator-modal', {
      'modal-open': addTransitionClass
      }]"
     v-if="currentState"
>
    <div class="modal-content">
        <div class="modal-header">
            <i class="bx bx-x close-icon" @click="closeModal()"></i>
        </div>
        <div class="data-content">
            <header>{{ capitalize(reportName) }}</header>
            <data-generator
                :location-id="locationId"
                :hide-pipelines="hidePipelines"
                :hide-report-type="hideReportType"
                :hide-date-range="hideDateRange"
                v-model:date-range="range"
                v-model:report-type="type"
                v-model:pipeline="pipeline"
                v-model:preset="datePreset"
                v-if="currentState"
                @generate-report="getGeneration($event)"
                ref="dataGenerator"
            />
            <div class="button-section">
                <button @click="regenerate()"
                        :disabled="state.generating"
                        :class="[
                            'button generate-btn',
                            {
                                'button-blue-alt': !state.hasError,
                                'button-red': state.hasError
                            }]">
                    {{  state.text }}
                </button>

                <q-spinner v-if="state.generating" class="load-spinner"></q-spinner>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {capitalizeFirstLetter, capitalizeFirstLetters, replaceSlashes} from "../helpers/helper_functions";
import {getCurrentDate, getLastMonthDate} from "../helpers/date_functions";
import _ from "lodash";
import DataGenerator from "./DataGenerator.vue";
import { useStore } from "../store/dataStore";

export default {
    name: "DataGeneratorModal",
    components: {DataGenerator},
    setup() {
        return {
            store: useStore()
        }
    },
    props: {
        hideDateRange: {
            type: Boolean,
            required: false,
            default: false
        },
        hideReportType: {
            type: Boolean,
            required: false,
            default: false
        },
        hidePipelines: {
            type: Boolean,
            required: false,
            default: false
        },
        visible: {
            type: Boolean
        },
        locationId: {
            type: String,
            required: true
        },
        pipelineId: {
            type: String,
            required: true
        },
        reportType:{
            type: String,
            required: true
        },
        dateRange: {
            default: {},
            required: true
        },
        preset: {
            type: String,
            required: true
        },
        reportName: {
            type: String,
            required: true
        },
        filters: {
            type: Array,
        }
    },
    mounted() {
        this.setModalState(this.visible);
        document.addEventListener('click', this.detectOutsideClick)
    },
    unmounted() {
        document.removeEventListener('click', this.detectOutsideClick)
    },
    emits: [
        'generated',
        'update:visible'
    ],
    data() {
        return {
            open: false,
            addTransitionClass: false,
            location: this.locationId.slice(),
            pipeline: this.pipelineId.slice(),
            type: this.reportType.slice(),
            range: this.dateRange ? _.cloneDeep(this.dateRange) : this.defaultRange,
            datePreset: this.preset ? this.preset.slice() : 'No preset',
            state: {
                text: 'Generate',
                hasError: false,
                generating: false
            }
        }
    },
    computed: {
        currentState() {
            return this.visible ? this.visible : this.open
        },
        defaultRange() {
            return {
                from: replaceSlashes(getLastMonthDate()),
                to:  replaceSlashes(getCurrentDate())
            }
        },

        computedPreset() {
            return  this.preset ? this.preset : 'No preset';
        },

        computedRange() {
            return this.dateRange ? _.cloneDeep(this.dateRange) : this.defaultRange
        }
    },
    watch: {
        visible(state) {
            this.setModalState(state);
        },
        locationId(data) {
            this.location = data;
        },
        pipelineId(data) {
            this.pipeline = data;
        },
        reportType(data) {
            this.type = data;
        },
        preset() {
            this.datePreset = this.computedPreset
        },
        dateRange: {
            deep: true,
            handler() {
                this.range = this.computedRange
            }
        }
    },
    methods: {
        setModalState(state) {
            let timeout = state ? 0 : 300;
            if (!state) {
                this.addTransitionClass = state
            }

            let component = this;

            setTimeout(function () {
                component.open = state;

                if (state) {
                    component.addTransitionClass = state
                   // console.log({ stateData: state, transitionClass: this.addTransitionClass })
                }


            }, timeout)
        },
        closeModal() {
            this.$emit('update:visible', false)
        },

        capitalize(text) {
            return capitalizeFirstLetters(text)
        },

        regenerate() {
            let dataGenerator = this.$refs.dataGenerator;

            if (!dataGenerator) {
                this.state = {
                    ...this.state,
                    generating: false,
                    hasError: true,
                    text: 'Data generator component not found'
                }
                return
            }


            dataGenerator.emitReportGeneration()
        },

        async getReport(data) {
            let range;
            if (data.presets && data.presets !== 'No preset') {
                range = this.store.getPresetRange(data.presets)
            } else {
                range = {
                    from:  replaceSlashes(data.start_date),
                    to:  replaceSlashes(data.end_date)
                }
            }

           // console.log({ range, data })

            let filters = this.filters && Array.isArray(this.filters) && this.reportType === data.type ? this.filters : [];
            let request = await this.store.getReportTableData(this.location, this.pipeline, this.type, range, filters);

            let report = request.data.results;
            let pipeline = {
                name: data.pipeline_name,
                id: data.pipeline_id
            }



            return { report, pipeline, range, filters }
        },

        getGeneration(data) {
           // console.log({ data })
           // console.log({ data, generator: this.$refs.dataGenerator })
            let missingValues = Object.keys(data).filter(key => !data[key] && key !== 'pipeline_name' && key !== 'presets')
            if (missingValues.length > 0) {
               let valueString = '';
               missingValues.forEach((value, index) => {
                   if (value.includes('pipeline')) {
                       value = 'pipeline'
                   }
                   let valueText = capitalizeFirstLetter(value);
                   if (index > 0) {
                       if (index === missingValues.length - 1){
                           if (missingValues.length > 1) {
                               valueText = ' and ' + valueText
                           }
                       } else {
                          valueText = ', ' + valueText
                       }
                   }

                   valueString += valueText
               })

                this.state = {
                    ...this.state,
                    generating: false,
                    hasError: true,
                    text: valueString + ' not selected'
                }
                return
            }
            this.state = {
                ...this.state,
                generating: true,
                hasError: false,
                text: 'Generating report...'
            }

                this.getReport(data).then(response => {
                   // console.log({ response })
                    let { report, pipeline, range, filters } = response
                    if (report.length === 0) {
                        this.state = {
                            ...this.state,
                            generating: false,
                            hasError: true,
                            text: 'No results found'
                        }

                        this.clearErrorState()
                        return
                    }
                    this.state = {
                        ...this.state,
                        generating: false,
                        hasError: false,
                        text: 'Generate'
                    }

                    let emitData = {
                        reportData: report,
                        reportType: this.type,
                        pipeline,
                        range,
                        presets: data.presets,
                        filters,
                        location: this.locationId
                    }

                    this.$emit('generated', emitData);
                    this.closeModal()


                }).catch(error => {
                    this.state = {
                        ...this.state,
                        generating: false,
                        hasError: true,
                        text: error.message
                    }

                    this.clearErrorState()

            })
        },

        clearErrorState() {
            let component = this;
            setTimeout(function () {
                component.state = {
                    ...component.state,
                    hasError: false,
                    text: 'Generate'
                }
            }, 5000)
        },

        detectOutsideClick(event) {
            let datePicker = event.target.closest('.date-range-picker')
            if (!event.target.closest('.data-generator-modal .modal-content') && this.open && !datePicker) {
                this.closeModal()
            }
        }
    }
}
</script>

<style lang="scss">
.modal{
    &.data-generator-modal{
        .modal-content{
            .data-content{
                .date-group{
                    label{
                        display: none;
                    }
                }

                .date-field{
                    max-width: 100%;
                }
            }

            .generate-action-container{
                display: none;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.modal{
  &.data-generator-modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .modal-content{
      width: 560px;
      max-width: calc(100% - 1rem);
      background: var(--white);
      transition: ease-in-out .3s;
      transform: scale(0.85);
      opacity: 0;
      padding: 1.2rem 1rem;
      border-radius: 12px;
    }

    &.modal-open{
      .modal-content{
        transform: scale(1);
        opacity: 1;
      }
    }

      .data-content{
          padding: 1.5rem 0 .5rem;

          header{
              font-weight: 700;
              font-size: .95rem;
              margin-bottom: 1rem;
          }

          .button-section{
              position: relative;
              margin-top: 2rem;

              button{
                  width: 100%;

                  &:hover{
                      background: var(--theme-blue-alt);
                      color: var(--white);
                      opacity: .6;
                  }
              }

              .load-spinner{
                  position: absolute;
                  top: 6px;
                  right: 8px;
                  font-size: 20px;
                  color: var(--white);
              }
          }
      }
  }


}
</style>
