<template>
<q-dialog class="delete-dialog" position="top" v-model="open">
  <div class="dialog-box">
    <div class="modal-header">
      <h3 class="header-text">{{ `Delete report${reports.length > 1 ? 's' : ''}`}}</h3>

      <i class="bx bx-x close-icon" @click="open = false"></i>
    </div>

    <div class="data-text text-center">
      Are you sure you want to delete {{ reports.length ===  1 ? 'the' : 'these' }}
      <span class="bold-text">{{ reports.length ===  1 ? reports[0]['report_name'] + `${ reportNameLastWord !== 'report' ? ' report' : ''}` : reports.length + ' reports' }}</span>
      ?
      <span>
        The associated overview metrics with {{ reports.length ===  1 ? 'this report' : 'these reports' }} will also be deleted.
      </span>

      <div class="error-text top-margin-sm text-center" v-if="state.deleteError && state.errorText" >
        {{ state.errorText }}
      </div>
    </div>



    <div class="action-section flex">
      <button class="button button-red"
              @click="performDelete()"
              :disabled="state.deleting"
      >
        <span>
          {{ state.buttonText + `${reports.length && !state.deleting > 1 ? 's' : ''}` }}
        </span>

        <span v-if="state.deleting" class="process-spinner">
          <q-spinner />
        </span>
      </button>

      <button class="button button-red-transparent" @click="open = false">
        Cancel
      </button>
    </div>
  </div>
</q-dialog>
</template>

<script>
import { useStore } from "../store/dataStore";
import _ from "lodash";

export default {
  name: "ReportDeletor",
  props: {
    visible: {
      type: Boolean,
    },
    reports: {
      type: Array,
      required: true
    }
  },
  setup() {
    let store = useStore();

    return {
      store
    }
  },
  emits: [
      'update:visible',
      'complete',
      'close'
  ],
  mounted() {
    this.open = this.visible
  },
  computed: {
    reportNameLastWord() {
      let reportName = this.reports[0]['report_name'].toLowerCase()
      let nameSplit = reportName.split(" ");
      return nameSplit[nameSplit.length-1]
    }
  },
  watch: {
    visible(state) {
      this.open = state
    },

    open(state) {
      if (!state) {
        this.$emit('close');
        this.$emit('update:visible', state)
      }
    }
  },

  data() {
    return {
      open: false,
      state: {
        deleting: false,
        deleteError: false,
        deleteSuccess: false,
        errorText: '',
        buttonText: 'Delete Report'
      }
    }
  },
  methods: {
    performDelete() {
      this.state = {
        ...this.state,
        deleting: true,
        deleteError: false,
        deleteSuccess: false,
        errorText: '',
        buttonText: 'Deleting...'
      }

      this.deleteReports()
          .then(() => {
            this.state = {
              deleting: false,
              deleteError: false,
              deleteSuccess: true,
              buttonText: `${this.reports.length === 1 ? 'Report' : 'Reports'} Deleted!`
            }

            setTimeout(() => {
              this.open = false;
              this.$emit('complete')
            }, 1000)
          }).catch(error => {
            this.state = {
              deleting: false,
              deleteError: true,
              deleteSuccess: false,
              errorText: error.message,
              buttonText: 'Delete Report'
            }


            setTimeout(() => {
              this.state = {
                ...this.state,
                deleteError: false,
                errorText: ''
              }
            }, 3000)
           })
    },

    async deleteReports() {
      let reports = _.cloneDeep(this.reports);
      let store = this.store;

      return await new Promise((resolve, reject) => {
        deleteReport(0)


        function deleteReport(index) {
          if (index === reports.length) {
            resolve({ deleted: true })
            return
          }

          let report = reports[index];
          let existingReport = store.savedReports.find(reportData => reportData.id === report.id);

          if (!existingReport) {
              deleteReport(index+1)
              return
          }
          store.sendDeleteRequest(report.location_id, report.id)
              .then(() => {
                  store.removeReport(report.id)
                  deleteReport(index+1)
              }).catch(error => {
                  reject(error)
              })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-box{
  border-radius: 12px;
  box-shadow: 0 0 2px 4px rgba(80, 148, 221, .09);
}

.modal-header{
  padding: .25rem 0 .35rem;
  border-bottom: 1px solid rgba(80, 148, 221, .2);

  .header-text{
    font-size: 1rem;
  }
}
.data-text{
  font-size: .95rem;
  padding: 2rem 1.5rem;
  width: calc(100% - 1rem);
  margin: 0 auto;
  line-height: 1.5rem;
  color: var(--theme-blue-alt);
}

.error-text{
  font-size: .9rem;
}

.action-section{
  padding: 1rem 0;
  border-top: 1px solid rgba(80, 148, 221, .2);
  justify-content: center;

  button{
    min-width: 150px;
    max-width: 100%;
    position: relative;
    &:first-child{
      margin-right: .6rem;
    }

    .process-spinner{
      position: absolute;
      top: 45%;
      transform: translateY(-50%);
      right: 4px;
      font-size: 18px;

      svg{
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
</style>
