<template>
  <div class="snippet-container">
    <header>Code for your HighLevel Account</header>

    <div class="snippet-box">
      <Transition name="faded">
        <div
            v-if="show_feedback"
            class="tooltip action-feedback position-top"
            :class="{ 'success-tooltip': copy_success, 'error-tooltip': !copy_success }">
          <div class="tooltip-content">{{ feedback_text }}</div>
        </div>
      </Transition>
      <span class="clipboard-content" ref="clipboard_content">
        {{ code_snippet }}
      </span>
    </div>

    <div class="button-container">
      <button class="button button-blue" @click="copyToClipboard">Copy to clipboard</button>
    </div>
  </div>

</template>

<script>
export default {
  name: "CodeSnippet",
  props: {
    'snippet': String
  },
  mounted() {
    this.code_snippet = this.snippet
  },
  data() {
    return {
      code_snippet: '',
      feedback_text: 'Copied!',
      copy_success: true,
      show_feedback: false
    }
  },
  methods: {
    copyToClipboard() {
      const clipboard_content = this.$refs.clipboard_content.innerText
      let textarea = document.createElement("textarea")
      textarea.innerHTML = clipboard_content
      document.body.appendChild(textarea)
      textarea.select()
      let content_copied = document.execCommand('copy');

      content_copied ? this.feedback_text = 'Copied!' : this.feedback_text = 'Error copying to clipboard';
      this.copy_success = content_copied
      document.body.removeChild(textarea)

      this.show_feedback ? this.feedback_text = 'Item already copied to clipboard' : null;
      this.show_feedback = true
      setTimeout(() => {this.show_feedback = false;}, 2500)
    }
  }
}
</script>

<style scoped lang="scss">
  .snippet-container{
    margin: 1.5rem auto;


    @media screen and (min-width: 990px) and (max-width: 1100px) {
      width: calc(100% - 10rem);
    }

    @media screen and (min-width: 1101px) {
      width: 600px;
    }

    @media screen and (max-width: 989px) {
      width: calc(100% - 2.5rem);
    }

    header{
      font-size: .9rem;
    }

    .snippet-box{
      padding: 1.2rem .9rem;
      width: 100%;
      border-radius: .5rem;
      border: 1px solid rgba(0, 0, 0, .4);
      margin: 1rem 0;
      position: relative;

      .tooltip.action-feedback{
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .button-container{
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
    }

    .faded-enter-active{
      animation: fade .5s;
    }

    .faded-leave-active{
      animation: fade .3s reverse;
    }
    @keyframes fade {
      from {top: -15px; opacity: 0.3}
      to {top: -40px; opacity: 1}
    }
  }
</style>
