<template>
<div :class="['modal modal-center report-getter-modal', {
          'modal-open': addAnimationClass
      }]" v-if="state">
    <div class="modal-content">
        <div class="modal-header">
            <div class="header-text">
                Add {{ overviewMetricReport ? 'Overview Metric' : 'Report' }} Data
            </div>
            <i class="bx bx-x close-icon" @click="closeModal()"></i>
        </div>

        <div class="getter-content">
            <div class="location-search" v-if="agencyLocation">
                <location-selector
                        :location-id="locationId"
                        v-model:locations="addedLocations"
                        @select="getReports()"
                        @edit="filterReports()"
                />
            </div>

            <div :class="['selected-location-data', {
                     'visible': selectedReports.length > 0 && !reportState.fetching && !reportState.fetchError && reports.length > 0
                 }]"
                 >
                <span class="total-selected bold-text">{{ selectedReports.length }} </span> report{{ selectedReports.length > 1 ? 's' : '' }} selected
            </div>

            <div class="report-data-content">
                <div class="data-section" v-if="reportState.fetching">
                        <div class="loading-section">
                           <load-previewer
                               v-for="(item, index) in Array(previewItems)"
                               :key="index"
                               class="loading-section-preview"/>
                        </div>
                </div>
                <div class="full-width full-height" v-else>
                    <div class="data-section display-section"  v-if="reportState.fetchError">
                        <div class="error-text section-text">
                            <i class="bx bx-x-circle"></i>
                            <span>{{ reportState.errorText }}</span>
                        </div>
                    </div>


                    <div class="data-reports" v-else>
                        <div class="data-section display-section" v-if="reports.length === 0">
                            <div class="section-text">No reports available to select.</div>
                        </div>

                        <div class="report-list-section" v-else>
                                <div
                                    v-for="(report, index) in reports"
                                    :class="['report-item shadowed', {
                                        'selected-item': reportIsSelected(report)
                                     }]"
                                     :key="index"
                                >
                                    <div class="report-name">{{ report.report['report_name'] }}</div>
                                    <div class="report-location">{{ report.location.name }}</div>
                                    <div class="report-image">
                                            <img :src="report.report['screenshot_url']"
                                                 v-if="report.report['screenshot_url']"
                                                 v-show="report.imageLoaded"
                                                 alt="report screenshot"
                                                 @load="report.imageLoaded = true"
                                            />
                                            <i class="bx bxs-dashboard"
                                               v-if="!report.imageLoaded"></i>
                                    </div>

                                    <button @click="toggleReportSelection(report)"
                                            :class="['button select-btn', {
                                                'button-blue': !reportIsSelected(report),
                                                'button-white-transparent': reportIsSelected(report)
                                            }]"
                                            :disabled="report.added"
                                    >
                                        {{ reportIsSelected(report) ? 'Remove' : 'Add' }}
                                    </button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="button-section" v-if="reports.length > 0">
                <button
                    :class="[
                        'button save-btn',
                        {
                            'button-blue-alt': !fetchState.fetchError,
                            'button-red': fetchState.fetchError
                        }
                    ]"
                    :disabled="selectedReports.length === 0 || fetchState.fetching"
                    @click="fetchReports()"
                >
                    <span>{{ fetchState.buttonText }}</span>
                    <q-spinner v-if="fetchState.fetching" class="load-spinner"></q-spinner>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import LocationSelector from "./LocationSelector.vue";
import LoadPreviewer from "./LoadPreviewer.vue";
import {useStore} from "../store/dataStore";

export default {
    name: "ReportGetter",
    components: {LoadPreviewer, LocationSelector},
    setup() {
        return {
            store: useStore()
        }
    },
    props: {
        visible: {
            type: Boolean
        },
        locationId: {
            type: String,
            required: true
        },
        agencyLocation: {
            type: Boolean,
            required: true
        },
        overviewMetricReport: {
            type: Boolean,
            default: false
        },
        dashboardReports: {
            type: Array,
            required: true
        }
    },
    emits: [
        'update:visible',
        'fetch'
    ],
    mounted() {
        this.open = this.visible;
        this.setModalState(this.open);
        this.getReports()

        document.addEventListener('click', this.detectClick)
    },
    unmounted() {
        document.removeEventListener('click', this.detectClick)
    },
    data() {
        return {
            open: false,
            addAnimationClass: false,
            agencyLocationData: {},
            addedLocations: [],
            reports: [],
            reportState: {
                fetching: false,
                fetchError: false,
                errorText: ''
            },
            previewItems: 3,
            selectedReports: [],
            fetchState: {
                fetching: false,
                fetchError: false,
                buttonText: 'Continue'
            }
        }
    },
    computed: {
        state() {
            return this.visible ? this.visible : this.open
        }
    },
    watch: {
        visible(state) {
            this.setModalState(state)
        }
    },
    methods: {
        setModalState(state) {
            let timeout = state ? 0 : 300;
            let component = this;

            if (!state) {
                this.addAnimationClass = state
            }

            setTimeout(function () {
                  component.open = state;
                  if (state) {
                      component.addAnimationClass = state;
                      component.getReports();
                  }
            }, timeout)
        },

        closeModal() {
            this.$emit('update:visible', false);
            this.selectedReports = [];
            this.addedLocations = [];
        },

        reportIsSelected(report) {
            return !!this.selectedReports.find(selectedReport => selectedReport.report.id === report.report.id && selectedReport.report.location_id === report.report.location_id);
        },

        toggleReportSelection(report) {
            let existingReport = this.reportIsSelected(report)
            if (existingReport) {
                this.selectedReports = this.selectedReports.filter(selectedReport => !(selectedReport.report.id === report.report.id && selectedReport.report.location_id === report.report.location_id))
            } else {
                this.selectedReports = [
                    ...this.selectedReports,
                    report
                ]
            }
        },

        detectClick(event) {
            let targets = [
                '.modal.report-getter-modal .modal-content',
                '.edit-btn',
                '.data-actions .close-section',
                '.edit-view-item'
            ]

            let matchingTarget = targets.filter(target => event.target.closest(target));
            if (this.open && matchingTarget.length === 0) {
                this.closeModal()
            }
        },

        async getFullReports(reports) {
            return await new Promise((resolve, reject) => {
                    let fullReports = [];
                    let store = this.store;

                    fetchFullReport(0)
                    function fetchFullReport(index) {
                        if (index < 0 || index >= reports.length) {
                            resolve(fullReports);
                            return
                        }

                        let report = reports[index];
                        store.getReportData(report.location_id, report.id).then(response => {
                            fullReports = [
                                ...fullReports,
                                response.data
                            ]

                            fetchFullReport(index+1)
                        }).catch(error => {
                            reject(error)
                        })
                    }
            })
        },

        async getLocationReports(locations) {
            return await new Promise((resolve, reject) => {
                let store = this.store;
                let reports = [];
               // console.log({ reports: JSON.parse(JSON.stringify(this.dashboardReports))})
                let dashboardReports = this.dashboardReports.filter(report => report.savedReport && !report.overviewMetricReport && !report.deleted).map(report => {
                    report = {
                        id: report.report.report.id,
                        locationId: report.report.report.location_id
                    }
                    return report
                })

                let component = this;

                function reportExists(reportId, locationId) {
                    let existingReport = dashboardReports.find(dashboardReport => dashboardReport.id === reportId && dashboardReport.locationId === locationId);
                    return !!existingReport
                }

                fetchReport(0)
                function fetchReport(index) {
                    if (index < 0 || index >= locations.length) {
                        reports = reports.map(report => {
                            report.added = reportExists(report.report.id, report.report.location_id);
                            return report
                        });


                        if (component.overviewMetricReport) {
                            reports = reports.filter(reportItem => {
                                let { metrics } = reportItem;
                                return component.hasOverviewMetrics(metrics['savedMetrics'])
                            })

                            // console.log({ reports })
                        }

                        if (reports.length === 0) {
                            reject({ message: 'No reports found' })
                        } else {
                            resolve({ reports })
                        }
                        return
                    }

                    let location = locations[index];

                    store.getLocationSavedReports(location.id).then(response => {
                        let fetchedReports = response.data.reports;
                        component.getFullReports(fetchedReports).then(fullReports => {
                            fullReports = fullReports.map(report => {
                                return {
                                    ...report,
                                    location,
                                    imageLoaded: false
                                }
                            })

                            reports = [
                                ...reports,
                                ...fullReports
                            ]

                            fetchReport(index+1)
                        }).catch(error => {
                            reject(error)
                        })
                    }).catch(error => {
                        reject(error)
                    })
                }
            })
        },

        hasOverviewMetrics(savedMetrics) {
            if (!savedMetrics) {
                return false
            }


            let hasMetrics = false;

            for (let key in savedMetrics) {
                if (key !== 'stages') {
                    if (savedMetrics[key] && !hasMetrics) {
                        hasMetrics = true
                    }
                } else {
                    savedMetrics[key].forEach(stage => {
                        for (let stageKey in stage) {
                            if (this.store.builtInStages.indexOf(stageKey) === -1 && stage[stageKey] && !hasMetrics) {
                                hasMetrics = true;
                            }
                        }
                    })
                }
            }

            return hasMetrics
        },

        async getAllReports() {
                let agencyLocation;
                if (!(this.agencyLocationData && "id" in this.agencyLocationData && "name" in this.agencyLocationData)) {
                    let locationData;
                    let agencyLocationRequest = await this.store.getAgencyLocation(this.locationId);
                    let location = agencyLocationRequest.data.location;
                    locationData = { name: location.name, id: location.id }

                    agencyLocation = locationData;
                    this.agencyLocationData = locationData
                } else {
                    agencyLocation = this.agencyLocationData
                }

            let locations = this.addedLocations;
            locations = [
                ...locations,
                agencyLocation
            ]

            return await this.getLocationReports(locations)
        },

        getReports() {
            if (!this.locationId) {
                return
            }

            this.reportState = {
                ...this.reportState,
                fetching: true,
                fetchError: false,
                errorText: ''
            }



            this.getAllReports().then(data => {
                this.reportState = {
                    ...this.reportState,
                    fetching: false,
                    fetchError: false
                }

                this.reports = data.reports;
            }).catch(error => {
                this.reportState = {
                    ...this.reportState,
                    fetching: false,
                    fetchError: true,
                    errorText: error.message
                }
            })

        },

        filterReports() {
            let locations = this.addedLocations.map(location => location.id);
            locations = [
                ...locations,
                this.agencyLocationData.id
            ]

           // console.log({ reports: this.reports, locations, locationData: this.agencyLocationData })
            this.reports = this.reports.filter(report => locations.indexOf(report.report.location_id) !== -1)
        },

        async getSelectedReports() {
            return await new Promise((resolve, reject) => {

                let reports = this.selectedReports;
                let store = this.store;
                let fetchedReports = [];

                getSavedReport(0)
                function getSavedReport(index) {
                    if (index < 0 || index >= reports.length) {
                        resolve(fetchedReports);
                        return
                    }


                    let { id, location_id } = reports[index].report;
                    store.getSavedReport(location_id, id).then(data => {
                        fetchedReports.push(data)
                        getSavedReport(index+1)
                    }).catch(error => {
                        reject(error)
                    })
                }
            })
        },

        fetchReports() {
            /*this.$emit('fetch', this.selectedReports);
            this.closeModal();*/
            this.fetchState = {
                ...this.fetchState,
                fetching: true,
                fetchError: false,
                buttonText: `Fetching ${this.overviewMetricReport ? 'overview metric' : 'report'} data...`
            }

            this.getSelectedReports().then(reports => {
                // console.log({ reports })

                this.fetchState = {
                    ...this.fetchState,
                    fetching: false,
                    buttonText: 'Continue'
                }

                this.$emit('fetch', reports);
                this.closeModal();
            }).catch(error => {
                this.fetchState = {
                    ...this.fetchState,
                    fetching: false,
                    fetchError: true,
                    buttonText: error.message ? error.message : 'Could not fetch reports'
                }

                let component = this;

                setTimeout(function () {
                   component.fetchState = {
                       ...component.fetchState,
                       fetchError: false,
                       buttonText: 'Continue'
                   }
                }, 7000)
            })
        }


    }
}
</script>

<style lang="scss" scoped>
.modal{
    .modal-content{
        transition: ease-in-out .3s;
        transform: scale(.9);
        opacity: 0;
        max-width: calc(100% - 1rem);
        width: 1050px;

        .modal-header{
           .header-text{
               width: 100%;
               text-align: center;
               margin-top: 6px;
               font-size: 1rem;
           }
        }

        .getter-content{
            padding: 1.2rem .4rem;
            max-height: calc(100% - 15px);
            overflow-y: auto;
            &::-webkit-scrollbar{
                width: 4px;
                background: transparent;
            }

            &::-webkit-scrollbar-thumb{
                background: rgba(36, 88, 141, .9);
                border-radius: 8px;
            }

            .location-search{
              width: calc(100% - 1rem);
              margin: 0 auto;
            }
            .selected-location-data{
                display: flex;
                align-items: center;
                font-size: .85rem;
                color: var(--theme-blue-alt);
                padding: .5rem .6rem;
                justify-content: flex-end;
                visibility: hidden;

                &.visible{
                    visibility: visible;
                }
                .total-selected{
                    margin-right: .35rem;
                }
            }

            .report-data-content{
                width: 100%;
                max-height: calc(60vh - 60px);
                overflow-y: auto;
                padding: 1rem 0;

                &::-webkit-scrollbar{
                  width: 4px;
                  background: transparent;
                }

                &::-webkit-scrollbar-thumb{
                  background: var(--theme-blue-alt);
                  border-radius: 4px;
                }

                .data-section{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    &.display-section{
                        height: calc(60vh - 100px);
                        max-height: 100%;
                    }

                    .error-text{
                        display: flex;
                        align-items: center;
                        i{
                            font-size: 24px;
                            margin-right: 6px;
                        }

                        span{
                            font-size: .9rem;
                        }
                    }

                    .section-text{
                        &:not(.error-text){
                            color: var(--theme-blue-alt);
                        }

                        font-size: .85rem;
                    }


                    .loading-section{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        width: 100%;


                    }

                    .data-reports{
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                    }

                }

                .report-list-section{
                    display: flex;
                    flex-wrap: wrap;

                    .report-item{


                        margin: 0 .5rem 1rem;
                        flex: 0 0 auto;
                        padding: .75rem;
                        display: flex;
                        flex-direction: column;
                        border-radius: 8px;
                        transition: ease-in-out .2s;

                        &.selected-item{
                            background: var(--theme-blue-alt);
                            color: var(--white);
                        }

                        .report-name{
                            font-size: .9rem;
                            font-weight: 700;
                        }

                        .report-location{
                            margin: .5rem 0 1rem;
                            font-size: .75rem;
                            opacity: .75;
                        }

                        .report-image{
                            width: 100%;
                            height: 180px;
                            margin-top: auto;
                            margin-bottom: 1.25rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            background: #f2f2f2;
                            border-radius: 12px;

                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }

                            i{
                                font-size: 40px;
                                opacity: .5;
                                color: var(--theme-blue-alt)
                            }
                        }

                        .select-btn{
                            width: calc(60% - 1rem);
                            margin: 0 auto;
                            max-width: 250px;
                            padding: .3rem 1.2rem;

                            &.button-white-transparent{
                                color: var(--white);
                                border: 1px solid var(--white);

                                &:hover{
                                    opacity: .75;
                                }
                            }
                        }
                    }
                }
            }

            .button-section{
                display: flex;
                justify-content: center;
                padding-top: 1rem;
                border-top: 1px solid  rgba(80, 148, 221, .3);

                button{
                    &:not(:last-child) {
                        margin-right: 1rem;
                    }
                    min-width: 250px;
                    max-width: 100%;
                    padding: .45rem 1.2rem;

                    position: relative;
                    .load-spinner{
                        position: absolute;
                        top: 6px;
                        right: 6px;
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            .report-item, .loading-section-preview{
                @media screen and (min-width: 990px) {
                    width: calc(33% - .9rem);
                }

                @media screen and (min-width: 651px) and (max-width: 989px) {
                    width: calc(50% - 1rem);
                }

                @media screen and (max-width: 650px) {
                    width: calc(100% - .9rem);
                }
            }
        }
    }

    &.modal-open{
        .modal-content{
            transform: scale(1);
            opacity: 1;
        }
    }
}
</style>


<style lang="scss">
.report-getter-modal{
    .modal-content{
        .data-search-list{
            top: 112px !important;
            left: 35px !important;
        }

        .loading-section{
            .loading-section-preview{
                width: calc(33% - 1rem);
                margin: 0 .5rem 1rem;
            }
        }

        .location-selector{
            .selection-section{
                padding: 0;
            }
        }
    }

}
</style>
