<template>
<div class="location-selector">
   <div class="search-group location-selector-group" ref="searchField">
       <div :class="['field-group', { 'list-visible': state.visible }]">
           <i class="bx bx-search-alt search-icon"></i>
           <input
                   :type="field.type"
                   :placeholder="field.placeholder"
                   :class="['form-field', {
                'input-error': field.hasError
            }]"
                   v-model="field.value"
                   @input="detectInput()"
                   @focus="displayList()"
           />
       </div>
       <div class="data-search-list"
            v-if="state.visible"
            ref="searchList"
            :style="{
                    position: 'fixed',
                    top: `${position.top}px`,
                    left: `${position.left}px`,
                    width: `${position.width}px`
                }"
       >
           <div class="search-loader" v-if="state.fetching">
               <q-spinner-dots class="dot-spinner"/>
               <span class="search-text">{{ state.searchText }}...</span>
           </div>

           <div class="search-data" v-if="!state.fetching">
               <div class="search-list-data" v-if="!state.fetchError">
                   <div class="search-list-section" v-if="availableLocationOptions.length > 0">
                       <ul>
                           <li v-for="(option, optionIndex) in availableLocationOptions"
                               :key="optionIndex"
                               @click="getOption(option)"
                               :aria-description="option.label"
                               :class="[{'selected': isASelectedLocation(option.value) }]"
                           >
                               {{ option.label  }}
                               <i class="bx bx-check check-icon"></i>
                           </li>

                       </ul>

                       <div  class="search-list-lower">
                           <div class="data-actions" v-if="!$slots.bottom">
                               <div class="action-item selections-item" >
                                   <span>{{ locations.length }} selected</span>
                               </div>

                               <div class="action-item select-section">
                                   <checkbox
                                           :checked="selectAll"
                                           @change="toggleSelection($event)"
                                   />
                                   <span>
                                        Select all
                                      </span>
                               </div>

                               <div class="action-item close-section" @click="closeSection()">
                                   <i class="bx bx-check-circle"></i>
                                   <span>Done</span>
                               </div>
                           </div>

                           <slot v-if="$slots.bottom" name="bottom" class="data-actions"></slot>
                       </div>
                   </div>

                   <div class="empty-list-data" v-if="availableLocationOptions.length === 0">
                       {{ state.emptyListText }}
                   </div>
               </div>

               <div class="search-list-error error-text" v-if="state.fetchError">
                   <i class="bx bx-error-circle"></i>
                   <span>{{ state.errorText }}</span>
               </div>
           </div>
       </div>
       <span v-show="field.hasError && field.errorText"
             class="error-text"
       >
            {{ field.errorText }}
        </span>

   </div>

    <div class="selection-section" v-if="locations.length > 0">
        <div class="selection-subview" v-if="subviewMode">
           <span>
                {{ subviewText }}
           </span>

            <button class="edit-btn" @click="switchToSubview($event, false)">
                Edit
            </button>
        </div>

        <div class="selection-edit-view" v-if="!subviewMode">
            <div class="edit-view-header">
                <button class="edit-btn"
                        @click="switchToSubview($event, true)"
                >
                    View summary
                </button>
            </div>

            <div class="selection-list">
              <span class="edit-view-item"
                    v-for="(item, itemIndex) in locations"
                    :key="itemIndex"
                    @click="removeSelection(item)"
              >
                  <span>{{ item.name }}</span>
                  <i class="bx bx-x close-icon"></i>
              </span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {useStore} from "../store/dataStore";
import CustomCheckbox from "./CustomCheckbox.vue";
import {getPosition, getScrollParent, sortStringRow} from "../helpers/helper_functions";
export default {
    name: "LocationSelector",
    components: {
       'checkbox': CustomCheckbox
    },
    setup() {
        return {
            store: useStore()
        }
    },
    props: {
        locationId: {
            type: String,
            required: true
        },
        locations: {
            type: Array
        }
    },
    mounted() {
       this.getCompanyLocations();
        setTimeout(() => {
            this.listPosition();

            this.scrollParent.addEventListener('scroll', this.listPosition);
        }, 0)
      document.addEventListener('click', this.detectOutsideClick);
      window.addEventListener('resize', this.detectResize);
    },
    unmounted() {
      document.removeEventListener('click', this.detectOutsideClick);
      this.scrollParent.removeEventListener('scroll', this.listPosition);
      window.removeEventListener('resize', this.detectResize);
    },
    emits: [
        'change',
        'open',
        'close',
        'update:locations',
        'edit',
        'select'
    ],
    data() {
       return {
           locationList: [],
           locationOptions: [],
           selectAll: false,
           subviewMode: true,
           state: {
               visible: false,
               fetching: false,
               fetchError: false,
               searchText: 'Searching for agency locations',
               errorText: '',
               emptyListText: 'No matching locations.'
           },
           field: {
               type: 'text',
               placeholder: 'Search for a location',
               hasError: false,
               required: true,
               errorText: '',
               value: '',
               name: 'locationQuery'
           },
           position: {
               top: 0,
               left: 0,
               width: 0
           },
       }
    },
    computed: {
        scrollParent() {
            let element = this.$refs.searchField
            let scrollParent = getScrollParent(element);

            if (!scrollParent) {
                scrollParent = window;
            }

            return scrollParent
        },
        availableLocationOptions() {
            return this.locationOptions.filter(option => option.label.toLowerCase().includes(this.field.value.toLowerCase()))
        },

        subviewText() {
            let text = '';
            if (this.locations.length === 0) {
                return ''
            }

            if (this.locations.length > 3) {
                let slicedText = this.locations.slice(0, 3).map(item => ' ' + item.name).join("`").replace(/`/g, ",");
                let unslicedSelections = this.locations.slice(3, this.locations.length);
                text += slicedText + ' and ' + unslicedSelections.length + ` other${unslicedSelections.length > 1 ? 's' : ''}.`
            } else {
                let selectionItems = this.locations.length
                let subText = this.locations.slice(0, selectionItems-1).map(item => {
                    return ` ${item.name}`
                }).join("`").replace(/`/g, ",");

                text += `${ this.locations.length > 1 ? `${subText} and ` : ''}${this.locations[selectionItems-1].name}`
            }

            return text
        }
    },
    methods: {
        detectResize() {
            this.listPosition();
        },

        listPosition() {
            setTimeout(() => {
                let element = this.$refs.searchField
                let { top, left, width } = getPosition(element, this.$refs.searchList)
                this.position = {
                    ...this.position,
                    top,
                    left,
                    width
                }
            }, 0)
        },

        toggleSelection(checked) {
            this.selectAll = checked;

            let selections = [];
            if (checked) {
                selections = this.locationOptions.map(option => {
                    let { label, value } = option
                    return {
                        name: label,
                        id: value
                    }
                })
            }

            this.$emit('update:locations', selections);
            this.$emit('change', selections)
            this.subviewMode = true;
        },


        isASelectedLocation(locationId) {
            return !!this.locations.find(selection => selection.id === locationId)
        },


        detectOutsideClick(event) {
          if (!event.target.closest('.location-selector-group') && this.state.visible) {
              this.state.visible = false;
              this.$emit('close')
          }
        },

        async getAgencyLocations(locationId) {
            let agencyLocation = await this.store.getAgencyLocation(locationId);
            let agencyLocationID = agencyLocation.data.location.id;
            return await this.store.getAgencyLocations(agencyLocationID)
        },
        getCompanyLocations() {
            this.state = {
                ...this.state,
                fetching: true,
                fetchError: false,
                searchText: 'Searching for company locations',
                errorText: ''
            }
            this.getAgencyLocations(this.locationId).then(response => {
                let locationData = response.data.locations;
                locationData = sortStringRow(locationData, 'name', false);
                this.locationList = locationData;

                this.locationOptions = locationData.map(location => {
                    return {
                        label: location.name,
                        value: location.id
                    }
                })

                this.state = {
                    ...this.state,
                    fetching: false,
                    fetchError: false
                }
            }).catch(() => {
                this.state = {
                    ...this.state,
                    fetching: false,
                    fetchError: true,
                    errorText: 'Cannot fetch company locations'
                }
            })
        },

        displayList() {
            if (!this.state.visible) {
                this.state.visible = true
                this.$emit('open')
            }

            this.listPosition()
        },

        toggleSelect(option) {
            let locations = [];
            if (this.isASelectedLocation(option.value)) {
                locations = this.locations.filter(selection => selection.id !== option.value)
            } else {
                locations = [
                    ...this.locations,
                    {
                        name: option.label,
                        id: option.value
                    }
                ]
            }
            this.selectAll = locations.length === this.locationOptions.length

            this.$emit('update:locations', locations);
            this.$emit('change', locations)
        },

        switchToSubview(event, mode) {
            event.preventDefault();
            this.subviewMode = mode;
        },


        removeSelection(item) {
            let { name, id } = item
            let option = { label: name, value: id };
            this.toggleSelect(option);
            this.$emit('edit')

        },

        getOption(option) {
            this.toggleSelect(option);
            this.subviewMode = true;
        },

        closeSection() {
            this.state.visible = false;
            this.field.value = '';
            this.$emit('select', this.locations);
            this.$emit('close')

        },

        detectInput() {
            let changedPosition = false;
            let detectedPosition = getPosition(this.$refs.searchField, this.$refs.searchList);

            for (let key in this.position) {
                if (this.position[key] !== detectedPosition[key] && !changedPosition) {
                    changedPosition = true;
                }
            }

            if (changedPosition)  {
                let { top, left, width } = detectedPosition;

                this.position = {
                    ...this.position,
                    top,
                    left,
                    width
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.location-selector{
  width: 100%;
  margin-top: .4rem;

  .search-group{
    width: 100%;

    .field-group{
      width: 100%;
      position: relative;
      &.list-visible{
        input{
          border-radius:  8px 8px 0 0;
        }
      }

      .search-icon{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        font-size: 17px;
        color: var(--theme-blue-alt);
      }

      .form-field{
        padding: 8px 32px;
        margin-top: 0;
      }


    }
  }


  .selection-section{
    margin-top: .4rem;
    padding: 0 .4rem;

    .selection-subview{
       color: var(--theme-blue-alt);
       opacity: .9;
       font-size: .8rem;


    }

    .selection-subview, .selection-edit-view{
      button{
        &.edit-btn{
          font-size: .8rem;
          color: var(--theme-blue-alt);
          margin-left: .1rem;
          font-weight: 700;
          background: transparent;
          border: 1px solid transparent;
          cursor: pointer;

          &:hover{
            text-decoration: underline;
          }
        }
      }
    }

    .selection-edit-view{
      .edit-view-header{
        display: flex;
        justify-content: flex-end;

        button{

        }
      }

      .selection-list{
        display: flex;
        flex-wrap: wrap;
        padding: .25rem 0;

        .edit-view-item{
          flex: 0 0 auto;
          margin-right: .5rem;
          display: flex;
          align-items: center;
          color: var(--theme-blue-alt);
          border-radius: 12px;
          padding: .15rem .6rem;
          border: 1px solid var(--theme-blue-alt);
          transition: ease-in-out .15s;
          margin-top: .5rem;

          &:hover{
            background: var(--theme-blue-alt);
            color: var(--white);
            cursor: pointer;

            i{
              cursor: pointer;
              transform: scale(1.1);
            }
          }


          span{
            font-size: .8rem;
            margin-right: .4rem;
          }

          i{
            font-size: 20px;
          }
        }
      }
    }
  }
}
.data-search-list{
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: var(--white);
  z-index: 99;
  box-shadow: 0 0 1px 2px rgba(36, 88, 141, .1);

  .search-loader,
  .search-list-error,
  .empty-list-data{
    padding: .75rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-loader{
    .dot-spinner{
      color: var(--theme-blue-alt);
      width: 20px;
      height: 20px;
      margin-right: .5rem;
    }

    .search-text{
      color: var(--theme-blue-alt);
      opacity: .85;
      font-size: .8rem;
    }
  }

  .search-list-error{
    i{
      font-size: 20px;
      margin-right: .4rem;
    }
  }

  .empty-list-data{
    font-size: .85rem;
    color: var(--theme-blue-alt);
  }

  .search-list-section{
    max-height: 250px;

    ul{
      margin: 0;
      padding-left: 0;
      height: 100%;
      max-height: 210px;

      overflow-y: auto;

      &::-webkit-scrollbar{
        width: 4px;
        background: transparent;
      }


      &::-webkit-scrollbar-thumb{
        background: var(--theme-blue-alt);
        border-radius: 4px;
      }

      li{
        list-style: none;
        font-size: .85rem;
        color: var(--theme-blue-alt);
        padding: .5rem 1.35rem;
        transition: ease-in-out .15s;
        cursor: pointer;
        position: relative;

        &.selected{
          background: var(--theme-blue-alt);
          color: var(--white);

          .check-icon{
            visibility: visible;
          }
        }

        .check-icon{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 6px;
          font-size: 20px;
          color: var(--white);
        }

        &:not(.selected) {
          &:hover{
            background: rgba(36, 88, 141, .1);
          }

          .check-icon{
            visibility: hidden;
          }
        }
      }
    }

    .search-list-lower{
      display: flex;
      align-items: center;
      height: 40px;

      .data-actions{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 1.2rem;
        border-top: 1px solid rgba(36, 88, 141, .25);
        .action-item{
          display: flex;
          align-items: center;

          &.selections-item{
            opacity: .85;
          }

          &.select-section{
            margin-left: auto;
            span {
              margin-left: .5rem;
            }
          }

          span{
            font-size: .8rem;
          }

          span, i{
            color: var(--theme-blue-alt);
          }

          i{
            font-size: 24px;
            margin-left: .25rem;
            font-weight: 400;
            line-height: 1.2rem;
            display: inline-block;
            vertical-align: middle;
            margin-top: .1rem;
          }

          &.remove-item{
            transition: ease-in-out .2s;
            opacity: .85;

            &:hover{
              opacity: 1;
              cursor: pointer;
              span{
                text-decoration: underline;
              }
            }
          }

          &.close-section{
            margin-left: .6rem;
            transition: ease-in-out .2s;

            i{
              margin-right: .3rem;
            }

            &:hover{
              opacity: .6;
              cursor: pointer;
            }
          }
        }
      }
    }
  }


}
</style>

