<template>
<q-dialog class="video-window" position="standard" v-model="open">
<div class="dialog-box">
    <div class="modal-header">
        <h4 class="header-text">
            <span v-if="!$slots.header">Watch video</span>
            <slot name="header" v-if="$slots.header"></slot>
        </h4>
        <i class="bx bx-x close-icon" @click="open = false"></i>
    </div>

    <div class="video-description" v-if="$slots.body">
        <slot name="body"></slot>
    </div>

    <div class="video-player-section">
        <video-player
            :video-link="videoLink"
            :cover-image-link="coverImageLink"
            :auto-play="autoPlay"
        />
    </div>
</div>
</q-dialog>
</template>

<script>
import VideoPlayer from "./VideoPlayer.vue";

export default {
    name: "VideoWindow",
    components: {VideoPlayer},
    props: {
        visible: {
            type: Boolean
        },
        videoLink: {
            type: String,
            required: true
        },
        coverImageLink: {
            type: String,
            required: false
        },
        autoPlay: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'update:visible',
        'close',
        'open'
    ],
    mounted() {
        this.open = this.visible;
    },
    watch: {
        visible(state) {
            this.open = state
        },

        open(state) {
            if (!state) {
                this.$emit('update:visible', state)
                this.$emit('close');
            } else {
                this.$emit('open')
            }
        }
    },
    data() {
        return {
            open: false
        }
    }
}
</script>

<style>
@media screen and (min-width: 990px) {
    .q-dialog.video-window .q-dialog__inner .dialog-box{
            max-width: 900px;
    }
}

@media screen and (max-width: 989px) {
    .q-dialog.video-window .q-dialog__inner .dialog-box{
        max-width: calc(100% - .5rem);
    }
}

@media screen and (max-width: 600px) {
    .q-dialog .q-dialog__inner{
        padding: 24px 6px;
    }
}
</style>

<style lang="scss" scoped>
.dialog-box{
    margin-top: 0;
    padding: 1rem;
}

.modal-header{
    padding: .25rem 0 .35rem;

    .header-text{
        font-size: 1rem;
        padding-right: .5rem;
        white-space: initial;
        line-height: 1.35rem;
    }
}

.video-description{
    font-size: .95rem;
    color: rgba(0, 0, 0, .9);
    line-height: 1.2rem;
    padding-top: 1.2rem;
}

.video-player-section{
    padding: 1.2rem 0;
    width: 100%;

    @media screen and (min-width: 480px) {
        height: 450px;
    }

    @media screen and (max-width: 479px) {
        height: 450px;
    }
}
</style>
