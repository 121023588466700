<template>
  <div class="modal loading-modal">
    <div class="modal-content report-loading">
      <div class="loading-text">{{ text }}...</div>
      <div class="loading-bar">
        <div class="bar-animated"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSequence",
  props: {
    text: String
  }
}
</script>

<style scoped>
.modal.loading-modal{
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, .8);
}

.modal.loading-modal .modal-content{
  background: transparent;
}
@media screen and (min-width: 1024px) {
  .loading-modal .report-loading{
    width: calc(100% - 55rem);
  }
}

@media screen and (min-width: 750px) and (max-width: 1024px) {
  .loading-modal .report-loading{
    width: calc(100% - 12.5rem);
  }
}

@media screen and (max-width: 749px) {
  .loading-modal .report-loading{
    width: calc(100% - 2.5rem);
  }
}

.loading-modal .report-loading .loading-text{
  text-align: center;
  color: var(--white);
  font-weight: 700;
  font-size: 1.35rem;
}

.loading-modal .loading-bar{
  position: relative;
  width: 100%;
  height: 4px;
  overflow: hidden;
  margin: 1.5rem 0;
}

.loading-modal .loading-bar .bar-animated{
  background: var(--theme-blue-alt);
  width: 250px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  animation: slide-left 1s infinite;
}

@keyframes slide-left {
  0%{left: 0%}
  100%{left: 120%}
}

@-webkit-keyframes slide-left {
  0%{left: 0%}
  100%{left: 120%}
}

@-moz-keyframes slide-left {
  0%{left: 0%}
  100%{left: 120%}
}
</style>
