import { percentFields, dollarFields } from "./field_data_types";
import { setTimeValue } from "./date_functions";
import hexRgb from "hex-rgb";
import {v4 as uuidv4} from "uuid";
import axios from "axios";

export const replaceSlashes = (string) => {
    return string.replace(/\//g, '-');
};

export const replaceSpaceWithHyphens = (string) => {
    return string.replace(/\s/g, "-");
}

export const replaceSpaceWithUnderscores = (string) => {
    if (!string) {
        return string
    }
    return string.toLowerCase().replace(/\s/g, "_")
}

export const capitalizeFirstLetter = (string) => {
    if (!string) {
        return string
    }
    return string.toString().charAt(0).toUpperCase() + string.slice(1);
}
export const capitalizeFirstLetters = (string) => {
    if (!string) {
        return string
    }
    return string.toString().split(" ").map(item => {return capitalizeFirstLetter(item)}).toString().replace(/,/g, " ")
}

export const generateKey = (length = 16) => {
    let text = '';
    const possible = '0123456789';

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return parseInt(text)
}

/**
 * Removes special characters from strings except "." (dots) and "-" (minus signs)
 * **/
export function removeSpecialChars(string) {
    if (!string){
        return string
    }
   return string.toString().split(" ")
                .map(item => ( item.replace(/[^a-zA-Z0-9.-]/g, "")))
                .join().replace(/,/g, " ")
}

/**
 * Takes price values and adds commas at the right positions
 * **/

export function splitIntoCommas(intValue) {
    if (!intValue) {
        return intValue
    }

    let separatedValues;
    let splitValue = removeSpecialChars(intValue);

    if (splitValue[0] === '0' && splitValue.length > 1) {
        splitValue = splitValue.slice(1);
    }

    let valueSplit = splitValue.split(".");
    let floatValue = valueSplit.length > 1;

    if (!floatValue) {
        separatedValues = addCommas(splitValue)
    } else {
        let approxFloat = parseFloat(`0.${valueSplit[1]}`).toFixed(2).split(".")[1];
        separatedValues = `${addCommas(valueSplit[0])}.${approxFloat}`
    }


    function addCommas(value) {
        let returnValue = '';
        let negativeValue = value[0] === '-'

        let intValue = negativeValue ? value.slice(1, value.length) : value

        if (intValue.length > 3) {
            let splitPos = intValue.length % 3;
            let startIndex = splitPos === 0 ?  3 : splitPos;

            let valueArray = intValue.split("");
            let finalValue = []

            valueArray.forEach((val, index) => {
                if (index === startIndex && startIndex !== valueArray.length) {
                    finalValue[index] = `,${val}`
                    startIndex += 3
                } else {
                    finalValue[index] = val
                }
            })

            returnValue = finalValue.join("+").replace(/\+/g, "");

        } else {
            returnValue = intValue
        }

        if (negativeValue) {
            returnValue = `-${returnValue}`
        }

        return returnValue
    }

    return separatedValues
}


/**
 * Copies text to clipboard
* **/
export const copyToClipboard = async (text) => {
    if (!navigator.clipboard) {
        let textarea = document.createElement("textarea")
        textarea.innerHTML = text;
        document.body.appendChild(textarea)
        textarea.select()

        return document.execCommand('copy')
    } else {
        return await navigator.clipboard.writeText(text)
    }
}

export const addFieldSigns = (field, key) => {
    return key.includes('percent') || percentFields.indexOf(key) !== -1 ? `${field} %` :
        dollarFields.indexOf(key) !== -1 ? `$ ${field}` : field.toString();
}

export const formatValue = (name, value, unchecked = []) => {
    if (unchecked.length > 0 && unchecked.indexOf(name) !== -1) {
        if ( value === 0) {
            value = ''
        }
        return value
    }

    if (!value || parseFloat(value) === 0) {
        return '-'
    }

    if (name.includes('time')) {
        // console.log({ name, value })
        return setTimeValue(value)
    } else {
        if (name !== 'key') {
            if (name.includes('percent') || percentFields.indexOf(name) !== -1) {
                value = parseValue(value);
                return addFieldSigns(value, name)
            }

            if (dollarFields.indexOf(name) !== -1) {
                value = splitIntoCommas(parseValue(value))
                return addFieldSigns(value, name)
            }

            return splitIntoCommas(parseValue(value))
        }
    }

    return "-"
}

export function parseValue(value) {
    return value.toString().includes(".") ? parseFloat(value).toFixed(2) : value.toString()
}


/** Converts string to lowercase and replaces white space with underscores **/
export function formatString(string) {
    if (!string) {
        return ''
    }
    return string.toLowerCase().replace(/\s/g, "_");
}

export function formatKeyToUnderScore(key) {
    let key_pair_split = key.split("");
    key_pair_split.map((value, index) => {
        if (value.toUpperCase() === value) {
            key_pair_split[index] = value.toLowerCase();
            key_pair_split.splice(index, 0, "_")
        }
    })

    return key_pair_split.toString().replace(/,/g, "")
}

export const isValidColor = (hexCode) => {
    let regex =/^#([0-9a-f]{3}){1,2}$/i;
    return regex.test(hexCode)
}

export function validateInput(input, supportFloat = true) {
    let supported_characters = new Array(10).fill('')
        .map((item, index) => {
            return index.toString()
        });

    if (supportFloat) {
        supported_characters.push(".")
    }

    let split_values = removeSpecialChars(input.toString()).split("");


    let formattedValue = ''
    split_values.forEach(value => {
        if (supported_characters.indexOf(value) !== -1) {
            if (value !== '.') {
               formattedValue += value
            } else {
                if (!formattedValue.includes(".")) {
                    formattedValue += value
                }
            }
        }
    });

    return formattedValue;
}


export const replaceSpaceWithUnderscore = (string) => {
    return string.toLowerCase().replace(/\s/g, "_")
}

export function getRgbValue(color, opacity = 1) {
    let { red, green, blue } = hexRgb(color);
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`
}


export function getPosition(element, dropdownElement) {
    let offsetTop = 0, offsetLeft = 0, elementWidth = 0;

    if (element) {
        let { top, left, width, height } = element.getBoundingClientRect();
        let elementStyle = window.getComputedStyle(element);

        let margins = {
            top: parseFloat(elementStyle['margin-top']),
            left: parseFloat(elementStyle['margin-left']),
            right: parseFloat(elementStyle['margin-right']),
            bottom: parseFloat(elementStyle['margin-bottom'])
        }

        let bottomOffset = top + height;
        let topOffset = 0, dropdownHeight = 0;

        if (dropdownElement) {
            dropdownHeight = dropdownElement.clientHeight;
            topOffset = top - dropdownHeight;
        }

        offsetTop = bottomOffset + dropdownHeight < window.innerHeight ? bottomOffset : topOffset;
        offsetLeft = left;
        elementWidth = width;

        offsetLeft -= margins.left;
        offsetTop -= margins.top;
    }

    return { top: offsetTop, left: offsetLeft, width: elementWidth }


}

export function getScrollParent(element) {
    let unallowedNodes = ['html', '#document']
    if (!element) {
        return null
    }

    if (unallowedNodes.indexOf(element.nodeName.toLowerCase()) !== -1) {
        return null
    }

    let overflowY = window.getComputedStyle(element).overflowY;
    let isScrollable = overflowY !== 'hidden' && overflowY !== 'visible';

    if (isScrollable && element.scrollHeight > element.clientHeight) {
        return element
    } else {
        return getScrollParent(element.parentNode)
    }
}

export function sortStringRow(dataRows, sortKey, descending = false) {
    if (!dataRows) {return dataRows}
    if (!Array.isArray(dataRows)) {return dataRows}
    if (dataRows.length === 0) {return dataRows}

    if (dataRows[0][sortKey] === undefined) {
        return dataRows
    }

    let sortID = uuidv4();

    let rowsWithKeys = dataRows.map(row => {
        return { ...row, dataSortKey: uuidv4() }
    })
    let mappedRows = rowsWithKeys.map(row => {
        let rowData = {
            dataSortKey: row.dataSortKey
        }
        rowData[sortKey] = row[sortKey]
        return rowData
    }).map(row => (`${row[sortKey]} dataSortKey-${sortID}:${row.dataSortKey}`));

    mappedRows = descending ? mappedRows.sort().reverse() : mappedRows.sort();

    return mappedRows.map(row => {
        let rowData = {};
        let valueSplit = row.split(`dataSortKey-${sortID}:`);
        rowData = {
            dataSortKey: valueSplit[1]
        }
        return rowData
    }).map(row => {
        let matchingRow = rowsWithKeys.find(item => item.dataSortKey === row.dataSortKey);
        delete matchingRow.dataSortKey;

        return matchingRow
    })
}

/*
let dashboardReport = {
            id: report.id,
            reportMetricId: report.id,
            location: location_id,
            pipeline: { name: pipeline && "name" in pipeline ? pipeline.name : "", id: pipeline_id },
            reportType: report_type,
            refresh: false,
            range: dateRange,
            metricColors: colors,
            preset,
            template: {},
            report: data,
            savedMetricId: savedMetricsId,
            starredMetrics: [],
            reportData,
            kpiData,
            filters: [],
            tableData: {
                rows: [],
                columns: [],
                visible_columns: [],
                filter_items: []
            },
            metricData: {
                totals: {},
                averages: {},
                columns: [],
                kpis: [],
                customMetrics: []
            },
            overviewMetricReport: false,
            errorText: "",
            savedReport
        }

        let metric = {
            reportId: report.id,
            reportMetricId: report.id,
            locationId: report.location_id,
            rows: [],
            reportType: report_type,
            totals: {},
            averages: {},
            columns: [],
            kpis: [],
            customMetrics: [],
            colors
        }

        this.dashboardReports = [
            ...this.dashboardReports,
            dashboardReport
        ]

        this.overviewMetrics = [
            ...this.overviewMetrics,
            metric
        ]
*/


export function formatTemplateName(templateName) {
    if (!templateName) {
        return ''
    }

    let words = templateName.split(" ");
    if (words.length > 0){
        let lastWord = words[words.length-1];

        if (lastWord.toLowerCase() !== 'template') {
            templateName += ' template'
        }
    }

    templateName = capitalizeFirstLetters(templateName);
    return templateName
}



export async function getLocationKey(locationID) {
    let backendServer = "https://ordermotive.fasterapps.com/";
    return await axios.get(`${backendServer}locationKey/${locationID}`)
}
