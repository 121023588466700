<template>
 <div class="content-holder nav-present">
   <error-handler v-if="locationId === ''" :error="errorText"></error-handler>
   <div v-if="locationId !== ''">
     <div class="setup-header">Setup</div>

     <div class="setup-tab-container">
       <div class="flexbox tab-heading">
         <div class="header-item"
              v-for="(item, index) in header_items"
              :key="index"
              :class="{'active-item': index === active_header_index}"
              @click="active_header_index = index"
         >{{ item }} Setup</div>
       </div>

       <div class="tab-content report-content">
         <div class="content-item">
           <header>Add snippet of code to your HighLevel Account</header>

           <!-- <img src="../assets/icons/video-snippet.svg" alt="Code snippet icon"> -->

           <video-player :video-link="videoLink"/>

           <code-snippet :snippet="locationSnippet"></code-snippet>
         </div>
       </div>
     </div>
   </div>
 </div>
</template>

<script>
import CodeSnippet from "../components/CodeSnippet";
import VideoPlayer from "@/components/VideoPlayer";
import ErrorHandler from "@/components/ErrorHandler";
export default {
  name: "SetupView",
  components: {
    ErrorHandler,
    'code-snippet': CodeSnippet,
    'video-player': VideoPlayer
  },
  mounted() {

    //console.log('Window location:', window.location)
    let params = new URLSearchParams(window.location.search)
      if (params.has('location_id')) {
        this.locationId = params.get('location_id')
        this.locationSnippet = `${window.location.origin}/location_id=${this.locationId}`
      }
  },
  data () {
    return {
      active_header_index: 0,
      header_items: ['Report', 'Visualization', 'Field Management'],
      videoLink: require('../videos/sales_analysis.mp4'),
      locationId: '',
      errorText: 'No location detected',
      codeSnippet: '<iframe width=“560” height=“315” src=“https://www.youtube.com/embed/owsfdh4gxyc” frameborder=“0” allowfullscreen></iframe>',
      locationSnippet: ''
    }
  }
}
</script>

<style lang="scss" scoped>
$header-color: rgba(0, 0, 0, .85);
$header-font-size: 1.05rem;
$bg-color: #ffffff;
$header-border-color: rgb(36, 88, 141, .25);

body{
  background: $bg-color;
}

.setup-header{
  font-size: 1.15rem;
  color: $header-color;
  font-weight: 700;
  line-height: 2rem;
}

.setup-tab-container{
  border-radius: 1rem;
  box-shadow: 2px 6px 10px rgb(36, 88, 141, .1);
  margin: 1.8rem 0;
  background: var(--white);
  .tab-heading {
    border: 1px solid $header-border-color;
    border-radius: 1rem 1rem 0 0;
    .header-item {
      width: calc(100% / 3);
      font-size: $header-font-size;
      text-align: center;
      padding: 1.2rem .8rem;
    }

    .header-item.active-item{
      background: var(--theme-blue);
      color: var(--white);
    }

    .header-item:first-child{
      border-radius: 1rem 0 0 0;
    }

    .header-item:last-child{
      border-radius: 0 1rem 0 0;
    }

    .header-item:not(:last-child) {
      border-right: 1px solid $header-border-color;
    }

    .header-item:not(.active-item):hover{
      background: rgb(36, 88, 141, .25);
      color: var(--white);
      transition: .2s ease;
      cursor: pointer;
    }
  }

  .tab-content{
    padding: 2.2rem 0;
    .content-item{
      @media screen and (min-width: 990px) {
        width: calc(100% - 15rem);
      }
      @media screen and (min-width: 651px) and (max-width: 989px) {
        width: calc(100% - 10rem);
      }

      @media screen and (max-width: 650px) {
        width: calc(100% - 1.5rem);
      }

      margin: 0 auto;

      header{
        font-size: 1.65rem;
        font-weight: 700;
        text-align: center;
      }

      img{
        display: block;
        margin: 2.8rem auto;
      }
    }
  }
}
</style>

