<template>
  <div class="percent-checkmark">
    <div class="question">
      ?
      <div class="popup-text">
        <div class="content">{{ text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoPopup",
  props: {
    text: String
  }
}
</script>

<style scoped lang="scss">
.percent-checkmark{
  position: absolute;
  top: 3px;
  right: 3px;
  background: var(--theme-blue);
  width: 15px;
  height: 15px;
  color: var(--white);
  font-size: .7rem;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;

  .question{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;

    &:hover{
      .popup-text{
        transform: translateX(-50%) scale(1);
      }
    }

    .popup-text{
      transition: ease-in-out .3s;
      position: absolute;
      top: -30px;
      left: 50%;
      border-radius: 4px;
      transform: translateX(-50%) scale(0);
      max-width: 200px;
      background: var(--theme-blue-alt);
      font-size: .7rem;
      z-index: 12;



      .content{
        width: 100%;
        height: 100%;
        position: relative;
        padding: .3rem .6rem;

        &::after{
          position: absolute;
          content: "";
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-top: 6px solid var(--theme-blue-alt);
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
        }
      }
    }
  }
}


</style>
