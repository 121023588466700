<template>
<div :class="['field-group report-search-group', {'edit-mode': inEditMode }]">
    <div class="field-container">
        <input v-model="fieldValue"
               class="form-field"
               placeholder="Search for a report using its name"
               @input="getFieldValue($event)"
        />

        <i class="bx bx-search-alt search-icon"></i>
    </div>

    <edit-overlay
        :visible="inEditMode"
        v-model:hide="hideComponent"
    />
</div>
</template>

<script>
import EditOverlay from "./EditOverlay.vue";

export default {
    name: "ReportSearch",
    components: {EditOverlay},
    props: {
        value: {
            required: true
        },
        inEditMode: {
            type: Boolean,
            default: false
        },
        hidden: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'update:value',
        'search',
        'update:hidden'
    ],
    watch: {
        inEditMode: {
            handler(state) {
                // console.log('In edit mode', state, this.hidden)
                if (state) {
                    this.hideComponent = this.hidden
                }
            },
            immediate: true
        },
        hideComponent(state){
            //  console.log('hideComponentState', state)
            this.$emit('update:hidden', state)
        },

        hidden(state) {
            this.hideComponent = state
        },
    },
    data() {
        return {
            fieldValue: '',
            hideComponent: false
        }
    },
    methods: {
        getFieldValue(event) {
            let value = event.target.value;
            this.$emit('update:value', value);
            this.$emit('search', { value, originalEvent: event })
        }
    }
}
</script>

<style lang="scss" scoped>
.report-search-group{

    &.edit-mode{
        .field-container{
            position: relative;
            z-index: 2;
        }
    }

  /*background: var(--white);*/
  height: 100%;
  /*box-shadow: 6px 8px 16px rgba(80, 148, 221, .12);*/
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;

  .field-container{
    position: relative;

    .form-field{
      padding: 8px 36px;
      margin-top: 0;
        background: var(--white);
    }

    .search-icon{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
      font-size: 16px;
      color: var(--theme-blue-alt);
    }
  }


}
</style>
