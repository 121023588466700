<template>
<div class="modal report-save-modal" v-if="open">
  <div :class="['modal-content', {'is-visible': visible}]">
      <div class="modal-header">
        <i class="bx bx-x close-icon" @click="closeSaver()"></i>
      </div>
      <div class="save-section" v-if="!state.saveSuccess">
       <dropdown
           :items="saveOptions"
           :searchable="false"
           :default-value="defaultSelection"
           class="option-select"
           @valueChange="getSelectedValue($event)"
       />

        <div>
          <img alt="screenshot" v-if="screenshotUrl" :src="screenshotUrl" style="width: 100%; height: 300px; object-fit: contain"/>
        </div>

        <div class="save-options-section top-margin-sm">
          <div class="option-positioner" :style="{ 'transform': `translateX(-${activeTab * 100}%)`}">
            <div v-for="(option, optionIndex) in saveOptions"
                 :key="optionIndex"
                 :class="[
                     'option-section',
                     {
                       'save-confirm-section' : option.value === 'save',
                       'saveas-section': option.value === 'saveAs'
                     }
                 ]"
            >
             <div class="confirmation-section" v-if="option.value === 'save'">
               <div class="half-width text-center confirmation-text">
                <span>
                  Your report will be saved as <span class="bold-text report-text">{{ defaultReportName }}</span>
                </span>
               </div>
               <div class="flex flex-align-center top-margin-md">
                    <checkbox
                        color="#5094DD"
                        :checked="makeDefault"
                        @change="setDefault($event)"
                    />
                    <span class="left-margin-sm">Set as default report</span>
               </div>
             </div>

              <div class="save-select-section" v-else-if="option.value === 'saveAs'">
                <div
                    v-for="(field, index) in saveAsFields"
                    :key="index"
                    :class="['form-group']">
                  <div :class="[
                      'group-item',
                      {
                        'checkbox-group': field.fieldType === 'checkbox'
                      }
                    ]"
                  >
                    <label class="field-label">
                      {{ field.label }}
                    </label>

                    <input
                        :type="field.type"
                        :placeholder="field.placeholder"
                        v-if="field.fieldType === 'input'"
                        :class="['form-field', { 'input-error' : field.hasError }]"
                        v-model="field.value"
                        @input="checkForExistingReport($event, field)"
                        :ref="'input-field-'+index"
                    />

                    <checkbox
                        v-if="field.fieldType === 'checkbox'"
                        :checked="field.value"
                        :color="field.color"
                        @change="setCheckValue(field, $event)"
                    />
                  </div>

                  <span :class="[ 'error-text', { 'error-visible': field.hasError && field.errorText }]">
                    {{ field.errorText }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div v-if="state.saveError && state.errorText" class="y-margin-sm error-text text-center">
          {{ state.errorText }}
        </div>

        <div class="flexbox justify-center action-section">
          <button class="button button-red-transparent"
                  @click="closeSaver()">
            Cancel
          </button>

          <button class="button button-blue-alt"
                  :disabled="hasErrors() || state.saving"
                  @click="performAction()"
          >
            {{ state.buttonText }}

            <span class="process-spinner">
              <q-spinner
                  v-if="state.saving"
                  :thickness="4"
              />
            </span>
          </button>
        </div>
      </div>

    <div class="success-section" v-if="state.saveSuccess">
        <check-mark
            :size="60"
            border-width=".3rem"
        />

      <span class="feedback-text">
        Report {{ selectedOption === 'save' ? 'saved' : 'created' }} successfully!
      </span>
    </div>
  </div>
</div>
</template>

<script>
import CustomDropDown from "./CustomDropDown.vue";
import CustomCheckbox from "./CustomCheckbox.vue";
import {capitalizeFirstLetters} from "../helpers/helper_functions";
import CheckMark from "./CheckMark.vue";
import {useStore} from "../store/dataStore";
import _ from "lodash";

export default {
  name: "ReportSaver",
  setup() {
    let store = useStore();
    return {
      store
    }
  },
  props: {
    open: {
      type: Boolean
    },
    reportType: {
      type: String,
      required: true
    },
    reportData: {
      type: Object,
      required: true
    },
    pipeline: {
      type: String,
      required: true
    },
    location: {
      type: String,
      required: true
    }
  },
  components: {
    CheckMark,
      'dropdown': CustomDropDown,
      'checkbox': CustomCheckbox
  },
  emits: [
      'update:open',
      'save'
  ],
  watch: {
    open(state) {
       setTimeout(() => {
         this.visible = state
         if (state) {
           this.setDefaultState()
         }
       }, 0)

    }
  },
  mounted() {
    this.setDefaultState()
    document.addEventListener('click', this.detectOutsideClick)
  },
  unmounted() {
    document.removeEventListener('click', this.detectOutsideClick)
  },
  data() {
    return {
      visible: false,
      defaultSelection: 'Save As',
      makeDefault: false,
      selectedOption: 'saveAs',
      defaultReportName: '',
      activeTab: 1,
      state: {
        saving: false,
        saveError: false,
        saveSuccess: false,
        buttonText: 'Save Report',
        errorText: ''
      },
      saveOptions: [
        {
          label: 'Save',
          value: 'save'
        },
        {
          label: 'Save As',
          value: 'saveAs'
        }
      ],

      saveAsFields: [
        {
          fieldType: "input",
          type: "text",
          value: "",
          placeholder: "What do you want to name the report?",
          label: "Report name",
          hasError: false,
          key: "reportName",
          errorText: ''
        },
        {
          fieldType: "checkbox",
          type: "checkbox",
          value: false,
          placeholder: "",
          label: "Set as default report",
          hasError: false,
          key: "default",
          errorText: '',
          color: '#5094DD'
        }
      ],
      screenshotUrl: '',
      createdReport: null
    }
  },
  methods: {
    setDefault(state) {
        this.makeDefault = state
    },
    checkForExistingReport(event, field) {
      let value = event.target.value
      let savedReports = this.store.savedReports;

      let existingReport = savedReports.find(report => report['report_name'].toLowerCase() === value.toLowerCase());

      if (value && existingReport) {
        field.hasError = true;
        field.errorText = 'This report name is taken.'
      } else {
        if (field.hasError) {
          field.hasError = false;
          field.errorText= ''
        }
      }
    },
    setDefaultState() {
      this.visible = this.open;
      this.activeTab = 1;
      this.selectedOption = 'saveAs';
      this.makeDefault = false;


      let defaultName = `${ this.reportType } report`

      let savedReports =
          this.store.savedReports
              .filter(report => report['report_name'].toLowerCase().includes(defaultName))
              .filter(report => report['report_name'].toLowerCase().slice(0, report['report_name'].toLowerCase().lastIndexOf(defaultName)) === '')


      if (savedReports.length > 0) {
        let lastReportNumber = savedReports.length + 1
        let reportsWithNumbers = savedReports.filter(report => {
          let nameSplit = report['report_name'].split(" ");
          return !isNaN(nameSplit[nameSplit.length-1])
        });

        if (reportsWithNumbers.length > 0) {
          let lastReport = reportsWithNumbers.reverse()[0];
          let lastReportName = lastReport['report_name'].split(" ");

          lastReportNumber = parseInt(lastReportName[lastReportName.length-1]) + 1;
        }

        defaultName = `${defaultName} ${lastReportNumber}`
      }

      this.saveAsFields = this.saveAsFields.map(field => {
          if (field.fieldType === 'input') {
              field.value = ''
          }

          if (field.fieldType === 'checkbox') {
              field.value = false
          }

          field.hasError = false;
          field.errorText = '';

          return field
      })

      this.defaultReportName = this.capitalize(defaultName);
      this.focusOnField()

    },
    closeSaver() {
      this.visible = false;
      setTimeout(() => {
        this.activeTab = 1;
        this.selectedOption = 'saveAs';
        this.resetState()
        this.$emit('update:open', false)
      }, 200)
    },

    getSelectedValue(selectedOption) {
        this.selectedOption = selectedOption.value;
        let optionData = this.saveOptions.find(option => option.value === selectedOption.value);

        if (!optionData) {
          return
        }
        this.activeTab = this.saveOptions.indexOf(optionData);


       if (!this.state.saving) {
         this.state.buttonText = 'Save Report';
       }

      if (this.selectedOption === 'saveAs') {
        this.focusOnField()
      }

    },

    focusOnField() {
        setTimeout(() => {
          let inputField = this.$refs['input-field-0'];
          if (inputField) {
            inputField.focus()
          }
        }, 250)
    },
    capitalize(string) {
      return capitalizeFirstLetters(string)
    },

    setCheckValue(field, checked) {
      field.value = checked
    },

    hasErrors() {
      let disabled = false;
      let fieldWithErrors = this.saveAsFields.filter(field => field.fieldType === 'input' && (!field.value || field.hasError))

      if (this.selectedOption === 'saveAs' && fieldWithErrors.length > 0) {
        disabled = true
      }

      return disabled
    },

    returnError(message, updateRequest = false) {
      this.state = {
        ...this.state,
        saving: false,
        saveError: true,
        saveSuccess: false,
        buttonText: updateRequest ? 'Update Report' : 'Save Report',
        errorText: message
      }

      setTimeout(() => {
        this.state = {
          ...this.state,
          saveError: false,
          errorText: ''
        }
      }, 3000)
    },

    returnSuccess(savedReport, updateRequest = false) {
      this.state = {
        ...this.state,
        saveSuccess: true,
        buttonText: updateRequest ? 'Update Report' : 'Save Report',
      }

      this.$emit('save', savedReport)
      setTimeout(() => {
        this.closeSaver();
      }, 2000)
    },

    saveReport() {
      let format = _.cloneDeep(this.reportData);
      format['reportName'] = this.defaultReportName;
      format.default = this.makeDefault;

      this.sendSaveRequest(format)
    },

    createReport() {
      let format = _.cloneDeep(this.reportData);

      this.saveAsFields.forEach(field => {
        format[field.key] = field.value
      })

       this.sendSaveRequest(format)
    },

    sendSaveRequest(data) {
      this.addNewReport(data).then(report => {
            this.createdReport = null;
            this.returnSuccess(report);
          }).catch(error => {
            this.returnError(error.message)
          })
    },

    async addNewReport(data) {
        let report = await this.addReport(data);
        return await this.store.getSavedReport(report.location_id, report.id)
    },


    async addReport(data) {
      let report = this.createdReport;
      let requestData = _.cloneDeep(data);
      let customMetrics = _.cloneDeep(requestData.customMetrics);

      if (!report) {
          delete requestData.metricId;
          delete requestData.customMetrics;

          requestData.screenshot = await this.store.getDashboardImage();
          let reportRequest = await this.store.saveReportWithMetrics(this.location, this.pipeline, this.reportType, requestData);
          report = reportRequest.data.report;

          this.createdReport = report;
          this.store.addNewSavedReport(report);
      }

      if (customMetrics.length > 0) {
        report = await this.store.addCustomMetricsToReport(customMetrics, report)
      }

      return report
    },

    performAction() {
      this.state = {
        ...this.state,
        saving: true,
        saveError: false,
        saveSuccess: false,
        buttonText: "Saving..."
      }

      switch (this.selectedOption) {
        case "save":
          this.saveReport();
          break
        case "saveAs":
          this.createReport();
          break
      }
    },

    resetState() {
      this.state = {
        saving: false,
        saveError: false,
        saveSuccess: false,
        buttonText: 'Save Report',
        errorText: ''
      }

      this.saveAsFields = this.saveAsFields.map(field => {
        field.value = field.fieldType === 'checkbox' ? false : ''
        return field
      })
    },

    detectOutsideClick(event) {
      if (!event.target.closest('.report-save-modal .modal-content') && this.visible) {
        this.closeSaver()
      }
    }
  }
}
</script>

<style>
.option-select .dropdown-menu{
  max-width: 100%;
  min-width: 100%;
}
</style>

<style lang="scss" scoped>
@import "../assets/css/report-saver.scss";
</style>
