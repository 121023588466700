<template>
   <div class="step-modifier">
     <div class="step-header">
       <span class="step-name">{{ stepName }}</span>
       <span class="close-step" @click="closeModifier()">
        &times;
       </span>
     </div>
     <form method="post"  @submit.prevent="modifySteps()">
       <div class="modifier-container flexbox">
         <div :key="index" class="modifier-item" v-for="(modifier, index) in stepModifiers">

           <div class="checkbox-item" @click="modifier.modifierApplied = !modifier.modifierApplied">
             <checkbox :checked="modifier.modifierApplied" :color="modifier.checkboxColor"></checkbox>
           </div>
           <dropdown
               :default-value="modifier.defaultValue ? modifier.defaultValue : ''"
               :items="modifier.options"
               @click="detectClick(index)"
               :key="modifier.componentKey"
               @valueChange="setModifierValue(index, $event)"
           ></dropdown>
         </div>
         <!--        <multi-value-select-bar
                     v-for="(modifier, index) in stepModifiers"
                     :key="index"
                     :label="modifier.label"
                     :placeholder="modifier.placeholder"
                     :options="modifier.options"
                     @option-change="setSelectedOptions($event, index)"
                 />-->
       </div>

       <div class="button-container">
         <button class="button button-blue">Save</button>
       </div>
     </form>
   </div>


</template>

<script>
//import MultiValueSelectBar from "./MultiValueSelectBar";
import CustomDropDown from "./CustomDropDown";
import CustomCheckbox from "./CustomCheckbox";
import { generateKey } from "../helpers/helper_functions";

export default {
  name: "StepModifier",
  props: {
    defaultStatus: String,
    stepName: String
  },
  components: {
   // MultiValueSelectBar,
    'dropdown': CustomDropDown,
    'checkbox': CustomCheckbox
  },
  mounted() {
    if (this.defaultStatus) {
      this.stepModifiers = this.stepModifiers.map(modifier => {

        if (modifier.type === 'status') {
          let matchingOption = modifier.options.find(option => option.value === this.defaultStatus);

          if (matchingOption) {
            modifier.value = matchingOption.value;
            modifier.defaultValue = matchingOption.label
          }
        }

        return modifier
      })
    }
  },
  data() {
    return {
      stepModifiers: [
         {
           label: 'Status',
           placeholder: 'Select status(es)',
           modifierApplied: true,
           checkboxColor: '#5094DD',
           type: 'status',
           componentKey: generateKey(),
           options: [
              {
                label: 'Lost',
                value: 'lost'
              },
              {
                label: 'Abandoned',
                value: 'abandoned'
              },
              {
                label: 'Open',
                value: 'open'
              },
              {
                label: 'Won',
                value: 'won'
              }
            ],
           value: "lost",
           defaultValue: "Lost"
          },

        {
          label: 'Tag',
          placeholder: 'Select tag(s)',
          value: 'salesrep',
          defaultValue: "Salesrep",
          modifierApplied: true,
          checkboxColor: '#5094DD',
          componentKey: generateKey(),
          type: 'tag',
          options: [
            {
              label: "Salesrep",
              value: "salesrep"
            },
            {
              label: "Source",
              value: "source"
            },
            {
              label: "Tag",
              value: "tag"
            }
          ]
        }

      ]
     ,
    }
  },
  methods: {
    closeModifier() {
      this.$emit('close')
    },
    setModifierValue(index, data) {
      this.stepModifiers[index].value = data.value;
    },

    modifySteps() {
      let modifierValues = this.stepModifiers.map(step => ({ type: step.type, value: step.value, applied: step.modifierApplied }))

      this.$emit('modified', modifierValues)
    },

    detectClick(index) {
      this.stepModifiers = this.stepModifiers.map((modifier, modifierIndex) => {
        if (modifierIndex !== index) {
          modifier.componentKey = generateKey()
        }

        return modifier
      })
      console.log('Clicked')
    }
  }
}
</script>

<style lang="scss" scoped>
    .step-modifier{
     border-radius: 8px;
      border: 1px solid var(--theme-blue-alt);
      box-shadow: 0 0 2px 2px rgba(80, 148, 221, .3);
      max-width: 500px;
      background: var(--white);
      padding: 0 .8rem;

      .step-header{
        padding: .5rem;
        display: flex;
        align-items: center;

        .step-name{
          font-weight: 700;
          margin-top: .2rem;
        }

        .close-step{
          display: block;
          font-size: 24px;
          width: fit-content;
          width: -moz-fit-content;
          margin-left: auto;
          transition: ease-in-out .3s;

          &:hover{
            transform: scale(1.2);
            cursor: pointer;
          }
        }
      }

      /*form{
        padding: 1.5rem 0 0;
      }*/

      .modifier-container{
        padding-bottom: .6rem;
        border-bottom: 1px solid  rgba(80, 148, 221, .3);
        justify-content: space-between;

        .modifier-item{
          display: flex;
          align-items: center;
          width: calc(50% - 1rem);

          .checkbox-item{
            margin-right: .6rem;
          }
        }
      }

      .button-container{
        padding: 1rem 0 1.5rem;

        .button{
          margin-left: auto;
          padding: 5px 20px;
        }
      }
    }
</style>
